import * as OrdersPayloadType   from 'shared/types/OrdersPayload';
import Orders                   from 'Dispatcher/components/Orders';

export interface Props {
    max_ms : number;
}

export default class LateOrders extends Orders {
    // @ts-expect-error
    public  props      : Props;

    protected fetchRows( orderby:string, limit:number, skip:number ) {
        return this.getTablePromise<OrdersPayloadType.Dehydrated<number>>(
            '/api/dispatcher/orders/late',
            'GET',
            undefined,
            {orderby,limit,skip,max_ms:this.props.max_ms}
        ).then(OrdersPayloadType.hydrate);
    }
}

