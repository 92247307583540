import React              from 'react';
import {
    Route,
    NavLink   }           from "react-router-dom";

import * as images        from 'images/';
import Map                from 'Driver/Route';
import PluginTest         from 'Driver/PluginTest';
import Dvi                from 'Driver/Dvi';
import Manifest           from 'Driver/Manifest';
import Orders             from 'Driver/Orders';
import User               from 'Driver/components/User';
import Profile            from 'components/Profile';
import Role               from 'utils/Role';
import tectransit         from 'utils/TecTransit';
import App                from '../App';

export const Index = ( app:App ) : Role => {
    const roleRoot          = "/Driver";
    const menuLinkClassName = "menu__link";
    const items = [
        <div className="menu__list-title">Driver</div>,
        <div className="menu__item">
            <NavLink className={`${menuLinkClassName}`} onClick={()=>app.menuItemClick()} to={`${roleRoot}/Route`}>
                <images.DriverRoute/>
                <span className="menu__name">Route</span>
            </NavLink>
        </div>
    ];
    // My account only
    const addPluginTest = (tectransit.user.provider_id==='cf+driver@tectransit.com');
    if( addPluginTest ) {
        items.push(
            <div className="menu__item">
                <NavLink className={`${menuLinkClassName}`} onClick={()=>app.menuItemClick()} to={`${roleRoot}/PluginTest`}>
                    <images.DriverRoute/>
                    <span className="menu__name">Plugin Test</span>
                </NavLink>
            </div>
        );
    }
    if( tectransit.agency.on_demand_enabled ) {
        items.push(
            <div className="menu__item">
                <NavLink className={`${menuLinkClassName}`} onClick={()=>app.menuItemClick()} to={`${roleRoot}/OnDemand/Manifest/`}>
                    <images.DriverManifest/>
                    <span className="menu__name">Manifests</span>
                </NavLink>
            </div>
        );
    }
    if( tectransit.agency.fixed_route_enabled ) {
        items.push(
            <div className="menu__item">
                <NavLink className={`${menuLinkClassName}`} onClick={()=>app.menuItemClick()} to={`${roleRoot}/FixedRoute/Manifest/`}>
                    <images.DriverManifest/>
                    <span className="menu__name">Manifests</span>
                </NavLink>
            </div>
        );
    }
    const needPastOrders = tectransit.agency.on_demand_enabled;
    const needProfile    = !tectransit.user.roles.includes("Passenger");
    if( needPastOrders ) {
        items.push(
            <div className="menu__item">
                <NavLink className={`${menuLinkClassName}`} onClick={()=>app.menuItemClick()} to={`${roleRoot}/Orders`}>
                    <images.DriverPastOrders/>
                    <span className="menu__name">Past Orders</span>
                </NavLink>
            </div>
        );
    }
    if( needProfile ) {
        // If the user is only a driver and does not have a "Passenger" role
        // then the user needs this menu item to access his/her profile
        items.push(
            <div className="menu__item">
                <NavLink className={`${menuLinkClassName}`} onClick={()=>app.menuItemClick()} to={`${roleRoot}/Profile`}>
                    <images.Profile/>
                    <span className="menu__name">Profile</span>
                </NavLink>
            </div>
        );
    }
    const routes = [
        <Route
            key         ={"driver_route"}
            path        ={`${roleRoot}/Route`}
            element     ={<Map/>}
        />
    ];
    if( addPluginTest ) {
        routes.push(
            <Route
                key         ={"driver_plugin_test"}
                path        ={`${roleRoot}/PluginTest`}
                element     ={<PluginTest/>}
            />
        );
    }
    if( tectransit.agency.on_demand_enabled ) {
        routes.push(
            <Route
                key     ={"driver_ondemand_manifest"}
                path    ={`${roleRoot}/OnDemand/Manifest/:yyyymmdd?`}
                element ={<Manifest type="ondemand"/>}
            />
        );
    }
    if( tectransit.agency.fixed_route_enabled ) {
        routes.push(
            <Route
                key     ={"driver_fixedroute_manifest"}
                path    ={`${roleRoot}/FixedRoute/Manifest/:yyyymmdd?`}
                element ={<Manifest type="fixedroute"/>}
            />
        );
    }
    routes.push(
        <Route
            key={"driver_passenger"}
            path={`${roleRoot}/Passenger/:id`}
            element={<User/>} />,
        <Route
            key={"driver_dvi"}
            path={`${roleRoot}/dvi/:type?`}
            element={<Dvi/>} />,
        <Route
            key={"driver"}
            path={roleRoot}
            element={<Map />} />
    );
    if( needPastOrders ) {
        routes.push(
            <Route
                key={"driver_orders"}
                path={`${roleRoot}/Orders`}
                element={<Orders/>} />
        );
    }
    if( needProfile ) {
        routes.push(
            <Route
                key={"driver_profile"}
                path={`${roleRoot}/Profile`}
                element={<Profile/>} />
        );
    }
    return {
        name   : "Driver",
        root   : roleRoot,
        items,
        routes
    };
}

export default Index;
