export default function getApiPromise<T=Record<string,any>>(
    uri      : string,
    method   ="GET",
    body     = {} as Record<string,any>,
    params   = {} as Record<string,any>,
    timeout  = 60000
) : Promise<T & {err?:string}> {
    const abortController = new AbortController();
    const timeoutId       = setTimeout(()=>abortController.abort(),timeout);
    const urlParams       = (new URLSearchParams(params)).toString();
    const url  = uri+(urlParams.length ? ("?"+urlParams) : '');
    const init = {
        method  : method,
        headers : {'Content-Type' : 'application/json'},
        signal  : abortController.signal
    } as Record<string,any>;
    if( method!=="GET" )
        init.body = JSON.stringify(body);
    return fetch(url,init)
        .then( response => {
            if( response.status>=400 )
                return JSON.stringify({err:response.statusText});
            return response.text();
        })
        .then( text => {
            // If server didn't find something (e.g. a user) then it will return ''
            // JSON.parse('') throws an exception. Work it around
            const json = text.length ? JSON.parse(text) : undefined;
            if( json?.authUrl ) {
                window.location = json.authUrl;
                throw Error(`Access denied`);
            }
            return json;
        })
        .finally(() => {
            clearTimeout(timeoutId);
        });
}
