import React                        from 'react';
import { Link }                     from 'react-router-dom';
import { HashLink }                 from 'react-router-hash-link';
import * as DndType                 from 'react-beautiful-dnd';

import * as UserType                from 'shared/types/User';
import * as LegType                 from 'shared/types/Leg';
import * as OrderType               from 'shared/types/Order';
import * as RouteType               from 'shared/types/Route';
import * as AgencyType              from 'shared/types/Agency';
import * as WSPayloadType           from 'shared/types/WSPayload';
import dayjs                        from 'shared/utils/day-timezone';

import Alert                        from 'utils/Alert';
import tectransit                   from 'utils/TecTransit';
import getApiPromise                from 'utils/getApiPromise';
import * as MenuItem                from 'components/MenuItem';
import * as googleMapsLinks         from 'utils/googleMapsLinks';

export interface Props {
}
export default class Routes extends React.Component {

    private alert          : Alert = new Alert();

    // @ts-expect-error
    public props : Props;
    public state : {
        wsPayload?              : WSPayloadType.WSPayload.Hydrated;
        lastReassignResult?     : Record<string,any>;
    } = {
    };

    // private
    private getUserLink( user?:Partial<UserType.User> ) {
        if( !user )
            return 'n/a';
        const title = `Last user login: ${user.last_login ? dayjs(user.last_login).tz(tectransit.agency.time_zone).format(tectransit.timeFormat): 'n/a'}`;
        return <Link key={`user_${user?._id}`} title={title} to={`/Dispatcher/User/${user?._id}`}>{[user?.given_name,user?.family_name].filter(n=>!!n).join(" ")||'n/a'}</Link>;
    }
    private getOrder(
        style       : Record<string,any>,
        vehicle     : WSPayloadType.Vehicle.Hydrated,
        leg         : LegType.Leg,
        order       : OrderType.Order,
        event_name  : ('pickup'|'dropoff'),
        defaultEtaSeconds:number
    ) {
        const legEvent   = leg[event_name];
        const orderEvent = order[event_name];
        const getEta = () => {
            // If this is a regular order, it has an ETA
            const eta_ms = order._id ? orderEvent.eta : (legEvent.seconds||defaultEtaSeconds)*1000;
            return dayjs(eta_ms).tz(tectransit.agency.time_zone).format("HH:mm:ss");
        }
        const getOrderTitle = () => {
            return Object.entries({
                'Scheduler Id': (order.scheduler_id||'n/a'),
                'Created At':   `${dayjs(order.created_at).tz(tectransit.agency.time_zone).format(tectransit.timeFormat)} by #${order.creator_id||'n/a'}`,
                'Updated At':   `${dayjs(order.updated_at).tz(tectransit.agency.time_zone).format(tectransit.timeFormat)} by #${order.updater_id||'n/a'}`,
            }).map( ([k,v]) => `${k}: ${v}` ).join("\n");
        }
        const orderType = (order.type==='pickup_ordered_at') ? `pu @ ${dayjs(order.ordered_at).tz(tectransit.agency.time_zone).format("HH:mm")}` :
                          (order.type==='dropoff_ordered_at')? `do @ ${dayjs(order.ordered_at).tz(tectransit.agency.time_zone).format("HH:mm")}` :
                          (order.type||'');
        return (
            <table key={order._id||order.scheduler_id} style={{...style,borderRadius:'0.5rem',boxShadow:'0 4px 25px 0 rgb(0 0 0 / 10%)',padding:'0.5rem'}}>
                <tbody>
                    {(!!vehicle.unavailability_reason) && (<tr>
                        <td colSpan={2} style={{color:'red'}}>UNSCHEDULED</td>
                    </tr>)}
                    <tr>
                        <td>ID</td>
                        <td title={getOrderTitle()}>{order._id||'n/a'}</td>
                    </tr>
                    <tr>
                        <td>Type</td>
                        <td>{orderType}</td>
                    </tr>
                    <tr>
                        <td>Eta</td>
                        <td>{getEta()}</td>
                    </tr>
                    <tr>
                        <td>User</td>
                        <td>{this.getUserLink(order.user)}</td>
                    </tr>
                </tbody>
            </table>
        );
    }
    private getFixedRouteRoute( vehicle:WSPayloadType.Vehicle.Hydrated ) {
        // TODO:
        // if tectransit.agency.issue422_public_key is set then show the users on board of FR vehicle
        return (
            <td valign='top' style={{padding:0}}>
                <table width="100%">
                    <tbody>
                    {vehicle.route.legs.map(leg=>leg.steps).flat().filter(step=>!!step.stop).map((step,ndx)=>{
                        const stop = step.stop!;
                        return (
                            <tr key={stop._id}>
                                <td>{googleMapsLinks.getPlace(stop,stop.name)}</td>
                                <td>{stop.stop_time.arrival_time}</td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            </td>
        )
    }
    private getOnDemandRoute( vehicle:WSPayloadType.Vehicle.Hydrated ) {
        const getOnDemandLeg = ( pickupSeconds:number, leg:LegType.Leg, ndx:number ) => {
            const dropoffSeconds = pickupSeconds+(leg.duration?.value||0);
            const pickupOrders   = leg.pickup.orders  || [];
            const dropoffOrders  = leg.dropoff.orders || [];
            return (<table key={ndx}>
                <tbody>
                    {((pickupOrders.length>0) || (ndx===0)) ? (<tr>
                        <td valign="top">
                            at {dayjs(pickupSeconds*1000).tz(tectransit.agency.time_zone).format("HH:mm:ss")}
                        </td>
                        <td>
                            {googleMapsLinks.getPlace(
                                leg.steps[0]?.start_location||leg.pickup.latlng,
                                pickupOrders.find(o=>o.pickup.address)?.pickup.address||leg.pickup.address
                            )}
                        </td>
                    </tr>) : false}
                    {(pickupOrders.length>0) ? (<tr>
                        <td valign="top">
                            Pickup
                        </td>
                        <td>
                            {pickupOrders.map( (o,ndx) => {
                                return (
                                    <DndType.Draggable key={ndx} draggableId={String(o._id)} index={ndx}>
                                        {(provided) => {
                                            return (<span ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                {this.getOrder(
                                                    {background:'#FFFFFF'},
                                                    vehicle,
                                                    leg,
                                                    o,
                                                    'pickup',
                                                    pickupSeconds
                                                )}
                                            </span>);
                                        }}
                                    </DndType.Draggable>
                                );
                            })}
                        </td>
                    </tr>) : false}
                    {(leg.distance?.value || leg.duration?.value) ? (
                        <tr>
                            <td valign="top">Drive</td>
                            <td>{leg.distance?.text} for {leg.duration?.text}</td>
                        </tr>
                    ) : false}
                    {(dropoffOrders.length>0) ? (<tr>
                        <td valign="top">
                            at {dayjs(dropoffSeconds*1000).tz(tectransit.agency.time_zone).format("HH:mm:ss")}
                        </td>
                        <td>
                            {googleMapsLinks.getPlace(
                                leg.steps[leg.steps.length-1]?.end_location||leg.dropoff.latlng,
                                dropoffOrders.find(o=>o.dropoff.address)?.dropoff.address||leg.dropoff.address
                            )}
                        </td>
                    </tr>) : false}
                    {(dropoffOrders.length>0) ? (<tr>
                        <td valign="top">
                            Dropoff
                        </td>
                        <td>
                            {dropoffOrders.map((o,ndx)=>(<span key={ndx}>{this.getOrder(
                                {background:'#dcdcdc'},
                                vehicle,
                                leg,
                                o,
                                'dropoff',
                                dropoffSeconds
                            )}</span>))}
                        </td>
                    </tr>) : false}
                </tbody>
            </table>);
        }
        const droppableId = String(vehicle._id);
        return (
            <DndType.Droppable key={droppableId} droppableId={droppableId} isDropDisabled={!!vehicle.unavailability_reason}>
                {(provided) => {
                    let seconds = this.state.wsPayload!.seconds;
                    return (<td key={vehicle._id} valign='top' style={{padding:0}} {...provided.droppableProps} ref={provided.innerRef}>
                        {vehicle.route.legs.map((leg,ndx) => {
                            const result = getOnDemandLeg(seconds,leg,ndx);
                            seconds += leg.duration?.value||0;
                            return result;
                        })}
                        {provided.placeholder}
                    </td>);
                }}
            </DndType.Droppable>
        );
    }
    private getWSPayload() : Promise<any> {
        return getApiPromise<WSPayloadType.WSPayload.Dehydrated>('/api/dispatcher/wsPayload','GET')
            .then( wsPayload => {
                if( wsPayload.err )
                    throw Error(wsPayload.err);
                this.setState({
                    wsPayload : WSPayloadType.WSPayload.hydrate(wsPayload)
                });
            })
            .catch( err => {
                this.alert.set(`Cannot get wsPayload (${err.message})`);
            });
    }
    private reassignOrder( order_id:number, from_vehicle_id:number, to_vehicle_id:number, acceptable_delay:number ) {
        getApiPromise('/api/dispatcher/order/reassign','POST',{order_id,from_vehicle_id,to_vehicle_id,acceptable_delay})
            .then( res => {
                this.setState({
                    lastReassignResult : res
                });
                if( res.err )
                    throw Error(res.err);
            })
            .catch( err => {
                this.alert.set(`Cannot re-assign order (${err.message})`)
            })
            .finally(() => {
                this.getWSPayload();
            });
    }
    // public
    constructor( props:Props ) {
        super(props);
        this.getWSPayload();
    }
    render() {
        return MenuItem.withMenuItem("Routes",( alert ) => {
            this.alert = alert;
            if( !this.state.wsPayload )
                return (<p>Loading...</p>);
            const vehicles = Object.values(this.state.wsPayload.vehiclesById).sort((v1,v2)=>(v1._id-v2._id));
            const onDragEnd = ( dragUpdate:DndType.DragUpdate ) => {
                if( !dragUpdate?.source ) {
                    alert.set(`DnD source is empty`);
                    return;
                }
                if( !dragUpdate?.destination ) {
                    alert.set(`DnD destination is empty`);
                    return;
                }
                if( dragUpdate.source.droppableId===dragUpdate.destination.droppableId ) {
                    alert.set(`You should choose a different vehicle to reassign the order to`);
                    return;
                }
                this.reassignOrder(
                    Number(dragUpdate.draggableId),
                    Number(dragUpdate.source.droppableId),
                    Number(dragUpdate.destination.droppableId),
                    tectransit.agency.max_lateness_seconds
                );
            };
            const getReassingResult = ( result:Record<string,any> ) => {
                const getDelayDescription = () => {
                    const secondsToText = ( seconds:number ) => {
                        const duration = dayjs.duration(seconds,'seconds');
                        return `${Math.floor(duration.asHours())}h ${duration.minutes()}m`;
                    }
                    const tryAgain = (e:React.MouseEvent<HTMLButtonElement>) => {
                        e.preventDefault();
                        e.stopPropagation();
                        return this.reassignOrder(
                            result.order_ids[0],
                            Number(result.from_vehicle_id),
                            Number(result.to_vehicle_id),
                            Number(result.best_delay)
                        );
                    };
                    const dismissMessage = (e:React.MouseEvent<HTMLButtonElement>) => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.alert.set('');
                        this.setState({
                            lastReassignResult : undefined
                        });
                    };
                    return (<tr key={'reassignResult'}>
                        <td colSpan={1+vehicles.length}>
                            We tried {result.routes_count} different routes to re-assign
                            order <strong title={result.scheduler_id}>{result.order_id||'n/a'}</strong> from vehicle <strong>{result.from_vehicle_id}</strong>&nbsp;
                            to <strong>{result.to_vehicle_id}</strong>. The best total delay
                            was <strong>{secondsToText(result.best_delay)}</strong> and consisted of following
                            <ol>
                            {result.best_latenesses.map( (l:Record<string,any>,ndx:number) => {
                                const duration = dayjs.duration(l.delay,'seconds');
                                if( duration.asMinutes()<1 )
                                    return false; // hide small delays
                                return (<li key={ndx}>
                                    Will be late by <strong>${Math.floor(duration.asHours())}h ${duration.minutes()}m</strong> at leg <strong>#{l.leg_ndx}</strong>
                                    {l.leg_event_name} of order(s) [{l.leg_event_order_ids}].
                                </li>);
                            })}
                            </ol>
                            Please <button onClick={tryAgain}>click here</button> if you would like to try again with the same <strong>{secondsToText(result.best_delay)}</strong> delay or smaller.
                            Or you can <button onClick={dismissMessage}>dismiss this message</button> without making any changes.
                        </td>
                    </tr>);
                };
                const getDMInfoDescription = () => {
                    const getEtcDescription = () => {
                        if( !Array.isArray(result.dm_info.dms_ids) )
                            return <span></span>; // there is even no DM
                        if( result.dm_info.dm_ids.length===0 )
                            return (<span>It does not belong to any driver manifest, nobody needs to be notified.</span>);
                        if( result.dm_info.dm_ids.length>1 )
                            return (<span>It was found in {result.dm_info.dm_ids.length} DMs. This probably indicates an error and <strong title={result.dm_info.dm_ids}>the DMs</strong> need to be looked at.</span>);
                        if( result.dm_info.vehicle_id===result.to_vehicle_id )
                            return (<span>It came from a <strong title={result.dm_info.dm_ids[0]}>DM</strong> for vehicle <strong>#{result.dm_info.vehicle_id}</strong> and is now being returned to it</span>);
                        if( isNaN(result.dm_info.committed_driver_id!) )
                            return (<span>
                                It came from a <strong title={result.dm_info.dm_ids[0]}>DM</strong> for vehicle <strong>#{result.dm_info.vehicle_id}</strong>&nbsp;
                                This DM is no longer valid but nobody has committed to this DM, so this is OK.
                            </span>);
                        const committedDriver = this.state.wsPayload?.usersById[result.dm_info.committed_driver_id];
                        return (<span>
                            It came from a <strong title={result.dm_info.dm_ids[0]}>DM</strong> for vehicle <strong>#{result.dm_info.vehicle_id}</strong>.&nbsp;
                            This DM is no longer valid and the driver {committedDriver?this.getUserLink(committedDriver):(<strong>#{result.dm_info.committed_driver_id}</strong>)}&nbsp;
                            who committed to <strong title={result.dm_info.dm_ids[0]}>this DM</strong> on
                            <strong>{dayjs(result.dm_info.committed_at).tz(tectransit.agency.time_zone).format(tectransit.timeFormat)}</strong>&nbsp;
                            needs to be notified.
                        </span>);
                    };
                    return (<tr>
                        <td colSpan={1+vehicles.length}>
                            <span>Order <strong title={result.scheduler_id}>{result.order_id||'n/a'}</strong> was moved from vehicle <strong>#{result.from_vehicle_id}</strong> to vehicle <strong>#{result.to_vehicle_id}</strong>.</span>
                            &nbsp;
                            {getEtcDescription()}
                        </td>
                    </tr>);
                }
                return [
                    (result.err && result.best_delay) ? getDelayDescription() : false,
                    result.dm_info    ? getDMInfoDescription() : false
                ];
            }
            return (<div className="wrapper">
                <DndType.DragDropContext onDragEnd={onDragEnd}>
                    <table width="100%">
                        <thead>
                            {this.state.lastReassignResult && getReassingResult(this.state.lastReassignResult)}
                            <tr>
                                <th>Fleet #</th>
                                {vehicles.map((v,ndx)=>{
                                    const driver    = this.state.wsPayload!.usersById[AgencyType.getDriverIdsByVehicleId(tectransit.agency,v._id)[0]||-1];
                                    const frWording = v.fixed_route_name ? `, fixed route is ${v.fixed_route_name}` : '';
                                    const title     = `${v.is_online?'On':'Off'}line since ${dayjs(v.is_online_changed_at).tz(tectransit.agency.time_zone).format(tectransit.timeFormat)}, assigned driver is ${driver?.name||'n/a'}${frWording}, license plate is ${v.license_plate}`;
                                    const vidSpan   = <span style={{color:(v.is_online?'blue':'red')}}>{v._id}</span>;
                                    const vid       = tectransit.user.roles.includes("Manager")
                                        ? <HashLink smooth to={`/Manager/Config/Vehicles#vehicle_${v._id}_fleet__`}>{vidSpan}</HashLink>
                                        : vidSpan;
                                    return (
                                        <th key={ndx} title={title}>{vid}</th>
                                    );
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Driver</td>
                                {vehicles.map((v,ndx)=>{
                                    return (
                                        <td key={ndx}>
                                            {this.getUserLink(this.state.wsPayload!.usersById[v.is_online ? (v.reported_driverid??-1) : -1])}
                                        </td>
                                    );
                                })}
                            </tr>
                            <tr>
                                <td>Availability</td>
                                {vehicles.map((v,ndx)=>{
                                    const color = v.unavailability_reason ? '#ff0000' : '#00ff00';
                                    return (<td key={ndx}><span style={{color}}>{v.unavailability_reason||'ok'}</span></td>);
                                })}
                            </tr>
                            <tr>
                                <td>Route</td>
                                {vehicles.map((v,ndx)=>{
                                    return (<td key={ndx} title={`Set at ${dayjs((v.route_set_seconds||0)*1000).tz(tectransit.agency.time_zone).format('MM/DD/YYYY HH:mm:ss')}`}>
                                        {(RouteType.getMeters(v.route)<tectransit.agency.gps_accuracy_meters) ? 'n/a' : (v.fixed_route_trip?.name||v.route.summary||'on demand')}
                                    </td>);
                                })}
                            </tr>
                            <tr>
                                <td valign='top'>Legs</td>
                                {vehicles.map((vehicle,vndx)=>{
                                    return (
                                        <React.Fragment key={vehicle._id}>
                                            {(vehicle.fixed_route_name || !tectransit.agency.on_demand_enabled) ? this.getFixedRouteRoute(vehicle) : this.getOnDemandRoute(vehicle)}
                                        </React.Fragment>
                                    );
                                })}
                            </tr>
                        </tbody>
                    </table>
                </DndType.DragDropContext>
            </div>);
        });
    }
}
