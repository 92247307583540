import * as LatLngType       from 'shared/types/LatLng';
import * as AgencyType       from 'shared/types/Agency';
import getApiPromise         from 'utils/getApiPromise';

export abstract class Geocoder {

    private byLatLng                    : Record<string,google.maps.GeocoderResult> = {};
    private byPlaceId                   : Record<string,google.maps.GeocoderResult> = {};
    public  readonly latLngPrecision    : number = 100;

    protected abstract getGeocoderPromise(latLng?:google.maps.LatLngLiteral,placeId?:string) : Promise<google.maps.GeocoderResponse>;

    private responseToResult = ( response:google.maps.GeocoderResponse ) : google.maps.GeocoderResult => {
        const result = response.results[0];
        if( result.geometry.location ) {
            if( !(result.geometry.location instanceof google.maps.LatLng) )
                result.geometry.location = new google.maps.LatLng(result.geometry.location);
            const latLngKey = LatLngType.toString(LatLngType.round(result.geometry.location.toJSON(),this.latLngPrecision));
            this.byLatLng[latLngKey] = result;
        }
        if( result.place_id )
            this.byPlaceId[result.place_id] = result;
        return result;
    }
    getLatlngLookupPromise( latlng:(google.maps.LatLngLiteral|google.maps.LatLng) ) : Promise<google.maps.GeocoderResult> {
        latlng = LatLngType.round((latlng instanceof google.maps.LatLng) ? latlng.toJSON() : latlng,this.latLngPrecision);
        const latLngKey = LatLngType.toString(latlng);
        if( latLngKey in this.byLatLng ) {
            console.log(`Found '${latLngKey}' in geocoder_latlng_lookup_cache`);
            return Promise.resolve(this.byLatLng[latLngKey]);
        }
        return this.getGeocoderPromise(latlng,undefined).then(this.responseToResult);
    }
    getPlaceidLookupPromise( placeId:string ) : Promise<google.maps.GeocoderResult> {
        if( placeId in this.byPlaceId ) {
            console.log(`Found '${placeId}' in geocoder_placeid_lookup_cache`);
            return Promise.resolve((this.byPlaceId[placeId]));
        }
        return this.getGeocoderPromise(undefined,placeId).then(this.responseToResult);
    }
}

export class GoogleGeocoder extends Geocoder {
    private geocoder : google.maps.Geocoder;
    private bounds   : google.maps.LatLngBounds;
    protected getGeocoderPromise(latLng?:google.maps.LatLngLiteral,placeId?:string) : Promise<google.maps.GeocoderResponse> {
        const params = {
            ...(latLng  ? {location:latLng} : {}),
            ...(placeId ? {placeId} : {}),
            bounds : this.bounds
        };
        return this.geocoder.geocode(params);
    }
    constructor( bounds:AgencyType.Bounds ) {
        super();
        this.geocoder   = new google.maps.Geocoder();
        this.bounds     = new google.maps.LatLngBounds(bounds.southwest,bounds.northeast);
    }
}

export class ApiGeocoder extends Geocoder {
    private apiUri : string;
    protected getGeocoderPromise(latLng?:google.maps.LatLngLiteral,placeId?:string) : Promise<google.maps.GeocoderResponse> {
        const params = {
            ...(latLng  ? {lat:latLng.lat,lng:latLng.lng} : {}),
            ...(placeId ? {placeId} : {})
        };
        return getApiPromise<google.maps.GeocoderResponse>(this.apiUri,'GET',undefined,params);
    }
    constructor( apiUri:string ) {
        super();
        this.apiUri = apiUri;
    }
}

export default Geocoder;