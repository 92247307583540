const safeAtan = ( lat:number, lng:number ) : (number|undefined) => {
    // if we can use the division method then use it. Unless the delta is too small
    if( Math.abs(lat)>0.00001 )
        return Math.atan(lng/lat)+((lat>0)?0:Math.PI);
    // latitude hasn't changed. The vehicle is going either directly north or directly south
    if( lng )
        return (lng>0) ? Math.PI/2 : -Math.PI/2;
    // this is "0/0" situation
    return undefined;
};

export default safeAtan;