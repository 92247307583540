import { USER__UPDATE } from "../actionTypes";

export default function user( state, action ) {
    const get_new_state = () => {
        switch( action.type ) {
        case USER__UPDATE:
            return action.payload;
        default:
            return {};
        }
    };
    return {
        ...state,
        ...get_new_state()
    };
}
