export interface Duration {
    value : number;
    text  : string;
}

export const fromSeconds = ( seconds:number ) : Duration => {
    const mins = Math.round(seconds/60);
    return {
        value : Math.floor(seconds),
        text  : ((mins>0) ? `${mins} min${mins>1?'s':''}` : `${Math.round(seconds)}s`)
    };
}

export const secondsToHMS = ( seconds_:number, rounder?:number ) : string => {
    if( seconds_<0 )
        return `-${secondsToHMS(-seconds_,rounder)}`;
    if( rounder )
        seconds_ = Math.round(seconds_/rounder)*rounder;
    // Is there a way to do this with dayjs?
    seconds_ = Math.round(seconds_);
    const seconds  = seconds_%60;
    const minutes_ = (seconds_-seconds)/60;
    const minutes  = minutes_%60;
    const hours    = (minutes_-minutes)/60;
    const parts    = [] as string[];
    if( hours>0 )
        parts.push(`${hours}h`);
    if( minutes>0 )
        parts.push(`${minutes}m`);
    if( seconds>0 )
        parts.push(`${seconds}s`);
    if( parts.length<1 )
        return `0m`;
    return parts.join(' ');
}

export default Duration;
