import React                from 'react';

import consts                   from 'shared/consts';
import * as OrderType           from 'shared/types/Order';
import * as OrdersPayloadType   from 'shared/types/OrdersPayload';
import dayjs                    from 'shared/utils/day-timezone';

import getApiPromise            from 'utils/getApiPromise';
import * as googleMapsLinks     from 'utils/googleMapsLinks';
import InfiniteTable            from 'components/InfiniteTable';

export default class Orders extends InfiniteTable<OrderType.Hydrated<OrderType.Order<number>>> {
    fetchRows( orderby:string, limit:number, skip:number ) {
        return this.getTablePromise<OrdersPayloadType.Dehydrated<number>>('/api/passenger/orders','GET',undefined,{orderby,limit,skip})
            .then( payload => {
                return OrdersPayloadType.hydrate(payload);
            });
    }
    cancelOrder( e:any, order_id:number ) {
        e.preventDefault();
        e.stopPropagation();
        return getApiPromise('/api/passenger/order','DELETE',{order_id})
            .then( res => {
                if( !res || res.err )
                    throw Error(res?.err||`empty response`);
                this.fetchPage(1,[]);
            })
            .catch( err => {
                this.alert.set(`Cannot delete order (${err.message})`);
            });
    }
    renderThead() {
        return (
            <tr>
                <th className={"align-top"} onClick={()=>this.sort('_id')}>{this.getSortingIcon('_id')}#</th>
                <th className={"align-top"} onClick={()=>this.sort('created_at')}>
                    {this.getSortingIcon('created_at')}
                    Created At
                </th>
                <th className={"align-top"} onClick={()=>this.sort('pickup.address')}>
                    {this.getSortingIcon('pickup.address')}
                    Pickup
                </th>
                <th className={"align-top"} onClick={()=>this.sort('dropoff.address')}>
                    {this.getSortingIcon('dropoff.address')}
                    Dropoff
                </th>
                <th className={"align-top"} onClick={()=>this.sort('type')}>
                    {this.getSortingIcon('type')}
                    Type
                </th>
                <th className={'align-top'}>
                    SAs
                </th>
                <th className={"align-top"}>ETAs</th>
                <th className={"align-top"} onClick={()=>this.sort('pickup.at')}>{this.getSortingIcon('pickup.at')}Pickup At</th>
                <th className={"align-top"} onClick={()=>this.sort('dropoff.at')}>{this.getSortingIcon('dropoff.at')}Dropoff At</th>
                <th className={"align-top"} onClick={()=>this.sort('meters')}>{this.getSortingIcon('meters')}Miles</th>
                <th className={"align-top"} onClick={()=>this.sort('status')}>{this.getSortingIcon('status')}Status</th>
            </tr>
        );
    }
    renderRow( o:OrderType.Order ) {
        return (
            <tr key={o._id}>
                <td>{o._id}</td>
                <td>
                    <div className="order-time">
                        {dayjs(o.created_at).format("MM/DD/YYYY HH:mm")}
                    </div>
                </td>
                <td>{googleMapsLinks.getPlace(o.pickup.location,o.pickup.address)}</td>
                <td>{googleMapsLinks.getPlace(o.dropoff.location,o.dropoff.address)}</td>
                <td>{(o.type==='pickup_ordered_at') ? `pu @ ${dayjs(o.ordered_at).format("MM/DD/YYYY HH:mm")}` :
                     (o.type==='dropoff_ordered_at')? `do @ ${dayjs(o.ordered_at).format("MM/DD/YYYY HH:mm")}` :
                     (o.type||'')}
                </td>
                <td>
                    {Object.entries(o.special_accommodations||{}).filter(([item,value]) => {
                        return !!value;
                    }).map( ([item,value])=> {
                        return (<div>{item}</div>);
                    })}
                </td>
                <td>
                    <div>pu @ {dayjs(o.pickup.eta).format("MM/DD/YYYY HH:mm")}</div>
                    <div>do @ {dayjs(o.dropoff.eta).format("MM/DD/YYYY HH:mm")}</div>
                </td>
                <td>{o.pickup.at ? dayjs(o.pickup.at).format("MM/DD/YYYY HH:mm") : 'n/a'}</td>
                <td>{o.dropoff.at ? dayjs(o.dropoff.at).format("MM/DD/YYYY HH:mm") : 'n/a'}</td>
                <td>{o.meters ? Math.round((100*o.meters)/consts.meters_in_mile)/100 : 'n/a'}</td>
                <td>
                    <span className={o.status}>
                        {['ordered','scheduled'].includes(o.status)?<button type="button" className="btn-theme" onClick={e=>this.cancelOrder(e,o._id!)}>Cancel</button>:o.status}
                    </span>
                </td>
            </tr>
        );
    }
}

