const miles_in_degree      = 69.169444444444444;
const meters_in_mile       = 1609.34;
const meters_in_degree     = (miles_in_degree * meters_in_mile);
const feet_in_meter        = 3.281;
const feet_in_mile         = 5280;
const max_vehicle_delay    = 15*60*1000;
const degrees_in_radian    = 180/Math.PI;

const consts = {
    miles_in_degree,
    meters_in_mile,
    meters_in_degree,
    feet_in_meter,
    feet_in_mile,
    max_vehicle_delay,
    degrees_in_radian
};

export default consts;
