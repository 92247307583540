import React              from 'react';
import {
    Link }                from "react-router-dom";

import * as AgencyType    from 'shared/types/Agency';
import * as ManifestType  from 'shared/types/Manifest';
import * as UserType      from 'shared/types/User';
import * as VehicleType   from 'shared/types/Vehicle';
import * as OrderType     from 'shared/types/Order'
import dayjs              from 'shared/utils/day-timezone';

import tectransit         from 'utils/TecTransit';

export interface VehicleManifestsProps {
    vehicleManifests    : ManifestType.VehicleManifest.Hydrated<OrderType.Order<string>>[];
    driversById         : Record<number,UserType.User>;
    vehiclesById        : Record<number,VehicleType.Vehicle>;
    // Required formatters
    getStops            : ( ndx:number ) => React.ReactNode;
    getDistance         : ( ndx:number ) => React.ReactNode;
    getStatus           : ( ndx:number ) => React.ReactNode;
    getSteps            : ( ndx:number ) => React.ReactNode;
    // Optional formatters
    getFleetNumber?     : ( ndx:number ) => React.ReactNode;
    getDepot?           : ( ndx:number ) => React.ReactNode;
    getDriver?          : ( ndx:number ) => React.ReactNode;
    getRequestedAt?     : ( ndx:number ) => React.ReactNode;
    getCommittedBy?     : ( ndx:number ) => React.ReactNode;
    getCommittedAt?     : ( ndx:number ) => React.ReactNode;
}
export const VehicleManifests : React.FC<VehicleManifestsProps> = props => {
    const getDriverLink = ( driver_id:(number|undefined), ndx:number=1 ) => {
        return Number.isInteger(driver_id) && (<Link key={ndx} to={`/Dispatcher/User/${driver_id}`}>{props.driversById[driver_id!]?.name||'n/a'}</Link>);
    };
    const vehicleManifests = props.vehicleManifests;
    const getFleetNumber = props.getFleetNumber || ((ndx:number) => {
        const vm = vehicleManifests[ndx];
        return <strong>{vm.vehicle_id}</strong>
    });
    const getDepot = props.getDepot || ((ndx:number) => {
        const vm = vehicleManifests[ndx];
        return <strong>{props.vehiclesById[vm.vehicle_id]?.depot_name||'??'}</strong>
    });
    const getDriver = props.getDriver || ((ndx:number) => {
        const vm = vehicleManifests[ndx];
        return <strong>{AgencyType.getDriverIdsByVehicleId(tectransit.agency,vm.vehicle_id).map(getDriverLink)}</strong>
    });
    const getRequestedAt = props.getRequestedAt || ((ndx:number) => {
        const vm = vehicleManifests[ndx];
        return <strong>{vm.requested_at ? dayjs(vm.requested_at).tz(tectransit.agency.time_zone).format(tectransit.timeFormat) : '-'}</strong>
    });
    const getCommittedBy = props.getCommittedBy || ((ndx:number) => {
        const vm = vehicleManifests[ndx];
        return <strong>{vm.committed_driver_id ? getDriverLink(vm.committed_driver_id) : '-'}</strong>;
    });
    const getCommittedAt = props.getCommittedAt || ((ndx:number) => {
        const vm = vehicleManifests[ndx];
        return <strong>{vm.committed_at ? dayjs(vm.committed_at).tz(tectransit.agency.time_zone).format(tectransit.timeFormat) : '-'}</strong>;
    });
    const getStops = props.getStops || ((ndx:number) => {
        return <strong>n/a</strong>;
    });
    const getDistance = props.getDistance || ((ndx:number) => {
        const vm = vehicleManifests[ndx];
        return <strong>{vm.distance}</strong>;
    });
    const getStatus = props.getStatus || ((ndx:number) => {
        return <strong>n/a</strong>;
    });
    const getSteps = props.getSteps || ((ndx:number) => {
        const vm = vehicleManifests[ndx];
        return <strong>{vm.steps.length}</strong>;
    });
    return (
        <table style={{/*'border':'1px solid black',*/'width':'100%'}}>
            {(props.vehicleManifests.length>0) ? (
                <tbody style={{verticalAlign:"top",textAlign:'left'}}>
                    <tr>
                        <td>Fleet #</td>
                        {vehicleManifests.map((vm,ndx)=>(<td key={ndx}>{getFleetNumber(ndx)}</td>))}
                    </tr>
                    <tr>
                        <td>Depot</td>
                        {vehicleManifests.map((vm,ndx)=>(<td key={ndx}>{getDepot(ndx)}</td>))}
                    </tr>
                    <tr>
                        <td>Driver</td>
                        {vehicleManifests.map((vm,ndx)=>(<td key={ndx}>{getDriver(ndx)}</td>))}
                    </tr>
                    <tr>
                        <td>Stops</td>
                        {vehicleManifests.map((vm,ndx)=>(<td key={ndx}>{getStops(ndx)}</td>))}
                    </tr>
                    <tr>
                        <td>Requested At</td>
                        {vehicleManifests.map((vm,ndx)=>(<td key={ndx}>{getRequestedAt(ndx)}</td>))}
                    </tr>
                    <tr>
                        <td>Committed By</td>
                        {vehicleManifests.map((vm,ndx)=>(<td key={ndx}>{getCommittedBy(ndx)}</td>))}
                    </tr>
                    <tr>
                        <td>Committed At</td>
                        {vehicleManifests.map((vm,ndx)=>(<td key={ndx}>{getCommittedAt(ndx)}</td>))}
                    </tr>
                    <tr>
                        <td>Distance</td>
                        {vehicleManifests.map((vm,ndx)=>(<td key={ndx}>{getDistance(ndx)}</td>))}
                    </tr>
                    <tr>
                        <td>Status</td>
                        {vehicleManifests.map((vm,ndx)=>(<td key={ndx}>{getStatus(ndx)}</td>))}
                    </tr>
                    <tr style={{verticalAlign:"top"}}>
                        <td>Steps</td>
                        {vehicleManifests.map((vm,ndx)=>(<td key={ndx}>{getSteps(ndx)}</td>))}
                    </tr>
                    <tr>
                        <th align="center" colSpan={vehicleManifests.length+1}>
                            {vehicleManifests.length} Manifests
                        </th>
                    </tr>
                </tbody>
            ) : (
                <tbody style={{verticalAlign:"top",textAlign:'center'}}>
                    <tr>
                        <td style={{padding:'20px'}}>
                            no manifests available
                        </td>
                    </tr>
                </tbody>
            )}
        </table>
    );
}

export default VehicleManifests;