import React             from 'react';
import {
    Route,
    NavLink }            from "react-router-dom";

import * as images       from 'images';
import tectransit        from 'utils/TecTransit';
import Role              from 'utils/Role';
import Profile           from 'components/Profile';
import Map               from 'Passenger/Map';
import AssignedOrders    from 'Passenger/Orders';
import StandingOrders    from 'Passenger/StandingOrders';
import App               from '../App';

export const Index = ( app:App ) : Role => {
    const roleRoot          = "/Passenger";
    const menuLinkClassName = "menu__link";
    const menuItems         = [
        <div className="profile">
            <img className="profile__image" id="profile__image" alt="icon" height={31} width={31} src={tectransit.user.picture||''}/>
            <span className="profile__username">{tectransit.user.name||'no name'}</span>
        </div>
    ];
    if( tectransit.agency.on_demand_enabled )
        menuItems.push(
            <div className="menu__item">
                <NavLink className={`${menuLinkClassName}`} onClick={()=>app.menuItemClick()} to={`${roleRoot}/Map`}>
                    <images.PassengerNewOrder/>
                    <span className="menu__name">New Order</span>
                </NavLink>
            </div>,
            <div className="menu__item">
                <NavLink className={`${menuLinkClassName}`} onClick={()=>app.menuItemClick()} to={`${roleRoot}/Orders`}>
                    <images.PassengerPastOrders/>
                    <span className="menu__name">Past Orders</span>
                </NavLink>
            </div>,
            <div className="menu__item">
                <NavLink className={`${menuLinkClassName}`} onClick={()=>app.menuItemClick()} to={`${roleRoot}/Scheduled`}>
                    <images.PassengerScheduledOrders/>
                    <span className="menu__name">Scheduled Orders</span>
                </NavLink>
            </div>
        );
    if( tectransit.user.roles.indexOf("Passenger")===0 )
        menuItems.push(
            <div className="menu__item">
                <NavLink className={`${menuLinkClassName}`} onClick={()=>app.menuItemClick()} to={`${roleRoot}/Profile`}>
                    <images.Profile/>
                    <span className="menu__name">Profile</span>
                </NavLink>
            </div>
        );
    return {
        name   : "Passenger",
        root   : roleRoot,
        items  : menuItems,
        routes : [
            <Route
                key={"passenger_profile"}
                path={`${roleRoot}/Profile`}
                element={<Profile/>} />,
            <Route
                key={"passenger_orders"}
                path={`${roleRoot}/Orders`}
                element={<AssignedOrders/>} />,
            <Route
                key={"passenger_orders_scheduled"}
                path={`${roleRoot}/Scheduled`}
                element={<StandingOrders/>} />,
            <Route
                key={"passenger_map"}
                path={`${roleRoot}/Map`}
                element={<Map/>} />,
            <Route
                key={"passenger"}
                path={roleRoot}
                element={<Map/>} />
        ]
    };
}

export default Index;
