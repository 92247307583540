import React                    from 'react';

import * as LatLngType          from 'shared/types/LatLng';
import * as UserType            from 'shared/types/User';
import * as OrderType           from 'shared/types/Order';
import * as AgencyType          from 'shared/types/Agency';
import dayjs                    from 'shared/utils/day-timezone';

import tectransit               from 'utils/TecTransit';
import MapMarker                from 'utils/MapMarker';
import MapOptions               from 'utils/MapOptions';
import Alert                    from 'utils/Alert';
import * as MenuItem            from 'components/MenuItem';
import * as OrderModalType      from 'components/OrderModal';

export interface Props {
    orderTypes          : OrderType.Type[];
    user                : UserType.User;
    order               : OrderType.Order;
    mapShown?           : boolean;
    dispatcherMode?     : boolean;
    style?              : Record<string,any>;
}

export interface BoundaryInfo {
    boundary           : google.maps.LatLngLiteral[];
    ndx                : number;
    distance           : number;
}

export abstract class OrderMap extends React.Component {

    protected   polyline?           : google.maps.Polyline;
    protected   clickListener?      : any;
    protected   lastMovedMarker?    : MapMarker;
    protected   mapBoundaries       : google.maps.Polyline[];
    protected   alert               : Alert = new Alert();
    protected   lastZoom            : number = 9;
    protected   secondsGranularity  : number = 60*10;         // 10 mins
    protected   excludedSecondsByKey: Record<string,AgencyType.NumRange[]> = {};
    public      googleMap?          : google.maps.Map;

    public      props       : Props;
    public      markers     : {
        pickup  : MapMarker,
        dropoff : MapMarker
    };

    public      state : {
        orderModalShown : boolean,
        mapShown        : boolean
    };

    abstract getMarkerNode(marker:MapMarker) : React.ReactNode;
    abstract onCreateOrder() : Promise<OrderType.Order & {err?:string}>;

    getStartMoment() : dayjs.Dayjs {
        return dayjs(Date.now());
    }
    getOrderLocationsKey() : string {
        const latLngToString = (latLng:(LatLngType.LatLng|undefined)) => {
            return LatLngType.toString(LatLngType.round(latLng||{lat:0,lng:0},50));
        };
        return `${latLngToString(this.props.order.pickup.location)}/${latLngToString(this.props.order.dropoff.location)}`;
    }
    getExcludedSeconds() : (AgencyType.NumRange[]|undefined) {
        return this.excludedSecondsByKey[this.getOrderLocationsKey()];
    }
    showOrderModalButtonHandler(e:React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        e.stopPropagation();
        if( this.state.orderModalShown )
            return this.alert.set(`This should never happen`);
        // There is some strange bug (perhaps having to do with async nature if this.moveMarker)
        // where the values in `this.props.order` are not updated by the time the user clicks on
        // the "Order" button. So let's give it one more chance to have the correct values.
        if( !this.props.order.pickup?.location  )
            this.props.order.pickup = {...this.markers.pickup.getPosition()};
        if( !this.props.order.dropoff?.location )
            this.props.order.dropoff = {...this.markers.dropoff.getPosition()};
        // Let's check if we are ready to show the dialog
        if( !this.props.order.pickup?.location  )
            return this.alert.set(`Pickup location is not choosen`);
        if( !this.props.order.dropoff?.location )
            return this.alert.set(`Dropoff location is not choosen`);
        if( LatLngType.getMetersBetween(this.props.order.pickup.location,this.props.order.dropoff.location)<5 )
            return this.alert.set(`Dropoff is too close to pickup`);
        // And we are ready to show the modal dialog
        return this.setState({orderModalShown : true});
    }

    getShowOrderModalButtonNode() : React.ReactNode {
        return (
            <button type="button" className="btn-theme btn-passenger" onClick={(e)=>this.showOrderModalButtonHandler(e)}>Order</button>
        );
    }

    constructor( props:Props ) {
        super(props);
        this.props   = props;
        this.markers = {
            pickup  : new MapMarker('pickup',"Pickup",'FR'),
            dropoff : new MapMarker('dropoff',"Dropoff",'TO')
        };
        const strokeColors = ["red","green","blue","yellow","pink","black","DarkCyan","DarkGoldenRod","DarkSalmon","DarkViolet"];
        this.mapBoundaries = (tectransit.agency.boundaries||[]).map( (boundary,ndx) => {
            return new google.maps.Polyline({
                strokeColor   : strokeColors[ndx%strokeColors.length],
                strokeOpacity : 1.0,
                strokeWeight  : 3,
                path          : boundary
            });
        });
        const warnings        = [] as string[];
        const allBoundaryNdxs = Object.values(this.markers).map( mapMarker => {
            const orderEvent = props.order[mapMarker.name];
            if( !orderEvent.location )
                return undefined;
            const boundaryNdxs = OrderMap.getBoundaryNdxsContainingLocation(this.mapBoundaries,new google.maps.LatLng(orderEvent.location));
            if( orderEvent.location && boundaryNdxs.length<1 ) {
                // Have to ignore this location
                orderEvent.location = undefined;
                orderEvent.address  = undefined;
                warnings.push(`${mapMarker.name} was outside of service area`);
                return undefined;
            }
            return boundaryNdxs;
        });
        if( allBoundaryNdxs[0] && allBoundaryNdxs[1] && (allBoundaryNdxs[0].findIndex(ndx=>allBoundaryNdxs[1]!.includes(ndx))<0) ) {
            props.order.dropoff.location = undefined;
            props.order.dropoff.address  = undefined;
            warnings.push(`Pickup location zones do not overlap with dropoff location zones. Only Pickup is going to be used`);
        }
        if( warnings.length>0 )
            setTimeout(() => this.alert?.set(warnings.join("; ")),500);
        Object.values(this.markers).forEach( mapMarker => {
            const orderEvent = props.order[mapMarker.name];
            if( orderEvent.location ) {
                mapMarker.setPosition(orderEvent.location,orderEvent.address||'n/a');
                this.lastMovedMarker = mapMarker;
            }
        });
        this.state = {
            orderModalShown : false,
            mapShown        : true // ((typeof props.mapShown === 'boolean') ? props.mapShown : true)
        };
    }
    protected movePolyline( marker:MapMarker ) {
        const locations = [this.markers.pickup,this.markers.dropoff].map(m=>m.marker?.getPosition());
        if( locations[0] && locations[1] ) {
            // Draw a line or create it
            if( this.polyline ) {
                const ndx = (marker.name==='pickup') ? 0 : 1;
                this.polyline.getPath().setAt(ndx,locations[ndx]!);
            }
            else {
                this.polyline = new google.maps.Polyline({
                    strokeColor   : "#FF0000",
                    strokeOpacity : 1.0,
                    strokeWeight  : 3,
                    map           : this.googleMap,
                    // @ts-expect-error TS2322
                    path          : locations,
                    icons         : [{
                        icon   : {
                            path          : google.maps.SymbolPath.FORWARD_OPEN_ARROW,
                            strokeWeight  : 2,
                            strokeColor   : "#FF0000",
                            scale         : 2
                        },
                        offset : "50%"
                    }]
                });
            }
            // Let's make sure the map is well placed around the polyline
            // @ts-expect-error TS2345
            this.setMapOptions(new MapOptions(MapOptions.getBounds(locations)),true);
        }
        else {
            // Remove the line
            if( this.polyline ) {
                this.polyline.setMap(null);
                this.polyline = undefined;
            }
            // Center the map around the marker
            const notEmptylocations = locations.filter(l=>!!l);
            if( notEmptylocations.length>0 )
                this.setMapOptions(new MapOptions(MapOptions.getBounds(notEmptylocations)),true);
        }
    }
    protected reset() {
        this.moveMarker(this.markers.pickup,undefined);
        this.moveMarker(this.markers.dropoff,undefined);
        return this;
    }
    protected getTimeOptions() : OrderModalType.TimeOption[] {
        const result                = [];
        const maxTimeChooserSeconds = (tectransit.agency.order_modal_max_advance_mins||(7*24*60))*60;
        const isMmntDisabled        = (mmnt:dayjs.Dayjs) => {
            const seconds = mmnt.valueOf()/1000;
            if( this.getExcludedSeconds()?.some(r=>((r[0]<=seconds) && (seconds<=r[1]))) )
                return true;
            if( !this.props.dispatcherMode ) {
                const dayMinutes  = (mmnt.hour()*60)+mmnt.minute();
                const preTripDurationMinutes    = (tectransit.agency.pre_trip_duration_mins||14);
                const postTripDurationMinutes   = (tectransit.agency.post_trip_duration_mins||6);
                const holiday     = tectransit.agency.holidays[mmnt.format("MM/DD/YYYY")];
                const workRanges  = holiday ? [] : tectransit.agency.workminutes[(mmnt.day()+6)%7];
                if( !workRanges.some(r=>(((r[0]+preTripDurationMinutes)<=dayMinutes) && (dayMinutes<(r[1]-postTripDurationMinutes)))) )
                    return true;
            }
            return false;
        }
        const formatToHHmm          = (seconds:number) => {
            return (this.props.dispatcherMode?dayjs(seconds*1000).tz(tectransit.agency.time_zone):dayjs(seconds*1000)).format('hh:mm');
        }
        const startMoment           = this.getStartMoment();
        let earliestDisabledOption  = undefined;
        for( let seconds=0; seconds<=maxTimeChooserSeconds; seconds+=this.secondsGranularity ) {
            const mmnt   = dayjs(startMoment).add(seconds,'seconds');
            const option = {
                disabled    : isMmntDisabled(mmnt),
                value       : Math.round(mmnt.valueOf()/1000),
                text        : mmnt.format('MMM DD, hh:mm A')
            };
            if( option.disabled ) {
                if (!earliestDisabledOption)
                    earliestDisabledOption = option;
            }
            else {
                if( earliestDisabledOption ) {
                    result.push({
                        disabled: true,
                        value   : -1,
                        text    : `sorry, ${formatToHHmm(earliestDisabledOption.value)} to ${formatToHHmm(option.value-60)} is not available`,
                    });
                    earliestDisabledOption = undefined;
                }
                result.push(option);
            }
        }
        return result;
    }
    protected getOrderModalNode() : React.ReactNode {
        return (<OrderModalType.OrderModal
            orderTypes        = {this.props.orderTypes}
            user              = {this.props.user}
            order             = {this.props.order}
            getTimeOptions    = { () => {
                return this.getTimeOptions();
            }}
            onSaveOrder       = { (order:Partial<OrderType.Order>) => {
                Object.assign(this.props.order,order);
                this.setState({orderModalShown:false});
            }}
            onCreateOrder     = { async (order:Partial<OrderType.Order>) : Promise<void|OrderModalType.TimeOption[]> => {
                if( !order.pickup?.location ) {
                    this.alert.set(`Invalid pickup location`);
                }
                else if( !order.dropoff?.location ) {
                    this.alert.set(`Invalid dropoff location`);
                }
                else if( (order.type==='pickup_ordered_at') && isNaN(order.ordered_at!) ) {
                    this.alert.set(`Order pickup date needs to be specified`);
                }
                else if( (order.type==='dropoff_ordered_at') && isNaN(order.ordered_at!) ) {
                    this.alert.set(`Order dropoff date needs to be specified`);
                }
                else {
                    Object.assign(this.props.order,order);
                    return this.onCreateOrder()
                        .then( order => {
                            if( order.err )
                                throw order;
                            if( order.message )
                                this.alert.set(order.message);
                            if( order.setup_return_order ) {
                                this.setState({orderModalShown:false},() => {
                                    setTimeout(() => {
                                        // Reverse the markers
                                        const pickupMarkerPosition = this.markers.pickup.marker.getPosition();
                                        this.moveMarker(this.markers.pickup,this.markers.dropoff.marker.getPosition()!);
                                        this.moveMarker(this.markers.dropoff,pickupMarkerPosition!);
                                        // Reverse the order events
                                        const pickupOrderEvent = this.props.order.pickup;
                                        this.props.order.pickup = this.props.order.dropoff;
                                        this.props.order.dropoff = pickupOrderEvent;
                                        // Show the modal again
                                        this.setState({orderModalShown:true});
                                    },1500);
                                });
                            }
                            else {
                                this.reset();
                                this.setState({orderModalShown:false});
                            }
                        })
                        .catch( err => {
                            if( (typeof err !== 'object') && (typeof err.earliest_seconds !== 'number') ) {
                                this.alert.set(`Cannot create order (${err.err||err.message||err})`);
                                return;
                            }
                            if( !this.excludedSecondsByKey[this.getOrderLocationsKey()] )
                                this.excludedSecondsByKey[this.getOrderLocationsKey()] = [];
                            this.excludedSecondsByKey[this.getOrderLocationsKey()].push([
                                this.props.order.ordered_at!/1000,
                                err.earliest_seconds
                            ]);
                            // Show the error message with time in correct time zone
                            const earliestMmnt = dayjs(err.earliest_seconds*1000);
                            if( this.props.dispatcherMode ) {
                                const earliestSecondsDescription = earliestMmnt.tz(tectransit.agency.time_zone).format('ddd, Do [at] hh:mm A');
                                this.alert.set(`${earliestSecondsDescription} is the earliest we can pick the user up`);
                            }
                            else {
                                const earliestSecondsDescription = earliestMmnt.format('ddd, D [at] hh:mm A');
                                if( tectransit.agency.phone_number )
                                    this.alert.set(`${earliestSecondsDescription} is the earliest we can pick you up. Or call an operator at ${tectransit.agency.phone_number}.`);
                                else
                                    this.alert.set(`${earliestSecondsDescription} is the earliest we can pick you up`);
                            }
                            return this.getTimeOptions();
                        });
                }
            }}
        />)
    }
    protected static getBoundaryNdxsContainingLocation( boundaries:google.maps.Polyline[], googleLatLng:google.maps.LatLng ) : number[] {
        const ndxInfos = boundaries.map( (boundary,ndx) => {
            return {
                ndx,
                containing : google.maps.geometry.poly.containsLocation(googleLatLng,boundary as google.maps.Polygon)
            };
        });
        return ndxInfos.filter(info=>info.containing).map(info=>info.ndx);
    }
    protected mapClickHandler(event:google.maps.MapMouseEvent) {
        if( !event.latLng )
            return;
        const marker = (this.lastMovedMarker===this.markers.pickup) ? this.markers.dropoff : this.markers.pickup;
        this.moveMarker(marker,event.latLng).then(message=>this.alert.set(message));
    }
    protected setMarker( mapMarker:MapMarker, latLng?:google.maps.LatLng, address?:string ) {
        if( !mapMarker.marker )
            return `Initialization is incomplete`;
        const street = address ? address.replace(/^(.+),\s*([^,]+,[^,]+),\s*(.+)$/,"$1") : 'unknown';
        mapMarker.popup!.setContent(`<div><b>${mapMarker.title}</b>: ${street}</div>`);
        mapMarker.setPosition(latLng,address||'n/a');
        this.props.order[mapMarker.name] = {
            ...this.props.order[mapMarker.name],
            location : latLng?.toJSON(),
            address  : address
        };
        this.forceUpdate(); // Have to re-render the marker nodes
        this.movePolyline(mapMarker);
    }
    async moveMarker( mapMarker:MapMarker, latLng:(google.maps.LatLng|undefined) ) : Promise<string> {
        if( !mapMarker.marker )
            return `Initialization is incomplete`;
        const otherMapMarker = Object.values(this.markers).find(m=>(m!==mapMarker));
        const otherLocation  = otherMapMarker?.getPosition()?.location;
        if( latLng ) {
            const boundaryNdxsContainingMarker = OrderMap.getBoundaryNdxsContainingLocation(this.mapBoundaries,latLng);
            if( boundaryNdxsContainingMarker.length<1 )
                return `${mapMarker.title} is outside of agency service area. Please choose ${mapMarker.name} location`;
            // Have to make sure that the other map marker (if set) is in the same zone
            // TODO: how would the user clear the other marker? Complete page reload?
            if( otherLocation ) {
                const boundaryNdxsContainingOtherMarker = OrderMap.getBoundaryNdxsContainingLocation(this.mapBoundaries,new google.maps.LatLng(otherLocation));
                if( boundaryNdxsContainingOtherMarker.findIndex(ndx=>boundaryNdxsContainingMarker.includes(ndx))<0 )
                    return `${mapMarker.title} can only be within the same zone as ${otherMapMarker.title}. Please choose ${mapMarker.name} location`;
            }
        }
        if( latLng ) {
            await tectransit.geocoder.getLatlngLookupPromise(latLng)
                .then( geocoderResult => {
                    this.setMarker(mapMarker,latLng,geocoderResult.formatted_address);
                })
                .catch( (err:Error) => {
                    console.error(`Cannot get address for ${latLng}: ${err.message}`);
                    this.setMarker(mapMarker,latLng,latLng.toString());
                });
        }
        else {
            this.setMarker(mapMarker,undefined,'n/a');
        }
        this.lastMovedMarker = mapMarker;
        return '';
    }
    componentDidMount() {
        console.log("OrderMap::componentDidMount");
        const mapOptions = new MapOptions(MapOptions.getBounds(tectransit.getAgencyBoundary(),0.025));
        this.googleMap = tectransit.getGoogleMap(document.getElementById("map")!,{
            zoom        : mapOptions.zoom,
            center      : mapOptions.center,
            restriction : {
                latLngBounds : mapOptions.bounds,
                strictBounds : false
            }
        });
        this.mapBoundaries.forEach( boundary => {
            boundary.setMap(this.googleMap||null);
        });
        if( this.props.orderTypes.length>0 ) {
            Object.values(this.markers).forEach( mapMarker => {
                mapMarker.setMap(this.googleMap||null);
            });
            this.movePolyline(this.markers.pickup/*this arg does not really matter*/);
            this.clickListener = this.googleMap.addListener('click',(e:google.maps.MapMouseEvent) => {
                return this.mapClickHandler(e);
            });
        }
    }
    componentWillUnmount() {
        console.log("OrderMap::componentWillUnmount");
        if( this.clickListener ) {
            this.clickListener.remove();
            delete this.clickListener;
        }
        this.mapBoundaries.forEach( boundary => {
            boundary.setMap(null);
        });
        Object.values(this.markers).forEach( mapMarker => {
            mapMarker.setMap(null);
        });
    }
    setMapShown( mapShown:boolean ) : void {
        // if( this.state.mapShown!==mapShown )
        //     this.setState({mapShown});
    }
    setMapOptions( mapOptions:MapOptions, ignoreLastZoom=false ) : boolean {
        if( !this.googleMap ) {
            console.log(`googleMap is empty`);
            return false;
        }
        if( !ignoreLastZoom ) {
            if( this.googleMap.getZoom()!==this.lastZoom ) {
                console.log(`current zoom level (${this.googleMap.getZoom()}) is different from last zoom level (${this.lastZoom})`);
                return false; // seems like user took over the control of the zoom level
            }
        }
        this.lastZoom = mapOptions.zoom;
        this.googleMap.setOptions(mapOptions);
        if( mapOptions.center )
            this.googleMap.setCenter(mapOptions.center);
        return true;
    }
    render() {
        // If map height is set to a fixed number then Google Map seems to adjust the width to the whole screen
        return MenuItem.withAlert(( alert ) => {
            this.alert = alert;
            return (<div className="orderMap" style={this.props.style||{}}>
                <div id="map" className="map-card" style={{visibility:(this.state.mapShown?'visible':'hidden'),height:`100%`}}/>
                <div className="wrapper" style={{position:'absolute',bottom:'0px'}}>
                    <div className="section-card" style={{marginBottom:'5px'}}>
                        <div className="section-card__header">
                            <div className="section-card__title">
                                {(this.props.orderTypes.length>0) ? `Please select your pickup and dropoff locations` :
                                    tectransit.agency.phone_number ? `Please contact the agency at ${tectransit.agency.phone_number} to place an order` :
                                     `Agency does now allow orders to be created by public`}
                            </div>
                        </div>
                        {(this.props.orderTypes.length>0) && (
                            <div className="section-card__body">
                                <div className="picker-wrap">
                                    <div className="picker" style={{border:0}}>
                                        <div className="picker__item">
                                            <img alt="pickup" src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjRweCIgaGVpZ2h0PSIyNHB4IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbD0iIzFEMUU3QSIgZD0ibTEyIDE3IDEtMlY5Ljg1OGMxLjcyMS0uNDQ3IDMtMiAzLTMuODU4IDAtMi4yMDYtMS43OTQtNC00LTRTOCAzLjc5NCA4IDZjMCAxLjg1OCAxLjI3OSAzLjQxMSAzIDMuODU4VjE1bDEgMnpNMTAgNmMwLTEuMTAzLjg5Ny0yIDItMnMyIC44OTcgMiAyLS44OTcgMi0yIDItMi0uODk3LTItMnoiLz48cGF0aCBmaWxsPSIjMUQxRTdBIiBkPSJtMTYuMjY3IDEwLjU2My0uNTMzIDEuOTI4QzE4LjMyNSAxMy4yMDcgMjAgMTQuNTg0IDIwIDE2YzAgMS44OTItMy4yODUgNC04IDRzLTgtMi4xMDgtOC00YzAtMS40MTYgMS42NzUtMi43OTMgNC4yNjctMy41MWwtLjUzMy0xLjkyOEM0LjE5NyAxMS41NCAyIDEzLjYyMyAyIDE2YzAgMy4zNjQgNC4zOTMgNiAxMCA2czEwLTIuNjM2IDEwLTZjMC0yLjM3Ny0yLjE5Ny00LjQ2LTUuNzMzLTUuNDM3eiIvPjwvc3ZnPg0K"/>
                                            {this.getMarkerNode(this.markers.pickup)}
                                        </div>
                                        <strong>&raquo;</strong>
                                        <div className="picker__item">
                                            <img alt="dropoff" src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjRweCIgaGVpZ2h0PSIyNHB4IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbD0iIzFEMUU3QSIgZD0ibTEyIDE3IDEtMlY5Ljg1OGMxLjcyMS0uNDQ3IDMtMiAzLTMuODU4IDAtMi4yMDYtMS43OTQtNC00LTRTOCAzLjc5NCA4IDZjMCAxLjg1OCAxLjI3OSAzLjQxMSAzIDMuODU4VjE1bDEgMnoiLz48cGF0aCBmaWxsPSIjMUQxRTdBIiBkPSJtMTYuMjY3IDEwLjU2My0uNTMzIDEuOTI4QzE4LjMyNSAxMy4yMDcgMjAgMTQuNTg0IDIwIDE2YzAgMS44OTItMy4yODUgNC04IDRzLTgtMi4xMDgtOC00YzAtMS40MTYgMS42NzUtMi43OTMgNC4yNjctMy41MWwtLjUzMy0xLjkyOEM0LjE5NyAxMS41NCAyIDEzLjYyMyAyIDE2YzAgMy4zNjQgNC4zOTMgNiAxMCA2czEwLTIuNjM2IDEwLTZjMC0yLjM3Ny0yLjE5Ny00LjQ2LTUuNzMzLTUuNDM3eiIvPjwvc3ZnPg0K"/>
                                            {this.getMarkerNode(this.markers.dropoff)}
                                        </div>
                                    </div>
                                    &nbsp;
                                    <div className="picker__btn">
                                        {this.state.orderModalShown ? this.getOrderModalNode() : undefined}
                                        {this.getShowOrderModalButtonNode()}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>);
        });
    }
}

export default OrderMap;
