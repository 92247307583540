import React                from 'react';
import { QrReader }         from 'react-qr-reader';
import * as ZXingLib        from '@zxing/library';

import * as UserType        from 'shared/types/User';
import tectransit           from 'utils/TecTransit';

export interface Props {
    onBoarding( userId:number ) : Promise<UserType.User>;
}
export interface State {
    user?   : UserType.Hydrated;
    err?    : string;
}
export class BadgeScanning extends React.Component<Props,State> {

    private qrcodePublicKey     : any;
    private lastPlayedSound?    : string;

    static getArrayBuffer = ( s:string ) : Uint8Array => {
        return Uint8Array.from(atob(s),c=>c.charCodeAt(0));
    }
    constructor( props:Props ) {
        super(props);
        this.state = {
        };
        if( tectransit.agency.issue422_public_key ) {
            crypto.subtle.importKey(
                'spki',
                BadgeScanning.getArrayBuffer(tectransit.agency.issue422_public_key),
                {name:'ECDSA',namedCurve:'P-256'},
                false,
                ['verify']
            ).then( publicKey => {
                this.qrcodePublicKey = publicKey;
            }).catch( err => {
                console.log(`Cannot import public key ${err.description}`);
            });
        }
    }
    playSound( src:string ) {
        if( this.lastPlayedSound===src )
            return;
        this.lastPlayedSound = src;
        return (new Audio(src)).play();
    }
    async onQrResult( result?:(ZXingLib.Result|null),err?:(Error|null)   ) {
        if( err ) {
            this.setState({
                err     : `no detection`,
                user    : undefined
            });
            return;
        }
        if( !result ) {
            this.setState({
                err : 'no result'
            });
            return;
        }
        try {
            const [data,signature]  = JSON.parse(result.getText());
            const verified          = await crypto.subtle.verify(
                {name:'ECDSA',hash:{name:'SHA-384'}},
                this.qrcodePublicKey,
                BadgeScanning.getArrayBuffer(signature),
                (new TextEncoder()).encode(JSON.stringify(data))
            );
            if( !verified ) {
                this.playSound("/buzzer.mp3");
                this.setState({
                    err : `QR code is invalid`
                });
                return;
            }
            if( (Date.now()-data.ts)>(24*60*60*1000) ) {
                this.playSound("/buzzer.mp3");
                this.setState({
                    err : `QR code has expired, please refresh it`
                });
                return;
            }
            if( data.user_id===this.state.user?._id ) {
                this.setState({
                    err : undefined
                });
                return;
            }
            // TODO:
            // Create a separate API for boarding
            this.props.onBoarding(data.user_id)
                .then( user => {
                    this.playSound("/chime.mp3");
                    this.setState({
                        user    : user,
                        err     : undefined
                    });
                })
                .catch( err => {
                    this.setState({
                        err : `Cannot save boarding #${data.user_id} (${(err as Error).message})`
                    });
                });
        }
        catch( err ) {
            this.playSound("/buzzer.mp3");
            this.setState({
                err : `Verification failed (${(err as Error).message})`
            });
        }
    }
    render() {
        const videoHeight = 150;
        const videoWidth  = 150;
        return (<table><tbody>
            <tr>
                <td>
                    <QrReader
                        constraints ={{
                            facingMode : 'user'
                        }}
                        videoContainerStyle={{
                            height     : `${videoHeight}px`,
                            width      : `${videoWidth}px`,
                            overflow   : 'hidden',
                            position   : 'relative',
                            paddingTop : '0px',
                        }}
                        onResult = {(result,err) => {
                            return this.onQrResult(result,err);
                        }}
                    />
                </td>
                <td>
                    { this.state.user && (
                        <img 
                            src     = {`${this.state.user.picture}?${Date.now()}`} 
                            alt     = "user" 
                            style   = {{height:`${videoHeight}px`,width:`${videoWidth}px`}}
                        />
                    )}
                </td>
            </tr>
            <tr>
                <td align='center'>
                    {!this.qrcodePublicKey ? (
                        <>Initializing...</>
                    ) : !this.state.err ? (
                        <>Detected...</>
                    ) : this.state.err }
                </td>
                <td align='center'>
                    {this.state.user && <><strong>{this.state.user.name}</strong>!</>}
               </td>
            </tr>
        </tbody></table>);
    }
};

export default BadgeScanning;