import React,{ FC }         from 'react';
import { Navigate }         from 'react-router';
import { 
    useLocation,
    useNavigate
}                           from 'react-router-dom';

import consts               from 'shared/consts';
import * as VehicleType     from 'shared/types/Vehicle';
import * as DVIRecordType   from 'shared/types/DVIRecord';

import tectransit           from 'utils/TecTransit';
import getApiPromise        from 'utils/getApiPromise';

import * as MenuItem        from 'components/MenuItem';
import * as DVISTableType   from 'Maintenance/components/DVISTable';

interface Props {
}
const NewRepair : FC<Props> = props => {

    const location       = useLocation();
    const navigate       = useNavigate();
    const vehicle        = location.state.vehicle as VehicleType.Vehicle;
    const [redirectToVehicles,setRedirectToVehicles] = React.useState<boolean>(false);
    const [dvis,setDVIs]                = React.useState<DVISTableType.DVIType[]|undefined>();
    const [errMessage,setErrMessage]    = React.useState<string>('');
    const [workItems,setWorkItems]      = React.useState<DVIRecordType.RepairWorkItem[]>([]);
    const [partItems,setPartItems]      = React.useState<DVIRecordType.RepairPartItem[]>([]);

    React.useEffect(() => {
        getApiPromise<DVISTableType.DVIType[]>('/api/maintenance/vehicle/dvis','GET',{},{
            vehicle_id  : vehicle._id,
            repair_id   : null,
            orderby     : 'ms desc',
            limit       : 1000
        }).then( dvis => {
            if( !dvis || dvis.err )
                throw Error(dvis?.err||`dvis are empty`);
            // auto-select the first DVI, if there is only one
            if( dvis.length===1 )
                dvis[0].selected = true;
            setDVIs(dvis);
        })
        .catch( err => {
            setErrMessage(`Cannot get DVIs (${err.message})`);
        });
    },[vehicle._id]);

    if( redirectToVehicles )
        return (<Navigate to={'/Maintenance/Vehicles'}/>);

    const formInput = React.createRef() as React.RefObject<HTMLFormElement>;
    return MenuItem.withMenuItem(`New Repair for Vehicle #${vehicle._id}`, (alert) => {
        if( errMessage ) {
            alert.set(errMessage);
            return false;
        }
        if( !dvis )
            return (<div>Loading...</div>);
        return [
            <DVISTableType.DVISTable
                key         = {'dvisTable'}
                vehicle     = {vehicle} 
                dvis        = {dvis}
                navigate    = {navigate}
                selectable  = {true}
            />,
            <form key={'form'} ref={formInput}>
                <table className="wrapper">
                    <tbody>
                        <tr>
                            <td><strong>Fleet #:</strong></td>
                            <td>{vehicle._id}</td>
                        </tr>
                        <tr>
                            <td><strong>Odometer:</strong></td>
                            <td>{Number(vehicle.odometer_meters/consts.meters_in_mile).toFixed(2)} miles</td>
                        </tr>
                        <tr>
                            <td><strong>License Plate:</strong></td>
                            <td>{vehicle.license_plate}, {vehicle.license_state}</td>
                        </tr>
                        <tr>
                            <td><strong>Make, Model, Year:</strong></td>
                            <td>{vehicle.make_model_year}</td>
                        </tr>
                        <tr>
                            <td><strong>Reported Problem:</strong></td>
                            <td>{vehicle.unavailability_reason}</td>
                        </tr>
                        <tr>
                            <td><strong>Mechanic:</strong></td>
                            <td>
                                <input
                                    name        ="mechanic"
                                    type        ="text"
                                    defaultValue=""
                                    size        ={50}
                                    placeholder ="enter the name of assigned mechanic"
                                    maxLength   ={80}/>
                            </td>
                        </tr>
                        <tr>
                            <td><strong>Other Comments</strong></td>
                            <td>
                                <input
                                    name        ="repairComment"
                                    type        ="text"
                                    defaultValue=""
                                    size        ={50}
                                    placeholder ="enter the repair comment"
                                    maxLength   ={80}/>
                            </td>
                        </tr>
                        <tr>
                            <td valign='top'><strong>Work Performed:</strong></td>
                            <td width={`150px`}>
                                <table>
                                    <tbody>
                                    <tr>
                                        <td><strong>Description</strong></td>
                                        <td><strong>Hours</strong></td>
                                        <td></td>
                                    </tr>
                                    {workItems.map((wi,wiNdx) => (
                                        <tr key={wiNdx}>
                                            <td>{wi.description}</td>
                                            <td>{wi.hours}</td>
                                            <td>
                                                <button
                                                    onClick={(e)=> {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        setWorkItems(workItems.filter((_,ndx)=>ndx!==wiNdx));
                                                    }}
                                                >
                                                -
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                    <tr>
                                        <td>
                                            <input
                                                key         = {`wi_description_${workItems.length}`}
                                                name        = "wi_description"
                                                type        = "text"
                                                defaultValue= ""
                                                size        = {50}/>
                                        </td>
                                        <td>
                                            <input
                                                key         = {`wi_hours_${workItems.length}`}
                                                name        = "wi_hours"
                                                type        = "number"
                                                min         = {0}
                                                defaultValue= ""
                                                size        = {5}/>
                                        </td>
                                        <td>
                                            <button
                                                onClick={(e)=> {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    const elements = formInput.current?.elements;
                                                    if( !elements )
                                                        return;
                                                    const description = elements.namedItem('wi_description') as HTMLInputElement;
                                                    const hours       = elements.namedItem('wi_hours') as HTMLInputElement;
                                                    if( !description || !hours ) {
                                                        alert.set('Cannot find form elements');
                                                        return;
                                                    }
                                                    description.value = description.value.trim();
                                                    console.log({
                                                        description : description.value,
                                                        hours       : hours.value
                                                    });
                                                    if( !description.value ) {
                                                        alert.set('Description should be provided');
                                                        return;
                                                    }
                                                    hours.value = hours.value.trim();
                                                    if( !/^\d+(\.\d+)?$/.test(hours.value) ) {
                                                        alert.set('Hours should be a number');
                                                        return;
                                                    }
                                                    setWorkItems([...workItems,{
                                                        description : description.value,
                                                        hours       : Number(hours.value)
                                                    }]);
                                                    return;
                                                }}
                                            >+
                                            </button>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td valign='top'><strong>Parts and Accessories:</strong></td>
                            <td width={`150px`}>
                                <table>
                                    <tbody>
                                    <tr>
                                        <td><strong>QTY.</strong></td>
                                        <td><strong>Part #</strong></td>
                                        <td><strong>Description</strong></td>
                                        <td><strong>Cost</strong></td>
                                        <td></td>
                                    </tr>
                                    {partItems.map((part,partNdx) => (
                                        <tr key={partNdx}>
                                            <td>{part.qty}</td>
                                            <td>{part.part}</td>
                                            <td>{part.description}</td>
                                            <td>{part.cost}</td>
                                            <td>
                                                <button
                                                    onClick={(e)=> {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        setPartItems(partItems.filter((_,ndx)=>ndx!==partNdx));
                                                    }}
                                                >
                                                -
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                    <tr>
                                        <td>
                                            <input
                                                key         = {`pi_qty_${partItems.length}`}
                                                name        = "pi_qty"
                                                type        = "number"
                                                min         = {1}
                                                defaultValue= ""
                                                size        = {5}/>
                                        </td>
                                        <td>
                                            <input
                                                key         = {`pi_part_${partItems.length}`}
                                                name        = "pi_part"
                                                type        = "text"
                                                defaultValue= ""
                                                size        = {10}/>
                                        </td>
                                        <td>
                                            <input
                                                key         = {`pi_description_${partItems.length}`}
                                                name        = "pi_description"
                                                type        = "text"
                                                defaultValue= ""
                                                size        = {30}/>
                                        </td>
                                        <td>
                                            <input
                                                key         = {`pi_cost_${partItems.length}`}
                                                name        = "pi_cost"
                                                type        = "number"
                                                min         = {0}
                                                defaultValue= ""
                                                size        = {8}/>
                                        </td>
                                        <td>
                                            <button
                                                onClick={(e)=> {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    const elements = formInput.current?.elements;
                                                    if( !elements )
                                                        return;
                                                    const qty           = elements.namedItem('pi_qty') as HTMLInputElement;
                                                    const part          = elements.namedItem('pi_part') as HTMLInputElement;
                                                    const description   = elements.namedItem('pi_description') as HTMLInputElement;
                                                    const cost          = elements.namedItem('pi_cost') as HTMLInputElement;
                                                    if( !qty || !part || !description || !cost ) {
                                                        alert.set('Cannot find form elements');
                                                        return;
                                                    }
                                                    qty.value = qty.value.trim();
                                                    if( !/^\d+$/.test(qty.value) ) {
                                                        alert.set('Quantity should be a number');
                                                        return;
                                                    }
                                                    part.value = part.value.trim();
                                                    if( !/^[#0-9a-zA-Z].*$/.test(part.value) ) {
                                                        alert.set('Part # should be provided');
                                                        return;
                                                    }
                                                    if( partItems.find(p=>p.part===part.value) ) {
                                                        alert.set(`Part #${part.value} already exists`);
                                                        return;
                                                    }
                                                    cost.value = cost.value.trim();
                                                    if( !/^\$?\d+(\.\d+)?$/.test(cost.value) ) {
                                                        alert.set('Cost should be a number');
                                                        return;
                                                    }
                                                    setPartItems([...partItems,{
                                                        qty         : Number(qty.value),
                                                        part        : part.value,
                                                        description : description.value,
                                                        cost        : Number(cost.value)
                                                    }]);
                                                    return;
                                                }}
                                            >+
                                            </button>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <br/>
                                <button
                                    className="btn-theme"
                                    onClick={(e)=> {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        const form = (Array.from(formInput.current?.elements||[]) as Record<string,any>[])
                                            .filter( input => {
                                                return (input.type!=='submit');
                                            })
                                            .reduce( (acc,input) => {
                                                acc[input.name] = (input.type==='checkbox') ? input.checked : input.value.trim();
                                                return acc;
                                            },{} as Record<string,any>);
                                        const repair = {
                                            _id             : '',
                                            mechanic        : form.mechanic,
                                            comment         : form.repairComment,
                                            workItems       : workItems,
                                            partItems       : partItems,
                                            user_id         : tectransit.user._id,
                                            vehicle_id      : vehicle._id,
                                            dvis            : dvis?.filter(dvi=>dvi.selected).map((dvi:any)=>dvi._id)
                                        };
                                        if( repair.dvis.length===0 ) {
                                            if( !window.confirm('No DVIs selected. Do you want to continue?') )
                                                return;
                                        }
                                        const result = DVIRecordType.isRepairValid(repair);
                                        if( result!=='' ) {
                                            alert.set(result);
                                            return;
                                        }
                                        getApiPromise('/api/maintenance/repair','POST',repair)
                                            .then( res => {
                                                if( !res || res.err )
                                                    throw Error(res?.err||'result is empty');
                                                setRedirectToVehicles(true);
                                            })
                                            .catch( err => {
                                                alert.set(`Cannot save (${err.message})`);
                                            });
                                    }}
                                >
                                    Create Repair Record
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        ];
    });
}

export default NewRepair;
