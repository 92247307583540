import * as images       from 'images/';
import MenuItem          from 'components/MenuItem';
import LateOrders        from 'Dispatcher/components/LateOrders';

export default function Late() {
    const max_minutes = 30;
    return (
        <MenuItem title={[`Late Orders with pickup or dropoff delayed by ${max_minutes} mins or more`,<a key='download' href='/api/dispatcher/orders/late?csv'><images.Download/></a>]}>
            <LateOrders max_ms={max_minutes*60*1000}/>
        </MenuItem>
    );
}
