import * as DnDType                     from 'react-beautiful-dnd';

import * as ManifestType                from 'shared/types/Manifest';
import * as VehicleType                 from 'shared/types/Vehicle';
import * as UserType                    from 'shared/types/User';
import * as DepotType                   from 'shared/types/Depot';
import * as OrderType                   from 'shared/types/Order';
import jsonParse                        from 'shared/utils/jsonParse';
import getAgencyWorkMinutes             from 'shared/utils/getAgencyWorkMinutes';

import Alert                            from 'utils/Alert';
import tectransit                       from 'utils/TecTransit';
import Schedulable                      from 'Dispatcher/utils/Schedulable';
import DraftVehicleManifest             from 'Dispatcher/utils/DraftVehicleManifest';

export type Type = 'ondemand'|'fixedroute';

export abstract class WhiteboardContext<SCHEDULABLE extends Schedulable=Schedulable> {

    public readonly workMinutes         : number[][];
    public readonly depotsByName        : Record<string,DepotType.Depot>;
    public readonly agencyOpensSeconds  : number;
    public readonly agencyClosesSeconds : number;
    public readonly drivingStartsSeconds: number;
    public readonly drivingStopsSeconds : number;

    public draftVehicleManifestsById    : Record<string,DraftVehicleManifest<SCHEDULABLE>> = {};

    constructor(
        public readonly type                    : Type,
        public readonly alert                   : Alert,
        public readonly dehydratedAgencyManifest: ManifestType.AgencyManifest.Dehydrated<string>,
        public readonly agencyManifest          : ManifestType.AgencyManifest.Hydrated<OrderType.Order<string>>,
        public readonly vehiclesById            : Record<number,VehicleType.Vehicle>,
        public readonly driversById             : Record<number,UserType.User>
    ) {
        // This is modeled like Server/utils/Manifest.Context to maximize code reuse
        if( !Number.isInteger(agencyManifest.dayStartAt) )
            throw Error(`'${agencyManifest.dayStartAt}' is not an integer`);

        this.workMinutes    = getAgencyWorkMinutes(tectransit.agency,this.agencyManifest.dayStartAt);
        this.depotsByName   = Object.values(tectransit.agency.depots).reduce((acc,depot) => {
            acc[depot.name] = depot;
            return acc;
        },{} as Record<string,DepotType.Depot>);
        this.agencyOpensSeconds         = this.workMinutes[0] ? (60*this.workMinutes[0][0]) : 0;
        this.agencyClosesSeconds        = this.workMinutes[this.workMinutes.length-1] ? (60*this.workMinutes[this.workMinutes.length-1][1]) : 0;
        this.drivingStartsSeconds       = this.agencyOpensSeconds+((tectransit.agency.pre_trip_duration_mins||14)*60);
        this.drivingStopsSeconds        = this.agencyClosesSeconds-((tectransit.agency.post_trip_duration_mins||6)*60);
        this.draftVehicleManifestsById  = this.restoreDraftVehicleManifestsById();
        this.setVehiclesInSchedulables();
        this.storeDraftVehicleManifestsIdBy();
    }
    private setVehiclesInSchedulables() {
        const vehiclesBySchedulableId = Object.values(this.draftVehicleManifestsById).reduce((acc,dvm)=> {
            dvm.schedulables.forEach( s => {
                acc[s._id!] = this.vehiclesById[dvm.originalVm.vehicle_id];
            });
            return acc;
        },{} as Record<string,VehicleType.Vehicle>);
        Object.values(this.getSchedulablesById()).forEach( s => {
            s.vehicle = vehiclesBySchedulableId[s._id!];
        });
    }
    protected storeDraftVehicleManifestsIdBy() : void {
        const storageKey = this.constructor.name;
        console.debug(`updating '${storageKey}'`);
        return sessionStorage.setItem(storageKey,JSON.stringify({
            ts : Date.now(),
            vehicleManifestsById : Object.values(this.draftVehicleManifestsById).reduce((acc,dvm)=> {
                acc[dvm.originalVm.vehicle_id] = {
                    steps : (dvm.steps||[]).map(step=>ManifestType.Step.dehydrate(step,{},{},{}))
                }
                return acc;
            },{} as Record<number,Partial<ManifestType.VehicleManifest.Dehydrated<string>>>)
        }));
    }
    private restoreDraftVehicleManifestsById() : Record<string,DraftVehicleManifest<SCHEDULABLE>> {
        const stored = jsonParse(sessionStorage.getItem(this.constructor.name)!,{})||{};
        // This restoring from session storage feature is for the following use case:
        //
        // 1/ Dispatcher clicks on Whiteboard in the menu
        // 2/ Whiteboard takes 2 minutes to load (taking a lot of server resources)
        // 3/ Dispatcher spends a lot of time moving orders around in the Whiteboard
        // 4/ Intentionally or not the dispatcher clicks on one of the links in the whiteboard and
        //    leaves the page
        // 5/ Shortly thereafter the dispatcher comes back to the Whiteboard only to find steps
        //    2/ and 3/ above have been lost and the dispatcher is back to square 1/
        //
        // This feature is _not_ designed for long term storage of the Whiteboard state.
        // Because storing for the long term interferes with the updates of the Whiteboard
        // at the back end.
        const fiveMinsAgo = Date.now()-5*60*1000;
        const dehydratedVehicleManifestsById = ((typeof stored.ts !== 'number') ? {} :
            (stored.ts<fiveMinsAgo) ? {} :
            (stored.vehicleManifestsById||{})) as Record<number,ManifestType.VehicleManifest.Dehydrated<string>>;
        // @ts-expect-error
        const DraftVehicleManifestConstructor = this.constructor.DraftVehicleManifestConstructor as {
            new(wc:WhiteboardContext<SCHEDULABLE>,vm:ManifestType.VehicleManifest.Hydrated<OrderType.Order<string>>,steps:ManifestType.Step.Hydrated<OrderType.Order<string>>[] ): DraftVehicleManifest<SCHEDULABLE>
        };
        if( typeof DraftVehicleManifestConstructor !== 'function' )
            throw Error(`There should be DraftVehicleManifestConstructor in ${this.constructor.name}`);
        // Go over every vehicle manifest in agency manifest and create a draft vehicle manifest for it
        // on the basis of the steps stored in session storage. If some of those steps have unknown orders
        // or fixed routes, then throw them out and use only steps with "valid" orders and fixed routes.
        const ordersById = this.agencyManifest.ordersById||{};
        return this.agencyManifest.vehicleManifests.reduce((acc,vm) => {
            const hydratedSteps = dehydratedVehicleManifestsById[vm.vehicle_id]?.steps
                .filter( dehydratedStep => {
                    if( dehydratedStep.name==='stop' ) {
                        dehydratedStep.pickups  = dehydratedStep.pickups?.filter(pickupId=>ordersById[pickupId]);
                        dehydratedStep.dropoffs = dehydratedStep.dropoffs?.filter(dropoffId=>ordersById[dropoffId]);
                        const eventsCount = (dehydratedStep.pickups?.length||0)+(dehydratedStep.dropoffs?.length||0);
                        if( eventsCount===0 )
                            return false;   // If there are no more orders then remove this step
                        // If we removed some orders from `pickups` or `dropoffs` then we should also update stop `seconds` value
                        // Do we take an average of events times for the remaining orders?
                        const hasSpecificPickupTime = dehydratedStep.pickups?.some(pickupId=>(ordersById[pickupId]?.type==='pickup_ordered_at'))||false;
                        const hasSpecificDropoffTime = dehydratedStep.dropoffs?.some(dropoffId=>(ordersById[dropoffId]?.type==='dropoff_ordered_at'))||false;
                        if( !hasSpecificDropoffTime && !hasSpecificPickupTime )
                            delete dehydratedStep.seconds;
                        return true;
                    }
                    else if( dehydratedStep.name==='fixedroute' ) {
                        dehydratedStep.frTrip = ((dehydratedStep.frTrip?.[0]||-1) in (this.agencyManifest.fixedRouteTripsById||{})) ? dehydratedStep.frTrip : undefined;
                        return dehydratedStep.frTrip!==undefined;
                    }
                    return false;
                })
                .map( dehydratedStep => {
                    return ManifestType.Step.hydrate(
                        dehydratedStep,
                        this.agencyManifest.ordersById||{},
                        this.dehydratedAgencyManifest.fixedRouteStopsById||{}
                    );
                });
            acc[vm.vehicle_id] = new DraftVehicleManifestConstructor(this,vm,hydratedSteps?hydratedSteps:vm.steps);
            return acc;
        },{} as Record<string,DraftVehicleManifest<SCHEDULABLE>>);
    }
    getNotYetScheduledCount() : number {
        return Object.values(this.getSchedulablesById()).reduce((acc,s)=>(acc+(s.vehicle?0:1)),0);
    }
    setDraftVehicleManifestsbyId( draftVehicleManifestsById:Record<string,DraftVehicleManifest<SCHEDULABLE>> ) {
        this.draftVehicleManifestsById = draftVehicleManifestsById;
        this.setVehiclesInSchedulables();
        this.storeDraftVehicleManifestsIdBy();
    }
    abstract getSchedulablesById() : Record<string,SCHEDULABLE>;
    abstract onDragEnd( dragUpdate:DnDType.DragUpdate ) : Record<string,DraftVehicleManifest<SCHEDULABLE>>;
    abstract getHeaderText() : JSX.Element;
    abstract getSchedulablesTable() : JSX.Element;
    abstract getDraftVehicleManifestStops( dvm:DraftVehicleManifest<SCHEDULABLE> ) : JSX.Element;
    abstract getDraftVehicleManifestStepCards( dvm:DraftVehicleManifest<SCHEDULABLE> ) :  React.ReactNode[];
    abstract getDraftVehicleManifestSteps( dvm:DraftVehicleManifest<SCHEDULABLE> ) :  React.ReactNode;
}

export default WhiteboardContext;