import React             from 'react';

// Initially I wanted to use 'push' menu from https://www.npmjs.com/package/react-burger-menu
// but the problem with it is that it just pushes the right part of the outer countainer out
// of the screen. So I had to somewhat reverse engineer react-burger-menu and find a way to
// apply a style transformation that not only moves the outer container to the right but also
// scales it along the X axis so that the entire outer container fits the screen.
//
// Yes, this is a hack
//
// @ts-expect-error
import menuFactory       from 'react-burger-menu/lib/menuFactory';

export interface Props {
    menuProps : Record<string,any>;
    children  : any;
}

const Menu = menuFactory({
    pageWrap( isOpen:boolean, widthPx:string, right:boolean ) {
        const menuWidth  = 300;
        const marginName = right ? 'margin-right' : 'margin-left';
        return isOpen ? {
            [marginName]: '0px',
            width       : '100%',
            transition  : '0.5s',
        } : {
            [marginName]: `${menuWidth}px`,
            width       : `calc(100% - ${menuWidth}px)`,
            transition  : '0.5s',
        };
    },
    outerContainer(isOpen:boolean) {
        return {
            overflow: isOpen ? '' : 'hidden'
        };
    }
});



export default function Hamburger( props:Props ) {
    return (
        <Menu
            noOverlay
            disableAutoFocus
            menuClassName="menu"
            {...props.menuProps}
        >
          {props.children}
        </Menu>
    );
}
