export interface Alert<T=string>{
    _id?            : T;
    text            : string;
    start_seconds   : number;
    end_seconds     : number;
}

export const isValid = <T>( alert:Alert<T>, currentSeconds=(Date.now()/1000) )  => {
    alert.text = (alert.text||'').trim();
    if( alert.text==="" )
        throw Error("Alert body empty!");
    if( alert.text.length > 500)
        throw Error("Alert body over 500 characters please shorten!");
    if( isNaN(alert.start_seconds) )
        throw Error(`start seconds are not numeric`);
    if( isNaN(alert.end_seconds) )
        throw Error(`end seconds are not numeric`);
    if (alert.start_seconds < 0 || alert.end_seconds < 0)
        throw Error("Start/End times cannot be negative.");
    if (alert.start_seconds >= alert.end_seconds)
        throw Error("Start date has to be before end date!");
    if (alert.end_seconds > 2147483647)
        throw Error("Alert set too far into the future! Only possible to set till Tuesday, January 19, 2038 at 3:14:07am UTC.");
    return alert;
}

export default Alert;