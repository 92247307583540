import * as OrderType from './Order';
import * as UserType  from './User';
import rehash         from '../utils/rehash';

export interface Dehydrated<T extends OrderType.IdTypes> {
    usersById : Record<number,UserType.Dehydrated>;
    orders    : OrderType.Dehydrated<T>[];
}

export const dehydrate = <T extends OrderType.IdTypes>( orders:OrderType.Hydrated<OrderType.Order<T>>[] ) : Dehydrated<T> => {
    return orders?.reduce((acc,order) => {
        acc.orders.push(OrderType.dehydrate(order,acc.usersById));
        return acc;
    },{
        usersById : {},
        orders    : []
    } as Dehydrated<T>);
}
export const hydrate = <T extends OrderType.IdTypes>( dehydrated:Dehydrated<T> ) : OrderType.Hydrated<OrderType.Order<T>>[] => {
    const usersById = rehash(dehydrated.usersById,user=>UserType.hydrate(user));
    return dehydrated.orders.map(o=>OrderType.hydrate(o,usersById));
}
