const dviDefectCheckboxes = [
    {
        "airCompressor"    : "Air Compressor",
        "airLines"         : "Air Lines",
        "battery"          : "Battery",
        "beltsAndHoses"    : "Belts And Hoses",
        "body"             : "Body",
        "brakeAccessories" : "Brake Accessories",
        "brakesParking"    : "Brakes, Parking",
        "brakesService"    : "Brakes, Service",
        "clutch"           : "Clutch",
        "couplingDevices"  : "Coupling Devices",
        "defrosterHeater"  : "Defroster/Heater",
        "driveLine"        : "Drive Line",
        "engine"           : "Engine",
        "exhaust"          : "Exhaust",
        "fifthWheel"       : "Fifth Wheel",
        "fluidLevels"      : "Fluid Levels",
        "frameAndAssembly" : "Frame and Assembly"
    },
    {
        "frontAxle"        : "Front Axle",
        "fuelTanks"        : "Fuel Tanks",
        "horn"             : "Horn",
        "headLights"       : "Head Lights",
        "stopLights"       : "Stop Lights",
        "turnIndicators"   : "Turn Indicators Lights",
        "clearanceMarker"  : "Clearance Marker Light",
        "mirrors"          : "Mirrors",
        "muffler"          : "Muffler",
        "oilPressure"      : "Oil Pressure",
        "radiator"         : "Radiator",
        "rearEnd"          : "Rear End",
        "reflectors"       : "Reflectors"
    },
    {
        "fireExtinguisher"    : "Fire Extinguisher",
        "flagsFlaresFuses"    : "Flags/Flares/Fuses",
        "reflectiveTriangles" : "Reflective Triangles",
        "spareBulbsAndFuses"  : "Spare Bulbs and Fuses",
        "spareSealBeam"       : "Spare Seal Beam",
        "starter"             : "Starter",
        "steering"            : "Steering",
        "suspensionSystem"    : "Suspension System",
        "tireChains"          : "Tire Chains",
        "tires"               : "Tires",
        "transmission"        : "Transmission",
        "tripRecorder"        : "Trip Recorder",
        "wheelsAndRims"       : "Wheels And Rims",
        "windows"             : "Windows",
        "windshieldWipers"    : "Windshield Wipers",
        "other"               : "Other"
    }
] as Record<string,string>[];

export default dviDefectCheckboxes;
