import React                from 'react';

import dayjs                from 'shared/utils/day-timezone';

import * as MenuItem        from 'components/MenuItem';
import Alert                from 'utils/Alert';
import getApiPromise        from 'utils/getApiPromise';
import tectransit           from "utils/TecTransit";

import * as AlertType       from 'shared/types/Alert';

export interface Props {
}
export default function Alerts( props:Props ) {
    let alert = new Alert("");
    const [alerts, setAlerts] = React.useState<AlertType.Alert[]>([]);

    // grab the alerts
    const refreshAlerts = () => {
        return getApiPromise<AlertType.Alert[]>('/api/manager/alerts', "GET")
            .then( alertsArr => {
                if( alertsArr.err )
                    throw Error(alertsArr.err);
                setAlerts(alertsArr);
            })
            .catch( err => {
                alert.set(`Cannot get alerts (${err.message})`)
            })
    }
    const submitAlert = (alertBodyText:string, startDate:string, endDate:string) => {
        // > dayjs.default("Asdadasd").valueOf()
        // NaN
        // >
        // If the string does not represent a parseable date, then dayjs(...).valueOf() will return `NaN`.
        // and this `NaN` will get checked inside of AlertType.isValid<T> and the exception will be thrown.
        const text         = String(alertBodyText||'');
        const startSeconds = dayjs(startDate,'YYYY-MM-DDTHH:mm').valueOf()/1000;
        const endSeconds   = dayjs(endDate,'YYYY-MM-DDTHH:mm').valueOf()/1000;
        // check that the alert is postable
        try {
            const alert_ = AlertType.isValid({text,start_seconds: startSeconds,end_seconds: endSeconds});
            return getApiPromise('/api/manager/alert',"POST",alert_,undefined)
                .then( so => {
                    if (!so || so.err)
                        throw Error(so?.err || 'unknown error');
                    alert.set(`Alert is created`,3000);
                    refreshAlerts();
                    (document.getElementById('alert-body-text') as HTMLTextAreaElement).value = "";
                })
                .catch( err => {
                    alert.set(`Cannot create alert (${err.message})`);
                });
        }
        catch( err ) {
            alert.set(`Alert is invalid (${(err as Error).message})`);
        };
    }
    const deleteAlert = (id : string) => {
        return getApiPromise('/api/manager/alert', "DELETE", undefined, {id: id})
            .then( del => {
                if (!del || del.err)
                    throw Error(del?.err || 'unknown error');
                alert.set(`Deleted alert!`, 1500);
                refreshAlerts();
            })
            .catch( err => {
                alert.set(`Cannot delete alert (${err.message})`);
            });
    }

    // Do the inital alerts load
    React.useEffect(() => {
        refreshAlerts(); // call once so alerts actually show up
    },[]);

    // This will return a menu item with the elements inside
    return MenuItem.withMenuItem("Alerts", (alert_) => {
        // set new alert
        alert = alert_;

        if (alerts === undefined)
            return   <div>Loading Alerts...</div>

        // adding alerts
        const mmntStart      = dayjs().tz(tectransit.agency.time_zone).format("YYYY-MM-DDT00:00");
        const mmntEnd        = dayjs().tz(tectransit.agency.time_zone).add(5,"years").format("YYYY-MM-DDT00:00");
        const dateNowSeconds = Date.now()/1000;
        const alertsTable = (
            <table className='table-theme' style={{width:"100%"}}>
                <thead>
                    <tr>
                        <th style={{width:"50%"}}>Message</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Status</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                <tr>
                    <td>
                        <textarea id='alert-body-text' cols={2} placeholder='Create alert using 500 characters or less.'
                                  style={{minWidth: "200px",maxWidth: "400px",minHeight: "50px",maxHeight: "150px"}}></textarea>
                    </td>
                    <td>
                        <input id='alert-start-date' type="datetime-local" min={mmntStart} max={mmntEnd}/>
                    </td>
                    <td>
                        <input id='alert-end-date' type="datetime-local" min={mmntStart} max={mmntEnd}/>
                    </td>
                    <td>
                        {'in-progress...'}
                    </td>
                    <td>
                        <button
                            className   = "btn-theme btn-add"
                            onClick     = {(e) => {
                                return submitAlert(
                                    (document.getElementById('alert-body-text') as HTMLTextAreaElement).value,
                                    (document.getElementById('alert-start-date') as HTMLInputElement).value,
                                    (document.getElementById('alert-end-date') as HTMLInputElement).value
                                );
                            }}
                        >
                            Submit
                        </button>
                    </td>
                </tr>
                    {alerts.map((alert) => {
                        const [
                            status,
                            backgroundColor
                        ] = (alert.end_seconds < dateNowSeconds) ? ["expired","#ff6363"] :
                            (alert.start_seconds > dateNowSeconds) ? ["upcoming","#fff954"] :
                            ["active","#24ff57"];
                        return (
                            <tr key={alert._id}>
                                <td style={{width:"50%",wordWrap : "break-word"}}>
                                    {alert.text}
                                </td>
                                <td>
                                    {dayjs(alert.start_seconds*1000).tz(tectransit.agency.time_zone).format(tectransit.timeFormat)}
                                </td>
                                <td>
                                    {dayjs(alert.end_seconds*1000).tz(tectransit.agency.time_zone).format(tectransit.timeFormat)}
                                </td>
                                <td style={{backgroundColor}}>
                                    {status}
                                </td>
                                <td>
                                    <button
                                        className   = "btn-theme btn-delete"
                                        style       = {{display:"block",backgroundColor:"#919191"}}
                                        onClick     = {(e) => {
                                            if (e.currentTarget.textContent === "Delete?") {
                                                e.currentTarget.style.backgroundColor = "#ff6161"
                                                e.currentTarget.textContent = "Click to confirm.";
                                            }
                                            else {
                                                deleteAlert(alert._id!);
                                            }
                                        }}
                                    >
                                        Delete?
                                    </button>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan={6}>Total number of alerts: {alerts.length}</td>
                    </tr>
                </tfoot>
            </table>
        );
        return (
            <div className='wrapper'>
                <div className="section-card__header">
                    <div className="section-card__title">Alerts</div>
                    <span>
                        These get shown on public pages.
                        The dates are in the agency time zone ({tectransit.agency.time_zone})
                    </span>
                </div>
                {alertsTable}
            </div>
        );
    });
}
