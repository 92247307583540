import * as InfiniteTableType from 'components/InfiniteTable';

export interface Props extends InfiniteTableType.Props {
    min_ts              : string;
    max_ts              : string;
    getTablePromiseArgs : (orderby:string,limit:number,skip:number) => any[];
}

export default abstract class DateRangeTable<T extends Record<string,any>> extends InfiniteTableType.InfiniteTable<T> {

    // @ts-expect-error TS2564
    public  props : Props;

    static getDerivedStateFromProps( props:Props, state:Record<string,any> ) : any {
        if( state.initialLoad ) {
            //console.log(`getDerivedStateFromProps:still loading`);
            return {...props};
        }
        if( (state.min_ts===props.min_ts) && (state.max_ts===props.max_ts) ) {
            //console.log(`getDerivedStateFromProps:nothing has changed`);
            return {};
        }
        console.log(`getDerivedStateFromProps:props have changed`,{props,state});
        return {
            initialLoad       : true,
            hasMore           : true,
            rows              : [],
            correctPageLoaded : 1,
            ...props
        };
    }
}

