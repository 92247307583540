import * as DnDType                     from 'react-beautiful-dnd';

import * as ManifestType                from 'shared/types/Manifest';
import * as DurationType                from 'shared/types/Duration';
import * as OrderType                   from 'shared/types/Order';
import dayjs                            from 'shared/utils/day-timezone';

import tectransit                       from 'utils/TecTransit';
import * as googleMapsLinks             from 'utils/googleMapsLinks';
import * as SchedulableType             from 'Dispatcher/utils/Schedulable';
import WhiteboardContext                from 'Dispatcher/utils/WhiteboardContext';
import FixedRouteDraftVehicleManifest   from 'Dispatcher/utils/FixedRouteDraftVehicleManifest';
import WhiteboardDraggable              from 'Dispatcher/components/WhiteboardDraggable';

export class FixedRouteWhiteboardContext extends WhiteboardContext<SchedulableType.FixedRouteSchedulable> {

    static DraftVehicleManifestConstructor  = FixedRouteDraftVehicleManifest;

    getSchedulablesById() {
        return (this.agencyManifest.fixedRouteTripsById||{}) as Record<string,SchedulableType.FixedRouteSchedulable>;
    }
    getDraftVehicleManifestFromVM(vm: ManifestType.VehicleManifest.Hydrated<OrderType.Order<string>>): FixedRouteDraftVehicleManifest {
        return new FixedRouteDraftVehicleManifest(this,vm,vm.steps);
    }
    onDragEnd( dragUpdate:DnDType.DragUpdate ) : Record<string,FixedRouteDraftVehicleManifest> {
        if( !dragUpdate.destination )
            throw Error(`drag destination is empty`);
        const draggableIdRE                 = /^fixedRoute_(.+)$/;
        const droppableRE                   = /^vehicle_(\d+)_step_(-?\d+)(?:_(\d))$/;
        const [,srcVehicleId,srcStepNdx,]   = dragUpdate.source.droppableId.match(droppableRE)||[0,-1,-1,0];
        const [,dstVehicleId,dstStepNdx,]   = dragUpdate.destination.droppableId.match(droppableRE)||[0,-1,-1,0];
        const [,draggedFixedRouteId]        = dragUpdate.draggableId.match(draggableIdRE)||[0,""];
        const draggedFixedRoute             = this.getSchedulablesById()[draggedFixedRouteId];
        const srcVehicleManifest            = this.draftVehicleManifestsById[srcVehicleId] as FixedRouteDraftVehicleManifest;
        const dstVehicleManifest            = this.draftVehicleManifestsById[dstVehicleId] as FixedRouteDraftVehicleManifest;
        console.debug({
            'source.droppageId' : dragUpdate.source.droppableId,
            'destination,dropppableId' : dragUpdate.destination.droppableId,
            draggedFixedRouteId,
            srcVehicleId,
            dstVehicleId,
            srcStepNdx,
            dstStepNdx
        });
        if( !draggedFixedRoute )
            throw Error(`Cannot find fixed route by id '${draggedFixedRouteId}'`);
        else if( srcVehicleManifest && dstVehicleManifest ) {
            if( srcVehicleManifest===dstVehicleManifest ) {
                // This means that we are dragging fixed routes events within the vehicle itself
                // This is not supposed because location of the fixed routes in vehicle is 100%
                // by the time of the first FR step. Just do not do anything in this case.
                return {
                };
            }
            else {
                // This means that we are dragging fixed route from one vehicle to another
                return {
                    [srcVehicleId] : srcVehicleManifest.getWithoutSchedulable(this,draggedFixedRoute),
                    [dstVehicleId] : dstVehicleManifest.getWithSchedulable(this,draggedFixedRoute)
                };
            }
        }
        else if( (dragUpdate.source.droppableId==='fixed_routes') && dstVehicleManifest ) {
            // Order is moved from fixed routes list down to the vehicles
            if( draggedFixedRoute.vehicle ) {
                this.alert.set(
                    `This fixed route is already allocated to vehicle '${draggedFixedRoute.vehicle.license_plate||'??'}, ${draggedFixedRoute.vehicle.license_state||'??'}'`,
                    6000
                );
            }
            else {
                return {
                    [dstVehicleId] : dstVehicleManifest.getWithSchedulable(this,draggedFixedRoute)
                };
            }
        }
        else if( srcVehicleManifest && (dragUpdate.destination.droppableId==='fixed_routes') ) {
            // Order is moved back from vehicles to the order list
            if( !draggedFixedRoute.vehicle ) {
                this.alert.set(
                    `This fixed route is not allocated to a vehicle`,
                    6000
                );
            }
            else {
                return {
                    [srcVehicleId] : srcVehicleManifest.getWithoutSchedulable(this,draggedFixedRoute)
                };
            }
        }
        return {
        };
    }
    getHeaderText() : JSX.Element {
        return (<>
            During <b>{dayjs(this.agencyManifest.dayStartAt).tz(tectransit.agency.time_zone).format('MM/DD/YYYY')}</b>&nbsp;
            the agency has <b>{this.agencyManifest.vehicleManifests.length||'no'}</b> vehicle manifests&nbsp;
            and <b>{Object.keys(this.agencyManifest.fixedRouteTripsById||{}).length||'no'}</b> trips.
        </>);
    }
    getSchedulablesTable(): JSX.Element {
        const schedulables = Object.values(this.getSchedulablesById()).sort((fr1,fr2)=>(fr1._id-fr2._id));
        const columnNames = ["Vehicle","Trip","First Stop","Last Stop"];
        const droppableId = `fixed_routes`;
        return (
            <DnDType.Droppable key={droppableId} droppableId={droppableId}>
                {(provided) => {
                    return (<span {...provided.droppableProps} ref={provided.innerRef}>
                        <table style={{/*'border':'1px solid black',*/'width':'100%'}}>
                            <tbody style={{verticalAlign:"top",textAlign:'left'}}>
                                <tr>
                                    {columnNames.map( name => {
                                        return (<th key={name} align="left">{name}</th>);
                                    })}
                                </tr>
                                {schedulables.map( (fr,ndx) => {
                                    const key = `${fr._id!}@${fr.vehicle ? fr.vehicle._id : -1}`;
                                    return (
                                        <tr key={key} style={fr.vehicle ? {} : {backgroundColor:'red'}}>
                                            <td>{fr.vehicle?._id||'n/a'}</td>
                                            <td>
                                                <WhiteboardDraggable
                                                    draggableId = {`fixedRoute_${fr._id!}`}
                                                    index       = {ndx}>
                                                    <strong>{fr.name}</strong>
                                                </WhiteboardDraggable>
                                            </td>
                                            <td>
                                                {googleMapsLinks.getPlace(fr.stops[0],fr.stops[0].name)} @ {fr.stops[0].stop_time.arrival_time}
                                            </td>
                                            <td>
                                                {googleMapsLinks.getPlace(fr.stops.at(-1),fr.stops.at(-1)!.name)} @ {fr.stops.at(-1)!.stop_time.arrival_time}
                                            </td>
                                        </tr>
                                    );
                                })}
                                <tr>
                                    <th align="center" colSpan={columnNames.length}>
                                        {schedulables.length} fixed routes, {schedulables.reduce((acc,so)=>(acc+(so.vehicle?0:1)),0)||'no'} to be scheduled
                                    </th>
                                </tr>
                            </tbody>
                        </table>
                        {provided.placeholder}
                    </span>);
                }}
            </DnDType.Droppable>
        );
    }
    getDraftVehicleManifestStepCards( dvm:FixedRouteDraftVehicleManifest ) :  React.ReactNode[] {
        const tableStyle = {
            background      : (dvm.isIntact ? '#ffffff' : '#f0dd25'),
            borderRadius    : '0.5rem',
            boxShadow       : '0 4px 25px 0 rgb(0 0 0 / 10%)',
            padding         : '0.5rem',
            marginTop       : '5px',
            marginBottom    : '5px',
            width           : '100%'
        };
        let draggableNdx = 0;
        const getFixedRouteDraggable = ( draggableId:string, frTrip:ManifestType.FixedRouteTrip.Hydrated ) : React.ReactNode => {
            return (<WhiteboardDraggable
                key         = { draggableId }
                draggableId = { draggableId }
                index       = { draggableNdx++ }>
                {' '}
                { frTrip.name }
            </WhiteboardDraggable>);
        }
        return dvm.steps.map((step,stepNdx) => {
            const [
                stepName,
                stepLatLng,
                stepAddress
            ] = step.frTrip ? [
                getFixedRouteDraggable(`fixedRoute_${step.frTrip._id}`,step.frTrip),
                step.frTrip.stops[0],
                step.frTrip.stops[0].name
            ] : [
                step.name,
                step.latlng,
                step.address
            ];
            const secondsStyle = step.problem ? {color:'red',fontWeight:'bold'} : {};
            const stepMoment   = step.seconds ? dayjs(step.seconds*1000).tz(tectransit.agency.time_zone) : undefined;
            return (<table key={`${stepNdx}_${step.name}`} style={tableStyle}>
                <tbody>
                    {stepLatLng && (
                        <tr>
                            <td colSpan={3} valign="top">{googleMapsLinks.getPlace(stepLatLng,stepAddress,googleMapsLinks.singleLineAddressFormatter)}</td>
                        </tr>
                    )}
                    <tr>
                        <td style={{width:'10%',verticalAlign:'top',...secondsStyle}} title={step.problem||stepMoment?.format(tectransit.timeFormat)||''}>
                            {stepMoment?.format('HH:mm')||'??'}
                        </td>
                        <td style={{width:'65%',verticalAlign:'top'}}>
                            <strong>{stepName}</strong>
                        </td>
                        <td style={{width:'100px'}}>
                            { step.duration ? DurationType.secondsToHMS(step.duration,60) : '??' }
                        </td>
                    </tr>
                </tbody>
            </table>);
        });
    }
    getDraftVehicleManifestSteps( dvm:FixedRouteDraftVehicleManifest ) :  React.ReactNode {
        return this.getDraftVehicleManifestStepCards(dvm).map( (card,cardNdx) => {
            // We need to wrap every card into a <DnDType.Droppable/> object and - in addition -
            // insert <DnDType.Droppable/> objects in between the cards so that the dispatchers
            // can drag a card in between 2 other cards
            const droppableId = `vehicle_${dvm.originalVm.vehicle_id}_step_${cardNdx}`;
            return (
                <DnDType.Droppable key={droppableId+"_1"} droppableId={droppableId+"_1"}>
                    {(provided) => {
                        return (
                            <div className="col" {...provided.droppableProps} ref={provided.innerRef}>
                                {card}
                                {provided.placeholder}
                            </div>
                        );
                    }}
                </DnDType.Droppable>
            );
        });
    }
    getDraftVehicleManifestStops( dvm:FixedRouteDraftVehicleManifest ) : JSX.Element {
        return <strong>{this.vehiclesById[dvm.originalVm.vehicle_id]?.fixed_route_name||'??'}</strong>;
    }
}

export default FixedRouteWhiteboardContext;