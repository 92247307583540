import React             from 'react';
import { useParams }     from 'react-router-dom';

import * as images       from 'images/';
import MenuItem          from 'components/MenuItem';
import UnassignedOrders  from 'Dispatcher/components/UnassignedOrders';

export default function Unassigned() {
    const params = useParams();
    return (
        <MenuItem title={["Orders assigned to unavailable vehicles or uncompleted",<a key='download' href='/api/dispatcher/orders/unassigned?csv'><images.Download/></a>]}>
            <UnassignedOrders {...params}/>
        </MenuItem>
    );
}
