import React        from 'react';

import {
    DateRangePicker,
    defaultInputRanges,
    InputRange
} from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import dayjs        from 'shared/utils/day-timezone';
import tectransit   from 'utils/TecTransit';
import MenuItem     from 'components/MenuItem';

interface Props {
}

export default abstract class DateRangeReport extends React.Component {

    private maxDate     : Date;
    private minDate     : Date;
    private inputRanges : InputRange[];
    public state        : {
        min_ts : string;
        max_ts : string;
    }

    abstract getTitle() : (string|React.ReactNode);
    abstract getTable() : React.ReactNode;

    constructor( props:Props ) {
        super(props);
        // Min and Max dates are calculated in agency's timezone
        const max_ts = dayjs().tz(tectransit.agency.time_zone).endOf('day');
        const min_ts = dayjs(max_ts).subtract(10,'days').startOf('day');
        this.state = {
            min_ts : min_ts.format('YYYY-MM-DD'),
            max_ts : max_ts.format('YYYY-MM-DD')
        };
        this.maxDate     = new Date(max_ts.format('YYYY-MM-DD'));
        this.minDate     = new Date(max_ts.subtract(1, 'year').format('YYYY-MM-DD'));
        this.inputRanges = defaultInputRanges.filter(r=>(r.label!=='days starting today'));
    }
    render() {
        return (
            <MenuItem title={this.getTitle()}>
                <div className="content container-fluid container center">
                    <DateRangePicker
                        ranges          = {[{
                            startDate: new Date(this.state.min_ts),
                            endDate:   new Date(this.state.max_ts),
                            key:       'selection',
                        }]}
                        onChange        = {(ranges)=>{
                            this.setState({
                                min_ts : dayjs(ranges.selection.startDate!).format('YYYY-MM-DD'),
                                max_ts : dayjs(ranges.selection.endDate!).format('YYYY-MM-DD')
                            });
                        }}
                        months          = {2}
                        minDate         = {this.minDate}
                        maxDate         = {this.maxDate}
                        showDateDisplay = {true}
                        showPreview     = {true}
                        direction       = {'horizontal'}
                        inputRanges     = {this.inputRanges}
                    />
                </div>
                {this.getTable()}
            </MenuItem>
        );
    }
}
