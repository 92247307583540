import LatLng           from './LatLng';
import arrayTrimEnd     from '../utils/arrayTrimEnd';

export interface SavedAddress {
    address  : string;
    location : LatLng;
};

export interface ChangedRateLog {
    seconds?        : number;
    rate?           : number;
};

export type Role = "Passenger"  | "Driver" | "Dispatcher" | "Manager" | "Maintenance" | "Observer";

export interface User {
    _id                              : number;
    created_at?                      : Date;
    updated_at?                      : Date;
    email?                           : string;
    name?                            : string;
    last_login?                      : Date;
    last_login_device_id?            : string;
    last_logout?                     : Date;
    roles                            : Role[];
    provider                         : string; // identity provider
    provider_id?                     : string; // This is the *provider* id, e.g. `109312895902863977575` for `google`
    picture                          : string;
    special_accommodations_on_order? : boolean;
    special_accommodations           : Record<string,boolean>;
    mode_of_transportation           : Record<string,boolean>;
    demographics_profile             : Record<string,string>;
    given_name?                      : string;
    family_name?                     : string;
    phone?                           : string;
    ada_certification?               : string;
    ada_certification_issued?        : number;
    ada_certification_expiry?        : number;
    on_vacation_till?                : number;  // milliseconds, in UTC
    addresses?                       : Record<string,SavedAddress>;
    password?                        : string;  // sha256 hash
    password_recovery_tokens?        : Record<string,number>;
    location?                        : LatLng;  // for some users (e.g. drivers) we do know the location
    suppressed_notifications?        : string[];// list of notification types NOT to send to the user
    // driver properties when user has "Driver" role
    driving_license_type?            : string;
    driving_license_number?          : string;
    last_training_date?              : string;
    driving_license_expiration?      : string;
    w4_date?                         : string;
    regular_rate?                    : ChangedRateLog[];
    overtime_rate?                   : ChangedRateLog[];

}

export type Hydrated = Omit<User,'password'|'password_recovery_tokens'|'_json'>;

export type Dehydrated = [
    number,                         // _id
    number|undefined,               // created_at
    number|undefined,               // updated_at
    string|undefined,               // email
    string|undefined,               // name
    number|undefined,               // last_login
    number|undefined,               // last_logout
    Role[],                         // roles
    string,                         // identity provider
    string|undefined,               // provider id
    string,                         // picture
    boolean|undefined,              // special_accommodations_on_order
    Record<string,boolean>,         // special_accommodations
    Record<string,boolean>,         // mode_of_transportation
    Record<string,string>,          // demographics_profile
    string?,                        // given_name
    string?,                        // family_name
    string?,                        // phone
    string?,                        // ada_certification
    number?,                        // ada_certification_issued
    number?,                        // ada_certification_expiry
    number?,                        // on_vacation_till
    Record<string,SavedAddress>?,   // addresses
    LatLng?,                        // location
    string?,                        // driving_license_type
    string?,                        // driving_license_number
    string?,                        // last_training_date
    string?,                        // driving_license_expiration
    string?,                        // w4_date
    ChangedRateLog[]?,              // regular_rate
    ChangedRateLog[]?,              // overtime_rate
];

export const hydrate = ( user:Dehydrated ) : Hydrated => {
    return {
        _id                         : user[0],
        created_at                  : user[1] ? new Date(user[1]) : undefined,
        updated_at                  : user[2] ? new Date(user[2]) : undefined,
        email                       : user[3],
        name                        : user[4],
        last_login                  : user[5] ? new Date(user[5]) : undefined,
        last_logout                 : user[6] ? new Date(user[6]) : undefined,
        roles                       : user[7],
        provider                    : user[8],
        provider_id                 : user[9],
        picture                     : user[10],
        special_accommodations_on_order : user[11],
        special_accommodations      : user[12],
        mode_of_transportation      : user[13],
        demographics_profile        : user[14],
        given_name                  : user[15],
        family_name                 : user[16],
        phone                       : user[17],
        ada_certification           : user[18],
        ada_certification_issued    : user[19],
        ada_certification_expiry    : user[20],
        on_vacation_till            : user[21],
        addresses                   : user[22],
        location                    : user[23],
        driving_license_type        : user[24],
        driving_license_number      : user[25],
        last_training_date          : user[26],
        driving_license_expiration  : user[27],
        w4_date                     : user[28],
        regular_rate                : user[29],
        overtime_rate               : user[30]
    };
}

export const dehydrate = ( user:Hydrated ) : Dehydrated => {
    // Users are dehydrated before they are sent to the client
    const dehydrated = [
        user._id,
        user.created_at ? user.created_at.valueOf() : undefined,
        user.updated_at ? user.updated_at.valueOf() : undefined,
        user.email,
        user.name,
        user.last_login ? user.last_login.valueOf() : undefined,
        user.last_logout ? user.last_logout.valueOf() : undefined,
        user.roles,
        user.provider,
        user.provider_id,
        user.picture,
        user.special_accommodations_on_order,
        user.special_accommodations,
        user.mode_of_transportation,
        user.demographics_profile,
        user.given_name,
        user.family_name,
        user.phone,
        user.ada_certification,
        user.ada_certification_issued,
        user.ada_certification_expiry,
        user.on_vacation_till,
        user.addresses,
        user.location,
        user.driving_license_type,
        user.driving_license_number,
        user.last_training_date,
        user.driving_license_expiration,
        user.w4_date,
        user.regular_rate,
        user.overtime_rate,
    ];
    return arrayTrimEnd(dehydrated) as Dehydrated;
}

export const phoneRegexp   = /^[0-9]{7,}$/;
export const emailRegexp   = /^([a-zA-Z0-9_\-.+]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/;

export default User;
