export const arrayTrimEnd = ( array:any[] ) : any[] => {
    // Throw out all undefs at the end
    const reverseNotUndefNdx = array.findIndex((e,ndx)=>{
        const element = array[array.length-1-ndx];
        return (element!==undefined) && (element!==null);
    });
    if( reverseNotUndefNdx<0 )
        return []; // This means all values are undefs
    return array.slice(0,array.length-reverseNotUndefNdx);
}

export default arrayTrimEnd;

