import * as UserType            from './User';
import * as OrderType           from './Order';
import * as FixedRouteType      from './FixedRoute';
import * as LatLngType          from './LatLng';
import * as AgencyType          from './Agency';
import rehash                   from '../utils/rehash';
import hashById                 from '../utils/hashById';

export type StepName            = ('pre-trip'|'stop'|'depot'|'break'|'fixedroute'|'driving'|'standby'|'post-trip');

export const DepotLatLngSteps   = {
    // The latlng of these steps is always the depot
    'pre-trip'  : 1,
    'depot'     : 1,
    'post-trip' : 1
} as Record<StepName,any>;

export namespace Order {

    export const stepsFilter = <T extends OrderType.OrderTypes>( s:Step.Hydrated<T> ) => {
        return s.name==='stop';
    }
    export const areEqual = <T extends OrderType.OrderTypes>( orders1?:T[], orders2?:T[] ) => {
        // For comparison purposes it does not matter in exactly which order the soIds
        // of two steps are sorted as long as they are sorted in the same compareFn
        // Mongo ObjectId's are sorted in the same way as strings
        // > Array(10).fill().map(()=>mongodb.ObjectId()).sort(()=>(Math.random()-0.5)).sort()
        //[
        //  new ObjectId("63f6a265869897736bf06239"),
        //  new ObjectId("63f6a265869897736bf0623a"),
        //  new ObjectId("63f6a265869897736bf0623b"),
        //  new ObjectId("63f6a265869897736bf0623c"),
        //  new ObjectId("63f6a265869897736bf0623d"),
        //  new ObjectId("63f6a265869897736bf0623e"),
        //  new ObjectId("63f6a265869897736bf0623f"),
        //  new ObjectId("63f6a265869897736bf06240"),
        //  new ObjectId("63f6a265869897736bf06241"),
        //  new ObjectId("63f6a265869897736bf06242")
        //]
        //
        return ((orders1||[]).map(o=>o._id).sort().join(',')===((orders2||[]).map(o=>o._id).sort().join(',')));
    }
}

export namespace FixedRouteTrip {
    export interface Hydrated extends FixedRouteType.DBTrip {
        stops   : FixedRouteType.Stop[];
    }
    export type Dehydrated = [
        // Types representing trip
        number,
        string,
        number,
        string,
        string,
        // Type representing an array of stop times
        [
            number,
            string,
            string,
            number,
            number
        ][]
    ];
    export const dehydrate = (
        frTrip              : Hydrated,
        fixedRouteStopsById : Record<number,FixedRouteType.DBStop>
    ) : Dehydrated => {
        if( !fixedRouteStopsById )
            throw Error(`fixedRouteStopsById are empty`);
        return [
             frTrip._id,
             frTrip.service_id,
             frTrip.shape_id,
             frTrip.name,
             frTrip.route_name,
             frTrip.stops.map( stop=> {
                 fixedRouteStopsById[stop._id] = {
                    lat : stop.lat,
                    lng : stop.lng,
                    _id : stop._id,
                    name: stop.name
                 };
                 const st = stop.stop_time;
                 return [
                     st.stop_id,
                     st.arrival_time,
                     st.departure_time,
                     st.stop_sequence,
                     st.shape_dist_traveled
                 ];
             })
        ]
     };
     export const hydrate = (
        dehydratedFixedRoute : Dehydrated,
        fixedRouteStopsById  : Record<number,FixedRouteType.DBStop>
     ) : Hydrated => {
        if( !fixedRouteStopsById )
            throw Error(`fixedRouteStopsById are empty`);
        return {
            _id         : dehydratedFixedRoute[0],
            service_id  : dehydratedFixedRoute[1],
            shape_id    : dehydratedFixedRoute[2],
            name        : dehydratedFixedRoute[3],
            route_name  : dehydratedFixedRoute[4],
            stops       : dehydratedFixedRoute[5].map( stArray => {
                const st = {
                    trip_id             : dehydratedFixedRoute[0],
                    stop_id             : stArray[0],
                    arrival_time        : stArray[1],
                    departure_time      : stArray[2],
                    stop_sequence       : stArray[3],
                    shape_dist_traveled : stArray[4]
                } as FixedRouteType.DBStopTime;
                return {
                    ...((fixedRouteStopsById[st.stop_id]||{}) as FixedRouteType.DBStop),
                    _id       : st.stop_id,
                    stop_time : st
                }
            })
        }
    };
    export const stepsFilter = <T extends OrderType.OrderTypes>( s:Step.Hydrated<T> ) => {
        return s.name==='fixedroute';
    }
    export const areEqual = ( fr1?:Hydrated, fr2?:Hydrated ) => {
        if( !fr1 )
            return !fr2;
        if( !fr2 )
            return !fr1;
        return (fr1._id===fr2._id)
            && (fr1.name===fr2.name)
            && (fr1.route_name===fr2.route_name)
            && (fr1.service_id===fr2.service_id)
            && (fr1.shape_id===fr2.shape_id)
            && (fr1.stops.map(s=>`${s._id},${s.stop_time.arrival_time}`).join(';')===fr2.stops.map(s=>`${s._id},${s.stop_time.arrival_time}`).join(';'));
    }
}

export namespace Step {
    export interface Hydrated<T extends OrderType.OrderTypes> {
        // The events of orders can be grouped together for dropoffs and pickups at the same location at the same time.
        // This interface represents such a group. Most commonly there will be only one order in the entire group but
        // a scenario where at a particular stop 5 orders are dropped off and 3 new ones are picked up is supported.
        name                    : StepName;
        latlng?                 : LatLngType.LatLng;    // empty if step is `driving` or `standby` or 'fixeroute'
        address?                : string;               // empty if step is `driving` or `standby` or 'fixeroute'
        seconds?                : number;               // In UTC seconds, empty if step is a part of DraftVehicleManifest
        duration?               : number;               // In seconds, empty if step is a part of DraftVehicleManifest
        etc?                    : string;               // Anything else that server might want to tell to the client
        problem?                : string;               // used in Whiteboard planning
        // Empty unless name is `stop`
        pickups?                : T[];
        dropoffs?               : T[];
        // Empty unless name is 'fixedroute'
        frTrip?                 : FixedRouteTrip.Hydrated;
    }
    export interface Dehydrated<T extends OrderType.IdTypes> extends Omit<Hydrated<OrderType.Order<T>>,'pickups'|'dropoffs'|'frTrip'> {
        // Empty unless name is `stop`
        pickups?                : T[];
        dropoffs?               : T[];
        // Empty unless name is 'fixedroute'
        frTrip?                 : FixedRouteTrip.Dehydrated;
    }
    export const dehydrate = <T extends OrderType.IdTypes>(
        step                    : Hydrated<OrderType.Order<T>>,
        ordersById              : Record<string,OrderType.Dehydrated<T>>,
        usersById               : Record<number,UserType.Dehydrated>,
        fixedRouteStopsById     : Record<number,FixedRouteType.DBStop>
    ) : Dehydrated<T> => {
        const orderReducer = ( acc:T[], order:OrderType.Hydrated<OrderType.Order<T>> ) : T[] => {
            if( order?._id!==undefined ) {
                ordersById[String(order._id!)] = OrderType.dehydrate(order,usersById);
                acc.push(order._id!);
            }
            return acc;
        };
        return {
            ...step,
            latlng      : !step.latlng ? undefined : {
                lat : step.latlng.lat,
                lng : step.latlng.lng
            },
            pickups     : (((step.pickups?.length||0)<1)  ? undefined : step.pickups!.reduce(orderReducer,[] as T[])),
            dropoffs    : (((step.dropoffs?.length||0)<1) ? undefined : step.dropoffs!.reduce(orderReducer,[] as T[])),
            frTrip      : !step.frTrip ? undefined : FixedRouteTrip.dehydrate(step.frTrip,fixedRouteStopsById)
        };
    }
    export const hydrate = <ID extends OrderType.IdTypes,ORDER extends OrderType.OrderTypes>(
        step                    : Dehydrated<ID>,
        ordersById              : Record<string,ORDER>,
        fixedRouteStopsById     : Record<number,FixedRouteType.DBStop>
    ) : Hydrated<ORDER> => {
        const mapOrders = ( ids?:ID[] ) : (ORDER[]|undefined) => {
            if( (ids?.length||0)<1 )
                return undefined;
            return ids!.map(id=>ordersById[String(id)]).filter(o=>!!o);
        };
        return {
            ...step,
            pickups     : mapOrders(step.pickups),
            dropoffs    : mapOrders(step.dropoffs),
            frTrip      : !step.frTrip ? undefined : FixedRouteTrip.hydrate(step.frTrip,fixedRouteStopsById)
        };
    }
    export const areEqual = <T extends OrderType.OrderTypes>( s1?:Hydrated<T>, s2?:Hydrated<T> ) => {
        if( !s1 )
            return !s2;
        if( !s2 )
            return !s1;
        if( (s1.name!==s2.name) || (s1.seconds!==s2.seconds) || (s1.duration!==s2.duration) )
            return false;
        if( LatLngType.getMetersBetween(s1.latlng||{lat:0,lng:0},s2.latlng||{lat:0,lng:0})>5 )
            return false;
        if( s1.name==='fixedroute' )
            return FixedRouteTrip.areEqual(s1.frTrip,s2.frTrip);
        if( s1.name==='stop' )
            return Order.areEqual(s1.pickups,s2.pickups) && Order.areEqual(s1.dropoffs,s2.dropoffs);
        return true;
    }
    export const getBoardingSeconds = <T extends OrderType.OrderTypes>(
        agency  : AgencyType.Agency,
        step    : { pickups?:T[]; dropoffs?:T[]; }
    ) => {
        const reducer        = ((acc:number,so:T)=>acc+AgencyType.getBoardingSeconds(agency,so));
        const dropoffSeconds = step.dropoffs?.reduce(reducer,0)||0;
        const pickupSeconds  = step.pickups?.reduce(reducer,0)||0;
        return dropoffSeconds+pickupSeconds;
    }
    export const getOrdersById = <T extends OrderType.OrderTypes>( steps:Step.Hydrated<T>[] ) : Record<string,OrderType.Hydrated<T>> => {
        const orderArrayReducer = ( acc:Record<string,OrderType.Hydrated<T>>, order:OrderType.Hydrated<T> ) => {
            if( order )
                acc[String(order._id!)] = order;
            return acc;
        };
        return (steps||[]).map(s=>(s.dropoffs||[])).flat().reduce(
            orderArrayReducer,
            (steps||[]).map(s=>(s.pickups||[])).flat().reduce(
                orderArrayReducer,
                {} as Record<string,OrderType.Hydrated<T>>
            )
        );
    }
    export const getFixedRouteTripsById = <T extends OrderType.OrderTypes>( steps:Step.Hydrated<T>[] ) : Record<string,FixedRouteTrip.Hydrated> => {
        return hashById((steps||[]).map(s=>s.frTrip));
    }
}

export namespace VehicleManifest {
    export interface Hydrated<T extends OrderType.OrderTypes> {
        _id                     : T['_id'];                             // i.e. the same type as the order id
        err?                    : string;                               // error message if any
        message?                : string;                               // just a message, not an error
        vehicle_id              : number;
        distance                : number;
        dayStartAt              : number;                               // utc milliseconds
        dayEndAt                : number;                               // utc milliseconds
        calcStartAt             : number;                               // utc milliseconds
        progress                : number;                               // 0..100
        steps                   : Step.Hydrated<T>[];                   //
        requested_at?           : number;                               // When the dispatcher generated this new committment from driver
        committed_driver_id?    : number;                               // Driver to committed to this manifest (not necessarily the current vehicle driver)
        committed_at?           : number;                               // timestamp of the driver's committment
        committed_ip?           : string;                               // the ip the driver used to commit to this route
    };
    export interface Dehydrated<T extends OrderType.IdTypes> extends Omit<Hydrated<OrderType.Order<T>>,'steps'> {
        steps                   : Step.Dehydrated<T>[];
        usersById?              : Record<number,UserType.Dehydrated>;
        ordersById?             : Record<string,OrderType.Dehydrated<T>>;
        fixedRouteStopsById?    : Record<number,FixedRouteType.DBStop>;
    }
    export const dehydrate = <T extends OrderType.IdTypes>( vm:Hydrated<OrderType.Order<T>> ) : Dehydrated<T> => {
        const usersById             = {} as Record<number,UserType.Dehydrated>;
        const ordersById            = {} as Record<number,OrderType.Dehydrated<T>>;
        const fixedRouteStopsById   = {} as Record<number,FixedRouteType.DBStop>;
        return {
            ...vm,
            steps      : (vm.steps||[]).map(step=>Step.dehydrate(step,ordersById,usersById,fixedRouteStopsById)),
            ordersById,
            usersById,
            fixedRouteStopsById
        };
    };
    export const hydrate = <ID extends OrderType.IdTypes,ORDER extends OrderType.OrderTypes>(
        vm                  : Dehydrated<ID>,
        ordersById          : Record<string,ORDER>,
        fixedRouteStopsById : Record<string,FixedRouteType.DBStop> ) : Hydrated<ORDER> => {
        return {
            ...vm,
            steps : (vm.steps||[]).map(step=>Step.hydrate(step,ordersById,fixedRouteStopsById)),
        };
    };
    export const getProblems = <T extends OrderType.IdTypes>( vehicleManifest:Partial<Hydrated<OrderType.Order<T>>> ) : string[] => {
        return [
            vehicleManifest.err,
            ...(vehicleManifest.steps||[]).map(s=>s.problem)
        ].filter(p=>!!p) as string[];
    };
    export const getDehydratedOrderIds = <T extends OrderType.IdTypes>( vehicleManifest:Dehydrated<T> ) : T[] => {
        return Object.values(
            (vehicleManifest?.steps||[]).reduce( (acc,step) => {
                acc = (step.pickups||[]).reduce( (acc,id) => {
                    acc[String(id)] = id;
                    return acc;
                },acc);
                return (step.dropoffs||[]).reduce( (acc,id) => {
                    acc[String(id)] = id;
                    return acc;
                },acc);
            },{} as Record<string,T>)
        );
    };
}

export namespace AgencyManifest {
    export interface Hydrated<T extends OrderType.OrderTypes> {
        message?                : string;                       // just a message, no errors
        dayStartAt              : number;                       // utc milliseconds
        dayEndAt                : number;                       // utc milliseconds
        calcStartAt             : number;                       // utc milliseconds
        progress                : number;                       // 0..100
        vehicleManifests        : VehicleManifest.Hydrated<T>[];
        // `ordersById` are _all_ the orders that need to be planned in this agency manifest,
        // even if some of those orders could not be allocated to specific vehicle manifest
        ordersById              : Record<string,T>;
        // `fixedRouteTripsById` are _all_ the FR trips that need to be planned in this agency
        // manifest, even if some of those FRs could not be allocated to specific vehicle manifest
        fixedRouteTripsById     : Record<number,FixedRouteTrip.Hydrated>;
    };
    export interface Dehydrated<ID extends OrderType.IdTypes> extends Omit<Hydrated<OrderType.Order<ID>>,'vehicleManifests'|'ordersById'|'fixedRouteTripsById'> {
        vehicleManifests        : VehicleManifest.Dehydrated<ID>[];
        usersById               : Record<number,UserType.Dehydrated>;
        ordersById              : Record<string,OrderType.Dehydrated<ID>>;
        fixedRouteStopsById     : Record<number,FixedRouteType.DBStop>;
        fixedRouteTripsById     : Record<number,FixedRouteTrip.Dehydrated>;
    }
    export const assertSameKeys = ( blurb:string, hash1:Record<string,any>, hash2:Record<string,any> ) => {
        const id1 = Object.keys(hash1).find(id=>!(id in hash2));
        if( id1 )
            throw Error(`${blurb} have changed (id ${id1} is not in hash1)`);
        const id2 = Object.keys(hash2).find(id=>!(id in hash1));
        if( id2 )
            throw Error(`${blurb} have changed (id ${id2} is not in hash2)`);
    }
    export const dehydrate = <T extends OrderType.IdTypes>( agencyManifest:Hydrated<OrderType.Order<T>> ) : Dehydrated<T> => {
        const usersById             = {} as Record<number,UserType.Dehydrated>;
        const ordersById            = rehash(agencyManifest.ordersById,order=>OrderType.dehydrate(order,usersById));
        const fixedRouteStopsById   = {} as Record<number,FixedRouteType.DBStop>;
        return {
            ...agencyManifest,
            vehicleManifests : agencyManifest.vehicleManifests.map( vm => {
                const dehydratedVehicleManifest = VehicleManifest.dehydrate(vm);
                Object.assign(ordersById,dehydratedVehicleManifest.ordersById);
                Object.assign(usersById,dehydratedVehicleManifest.usersById);
                Object.assign(fixedRouteStopsById,dehydratedVehicleManifest.fixedRouteStopsById);
                return {
                    ...dehydratedVehicleManifest,
                    // These will get taken over by the same fields in the overall agency manifest
                    ordersById          : undefined,
                    usersById           : undefined,
                    fixedRouteStopsById : undefined,
                };
            }),
            // TODO:
            // When the server sends agency manifest to the client, these values are of course needed.
            // But when the client sends an agency manifest back to the server, these values
            // are not needed because the server can get them from DB by the Id.
            // Perhaps - for the client - we need an option to skip adding these values.
            ordersById,
            usersById,
            fixedRouteStopsById,
            fixedRouteTripsById : rehash(agencyManifest.fixedRouteTripsById,frt=>FixedRouteTrip.dehydrate(frt,fixedRouteStopsById))
        };
    };
    export const hydrate = <ID extends OrderType.IdTypes,ORDER extends OrderType.OrderTypes>(
        agencyManifest      : Dehydrated<ID>,
        ordersById          : Record<string,ORDER>,
        fixedRouteTripsById : Record<number,FixedRouteTrip.Hydrated> ) : Hydrated<ORDER> => {
        assertSameKeys("orders",agencyManifest.ordersById,ordersById);
        assertSameKeys("frtrips",agencyManifest.fixedRouteTripsById,fixedRouteTripsById);
        return {
            ...agencyManifest,
            vehicleManifests : agencyManifest.vehicleManifests.map(vm=>VehicleManifest.hydrate(vm,ordersById,agencyManifest.fixedRouteStopsById!)),
            ordersById,
            fixedRouteTripsById
        };
    };
    export const getProblems = <T extends OrderType.OrderTypes>( agencyManifest:Partial<Hydrated<T>> ) : string[] => {
        return (agencyManifest.vehicleManifests||[]).map(VehicleManifest.getProblems).flat(Infinity).filter(p=>!!p) as string[];
    }
}
