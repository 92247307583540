import React                from 'react';

import * as AgencyType      from 'shared/types/Agency';

import tectransit           from 'utils/TecTransit';
import getApiPromise        from 'utils/getApiPromise';
import Alert                from 'utils/Alert';
import dviDefectCheckboxes  from 'utils/dviDefectCheckboxes';
import * as MenuItem        from 'components/MenuItem';

export interface Props {
}

export default class DVIs extends React.Component {

    private alert               : Alert = new Alert();

    // @ts-expect-error
    public props : Props;

    // Action handlers
    onSaveAgency( e:any, agency:Partial<AgencyType.Agency> ) {
        e.preventDefault();
        e.stopPropagation();
        getApiPromise('/api/manager/agency','PUT',agency)
            .then(res => {
                if (!res || res.err)
                    throw Error(`Cannot save agency ${res?.err||'unknown error'}`);
                tectransit.agency = agency;
                this.alert.set(`Agency is saved`,10000);
                return `agency is updated`;
            })
            .catch( err => {
                this.alert.set(`Cannot save agency (${err.message})`);
            })
    }

    // rendering
    render() {
        return MenuItem.withMenuItem("DVI Checkboxes",( alert_ ) => {
            this.alert = alert_;
            let tabIndex = 1;
            return (
                <div className="content">
                    <div className="wrapper wrap-column">
                        <div className="section-card-inner" style={{width:"50%"}}>
                            <div className="section-card top-1">
                                <div className="section-card__header">
                                    <div className="section-card__title">Daily Vehicle Inspections</div>
                                </div>
                                <div className="setting item-condition">
                                    <div className="item-condition-inn field-group">
                                        <div className="field-group">
                                            <input
                                                type            = "checkbox"
                                                id              = "need_pre_trip"
                                                name            = "need_pre_trip"
                                                defaultChecked  = {!tectransit.agency.skip_dvis}
                                            />
                                            <label htmlFor="need_pre_trip" className="checkbox" style={{whiteSpace:'nowrap'}}>Require Pre-Trip Inspections</label>
                                        </div>
                                    </div>
                                    <div className="item-condition-inn field-group">
                                        <div className="field-group">
                                            <input
                                                type            = "checkbox"
                                                id              = "need_post_trip"
                                                name            = "need_post_trip"
                                                defaultChecked  = {!tectransit.agency.skip_ptis}
                                            />
                                            <label htmlFor="need_post_trip" className="checkbox" style={{whiteSpace:'nowrap'}}>Require Post-Trip Inspections</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="section-card-inner" style={{width:"50%"}}>
                            <div className="section-card top-1">
                                <div className="section-card__header">
                                    <div className="section-card__title">Required DVI Items</div>
                                </div>
                                <div className="setting item-condition">
                                    <div className="item-condition-inn field-group">
                                        {dviDefectCheckboxes.map( (column,ndx) => {
                                            return (
                                                <div key={ndx}>
                                                    {Object.entries(column).map(([key,value]) => (
                                                        <div key={key} className="field-group">
                                                            <input
                                                                type            = "checkbox"
                                                                id              = {key}
                                                                name            = {key}
                                                                defaultChecked  = {!tectransit.agency.hidden_dvi_checkboxes?.includes(key)}
                                                            />
                                                            <label htmlFor={key} className="checkbox" tabIndex={tabIndex++} style={{whiteSpace:'nowrap'}}>{value}</label>
                                                        </div>
                                                    ))}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="btn-wrap">
                        <button className="btn-theme" onClick={e=>this.onSaveAgency(e,{
                            skip_dvis            :  !(document.getElementById('need_pre_trip') as HTMLInputElement)?.checked,
                            skip_ptis            :  !(document.getElementById('need_post_trip') as HTMLInputElement)?.checked,
                            hidden_dvi_checkboxes: dviDefectCheckboxes.map(column=>Object.keys(column))
                                .flat()
                                .map( key => {
                                    return (document.getElementById(key) as HTMLInputElement)?.checked ? undefined : key;
                                })
                                .filter( key => {
                                    return key !== undefined;
                                }) as string[]
                        })}>Save</button>
                    </div>
                </div>
            );
        });
    }
}
