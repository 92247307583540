import React from 'react';

export interface Props {
    name        : string;
    id?         : string;
    checked?    : boolean;
    onChange?   : (checked:(boolean|undefined)) => void;
}
export const ThreeStateCheckbox : React.FC<Props> = props => {
    // Inspiered by https://dirask.com/posts/React-three-state-checkbox-with-indeterminate-property-1yNvvD
    const inputRef   = React.useRef<HTMLInputElement>(null);
    const checkedRef = React.useRef(props.checked);
    const updateInput = (checked:(boolean|undefined)) => {
        const input = inputRef.current;
        if (input) {
            // @ts-expect-error
            input.checked       = checked;
            input.indeterminate = (checked === undefined);
        }
    };
    React.useEffect(() => {
        checkedRef.current = props.checked;
        updateInput(props.checked);
    }, [props.checked]);
    const handleClick = () => {
        switch (checkedRef.current) {
        case true:
            checkedRef.current = undefined;
            break;
        case false:
            checkedRef.current = true;
            break;
        default: // null
            checkedRef.current = false;
            break;
        }
        updateInput(checkedRef.current);
        if (props.onChange)
            props.onChange(checkedRef.current);
    };
    return (
        <input
            ref     = {inputRef}
            type    = "checkbox"
            id      = {props.id}
            name    = {props.name}
            onClick = {handleClick}
        />
    );
}

export default ThreeStateCheckbox;