export default function arrayDedupe<T extends (string|number|undefined|{ _id : (string|number) })>( arr:T[] ) : T[] {
    if( !arr )
        return []; // because arrayDedupe(...[]) produces `arr` as `undefined`
    if( arr.length===0 )
        return [];
    if( typeof arr[0] === 'object' ) {
        const hash = {} as Record<string|number,number>;
        return (arr as { _id : (string|number) }[]).reduce( (a,t) => {
            if( !(t._id in hash) ) {
                hash[t._id] = 1;
                // @ts-expect-error TS2345
                a.push(t);
            }
            return a;
        },[] as T[]);
    }
    else {
        // @ts-expect-error TS2334
        const hash = {} as Record<(string|number|undefined),number>;
        return (arr as (string|number|undefined)[]).reduce( (a,t) => {
            if( !(t! in hash) ) {
                hash[t!] = 1;
                // @ts-expect-error TS2345
                a.push(t);
            }
            return a;
        },[] as T[]);
    }
}
