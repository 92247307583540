import * as OrderMapType     from 'components/OrderMap';
import tectransit            from 'utils/TecTransit';

export interface Props extends OrderMapType.Props {
}

export abstract class ParatransitOrderMap extends OrderMapType.OrderMap {

    protected paratransitBoundaries : (google.maps.Polygon|undefined);

    constructor( props:Props ) {
        super(props);
        if( Array.isArray(tectransit.agency.issue333_paratransit_areas) ) {
            this.paratransitBoundaries = new google.maps.Polygon({
                fillOpacity : 0.1,
                fillColor   : 'red',
                paths       : (tectransit.agency.issue333_paratransit_areas||[])
            });
        }
    }
    componentDidMount() {
        super.componentDidMount();
        if( this.paratransitBoundaries ) {
            this.paratransitBoundaries.setMap(this.googleMap||null);
            this.paratransitBoundaries.addListener('click',(e:google.maps.MapMouseEvent) => {
                return this.mapClickHandler(e);
            });
        }
    }
    componentWillUnmount() {
        if( this.paratransitBoundaries )
            this.paratransitBoundaries.setMap(null);
        super.componentWillUnmount();
    }
}

export default ParatransitOrderMap;