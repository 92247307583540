import React                            from 'react';
import * as DnDType                     from 'react-beautiful-dnd';

import * as AgencyType                  from 'shared/types/Agency';
import * as DistanceType                from 'shared/types/Distance';
import * as UserType                    from 'shared/types/User';
import * as ManifestType                from 'shared/types/Manifest';
import * as VehicleType                 from 'shared/types/Vehicle';
import * as OrderType                   from 'shared/types/Order';
import rehash                           from 'shared/utils/rehash';
import arrayDedupe                      from 'shared/utils/arrayDedupe';
import dayjs                            from 'shared/utils/day-timezone';

import Alert                            from 'utils/Alert';
import getApiPromise                    from 'utils/getApiPromise';
import tectransit                       from 'utils/TecTransit'
import getAgencyEarliestOpenAfter       from "utils/getAgencyEarliestOpenAfter";
import * as MenuItem                    from 'components/MenuItem';
import DraftVehicleManifest             from 'Dispatcher/utils/DraftVehicleManifest';
import * as WhiteboardContextType       from 'Dispatcher/utils/WhiteboardContext';
import VehicleManifests                 from 'Dispatcher/components/VehicleManifests';
import FixedRouteWhiteboardContext      from 'Dispatcher/components/FixedRouteWhiteboardContext';
import OnDemandWhiteboardContext        from 'Dispatcher/components/OnDemandWhiteboardContext';

interface WhiteboardProps {
    context                 : WhiteboardContextType.WhiteboardContext,
    createVehicleManifests  : ( vehicleManifests:DraftVehicleManifest[] )=>void;
}
const Whiteboard : React.FC<WhiteboardProps> = props => {
    const [draftVehicleManifestsById,setDraftVehicleManifestsById] = React.useState<Record<string,DraftVehicleManifest>>(
        props.context.draftVehicleManifestsById
    );
    props.context.setDraftVehicleManifestsbyId(draftVehicleManifestsById);
    const onDragEnd = ( dragUpdate:DnDType.DragUpdate ) => {
        console.log(dragUpdate);
        try {
            setDraftVehicleManifestsById({
                ...draftVehicleManifestsById,
                ...props.context.onDragEnd(dragUpdate)
            });
        }
        catch( err ) {
            props.context.alert.set(`Cannot handle DnD (${(err as Error).message})`,6000);
        }
    };
    const draftVehicleManifests = Object.values(draftVehicleManifestsById);
    const agencyManifestErrors  = ManifestType.AgencyManifest.getProblems(props.context.agencyManifest)
    return (
        <DnDType.DragDropContext onDragEnd={onDragEnd}>
            <div style={{textAlign:'center'}}>
                <div>
                    { (agencyManifestErrors.length>0)
                        ? (<div style={{color:'red'}}>Agency manifest got <b>{agencyManifestErrors.length}</b> errors</div>)
                        : (<div>{props.context.getHeaderText()}</div>)}
                    { props.context.agencyManifest.message && (<div>
                        {props.context.agencyManifest.message}
                    </div>) }
                    { (agencyManifestErrors.length===0) && (<div>
                        Total distance: <b>{DistanceType.fromMeters(draftVehicleManifests.reduce((acc,dvm)=>acc+dvm.originalVm.distance,0)).text}</b>
                    </div>) }
                </div>
                {props.context.getSchedulablesTable()}
                <hr style={{background:'black',height:'5px'}}/>
                <VehicleManifests
                    vehicleManifests    = { draftVehicleManifests.map(dvm=>dvm.originalVm) }
                    driversById         = { props.context.driversById }
                    vehiclesById        = { props.context.vehiclesById }
                    getStatus           = { ( ndx ) => {
                        const dvm      = draftVehicleManifests[ndx];
                        const problems = [props.context.vehiclesById[dvm.originalVm.vehicle_id]?.unavailability_reason,...(dvm.problems||[])].filter(p=>!!p) as string[];
                        const title    = `VM #${dvm.originalVm._id}: ${dvm.originalVm.message||''}`;
                        if( problems.length>0 )
                            return (<span style={{color:'red'}}><strong title={`${title},${problems}`}>{problems.length} problem(s)</strong></span>);
                        if( !dvm.isIntact )
                            return (<span style={{color:'green'}}><strong title={title}>draft</strong></span>);
                        return (<span style={{color:'green'}}><strong title={title}>ok</strong></span>);
                    }}
                    getStops            = { ( ndx ) => {
                        return props.context.getDraftVehicleManifestStops(draftVehicleManifests[ndx]);
                    }}
                    getDistance         = { ( ndx ) => {
                        const dvm = draftVehicleManifests[ndx];
                        if( (dvm.schedulables.length===0) )
                            return '0 mi';
                        if( !dvm.isIntact )
                            return '??';
                        return DistanceType.fromMeters(dvm.originalVm.distance).text;
                    }}
                    getSteps            = { ( ndx ) => {
                        return props.context.getDraftVehicleManifestSteps(draftVehicleManifests[ndx]);
                    }}
                    getRequestedAt      = { ( ndx ) => {
                        const dvm = draftVehicleManifests[ndx];
                        return (dvm.isIntact && Number.isInteger(dvm.originalVm.requested_at)) ? dayjs(dvm.originalVm.requested_at).tz(tectransit.agency.time_zone).format(tectransit.timeFormat) : '-';
                    }}
                    getCommittedBy      = { ( ndx:number ) => {
                        const dvm = draftVehicleManifests[ndx];
                        return (dvm.isIntact && Number.isInteger(dvm.originalVm.committed_driver_id)) ? dvm.originalVm.committed_driver_id : '-';
                    }}
                    getCommittedAt      = { ( ndx:number ) => {
                        const dvm = draftVehicleManifests[ndx];
                        return (dvm.isIntact && Number.isInteger(dvm.originalVm.committed_at)) ? dayjs(dvm.originalVm.committed_at).tz(tectransit.agency.time_zone).format(tectransit.timeFormat) : '-';
                    }}
                />
                <hr style={{background:'black',height:'5px'}}/>
                <button className="btn-theme" style={{margin:'auto'}} onClick={(e:React.MouseEvent<HTMLElement>)=>{
                    e.preventDefault();
                    e.stopPropagation();
                    const notYetScheduledCount = props.context.getNotYetScheduledCount();
                    if( notYetScheduledCount>0 ) {
                        props.context.alert.set(`There are ${notYetScheduledCount} item(s) still to schedule`,5000);
                        return;
                    }
                    if( draftVehicleManifests.every(dvm=>(dvm.isIntact && Number.isInteger(dvm.originalVm.requested_at))) ) {
                        if( !window.confirm(`All vehicle manifests are unchanged and have already been requested a confirmation of. Would you like to re-request it?`) ) {
                            props.context.alert.set(`Cancelled re-requesting of ${draftVehicleManifests.length} unchanges vehicle manifests`,5000);
                            return;
                        }
                    }
                    return props.createVehicleManifests(draftVehicleManifests);
                }}>
                    Create Vehicle Manifests
                </button>
                &nbsp;
            </div>
        </DnDType.DragDropContext>
    );
}

interface Props {
    type : WhiteboardContextType.Type;
}
export default class WhiteboardMenuItem extends React.Component {

    private alert                  : Alert = new Alert();
    private manifestMoment         : dayjs.Dayjs;
    private nextAgencyOpenYYYYMMDD : (string|undefined);

    // @ts-expect-error
    public props   : Props;
    public state   : {
        dehydratedAgencyManifest : (ManifestType.AgencyManifest.Dehydrated<string>&{err?:string})|undefined;
        agencyManifest           : (ManifestType.AgencyManifest.Hydrated<OrderType.Order<string>>|undefined);
        vehiclesById             : (Record<number,VehicleType.Vehicle>|undefined);
        driversById              : (Record<number,UserType.User>|undefined);
    } = {
        dehydratedAgencyManifest : undefined,
        agencyManifest           : undefined,
        vehiclesById             : undefined,
        driversById              : undefined
    };

    private updateState = ( dehydratedAgencyManifest:(ManifestType.AgencyManifest.Dehydrated<string>&{err?:string}) ) => {
        // If there is only error returned then throw it out
        const amProps = Object.keys(dehydratedAgencyManifest);
        if( amProps.length===1 && amProps[0]==='err' )
            throw Error(dehydratedAgencyManifest.err||'unknown error');
        // Looks like there is something else besides an error
        const usersById      = rehash(dehydratedAgencyManifest.usersById,UserType.hydrate);
        const agencyManifest = ManifestType.AgencyManifest.hydrate(
            dehydratedAgencyManifest,
            rehash(dehydratedAgencyManifest.ordersById,order=>OrderType.hydrate(order,usersById)),
            rehash(dehydratedAgencyManifest.fixedRouteTripsById,frt=>ManifestType.FixedRouteTrip.hydrate(frt,dehydratedAgencyManifest.fixedRouteStopsById))
        );
        const allDriverIds = arrayDedupe((agencyManifest.vehicleManifests||[]).map( vm => {
            return [...AgencyType.getDriverIdsByVehicleId(tectransit.agency,vm.vehicle_id),vm.committed_driver_id].filter(did=>!!did) as number[];
        }).flat());
        Promise.all(allDriverIds.map( driver_id => {
            if( (this.state.driversById||{})[driver_id] )
                return this.state.driversById![driver_id]; // this user is already in the state
            // Else do it the hard way
            return getApiPromise<UserType.Dehydrated>('/api/dispatcher/user','GET',undefined,{id:driver_id}).then( driver => {
                return driver ? UserType.hydrate(driver) : {
                    _id  : driver_id,
                    name : `Unknown Driver #${driver_id}`
                } as UserType.Hydrated;
            })
        })).then( drivers => {
            this.setState({
                dehydratedAgencyManifest,
                agencyManifest,
                driversById : drivers.reduce((acc,driver)=>{
                    acc[driver._id] = driver;
                    return acc;
                },{} as Record<string,UserType.User>)
            });
        });
    };

    constructor( props:Props ) {
        super(props);
        this.manifestMoment         = dayjs().tz(tectransit.agency.time_zone).add(1,'day').startOf('day'); // always start from next day
        this.nextAgencyOpenYYYYMMDD = getAgencyEarliestOpenAfter(tectransit.agency,this.manifestMoment)?.format("YYYY-MM-DD");
        Promise.all([
            getApiPromise<ManifestType.AgencyManifest.Dehydrated<string>>(`/api/dispatcher/${props.type}/agencyManifest`,'GET',{},{date:this.nextAgencyOpenYYYYMMDD},180000)
                .then(this.updateState)
                .catch(err => {
                    throw Error(`Cannot get agency manifest (${err.message})`);
                }),
            getApiPromise<VehicleType.Vehicle[]>('/api/dispatcher/vehicles','GET')
                .then( vehicles => {
                    this.setState({
                        vehiclesById : vehicles.reduce((acc,vehicle) => {
                            acc[vehicle._id] = vehicle;
                            return acc;
                        },{} as Record<number,VehicleType.Vehicle>)
                    })
                })
                .catch(err=>{
                    throw Error(`Cannot get vehicles (${err.message})`);
                })
        ]).catch( err => {
            this.alert.set(`Cannot initialize (${err.message})`)
        })
    }

    render() {
        return MenuItem.withMenuItem(`Agency ${this.props.type==='fixedroute'?'Fixed Routes':'On Demand'} Manifest for ${this.nextAgencyOpenYYYYMMDD}`,( alert ) => {

            this.alert = alert;

            if( !this.nextAgencyOpenYYYYMMDD )
                return (<div style={{textAlign:'center'}}>Cannot find when the agency is open after {this.manifestMoment.format('MM/DD/YYYY')}</div>);
            if( !this.state.dehydratedAgencyManifest || !this.state.agencyManifest )
                return (<div style={{textAlign:'center'}}>Loading agency manifest...</div>);
            if( !this.state.vehiclesById )
                return (<div style={{textAlign:'center'}}>Loading vehicles...</div>);
            if( !this.state.driversById )
                return (<div style={{textAlign:'center'}}>Loading drivers...</div>);

            if( this.state.agencyManifest.progress<100 ) {
                return (
                    <div style={{textAlign:'center'}}>
                        <br/>
                        The calculation of the vehicle manifests has started at
                        &nbsp;<strong>{dayjs(this.state.agencyManifest.calcStartAt).tz(tectransit.agency.time_zone).format(tectransit.timeFormat)}</strong>
                        &nbsp;and is now <strong>{this.state.agencyManifest.progress}%</strong> complete.
                        <br/>
                        <button type="button" className="btn-theme btn-small" style={{margin:'auto'}} onClick={(e) => {
                            getApiPromise<ManifestType.AgencyManifest.Dehydrated<string>>(`/api/dispatcher/${this.props.type}/agencyManifest`,'GET',{},{date:this.nextAgencyOpenYYYYMMDD},180000)
                                .then(this.updateState)
                                .catch( err => {
                                    throw Error(`Cannot get agency manifest (${err.message})`);
                                });
                        }}>
                            Refresh
                        </button>
                    </div>
                );
            }

            const WhiteboardConstructor = (this.props.type==='fixedroute') ? FixedRouteWhiteboardContext : OnDemandWhiteboardContext;
            const whiteboardContext     = new WhiteboardConstructor(
                this.props.type,
                alert,
                this.state.dehydratedAgencyManifest,
                this.state.agencyManifest,
                this.state.vehiclesById,
                this.state.driversById
            );

            const createVehicleManifests = ( dvms:DraftVehicleManifest[] ) : void => {
                if( !this.state.dehydratedAgencyManifest ) {
                    this.alert.set('Cannot create vehicle manifests because there is no dehydrated agency manifest');
                    return;
                }
                const dehydratedAgencyManifest : ManifestType.AgencyManifest.Dehydrated<string> = {
                    ...this.state.dehydratedAgencyManifest,
                    vehicleManifests : dvms.map( dvm => {
                        return {
                            ...ManifestType.VehicleManifest.dehydrate({
                                ...dvm.originalVm,
                                steps : dvm.steps
                            }),
                            // Server does not need these values, it needs only dehydrated ids
                            ordersById          : undefined,
                            usersById           : undefined,
                            fixedRouteStopsById : undefined
                        };
                    })
                }
                this.setState({
                    dehydratedAgencyManifest : undefined,
                    agencyManifest           : undefined,
                    driversById              : undefined
                });
                getApiPromise<ManifestType.AgencyManifest.Dehydrated<string>>(`/api/dispatcher/${this.props.type}/agencyManifest`,'POST',dehydratedAgencyManifest,{},180000)
                    .then( dehydratedAgencyManifest => {
                        // since we have just POSTed the new manifest, we need to show the resulting agency manifest
                        // as it is returned by the server, not as it might be stored in the session storage. This is
                        // why let's wipe the session storage out.
                        sessionStorage.removeItem(whiteboardContext.constructor.name);
                        return this.updateState(dehydratedAgencyManifest);
                    })
                    .catch( err=> {
                        this.alert.set(err.message);
                    });
            };
            return (
                <div className="wrapper">
                    <Whiteboard
                        context                 = { whiteboardContext }
                        createVehicleManifests  = { createVehicleManifests }
                    />
                </div>
            );
        });
    }
}

