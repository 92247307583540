import React, {
    useEffect,
    useState,
    useReducer }    from 'react';

import * as UserType      from 'shared/types/User';
import tectransit         from 'utils/TecTransit';
import getApiPromise      from 'utils/getApiPromise';
import EditUserInfo       from 'components/EditUserInfo';
import * as MenuItem      from 'components/MenuItem';

export default function Profile() {

    const [/*ignored*/,forceUpdate]  = useReducer(x=>x+1,0);
    const [errMessage,setErrMessage] = useState<string>('');

    useEffect(() => {
        return tectransit.store.subscribe(() => {
            // TODO:
            // Even though I can forceUpdate my component, the child components
            // might not be updated. Especially if they use properties like
            // user={tectransit.user}
            forceUpdate();
        });
    },[]);

    const onUserChange = ( user:Partial<UserType.User> ) => {
        return getApiPromise("/api/passenger/user","PUT",user)
            .then( res => {
                if( !res || res.err )
                    throw Error(res?.err||'empty response');
                tectransit.user = user;
            })
            .catch( err => {
                setErrMessage(`Cannot post to user (${err.message})`);
            });
    };

    return MenuItem.withMenuItem("Profile",( alert_ ) => {
        if( errMessage ) {
            alert_.set(errMessage);
            return false;
        }
        return (
            <div className="wrapper">
                <EditUserInfo
                    user={tectransit.user}
                    onUserChange={onUserChange}/>
            </div>
        );
    });
}
