import React             from 'react';
import { useParams }     from 'react-router-dom';

import dayjs             from 'shared/utils/day-timezone';

import * as images       from 'images/';
import MenuItem          from 'components/MenuItem';
import RoutesReport      from 'Manager/components/OnDemand/RoutesReport';

import tectransit        from 'utils/TecTransit';

export default function Routes() {
    const params = useParams();
    const min_ts = dayjs(Date.now()).tz(tectransit.agency.time_zone).add(-1,'year').startOf('month').format("YYYY-MM-DD");
    const max_ts = dayjs(Date.now()).tz(tectransit.agency.time_zone).format("YYYY-MM-DD");
    return (
        <MenuItem title={["Routes",<a key='download' href={`/api/manager/report/ondemand/routes?min_ts=${min_ts}&max_ts=${max_ts}&csv`}><images.Download/></a>]}>
            <RoutesReport {...params} min_ts={min_ts} max_ts={max_ts}/>
        </MenuItem>
    );
}
