import React                            from 'react';

import * as LatLngType                  from 'shared/types/LatLng';
import * as OrderType                   from 'shared/types/Order';
import * as OrdersPayloadType           from 'shared/types/OrdersPayload';
import * as WSPayloadType               from 'shared/types/WSPayload';
import dayjs                            from 'shared/utils/day-timezone';

import Alert                            from 'utils/Alert';
import tectransit                       from 'utils/TecTransit';
import getApiPromise                    from 'utils/getApiPromise';
import MapMarker                        from 'utils/MapMarker';
import MapOptions                       from 'utils/MapOptions';
import MapVehicle                       from 'utils/MapVehicle';
import getAgencyEarliestOpenAfter       from "utils/getAgencyEarliestOpenAfter";
import * as MenuItem                    from 'components/MenuItem';
import * as ParatransitOrderMapType     from 'components/ParatransitOrderMap';
import OrderModal                       from 'components/OrderModal';
import PositionSelector                 from 'Passenger/components/PositionSelector';

export interface PassengerOrderMapProps extends ParatransitOrderMapType.Props {
    mmi : MapMenuItem;
}

class PassengerOrderMap extends ParatransitOrderMapType.ParatransitOrderMap {

    // @ts-expect-error
    public    props                       : PassengerOrderMapProps;
    protected ws?                         : WebSocket;
    protected mapVehiclesById             : Record<number,MapVehicle> = {};
    protected allowedPickupMarkers        : (google.maps.Marker[]|undefined);
    protected allowedDropoffMarkers       : (google.maps.Marker[]|undefined);
    protected paratransitDropoffLatLngs   : (LatLngType.NamedLatLng[]|undefined);
    protected currentSeconds?             : number;
    protected orderStartSeconds?          : number;

    private allowedMarkersLatLngsByKey = {} as Record<string,google.maps.Marker>;
    private getAllowedMarker( nll:LatLngType.NamedLatLng ) : (google.maps.Marker|undefined) {
        const key = tectransit.getLatLngKey(nll);
        if( key in this.allowedMarkersLatLngsByKey )
            return undefined;
        const marker = new google.maps.Marker({
            map      : this.googleMap,
            title    : nll.name,
            label    : nll.name,
            position : nll,
            icon     : {
                path          : google.maps.SymbolPath.CIRCLE,
                fillColor     : '#00FF00',
                fillOpacity   : 0.5,
                strokeWeight  : 2,
                strokeColor   : '#000000',
                scale         : 10
            }
        });
        marker.addListener('click',(e:google.maps.MapMouseEvent) => {
            return this.mapClickHandler(e);
        });
        this.allowedMarkersLatLngsByKey[LatLngType.toString(nll)] = marker;
        return marker;
    }
    protected mapClickHandler(event:google.maps.MapMouseEvent) {
        if( !event.latLng )
            return;

        // If paratransitDropoffLatLngs is defined then the dropoff location is always one of
        // paratransitDropoffLatLngs points closest to the pickup location.
        // In this regime it does not make sense to let users pick the dropoff location and
        // therefore every time when user clicks the map, the user always chooses pickup point.
        //
        // Otherwise the moved marker is always the marker opposite to the one that has been
        // moved last time.
        const marker = this.paratransitDropoffLatLngs ? this.markers.pickup
            : (this.lastMovedMarker===this.markers.pickup) ? this.markers.dropoff
            : this.markers.pickup;

        // Let's make sure that the chosen pickup/dropoff points is one of the allowed
        const allowedMakers = (marker===this.markers.dropoff) ? this.allowedDropoffMarkers : this.allowedPickupMarkers;
        if( Array.isArray(allowedMakers) ) {
            const latLngKey = tectransit.getLatLngKey(event.latLng.toJSON());
            if( !allowedMakers.some(m=>(tectransit.getLatLngKey(m.getPosition()?.toJSON()||{lat:-1,lng:-1})===latLngKey)) ) {
                // this.alert.set(`${marker.title} is not next to one of the allowed locations`,3000);
                return;
            }
        }

        if( this.paratransitBoundaries ) {
            if( marker.name==='pickup' ) {
                if( google.maps.geometry.poly.containsLocation(event.latLng,this.paratransitBoundaries) ) {
                    const previousUserOrder = this.props.mmi.previousUserOrder;
                    if( !previousUserOrder ) {
                        this.alert.set(`Pickup is not allowed to be within paratransit service boundaries`,3000);
                        return;
                    }
                    const dropoffLatLng = this.markers.dropoff.getPosition()?.location;
                    // If the dropoff location is not yet defined then also flag the error because the user
                    // has a chance now to choose dropoff location and then choose the pickup one again.
                    if( !dropoffLatLng || (LatLngType.getMetersBetween(dropoffLatLng,previousUserOrder.pickup.location!)>1000) ) {
                        this.alert.set(`Pickup is allowed to be within paratransit service boundaries only for a return order`,3000);
                        return;
                    }
                }
            }
            if( this.paratransitDropoffLatLngs ) {
                if( marker.name!=='pickup' )
                    console.warn(`Move marker name in this case could only be 'pickup' but it is '${marker.name}'`);
                // We are moving pickup marker, just need to catchup the dropoff marker per the rules
                const pickupToDropoffSquaredDegrees         = this.markers.dropoff.getSquaredDegreesTo(event.latLng.toJSON(),Infinity);
                const nearestParatransitDropoffLatLngInfo   = LatLngType.getNearestLatLngInfo(this.paratransitDropoffLatLngs,event.latLng.toJSON());
                if( pickupToDropoffSquaredDegrees>nearestParatransitDropoffLatLngInfo.distance ) {
                    const nearestParatransitDropoffLatLng = this.paratransitDropoffLatLngs[nearestParatransitDropoffLatLngInfo.ndx];
                    this.setMarker(
                        this.markers.dropoff,
                        new google.maps.LatLng(nearestParatransitDropoffLatLng),
                        nearestParatransitDropoffLatLng?.name
                    );
                    this.alert.set(`Dropoff location was set to the closest bus stop`,5000);
                    // Fall through to actually set the pickup marker
                }
                else  {
                    // The new pickup location is closer to the dropoff than the nearest paratransit location
                    // Nothing needs to be done
                }
            }
        }
        this.moveMarker(marker,event.latLng).then( message => {
            // Reset the alert only if there is something to say
            if( message )
                this.alert.set(message);
        });
    }
    protected closeWebSocket( reason:string ) {
        if( this.ws ) {
            try {
                this.ws.close(3001,reason);
            }
            catch (err) {
                console.error(`Cannot close websocket`,err);
            }
            this.ws = undefined;
        }
    }
    protected getWebsocket( timeout=1000 ) {
        const onClose = () => {
            timeout *= 1.5; // exponential back-off
            this.ws = undefined;
            window.setTimeout(() => {
                // Re-open only if we haven't explicitly closet the socket when unmounted the component
                if( this.ws )
                    this.ws = this.getWebsocket(timeout);
            },timeout);
        };
        const onData = (data:Record<string,any>) => {
            const dehydrated = data as WSPayloadType.WSPayload.Dehydrated;
            if( WSPayloadType.WSPayload.isValid(dehydrated) ) {
                const wsPayload = WSPayloadType.WSPayload.hydrate(dehydrated);
                this.currentSeconds  = wsPayload.seconds;
                const serverVehicles = Object.values(wsPayload.vehiclesById);
                serverVehicles.forEach( serverVehicle => {
                    let mapVehicle = this.mapVehiclesById[serverVehicle._id];
                    if( !mapVehicle ) {
                        mapVehicle = this.mapVehiclesById[serverVehicle._id] = new MapVehicle(this.googleMap!);
                        console.log(`Added MapVehicle ${serverVehicle._id} to cache`,mapVehicle);
                    }
                    // If there is only one vehicle and there are no markers on the map then center and zoom the map
                    // depending on how far the vehicle is from the passenger
                    if( mapVehicle.update(serverVehicle) && (serverVehicles.length===1) && !Object.values(this.markers||{}).some(m=>m.marker.getPosition()) ) {
                        const legs             = serverVehicle.route.legs || [];
                        const vehicleLocation = legs[0]?.pickup?.latlng;
                        if( !vehicleLocation )
                            return;
                        const pickupOrder  = legs.map(l=>l.pickup.orders||[]).flat().find(o=>(o.user_id===tectransit.user._id));
                        const dropoffOrder = legs.map(l=>l.dropoff.orders||[]).flat().find(o=>(o.user_id===tectransit.user._id));
                        // If order is still to be picked up then scale according to the distance between vehicle location and pickup location
                        // Otherwise scale according the the distance between vehicle location and dropoff location
                        const orderLocation = pickupOrder?.pickup.location || dropoffOrder?.dropoff.location;
                        if( !orderLocation )
                            return;
                        this.setMapOptions(new MapOptions(MapOptions.getBounds([vehicleLocation,orderLocation])));
                    }
                });
                // Remove from map the vehicles that no longer have passenger orders
                Object.values(this.mapVehiclesById).filter(mapVehicle=>!(mapVehicle.serverVehicle?._id in wsPayload.vehiclesById)).forEach( mapVehicle => {
                    console.log(`Removing MapVehicle ${mapVehicle.serverVehicle._id} from cache`);
                    mapVehicle.remove();
                    delete this.mapVehiclesById[mapVehicle.serverVehicle._id];
                });
            }
            else if( data.message ) {
                console.log(`Got a message from server `,data);
                if( data.donotReopen )
                    this.closeWebSocket('do not re-open');
            }
            else {
                console.warn(`Got unrecognized WS payload: `,data);
            }
        };
        return tectransit.getWebsocket('ws/Passenger',onClose,onData);
    }
    getStartMoment() {
        const startSeconds       = Math.max(this.currentSeconds||-Infinity,Date.now()/1000);
        const startMoment        = dayjs((startSeconds+((tectransit.agency.order_modal_start_ahead_mins||0)*60))*1000);
        // For `Departing At` and `Arriving At` we need to determine startUtcSeconds as follows
        // * If it is before `next_day_orders_cutoff_hour` in agency time zone then startUtcSeconds = getAgencyEarliestOpenAfter(00:00 of the next day)
        // * If it is after `next_day_orders_cutoff_hour`in agency time zone then startUtcSeconds = getAgencyEarliestOpenAfter(00:00 of the day after next)
        // In other words:
        // * if a passenger makes a reservation before `next_day_orders_cutoff_hour` then it can make it for the next day and after.
        // * if a passenger makes a reservation after `next_day_orders_cutoff_hour` then it can make it for the day after next.
        const agencyStartMoment  = startMoment.tz(tectransit.agency.time_zone);
        const agencyHours        = agencyStartMoment.hour();
        const after              = agencyStartMoment.add(((agencyHours<(tectransit.agency.next_day_orders_cutoff_hour||12)) ? 1 : 2),'day').startOf('day');
        return getAgencyEarliestOpenAfter(tectransit.agency,after)||after;
    }
    getMarkerNode( mapMarker:MapMarker ) {
        const position          = mapMarker.getPosition();
        const allowedMarkers    = (mapMarker.name==='dropoff') ? this.allowedDropoffMarkers : this.allowedPickupMarkers;
        if( Array.isArray(allowedMarkers) ) {
            const chosenMarkerNdx    = allowedMarkers.findIndex((marker,ndx)=>{
                const latLng = marker.getPosition()?.toJSON();
                if( !latLng )
                    return false;
                if( LatLngType.getMetersBetween(latLng,this.props.mmi.order[mapMarker.name].location||{lat:0,lng:0})>tectransit.geocoder.latLngPrecision )
                    return false;
                return true;
            });
            return (
                <select
                    className    = "input-theme input-theme-min"
                    style        = {{cursor:'pointer',border:0}}
                    title        = {position?.location ? tectransit.getLatLngKey(position.location) : `${mapMarker.title} address`}
                    defaultValue = {chosenMarkerNdx}
                    onChange     = {(e:React.ChangeEvent<HTMLSelectElement>) => {
                        this.moveMarker(mapMarker,allowedMarkers[Number(e.target.value)].getPosition()||undefined);
                    }}>
                    {allowedMarkers.map((marker,markerNdx)=>{
                        return (<option selected={markerNdx===chosenMarkerNdx} key={markerNdx} value={markerNdx}>{marker.getTitle()!}</option>);
                    })}
                </select>
            );
        }
        return (
            <div
                style={{cursor:'pointer'}}
                title={position?.location ? tectransit.getLatLngKey(position.location) : `${mapMarker.title} address`}
                onClick={(e:React.MouseEvent<HTMLElement>) => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.alert.set(undefined);
                    this.props.mmi.setState({state:`choose_${mapMarker.name}`});
                }}>
                {position?.address || (<span className="text-muted">{mapMarker.title}&nbsp;address</span>)}
            </div>
        );
    }
    onCreateOrder() {
        return this.props.mmi.onCreateOrder();
    }
    constructor( props:PassengerOrderMapProps ) {
        super(props);
        this.paratransitDropoffLatLngs = (Array.isArray(props.mmi.paratransitDropoffLatLngs) && (props.mmi.paratransitDropoffLatLngs.length>0))
            ? props.mmi.paratransitDropoffLatLngs
            : undefined;
    }
    componentDidMount() {
        super.componentDidMount();
        const guessPickupLocation = () => {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    if( this.props.mmi.order.pickup.location ) {
                        console.log(`User has already chosen pickup position`);
                    }
                    else {
                        const latlng = new google.maps.LatLng({
                            lat : position.coords.latitude,
                            lng : position.coords.longitude
                        });
                        this.moveMarker(this.markers.pickup,latlng).then( message => {
                            if( message )
                                this.alert.set(`You are outside of service area. Please choose pickup location`);
                            else
                                this.forceUpdate(); // because the position has just changed
                        });
                    }
                },
                (err) => {
                    this.alert.set(`Please allow TecTransit to read geolocation of your device`);
                }
            );
        }
        this.ws = this.getWebsocket();
        // Create pickup/dropoff markers only for distinct allowed pickup and dropoff latlngs
        if( this.paratransitBoundaries ) {
            // If this feature is on then the only allowed dropoff marker is the marker
            // of paratransit buses *closest* to the chosen pickup location.
            // See https://github.com/TecTransit/TecTransit/issues/333
            // Initially - though - there are no restrictions on dropoff points because
            // the pickup point is not yet chosen
            if( Array.isArray(this.props.mmi.pickupLatLngs) ) {
                // User is allowed to choose pickups only from some locations
                this.allowedPickupMarkers  = this.props.mmi.pickupLatLngs.map(nll=>this.getAllowedMarker(nll)).filter(m=>!!m) as google.maps.Marker[];
            }
            else {
                // Yeah we can guess user pickup location in this case but what happens if this location
                // is within paratransitBoundaries? How is it going to be fixed? So let's not do it.
                // guessPickupLocation();
            }
        }
        else {
            if( Array.isArray(this.props.mmi.dropoffLatLngs) )
                this.allowedDropoffMarkers = this.props.mmi.dropoffLatLngs.map(nll=>this.getAllowedMarker(nll)).filter(m=>!!m) as google.maps.Marker[];
            if( Array.isArray(this.props.mmi.pickupLatLngs) ) {
                // User is allowed to choose pickups only from some locations
                this.allowedPickupMarkers  = this.props.mmi.pickupLatLngs.map(nll=>this.getAllowedMarker(nll)).filter(m=>!!m) as google.maps.Marker[];
            }
            else {
                // User is allowed to get pickup from anywhere, so let's help
                // the user by looking at user vacation
                guessPickupLocation();
            }
        }
    }
    componentWillUnmount() {
        this.allowedPickupMarkers?.forEach( marker => {
            marker.setMap(this.googleMap||null);
        });
        this.allowedDropoffMarkers?.forEach( marker => {
            marker.setMap(this.googleMap||null);
        });
        this.closeWebSocket("unmounting");
        super.componentWillUnmount();
    }
}

export interface Props {
}

export default class MapMenuItem extends React.Component {

    private orderMap?                   : PassengerOrderMap;
    private alert                       : Alert = new Alert();

    public order                        : OrderType.Order = {
        status                          : 'unknown',
        type                            : 'asap',
        seats                           : ((tectransit.user.special_accommodations?.wheelchair?0:1)+(tectransit.user.special_accommodations?.personalcareattendant?1:0)),
        wheelchairs                     : (tectransit.user.special_accommodations?.wheelchair?1:0),
        pickup                          : {},
        dropoff                         : {},
        special_accommodations          : (tectransit.user.special_accommodations || {})
    };
    public  pickupLatLngs               : (LatLngType.NamedLatLng[]|undefined) = undefined;
    public  dropoffLatLngs              : (LatLngType.NamedLatLng[]|undefined) = undefined;
    public  orderTypes                  : OrderType.Type[] = Object.keys(OrderModal.orderTypeOptions) as OrderType.Type[];
    public  paratransitDropoffLatLngs   : (LatLngType.NamedLatLng[]|undefined);
    public  previousUserOrder           : (OrderType.Hydrated<OrderType.Order<number>>|undefined);

    public  state               : {
        state                       : string;
        loading                     : boolean;
    } = {
        state                       : "map",
        loading                     : true
    };

    constructor( props:Props ) {
        super(props);
        if( tectransit.agency.pickup_latlngs_enabled && Array.isArray(tectransit.agency.allowed_latlngs) ) {
            this.pickupLatLngs          = tectransit.agency.allowed_latlngs;
            this.order.pickup.address   = this.pickupLatLngs[0]?.name;
            this.order.pickup.location  = this.pickupLatLngs[0];
        }
        if( tectransit.agency.dropoff_latlngs_enabled && Array.isArray(tectransit.agency.allowed_latlngs) ) {
            this.dropoffLatLngs         = tectransit.agency.allowed_latlngs;
            this.order.dropoff.address  = this.dropoffLatLngs[0]?.name;
            this.order.dropoff.location = this.dropoffLatLngs[0];
        }
        Promise.all([
            // Let's see which orders types are available for passengers
            getApiPromise<OrderType.Type[]>('/api/passenger/orders/types')
                .catch( err => {
                    console.error(`Cannot get allowed order types (${err.message})`);
                    return this.orderTypes;
                })
                .then( orderTypes => {
                    this.orderTypes = orderTypes;
                }),
            // Also fetch the allowed parateransit dropoff locations
            getApiPromise<LatLngType.NamedLatLng[]|undefined>('/api/passenger/orders/paratransitDropoffLatLngs')
                .then( paratransitDropoffLatLngs => {
                    this.paratransitDropoffLatLngs = paratransitDropoffLatLngs;
                })
                .catch( err => {
                    throw Error(`Cannot get allowed order types (${err.message})`);
                }),
            getApiPromise<OrdersPayloadType.Dehydrated<number>>('/api/passenger/orders','GET',undefined,{limit:1,orderby:'_id DESC'})
                .then( ordersPayload => {
                    this.previousUserOrder = ordersPayload ? OrdersPayloadType.hydrate(ordersPayload)[0] : undefined;
                })
                .catch( err => {
                    throw Error(`Cannot get user orders (${err.message})`);
                })
        ]).then(() => {
            this.setState({
                loading : false
            });
        }).catch( err => {
            console.error(err.message||'unknown error')
        })
    }
    onCreateOrder() {
        return getApiPromise<OrderType.Order>('/api/passenger/order',"POST",this.order)
            .then( order => {
                if( !order )
                    throw Error(`server did not respond`);
                if( order.err )
                    throw order;
                this.previousUserOrder = order;
                this.forceUpdate();
                return order;
            });
    }
    render() {

        if( this.state.loading )
            return (<div>
                Loading...
            </div>);

        const getPositionSelector = ( mapMarker:MapMarker ) : React.ReactNode => {
            return (<PositionSelector
                        onChoose = {(pos) => {
                            this.order[mapMarker.name].address  = pos.address;
                            this.order[mapMarker.name].location = pos.location;
                            this.setState({state:'map'});
                        }}
                        onCancel={()=>this.setState({state:"map"})}
            />);
        };

        switch( this.state.state ) {
            case "map":
                return MenuItem.withMenuItem("Map",( alert:Alert ) => {
                    this.alert = alert;
                    return (
                        <PassengerOrderMap
                            mmi                = { this }
                            orderTypes         = { this.orderTypes }
                            user               = { tectransit.user }
                            order              = { this.order }
                            mapShown           = { false }
                            style              = {{
                                height : `${window.innerHeight-MenuItem.height}px`
                            }}
                            ref                = {(orderMap:PassengerOrderMap) => {
                                this.orderMap = orderMap;
                            }}
                        />
                    );
                });
            case "choose_pickup":
                return MenuItem.withMenuItem("Choose Pickup",( alert:Alert ) => {
                    this.alert = alert;
                    if( !this.orderMap ) {
                        alert.set(`Order Map is not initialized`);
                        return false;
                    }
                    return getPositionSelector(this.orderMap.markers.pickup);
                });
            case "choose_dropoff":
                return MenuItem.withMenuItem("Choose Dropoff",( alert:Alert ) => {
                    this.alert = alert;
                    if( !this.orderMap ) {
                        alert.set(`Order Map is not initialized`);
                        return false;
                    }
                    return getPositionSelector(this.orderMap.markers.dropoff);
                });
            default:
                break;
        }
        return MenuItem.withMenuItem("Error",()=>`Unknown state ${this.state.state}`);
    }
}


