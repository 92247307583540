import React    from 'react';

import * as images     from 'images/';
import MenuItem        from 'components/MenuItem';
import StandingOrders  from 'Passenger/components/StandingOrders';

export default function StandingOrdersMenuItem() {
    return (
        <MenuItem title={["Scheduled Orders ",<a key='download' href='/api/passenger/orders/scheduled?csv'><images.Download/></a>]}>
            <StandingOrders/>
        </MenuItem>
    );
}
