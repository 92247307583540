import * as AgencyType  from 'shared/types/Agency';
import dayjs            from 'shared/utils/day-timezone';

export const getAgencyEarliestOpenAfter = ( agency:AgencyType.Agency, after:dayjs.Dayjs ) : (dayjs.Dayjs|undefined) => {
    // Always make calculations on a copy of the argument
    // And in the agency time zone.
    after = after.tz(agency.time_zone);
    for (let ndx = 0; ndx < 14; ndx++) {
        const mmnt = after.add(ndx,'day');
        if( agency.holidays[mmnt.format("MM/DD/YYYY")] )
            continue;
        const workminutes = agency.workminutes[(mmnt.day()+6)%7]||[];
        if( !workminutes[0] )
            continue;
        return mmnt.minute(Number(workminutes[0][0]));
    }
    return undefined;
}

export default getAgencyEarliestOpenAfter;