import React                from 'react';

import consts               from 'shared/consts';
import * as LatLngType      from 'shared/types/LatLng';
import * as LegType         from 'shared/types/Leg';
import * as OrderType       from 'shared/types/Order';
import * as WSPayloadType   from 'shared/types/WSPayload';

import MapVehicle           from 'utils/MapVehicle';
import tectransit           from 'utils/TecTransit';

export class Instruction {
    constructor( public message:string, public startMeters:number, public startMs:number=Date.now() ) {
    }
}

export class RouteStep {
    private static styles = {
        'ferry': {
            backgroundPosition: '0 -614px',
        },
        'ferry-train': {
            backgroundPosition: '0 -566px',
        },
        'merge': {
            backgroundPosition: '0 -143px',
        },
        'straight': {
            backgroundPosition: '0 -534px',
        },
        'fork-left': {
            backgroundPosition: '0 -550px',
        },
        'ramp-left': {
            backgroundPosition: '0 -598px',
        },
        'roundabout-left': {
            backgroundPosition: '0 -197px',
        },
        'turn-left': {
            backgroundPosition: '0 -413px',
        },
        'keep-left': {
            backgroundPosition: '0 -413px',
        },
        'turn-sharp-left': {
            backgroundPosition: '0 0',
        },
        'turn-slight-left': {
            backgroundPosition: '0 -378px',
        },
        'uturn-left': {
            backgroundPosition: '0 -305px',
        },
        'fork-right': {
            backgroundPosition: '0 -499px',
        },
        'ramp-right': {
            backgroundPosition: '0 -429px',
        },
        'roundabout-right': {
            backgroundPosition: '0 -232px',
        },
        'turn-right': {
            backgroundPosition: '0 -483px',
        },
        'keep-right': {
            backgroundPosition: '0 -483px',
        },
        'turn-sharp-right': {
            backgroundPosition: '0 -582px',
        },
        'turn-slight-right': {
            backgroundPosition: '0 -51px',
        },
        'uturn-right': {
            backgroundPosition: '0 -35px',
        },
        'pickup': {
            border         : "1px solid darkgrey",
            backgroundColor: 'white',
            padding        : '5px',
            width          : 'max-content',
        },
        'dropoff': {
            border         : "1px solid darkgrey",
            backgroundColor: "white",
            padding        : '5px',
            width          : 'max-content',
        }
    };
    constructor(
        public step              : LegType.Step,
        public eta               : number, // in seconds
        public eda               : number,
        public maneuverNode      : React.ReactNode,
        public instructionsNode  : React.ReactNode
    ) {
    }
    getDistanceDuration() : string {
        const parts = [this.step.distance.text];
        // If duration is 0 then don't show it
        if( this.step.duration.value>0 )
            parts.push(this.step.duration.text);
        return parts.join(' ');
    }
}

export interface Props {
}
export class RouteContainer extends React.Component {

    protected trCount : number = 0;

    constructor( public props:Props, public text : (Instruction|undefined) = undefined ) {
        super(props);
    }

    protected getStepAddress( legEvent:LegType.Event, step:LegType.Step ) {
        const usaAddressRE    = /^(.+),(\s*[A-Z]{2}\s+[0-9]{5,},\s*USA\s*)$/;
        const getOrderAddress = (step.maneuver==='pickup')
            ? ((o:OrderType.Order)=>(o.pickup.address||''))
            : ((o:OrderType.Order)=>(o.dropoff.address||''));
        const orderAddress    = (legEvent.orders||[]).map(getOrderAddress).find(a=>a);
        return (<strong>{(orderAddress || legEvent.address || LatLngType.toString(legEvent.latlng)).replace(usaAddressRE,'$1')}</strong>);
    }
    protected getStepManeuverNode( leg:LegType.Leg, step:LegType.Step, pinColor:string ) {
        switch( step.maneuver ) {
            case 'fixed_route_stop':
            case 'pickup':
                return (<svg version="1.1" style={{width:22,height:22}}><g fill={pinColor}><path d={MapVehicle.pickup_icon_path}/></g></svg>);
            case 'dropoff':
                return (<svg version="1.1" style={{width:22,height:22}}><g fill={pinColor}><path d={MapVehicle.dropoff_icon_path}/></g></svg>);
        }
        const style = {
            backgroundImage    : 'url(/maneuvers.png)',
            overflow           : 'hidden',
            position           : 'relative',
            top                : 0,
            left               : 0,
            width              : '16px',
            height             : '16px',
            // @ts-expect-error
            ...RouteStep.styles[step.maneuver||'straight']
        };
        return (<div style={style}/>);
    }
    protected getStepInstructionsNode( leg:LegType.Leg, step:LegType.Step ) : React.ReactNode {
        switch( step.maneuver ) {
        case 'pickup':
        case 'dropoff':
            return this.getStepAddress(leg[step.maneuver],step);
        default:
            return (<div dangerouslySetInnerHTML={{__html:step.html_instructions}}/>);
        }
    }
    protected getDirectionsTr( tds:React.ReactNode[] ) : React.ReactNode {
        const background = (this.trCount%2) ? '#dcdcdc' : '#ffffff';
        return (<tr key={this.trCount++} style={{background,verticalAlign:'top'}}>{tds.map((td,ndx)=>(<td key={ndx}>{td}</td>))}</tr>);
    }
    protected getRouteSteps( serverVehicle:WSPayloadType.Vehicle.Hydrated, eta:number ) : RouteStep[] {
        this.trCount       = 0;
        // The purpose of the below is to pre-calculate React elements necessary
        // for the visualization of the route
        const originalEda       = (serverVehicle.odometer_meters||0)*consts.meters_in_mile;
        let   eda               = originalEda;
        const stepManeuverColor = MapVehicle.colors.includes(serverVehicle.fixed_route_name!) ? serverVehicle.fixed_route_name! : MapVehicle.colors[serverVehicle!._id%MapVehicle.colors.length];
        let   metersToStop    = 0;
        return (serverVehicle.route?.legs||[]).reduce((acc,leg) => {
            metersToStop += (leg.distance?.value||0);
            return (leg.steps||[]).reduce((acc,step) => {
                if( (acc.length===0) && (step.maneuver!=='straight') && !LegType.PickupDropoffManeuvers.includes(step.maneuver!) ) {
                    // We are dealing with the very first step and it happens to be a moving step
                    // if we have been showing first step html_instructions for too many meters
                    // then it is time to change them to 'drive straight'
                    if( !this.text || (this.text.message!==step.html_instructions) ) {
                        // The instructions have changed
                        // Record at what meters we are to start showing them
                        this.text = new Instruction(step.html_instructions,eda,Date.now());
                    }
                    if( (eda-this.text.startMeters)>tectransit.agency.maneuver_announcement_meters ) {
                        // We have already been displaying step instructions for enough meters.
                        step.maneuver          = 'straight';
                        step.html_instructions = 'Drive straight';
                    }
                }
                if( serverVehicle.fixed_route_trip ) {
                    // For FR push only FR stops
                    if( step.maneuver==='fixed_route_stop' ) {
                        acc.push(new RouteStep(
                            {
                                ...step,
                                // Provide special distance because the "distance" of the FR stop itself is always 0
                                distance: {
                                    value: Math.floor(metersToStop),
                                    text : `${(metersToStop/consts.meters_in_mile).toFixed(2)} mi`
                                },
                            },
                            eta,
                            eda,
                            this.getStepManeuverNode(leg,step,stepManeuverColor),
                            this.getStepInstructionsNode(leg,step)
                        ));
                    }
                }
                else {
                    // Not a FR route. Show all the steps
                    acc.push(new RouteStep(
                        step,
                        eta,
                        eda,
                        this.getStepManeuverNode(leg,step,stepManeuverColor),
                        this.getStepInstructionsNode(leg,step)
                    ));
                }
                eta += (step.duration?.value||0);
                eda += (step.distance?.value||0);
                return acc;
            },acc);
        },[] as RouteStep[]);
    }
    // public
}

export default RouteContainer;
