import React        from 'react';
import {
    Route,
    Routes }        from 'react-router-dom';

import './styles/index.scss';

import tectransit   from 'utils/TecTransit';
import Role         from 'utils/Role';
import Hamburger    from 'components/Hamburger';

export default class App extends React.Component {

    private static minWidthQuery = "(min-width: 768px)";
    private static rolesOrder = {
        "Passenger"  : 1,
        "Driver"     : 2,
        "Maintenance": 3,
        "Dispatcher" : 4,
        "Manager"    : 5,
        "Observer"   : 6,
    };
    public state : {
        menuIsOpen    : boolean
    };
    constructor( props:{} ) {
        super(props);
        this.state = {
            menuIsOpen : window.matchMedia(App.minWidthQuery).matches
        };
        tectransit.menu = {isOpen : this.state.menuIsOpen};
    }
    static getDerivedStateFromError() {
        return {};
    }
    componentDidMount() {
        // The customers are reporting that drivers are complaning about 
        // "tablets expands randomly throughout their route".
        // This means that the menu is being opened and closed randomly.
        // Most likely this happends because of the vehicle shaking and 
        // `window.matchMedia` firing the event.
        // Let's suppress this behavior for the drivers.
        if( !tectransit.user.roles.includes('Driver') ) {
            window.matchMedia(App.minWidthQuery).addEventListener('change',(e:{matches:boolean}) => {
                tectransit.menu = {isOpen : e.matches};
                this.setState({menuIsOpen:e.matches});
            });
        }
    }
    componentDidCatch( error:Error, info:Record<string,any> ) {
        console.error(`Application caught error:`,error,`info:`,info);
    }
    menuItemClick() {
        if( !window.matchMedia(App.minWidthQuery).matches ) {
            tectransit.menu = {isOpen : false};
            this.setState({menuIsOpen:false});
        }
    }
    render() {
        // Always make sure that passenger goes first
        // In case of fixed routes only, there is no passenger experience
        const userRoles = (tectransit.agency.on_demand_enabled || tectransit.agency.issue422_public_key) ? tectransit.user.roles : tectransit.user.roles.filter(role=>(role!=="Passenger"));

        const roles = userRoles.sort((r1,r2)=>(App.rolesOrder[r1]-App.rolesOrder[r2])).map( role => {
            const module = require(`./${role}/`).default;
            return module(this) as Role;
        });
        const menuItems  = roles.map(role=>role.items).flat();
        if( menuItems.length<1 ) {
            // If there are no menu items (as in case of Baldwin Park), then at least show the user name
            // and picture. This is necessary for monitoring purposes.
            menuItems.push(
                <div className="profile">
                    <img className="profile__image" alt="icon" height={31} width={31} src={tectransit.user.picture||''}/>
                    <span className="profile__username">{tectransit.user.name||'no name'}</span>
                </div>
            );
        }
        const firstRoute        = roles[0]?.routes[0];
        const menuLinksCount    = menuItems.reduce( (acc,mi) => {
            if( mi.props.children?.props?.className==='menu__link' )
                acc++;
            return acc;
        },0);
        return (<div id="outer-container">
            <Hamburger
                menuProps = {{
                    outerContainerId : "outer-container",
                    pageWrapId       : "pagewrap-container",
                    // Does not make sense to open menu if there is only one menu item
                    isOpen           : this.state.menuIsOpen && (menuLinksCount>1),
                    onStateChange    : ((menuState:{isOpen:boolean}) => {
                        tectransit.menu = {isOpen : menuState.isOpen};
                        return this.setState({menuIsOpen:menuState.isOpen});
                    })
                }}
            >
                { menuItems.map((mi,ndx)=>(<React.Fragment key={ndx}>{mi}</React.Fragment>)) }
                <div className="menu__footerLink">
                    <a className="menu__link" href="/privacy_policy"><span className="menu__name">Privacy</span></a>
                    <a className="menu__link" href="/terms_of_service"><span className="menu__name">Terms Of Service</span></a>
                </div>
            </Hamburger>
            <div id="pagewrap-container">
                <Routes>
                    {
                        roles.map(role=>role.routes)
                    }
                    { firstRoute ? <Route key={'default'} path="*" element={firstRoute?.props?.element}/> : null }
                </Routes>
            </div>
        </div>);
    }
}
