import { Link }                 from "react-router-dom";
import * as OrdersPayloadType   from 'shared/types/OrdersPayload';
import Orders                   from 'Dispatcher/components/Orders';

export interface Props {
}

export default class UnassignedOrders extends Orders {
    // @ts-expect-error
    public  props      : Props;

    protected fetchRows( orderby:string, limit:number, skip:number ) {
        return this.getTablePromise<OrdersPayloadType.Dehydrated<number>>(
            '/api/dispatcher/orders/unassigned',
            'GET',
            undefined,
            {orderby,limit,skip}
        ).then(OrdersPayloadType.hydrate);
    }
    protected renderTfoot(colSpan: number) {
        if( this.state.hasMore || (this.state.rows||[]).length===0 )
            return super.renderTfoot(colSpan);
        return (
            <tr key="-2">
                <td colSpan={colSpan} className="text-center">
                    <strong>Total rows</strong>: {(this.state.rows||[]).length}
                    <p>
                        To re-assign these orders please go to <Link to="/Dispatcher/Routes">Routes Page</Link>.
                    </p>
                </td>
            </tr>
        );
    }
}
