import React                    from 'react';
import { parse as csvParse }    from 'csv-parse/browser/esm/sync';

import * as FixedRouteType from 'shared/types/FixedRoute';

import Alert                from 'utils/Alert';
import tectransit           from 'utils/TecTransit';
import MapOptions           from 'utils/MapOptions';
import getApiPromise        from 'utils/getApiPromise';
import * as googleMapsLinks from 'utils/googleMapsLinks';
import * as MenuItem        from 'components/MenuItem';
import dayjs from 'dayjs';

// This loosely follows https://developers.google.com/maps/documentation/javascript/examples/delete-vertex-menu

interface Props {
    params? : Record<string,any>;
}

export class FixedRoutes extends React.Component {

    // @ts-expect-error
    public  props          : Props;

    private alert          : Alert;
    private googleMap?     : google.maps.Map;
    private shapePolyline? : google.maps.Polyline;
    private stopMarkers    : google.maps.Marker[] = [];
    private mapBoundaries? : google.maps.Polyline[];

    private busPolyline?   : google.maps.Polyline;
    private routePolyline? : google.maps.Polyline;

    private static strokeColors = [
        "red",
        "green",
        "blue",
        "yellow",
        "pink",
        "black",
        "DarkCyan",
        "DarkGoldenRod",
        "DarkSalmon",
        "DarkViolet"
    ];

    public state : {
        dbTrips     : FixedRouteType.DBTrip[];
        frTrip?     : FixedRouteType.Trip;
        records     : Record<string,any>[];
        recordNdx   : number;
    } = {
        dbTrips    : [],
        records    : [],
        recordNdx  : 0
    };

    private setPolylinePath = ( frTrip:FixedRouteType.Trip ) => {
        if( !frTrip ) {
            this.alert.set(`Please choose a trip`);
            return;
        }
        const shapes = frTrip.shapes;
        if( !Array.isArray(shapes) || shapes.length<2 ) {
            this.alert.set(`Shapes is invalid`);
            return;
        }
        if( this.shapePolyline )
            this.shapePolyline.setMap(null);
        this.shapePolyline = new window.google.maps.Polyline({
            map           : this.googleMap,
            path          : shapes,
            editable      : true,
            strokeColor   : (FixedRoutes.strokeColors.includes(frTrip.route_name.toLowerCase()) ? frTrip.route_name : 'black'),
            strokeOpacity : 1.0,
            strokeWeight  : 3
        });
        this.shapePolyline.addListener('click', (e:google.maps.PolyMouseEvent) => {
            if( window.confirm(`Delete vertex #${e.vertex}?`) ) {
                const path = this.shapePolyline?.getPath();
                if( path ) {
                    path.removeAt(e.vertex!);
                    this.shapePolyline?.setPath(path);
                }
            }
        });
    }
    private setStopMarkers = ( frTrip:FixedRouteType.Trip ) => {
        if( !frTrip ) {
            this.alert.set(`Please choose a trip`);
            return;
        }
        const stops = frTrip.stops;
        if( !Array.isArray(stops) || stops.length<2 ) {
            this.alert.set(`Stops is invalid`);
            return;
        }
        // Remove the old markers
        this.stopMarkers.forEach( m => {
            m.setMap(null);
        });
        // Create new stop markers
        this.stopMarkers = stops.map( (s,sndx) => {
            const marker = new google.maps.Marker({
                map      : this.googleMap,
                title    : `Stop #${s._id} at ${s.name}\n`+
                    `Arrival at ${s.stop_time.arrival_time}\n`+
                    `Departure at ${s.stop_time.departure_time}\n`,
                label    : String(sndx+1),
                position : s,
                icon     : {
                    path          : google.maps.SymbolPath.CIRCLE,
                    strokeWeight  : 2,
                    strokeColor   : (FixedRoutes.strokeColors.includes(frTrip!.route_name.toLowerCase()) ? frTrip!.route_name : 'black'),
                    scale         : 10
                }
            });
            marker.addListener('click',() => {
                window.open(`https://www.google.com/maps/place/${s.lat},${s.lng}`,'googleMap');
            });
            return marker;
        });
    }
    private onSelectTrip = ( tripId:number ) => {
        return getApiPromise<FixedRouteType.Trip>('/api/manager/fixedRoute/trip','GET',undefined,{trip_id:tripId})
            .then( frTrip => {
                if( !frTrip || frTrip.err )
                    throw Error(frTrip?.err||'frTrip is empty');
                this.setState({frTrip},() => {
                    this.setPolylinePath(frTrip);
                    this.setStopMarkers(frTrip);
                });
            })
            .catch( err => {
                this.alert.set(`Cannot find trip (${err.message})`);
            });
    }
    private drawPolylines = () => {
        if( !Array.isArray(this.state.records) || this.state.records.length===0 ) {
            this.alert.set(`Please load records first`);
            return;
        }
        const getPolyline = (id:string,strokeColor:string) => {
            return new window.google.maps.Polyline({
                map           : this.googleMap,
                path          : this.state.records.slice(0,this.state.recordNdx).map(r => {
                    return {
                        lat : r[`${id}.lat`],
                        lng : r[`${id}.lng`]
                    };
                }),
                editable      : false,
                strokeColor   : strokeColor,
                strokeOpacity : 1.0,
                strokeWeight  : 3
            });
        }
        if( this.busPolyline )
            this.busPolyline.setMap(null);
        this.busPolyline    = getPolyline('location','red');
        if( this.routePolyline )
            this.routePolyline.setMap(null);
        this.routePolyline  = getPolyline('curr_point','blue');
    }
    constructor( props:Props ) {
        super(props);
        this.alert = new Alert();
        getApiPromise<FixedRouteType.DBTrip[]>('/api/manager/fixedRoute/trips')
            .then( dbTrips => {
                if( !dbTrips || dbTrips.err )
                    throw Error(dbTrips?.err||'trips are empty');
                dbTrips.sort((t1,t2)=>t1.name.localeCompare(t2.name));
                this.setState({dbTrips});
                return dbTrips;
            })
            .then( trips => {
                const tripId = parseInt(this.props.params?.tripId||trips[0]._id);
                if( !isNaN(tripId) )
                    this.onSelectTrip(tripId);
            })
            .catch( err => {
                this.alert.set(`Cannot get fixed route trip information (${err.message}). Are fixed routes enabled for ${tectransit.agency.name}?`);
            });
    }
    // public
    componentDidMount() {
        // The center of map is arithmetic average of all depots
        this.googleMap     = tectransit.getGoogleMap(document.getElementById("map")!,new MapOptions(MapOptions.getBounds(tectransit.getAgencyBoundary(),0.025)));
        this.mapBoundaries = (tectransit?.agency?.boundaries||[]).map( (boundary,ndx) => {
            return new window.google.maps.Polyline({
                map           : this.googleMap,
                path          : boundary,
                editable      : false,
                strokeColor   : FixedRoutes.strokeColors[ndx%FixedRoutes.strokeColors.length],
                strokeOpacity : 1.0,
                strokeWeight  : 3,
            });
        });
    }
    render() {
        return MenuItem.withMenuItem("Fixed Routes", (alert) => {
            this.alert = alert;
            const record = this.state.records[this.state.recordNdx];
            return (
                <div className="content">
                    <div className="wrapper">
                        <div className="section-card top-1">
                            <div className="section-card-wrap top-1">
                                <table width="100%">
                                    <tbody>
                                    <tr>
                                        <td style={{width:"30%",maxWidth:"30%"}} valign="top">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td colSpan={2}>
                                                            { (this.state.dbTrips.length>0) ? (
                                                                <select
                                                                    style={{width:'100%'}}
                                                                    onChange={(e)=>this.onSelectTrip(Number(e.target.value))}>
                                                                    {this.state.dbTrips.map( (t,tndx) => {
                                                                        return <option key={tndx} value={t._id}>{t.name}</option>;
                                                                    })}
                                                                </select>
                                                            ) : (
                                                                <div>n/a</div>
                                                            ) }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={2} valign='top'>
                                                            <strong>CSV log</strong>
                                                            <textarea id="csvLog" style={{width:'100%',height:'100px'}}></textarea>
                                                            <button
                                                                className="btn btn-theme"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    e.preventDefault();
                                                                    try {
                                                                        const csvLogElement = document.getElementById('csvLog') as HTMLFormElement;
                                                                        if( !csvLogElement ) {
                                                                            this.alert.set(`Cannot find csvLog, recordNdx or record elements`);
                                                                        }
                                                                        else {
                                                                            const records = csvParse(csvLogElement.value,{
                                                                                cast: true,
                                                                                columns: true,
                                                                                skip_empty_lines: true
                                                                            });
                                                                            this.alert.set(`Parsed ${records.length} records`,3000);
                                                                            this.setState({
                                                                                records,
                                                                                recordNdx : 0
                                                                            },() => {
                                                                                this.drawPolylines();
                                                                            });
                                                                        }
                                                                    }
                                                                    catch( err ) {
                                                                        this.alert.set(`Cannot parse CSV log (${(err as Error).message})`);
                                                                    }
                                                                }}
                                                                >
                                                                Load
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    { record && (<React.Fragment>
                                                        <tr>
                                                            <td>Record:</td>
                                                            <td>{this.state.recordNdx}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Line:</td>
                                                            <td><input style={{width:"99%"}} type="text" readOnly value={record.line}/></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Bus:</td>
                                                            <td>
                                                                {googleMapsLinks.getPlace({lat:record['location.lat'],lng:record['location.lng']},undefined)}
                                                                &nbsp;at {dayjs(record['location.seconds']*1000).tz(tectransit.agency.time_zone).format('YYYY/MM/DD HH:mm:ss')}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Route:</td>
                                                            <td>
                                                                {googleMapsLinks.getPlace({lat:record['curr_point.lat'],lng:record['curr_point.lng']},undefined)}
                                                                &nbsp;at {dayjs(record['curr_point.eta']*1000).tz(tectransit.agency.time_zone).format('YYYY/MM/DD HH:mm:ss')}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Deviation:</td>
                                                            <td>{record['deviation_meters'].toFixed(2)}m</td>
                                                        </tr>
                                                        </React.Fragment>)}
                                                </tbody>
                                            </table>
                                        </td>
                                        <td style={{width:"70%",maxWidth:"70%"}} valign="top">
                                            <div id="map" style={{height:650}}></div>
                                            <table width="100%">
                                                <tbody>
                                                    <tr>
                                                    <td>
                                                        <button
                                                            className="btn btn-theme"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                e.preventDefault();
                                                                this.setState({recordNdx:Math.min(this.state.records.length,this.state.recordNdx+1)},() => {
                                                                    this.drawPolylines();
                                                                    console.log(this.state.records[this.state.recordNdx]);
                                                                });
                                                            }}
                                                        >
                                                            Forward &gt;&gt;
                                                        </button>
                                                    </td>
                                                    <td align="right">
                                                        <button
                                                            className="btn btn-theme btn-delete"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                e.preventDefault();
                                                                this.setState({recordNdx:Math.max(0,this.state.recordNdx-1)},() => {
                                                                    this.drawPolylines();
                                                                    console.log(this.state.records[this.state.recordNdx]);
                                                                });
                                                            }}
                                                        >
                                                            &lt;&lt; Backward
                                                        </button>
                                                    </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            );
        });
    }
}

export default FixedRoutes;