import * as LatLngType from 'shared/types/LatLng';

export type MarkerName = 'pickup' | 'dropoff';

export interface Position {
    address  : string;
    location : google.maps.LatLngLiteral;
}

export default class MapMarker {

    public marker      : google.maps.Marker;
    public popup       : google.maps.InfoWindow;
    public listener    : any;
    public address     : string;

    constructor(
        public  readonly name  : MarkerName,
        public  readonly title : string,
        public  readonly label : string ) {
        this.address = "n/a";
        this.marker  = new google.maps.Marker({
            title : this.title,
            label : this.label,
            icon  : {
                path          : google.maps.SymbolPath.CIRCLE,
                strokeWeight  : 2,
                strokeColor   : "#FF0000",
                scale         : 10
            }
        });
        this.popup    = new google.maps.InfoWindow({content:'n/a'});
        this.listener = this.marker.addListener('click',() => {
            const map = this.marker.getMap();
            if( map )
                this.popup.open(map,this.marker);
        });
    }
    setMap( map:(google.maps.Map|null) ) {
        this.marker.setMap(map);
    }
    setPosition( location:(google.maps.LatLngLiteral|google.maps.LatLng|undefined),address:string ) : void {
        this.marker.setPosition(location);
        this.address = address;
    }
    getPosition() : (Position|undefined) {
        const position = this.marker.getPosition();
        if( !position )
            return undefined;
        return {
            location : position.toJSON(),
            address  : this.address
        };
    }
    getSquaredDegreesTo( latLng:google.maps.LatLngLiteral, defaultValue=Infinity ) {
        const position = this.marker.getPosition();
        return position ? LatLngType.getSquaredDegreesBetween(position.toJSON(),latLng) : defaultValue;
    }
}

