import * as OrderType       from './Order';
import * as LatLngType      from './LatLng';
import * as DurationType    from './Duration';
import * as DistanceType    from './Distance';
import * as FixedRouteType  from './FixedRoute';

export const PickupDropoffManeuvers = [
    'pickup',
    'dropoff'
];

export interface Event<T extends (OrderType.OrderTypes|OrderType.IdTypes)=OrderType.Order<number>> {
    // Leg orders are persisted in DB as their ids
    // But when the leg is restored from DB, the ids are converted back to Orders
    latlng             : LatLngType.LatLng;
    address?           : string;
    orders?            : T[];
    seconds?           : number;    // in seconds, Infinity means no time committment, used in Bus.ts
}

export interface Step {
    start_location     : LatLngType.LatLng;
    end_location       : LatLngType.LatLng;
    duration           : DurationType.Duration;
    distance           : DistanceType.Distance;
    html_instructions  : string;
    polyline           : { points : string };
    maneuver?          : string;
    copyrights?        : string;
    original_duration? : DurationType.Duration;
    // For fixed route
    stop?              : FixedRouteType.Stop;
}

export interface Leg<T extends Event<OrderType.OrderTypes|OrderType.IdTypes>=Event<OrderType.Order<number>>> {
    steps              : Step[];
    duration?          : DurationType.Duration;
    distance?          : DistanceType.Distance;
    pickup             : T;
    dropoff            : T;
    original_duration? : DurationType.Duration;
    // For fixed route
    pickup_stop_id?    : number;
    dropoff_stop_id?   : number;
}

export const getEmpty = <T extends Event<OrderType.OrderTypes|OrderType.IdTypes>>( latlng:LatLngType.LatLng ) : Leg<T> => {
    return {
        distance       : DistanceType.fromMeters(0),
        duration       : DurationType.fromSeconds(0),
        steps          : [],
        pickup         : { latlng } as T,
        dropoff        : { latlng } as T
    };
}

export const getMeters = <T extends Event<OrderType.OrderTypes|OrderType.IdTypes>>( leg:Leg<T> ) : number => {
    if( !leg.distance )
        leg.distance = DistanceType.fromMeters(leg.steps.reduce((a,step)=>a+step.distance.value,0));
    return leg.distance.value;
}

export const getSeconds = <T extends Event<OrderType.OrderTypes|OrderType.IdTypes>>( leg:Leg<T> ) : number => {
    if( !leg.duration )
        leg.duration = DurationType.fromSeconds(leg.steps.reduce((a,step)=>a+step.duration.value,0));
    return leg.duration.value;
}

export default Leg;
