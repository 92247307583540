import React             from 'react';
import { useParams }     from 'react-router-dom';

import * as images       from 'images/';
import MenuItem          from 'components/MenuItem';
import AssignedOrders    from 'Dispatcher/components/AssignedOrders';

export default function Assigned() {
    const params = useParams();
    return (
        <MenuItem title={["All Orders Assigned To Vehicles",<a key='download' href='/api/dispatcher/orders/assigned?csv'><images.Download/></a>]}>
            <AssignedOrders {...params}/>
        </MenuItem>
    );
}