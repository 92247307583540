import consts from '../consts';

export interface LatLng {
    lat     : number;
    lng     : number;
};

export interface NamedLatLng extends LatLng {
    name    : string;
}

export const round = ( latlng:LatLng, meters:number ) : LatLng => {
    // Snap all coords to the grid defined by geo_precision (about 5m) - this will help caching.
    // {
    //   gps_accuracy_meters    : 5,
    //   gps_accuracy_degrees   : 0.0000449167071992953,
    //   geo_precision          : 22263.430744444442
    // }
    // round_lat(37.38554) 18530521311.52344
    // round_lat(37.38555) 18530543574.954185
    // round_lat(37.38556) 18530543574.954185
    // geo_precision does not have to be a round number to be able to snap coords
    const precision = consts.meters_in_degree/(meters||5);
    const rounder   = 1000000; // Google Maps API only supports 6 decimal places
    return {
        lat : Math.round((Math.round(latlng.lat*precision)/precision)*rounder)/rounder,
        lng : Math.round((Math.round(latlng.lng*precision)/precision)*rounder)/rounder
    };
}

export const isEqual = ( zis?:LatLng, zat?:LatLng ) : boolean => {
    return zis?.lat===zat?.lat && zis?.lng===zat?.lng;
}

export const toString = ( latLng:LatLng ) : string => {
    return (latLng.lat+','+latLng.lng);
}

export const fromPoint = ( p:number[] ) : LatLng => {
    return {lat:p[0],lng:p[1]};
}

export const toPoint = ( ll:LatLng ) : [number,number] => {
    return [ll.lat,ll.lng];
}

export const getSquaredDegreesBetween = ( l1:LatLng, l2:LatLng ) : number => {
    // this code figured out the best route solely on geographical distance. Need to
    // change it based on how long it really takes to get from point A to B by roads.
    const lat_delta = l1.lat-l2.lat;
    const lng_delta = l1.lng-l2.lng;
    return (lat_delta**2)+(lng_delta**2);
}

export const getDegreesBetween = ( l1:LatLng, l2:LatLng ) : number => {
    return Math.sqrt(getSquaredDegreesBetween(l1,l2));
}

export const getMetersBetween = ( l1:LatLng, l2:LatLng ) : number => {
    return getDegreesBetween(l1,l2)*consts.meters_in_degree;
}

export const getNearestLatLngInfo = <T extends LatLng>( latlngs:T[], latlng:LatLng ) => {
    return latlngs.reduce( (a,ll,ndx) => {
        const distance = getSquaredDegreesBetween(latlng,ll);
        if( distance<a.distance )
            a = { ndx,distance };
        return a;
    },{
        ndx      : -1,
        distance : Infinity
    });
}

export const getNearestLatLngNdx = <T extends LatLng>( latlngs:T[], location:LatLng ) : number => {
    return getNearestLatLngInfo(latlngs,location).ndx;
}

export const isValidLat = ( lat:any ) => {
    if( Number.isNaN(lat) )
        return false;
    // Latitudes change from -90 to +90
    return Math.abs(lat)<=90;
}

export const isValidLng = (lng: any) => {
    if( Number.isNaN(lng) )
        return false;
    // Longitudes change from -180 to +180
    return Math.abs(lng) <= 180;
}

export const isValid = ( latLng:any ) => {
    return (typeof latLng === 'object') && !!latLng && isValidLat(latLng.lat) && isValidLng(latLng.lng);
}

export const getNearestLatLng = <T extends LatLng>( latlngs:T[], location:LatLng ) : T => {
    return latlngs[getNearestLatLngNdx(latlngs,location)];
}

export default LatLng;
