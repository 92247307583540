import React                    from 'react';
import { Link }                 from 'react-router-dom';

import consts                   from 'shared/consts';
import * as OrderType           from 'shared/types/Order';
import * as OrdersPayloadType   from 'shared/types/OrdersPayload';
import dayjs                    from 'shared/utils/day-timezone';

import tectransit               from 'utils/TecTransit';
import * as googleMapsLinks     from 'utils/googleMapsLinks';
import InfiniteTable            from 'components/InfiniteTable';

export interface Props {
    user_id? : number;
}

export default class Orders extends InfiniteTable<OrderType.Hydrated<OrderType.Order<number>>> {

    // @ts-expect-error
    public  props       : Props;

    fetchRows( orderby:string, limit:number, skip:number ) {
        const promise = isNaN(this.props.user_id!)
                      ? this.getTablePromise<OrdersPayloadType.Dehydrated<number>>('/api/driver/orders','GET',undefined,{orderby,limit,skip})
                      : this.getTablePromise<OrdersPayloadType.Dehydrated<number>>('/api/driver/user/orders','GET',undefined,{user_id:this.props.user_id,orderby,limit,skip});
        return promise.then(OrdersPayloadType.hydrate);
    }

    renderThead() {
        return (
            <tr>
                <th className={"align-top"} onClick={()=>this.sort('_id')}>{this.getSortingIcon('_id')}</th>
                <th className={"align-top"} onClick={()=>this.sort('vehicle_id')}>{this.getSortingIcon('vehicle_id')}Vid</th>
                { !isNaN(this.props.user_id!) ? null : <th className={"align-top"} onClick={()=>this.sort('user_id')}>{this.getSortingIcon('user_id')}Passenger</th> }
                <th className={"align-top"} onClick={()=>this.sort('created_at')}>
                    {this.getSortingIcon('created_at')}
                    Created At
                </th>
                <th className={"align-top"} onClick={()=>this.sort('pickup.address')}>
                    {this.getSortingIcon('pickup.address')}
                    Pickup
                </th>
                <th className={"align-top"} onClick={()=>this.sort('dropoff.address')}>
                    {this.getSortingIcon('dropoff.address')}
                    Dropoff
                </th>
                <th className={"align-top"}>Type</th>
                <th className={"align-top"}>ETAs</th>
                <th className={"align-top"} onClick={()=>this.sort('pickup.at')}>{this.getSortingIcon('pickup.at')}Pickup At</th>
                <th className={"align-top"} onClick={()=>this.sort('dropoff.at')}>{this.getSortingIcon('dropoff.at')}Dropoff At</th>
                <th className={"align-top"} onClick={()=>this.sort('meters')}>{this.getSortingIcon('meters')}Miles</th>
                <th className={"align-top"} onClick={()=>this.sort('status')}>{this.getSortingIcon('status')}Status</th>
            </tr>
        );
    }
    renderRow( o:OrderType.Hydrated<OrderType.Order<number>> ) {
        return (
            <tr key={o._id}>
                <td>{o._id}</td>
                <td>{o.vehicle_id}</td>
                { !isNaN(this.props.user_id!) ? null : <td><Link to={`/Driver/Passenger/${o.user_id}`}>{o.user?.name||'n/a'}</Link></td> }
                <td>{dayjs(o.created_at!).tz(tectransit.agency.time_zone).format(tectransit.timeFormat)}</td>
                <td>{googleMapsLinks.getPlace(o.pickup.location,o.pickup.address)}</td>
                <td>{googleMapsLinks.getPlace(o.dropoff.location,o.dropoff.address)}</td>
                <td>{(o.type==='pickup_ordered_at') ? `pu @ ${dayjs(o.ordered_at||o.pickup.eta!).tz(tectransit.agency.time_zone).format(tectransit.timeFormat)}` :
                     (o.type==='dropoff_ordered_at')? `do @ ${dayjs(o.ordered_at||o.dropoff.eta!).tz(tectransit.agency.time_zone).format(tectransit.timeFormat)}` :
                     (o.type||'')}
                </td>
                <td>
                    <div>pu @ {dayjs(o.pickup.eta!).tz(tectransit.agency.time_zone).format(tectransit.timeFormat)}</div>
                    <div>do @ {dayjs(o.dropoff.eta!).tz(tectransit.agency.time_zone).format(tectransit.timeFormat)}</div>
                </td>
                <td>{o.pickup.at ? dayjs(o.pickup.at).tz(tectransit.agency.time_zone).format(tectransit.timeFormat) : 'n/a'}</td>
                <td>{o.dropoff.at ? dayjs(o.dropoff.at).tz(tectransit.agency.time_zone).format(tectransit.timeFormat) : 'n/a'}</td>
                <td>{o.meters ? Math.round((100*o.meters)/consts.meters_in_mile)/100 : 'n/a'}</td>
                <td><span className={o.status}>{o.status}</span></td>
            </tr>
        );
    }
}

