import { MENU__UPDATE } from "../actionTypes";

export default function menu( state, action ) {
    const get_new_state = () => {
        switch( action.type ) {
        case MENU__UPDATE: {
            return action.payload;
        }
        default:
            return {};
        }
    };
    return {
        ...state,
        ...get_new_state()
    };
}
