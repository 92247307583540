import * as LatLngType from './LatLng';

export interface Depot extends LatLngType.NamedLatLng {
}

export const isValid = ( depot:Depot ) : string => {
    if( (depot.name||'')==='' )
        return `depot name is missing`;
    if( !LatLngType.isValidLat(depot.lat) )
        return `latitude is invalid`;
    if (!LatLngType.isValidLng(depot.lng))
        return `longitude is invalid`;
    return '';
}

export default Depot;
