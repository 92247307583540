import React                    from 'react';
import { Link }                 from "react-router-dom";
import Modal                    from "react-bootstrap/Modal";

import consts                   from 'shared/consts';
import * as OrderType           from 'shared/types/Order';
import * as OrdersPayloadType   from 'shared/types/OrdersPayload';
import dayjs                    from 'shared/utils/day-timezone';

import tectransit               from 'utils/TecTransit';
import getApiPromise            from 'utils/getApiPromise';
import * as googleMapsLinks     from 'utils/googleMapsLinks';
import * as InfiniteTableType   from 'components/InfiniteTable';

const isValidDate = (d:(number|undefined)) => {
    if( typeof d !== 'number' )
        return false;
    if( isNaN(d) )
        return false;
    if( d<dayjs("2020-01-01").valueOf() )
        return false;
    //if( d>Date.now() )
    //    return false;
    return true;
};

export interface PropsInfo {
    label    : string;
    propName : string;
    getter   : (o:OrderType.Hydrated<OrderType.Order<number>>) => (number|undefined);
    setter   : (o:Partial<OrderType.Hydrated<OrderType.Order<number>>>,v:number) => number;
}

export const createdAtPropsInfo : PropsInfo = {
    label    : 'Created At',
    propName : 'created_at',
    getter   : (o:OrderType.Hydrated<OrderType.Order<number>>) => {
        return (o.created_at ? o.created_at.valueOf() : undefined);
    },
    setter   : (o:Partial<OrderType.Hydrated<OrderType.Order<number>>>,v:number) => {
        o.created_at = new Date(v);
        return v;
    }
}

export const scheduledAtPropsInfo : PropsInfo = {
    label    : 'Scheduled At',
    propName : 'scheduled_at',
    getter   : (o:OrderType.Hydrated<OrderType.Order<number>>) => {
        const result = Math.max(
            o.created_at?o.created_at.valueOf():-1,
            o.scheduled_at||-1
        );
        return result>0 ? result : undefined;
    },
    setter   : (o:Partial<OrderType.Hydrated<OrderType.Order<number>>>,v:number) => {
        o.scheduled_at = v;
        return v;
    }
}

export const pickupAtPropsInfo : PropsInfo = {
    label    : 'Picked Up At',
    propName : 'pickup.at',
    getter   : (o:OrderType.Hydrated<OrderType.Order<number>>) => {
        const result = Math.max(
            o.created_at?o.created_at.valueOf():-1,
            o.scheduled_at||-1,
            o.pickup.at||-1
        );
        return result>0 ? result : undefined;
    },
    setter   : (o:Partial<OrderType.Hydrated<OrderType.Order<number>>>,v:number) => {
        if( !o.pickup )
            o.pickup = {};
        o.pickup.at = v;
        return v;
    }
}

export const dropoffAtPropsInfo : PropsInfo = {
    label    : 'Dropped Off At',
    propName : 'dropoff.at',
    getter   : (o:OrderType.Hydrated<OrderType.Order<number>>) => {
        const result = Math.max(
            o.created_at?o.created_at.valueOf():-1,
            o.scheduled_at||-1,
            o.pickup.at||-1,
            o.dropoff.at||-1
        );
        return result>0 ? result : undefined;
    },
    setter   : (o:Partial<OrderType.Hydrated<OrderType.Order<number>>>,v:number) => {
        if( !o.dropoff )
            o.dropoff = {};
        o.dropoff.at = v;
        return v;
    }
}

export class OrderStatusInfo {
    constructor(
        public readonly status      : OrderType.Status,
        public readonly blurb       : string,
        public readonly propsInfos  : PropsInfo[]
    ) {
    }
}

export const orderStatusInfos = [
    new OrderStatusInfo(
        'unknown',
        'Unknown',
        []
    ),
    new OrderStatusInfo(
        'ordered',
        `the order has just entered into the dispatching queue and TSMS system has not yet assigned this order to a vehicle.
        Please provide the timestamps for the following events:`,
        [createdAtPropsInfo]
    ),
    new OrderStatusInfo(
        'scheduled',
        `TSMS has found the most optimal vehicle to carry out this order and assigned the order to that vehicle but the vehicle has not yet picked the order up.
        Please provide the timestamps for the following events:`,
        [createdAtPropsInfo,scheduledAtPropsInfo]
    ),
    new OrderStatusInfo(
        'pickedup',
        `a vehicle has picked up the order and is now carrying the order to the point of dropoff. The vehicle did not drop off the order yet.
        Please provide the timestamps for the following events:`,
        [createdAtPropsInfo,scheduledAtPropsInfo,pickupAtPropsInfo]
    ),
    new OrderStatusInfo(
        'finished',
        'a vehicle has dropped off the order and the order is over with. Please provide the timestamps for the following events:',
        [createdAtPropsInfo,scheduledAtPropsInfo,pickupAtPropsInfo,dropoffAtPropsInfo]
    ),
    new OrderStatusInfo(
        'cancelled',
        `a passenger or dispatcher has completely cancelled this order.
        Please confirm that you want to cancel this order and verify the timestamps for the following events:`,
        [createdAtPropsInfo,scheduledAtPropsInfo]
    ),
    new OrderStatusInfo(
        'noshow',
        `the passenger did not show up at the order pickup.
        Please confirm that you want to complete this order as 'No Show' and verify the timestamps for the following events:`,
        [createdAtPropsInfo,scheduledAtPropsInfo]
    ),
    new OrderStatusInfo(
        'missed',
        `the agency has failed to serve the order.
        Please confirm that you want to complete this order as 'Missed' and verify the timestamps for the following events:`,
        [createdAtPropsInfo,scheduledAtPropsInfo]
    ),
];

const statusInfoNdxByStatus = orderStatusInfos.reduce((acc,si,ndx)=>{
    acc[si.status]=ndx;
    return acc;
},{} as Record<OrderType.Status,number>);

export interface Props {
    user_id?           : number;
    driver_id?         : number;
}

export interface State extends InfiniteTableType.State<OrderType.Hydrated<OrderType.Order<number>>> {
    order?            : (OrderType.Order|undefined);
    statusInfo?       : (OrderStatusInfo|undefined);
}

export default class Orders extends InfiniteTableType.InfiniteTable<OrderType.Hydrated<OrderType.Order<number>>> {

    // @ts-expect-error
    public  props           : Props;

    private getOrderActions( order:OrderType.Order ) {
        if( OrderType.finalStatuses.includes(order.status) )
            return '-'; // These are finished orders, no actions on them
        const statusInfoNdx = statusInfoNdxByStatus[order.status]||-1;
        // TODO:
        // Potentially add "Re-assign" action for orders that are not yet assigned to a vehicle
        return (
            <select
                className   = "input-theme input-theme-min"
                onClick     = {(e)=>{
                    e.preventDefault();
                    e.stopPropagation();
                    this.setState({
                        order       : order,
                        // @ts-expect-error
                        statusInfo  : orderStatusInfos[Number(e.target.value)]
                    });
                }}
                style       = {{
                    minWidth        : '120px',
                    backgroundColor : '#3e4676',
                    color           : '#ffffff',
                    fontSize        : '1rem',
                    fontFamily      : 'Montserrat, sans-serif',
                    fontWeight      : 500,
                }}
            >
                { orderStatusInfos.map( (si,siNdx) => {
                    if( siNdx<=statusInfoNdx )
                        return undefined;
                    return <option key={si.status} value={siNdx}>Set {si.status}</option>;
                })}
            </select>
        );
    }
    private setOrderStatusTo( order:OrderType.Order, statusInfo:OrderStatusInfo ) {
        const patch = {
            status : statusInfo.status
        } as Partial<OrderType.Order>;
        const invalidPropInfo = statusInfo.propsInfos.find( pi => {
            // Interpret all dates in the agency time zone
            const mmnt = dayjs.tz((document.getElementById(pi.propName) as HTMLInputElement)?.value,tectransit.agency.time_zone);
            if( isValidDate(mmnt.valueOf()) ) {
                pi.setter(patch,mmnt.valueOf());
                return false;
            }
            return patch;
        });
        if( invalidPropInfo ) {
            this.alert.set(`Please provide a valid timestamp for '${invalidPropInfo.label}' for order #${order._id}`);
            return;
        }
        getApiPromise(`/api/dispatcher/order`,"PUT",patch,{id:order._id})
            .then( res => {
                if( !res || res.err )
                    throw new Error(res?.err||'Unknown error');
                Object.assign(order,patch);
                this.alert.set(`Status of order #${order._id} is set to '${statusInfo.status}'`);
            })
            .catch( err => {
                this.alert.set(`Cannot set order status to '${statusInfo.status}' (${err.message||'Unknown error'})`);
            })
            .finally( () => {
                this.setState({
                    order       : undefined,
                    statusInfo  : undefined
                });
            });
    }
    private getModal( order:OrderType.Order, statusInfo:(OrderStatusInfo|undefined) ) {
        if( !statusInfo )
            return undefined;
        return (
            <Modal
                centered        = {true}
                dialogClassName = "modal-90w"
                aria-labelledby = "order-modal"
                show            = {true}
                onEntered       = {(modal) => {
                    window.scrollTo(0,0);
                }}
                onHide          = {() => {
                    this.setState({
                        order       : undefined,
                        statusInfo  : undefined
                    });
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Changing Order #{order._id}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Status <strong>{statusInfo.status}</strong> means that {statusInfo.blurb}
                    </p>
                    <table>
                        <tbody>
                        {statusInfo.propsInfos.map( pi => {
                            const value = pi.getter(order);
                            return (
                                <tr key={pi.propName}>
                                    <td>
                                        <label htmlFor={pi.propName}>
                                            <strong>{pi.label}</strong>:&nbsp;&nbsp;
                                        </label>
                                    </td>
                                    <td>
                                        <input
                                            id           = {pi.propName}
                                            name         = {pi.propName}
                                            type         = "datetime-local"
                                            defaultValue = {dayjs(value).tz(tectransit.agency.time_zone).format('YYYY-MM-DDTHH:mm')}
                                            required     = {true}
                                        />
                                    </td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                    <p>
                        <button className="btn btn-theme" onClick={(e)=>{
                            e.preventDefault();
                            e.stopPropagation();
                            return this.setOrderStatusTo(order,statusInfo);
                        }}>Set status to {statusInfo.status}</button>
                    </p>
                </Modal.Body>
            </Modal>
        );
    }
    protected fetchRows( orderby:string, limit:number, skip:number ) {
        const promise = isNaN(this.props.user_id!)
            ? this.getTablePromise<OrdersPayloadType.Dehydrated<number>>('/api/dispatcher/orders','GET',undefined,{orderby,limit,skip})
            : this.getTablePromise<OrdersPayloadType.Dehydrated<number>>('/api/dispatcher/user/orders','GET',undefined,{user_id:this.props.user_id,orderby,limit,skip});
        return promise.then(OrdersPayloadType.hydrate);
    }
    protected renderThead() {
        return (
            <tr>
                <th className={"align-top"} onClick={()=>this.sort('_id')}>{this.getSortingIcon('_id')}#</th>
                { isNaN(this.props.user_id!) ?
                  <th className={"align-top"} onClick={()=>this.sort('user_id')}>{this.getSortingIcon('user_id')}Passenger</th> :
                  null }
                { isNaN(this.props.driver_id!) ?
                  <th className={"align-top"} onClick={()=>this.sort('driver_id')}>{this.getSortingIcon('driver_id')}Driver</th> :
                  null }
                <th className={"align-top"} onClick={()=>this.sort('vehicle_id')}>{this.getSortingIcon('vehicle_id')}Fleet #</th>
                <th className={"align-top"} onClick={()=>this.sort('created_at')}>{this.getSortingIcon('created_at')}Created At</th>
                <th className={"align-top"} onClick={()=>this.sort('pickup.address')}>
                    {this.getSortingIcon('pickup.address')}
                    <div className="thcenter">Pickup</div>
                </th>
                <th className={"align-top"} onClick={()=>this.sort('dropoff.address')}>
                    {this.getSortingIcon('dropoff.address')}
                    <div className="thcenter">Dropoff</div>
                </th>
                <th className={"align-top"}>Type</th>
                <th className={"align-top"}>ETAs</th>
                <th className={"align-top"} onClick={()=>this.sort('pickup.at')}>{this.getSortingIcon('pickup.at')}Pickup At</th>
                <th className={"align-top"} onClick={()=>this.sort('dropoff.at')}>{this.getSortingIcon('dropoff.at')}Dropoff At</th>
                <th className={"align-top"} onClick={()=>this.sort('meters')}>{this.getSortingIcon('meters')}Miles</th>
                <th className={"align-top"} onClick={()=>this.sort('status')}>{this.getSortingIcon('status')}Status</th>
                <th className={"align-top"}>Actions</th>
            </tr>
        );
    }
    protected renderRow( o:OrderType.Order ) {
        const specialAccomodations = 'SAs: '+(Object.entries(o.special_accommodations||{})
            .filter(([item,value]) => {
                return !!value;
            })
            .map( ([item,value])=> {
                return item;
            })
            .join(',')||"n/a");
        return (
            <React.Fragment key={o._id}>
                { o._id===(this.state as State).order?._id ? this.getModal(o,(this.state as State).statusInfo) : null}
                <tr key={o._id}>
                    <td>{o._id}</td>
                    { isNaN(this.props.user_id!/* If user was specified then there is no point in showing the column */) ?
                    <td><Link to={`/Dispatcher/User/${o.user_id}`} title={specialAccomodations}>{o.user?.name ? o.user.name : `User #${o.user_id}`}</Link></td> :
                    null }
                    { isNaN(this.props.driver_id!/* If driver was specified then there is no point in showing the column */) ?
                    (<td>
                        {isNaN(o.driver_id!) ? 'n/a' : <Link to={`/Dispatcher/User/${o.driver_id}`}>{o.driver?.name ? o.driver.name : `Driver #${o.driver_id}`}</Link>}
                    </td>) :
                    null }
                    <td>{isNaN(o.vehicle_id!) ? 'n/a' : o.vehicle_id}</td>
                    <td>{dayjs(o.created_at).tz(tectransit.agency.time_zone).format(tectransit.timeFormat)}</td>
                    <td>
                        {googleMapsLinks.getPlace(o.pickup.location,o.pickup.address)}
                    </td>
                    <td>
                        {googleMapsLinks.getPlace(o.dropoff.location,o.dropoff.address)}
                    </td>
                    <td>{(o.type==='pickup_ordered_at') ? `pu @ ${dayjs(o.ordered_at||o.pickup.eta).tz(tectransit.agency.time_zone).format(tectransit.timeFormat)}` :
                        (o.type==='dropoff_ordered_at')? `do @ ${dayjs(o.ordered_at||o.dropoff.eta).tz(tectransit.agency.time_zone).format(tectransit.timeFormat)}` :
                        (o.type||'')}
                    </td>
                    <td>
                        <div>pu @ {dayjs(o.pickup.eta).tz(tectransit.agency.time_zone).format(tectransit.timeFormat)}</div>
                        <div>do @ {dayjs(o.dropoff.eta).tz(tectransit.agency.time_zone).format(tectransit.timeFormat)}</div>
                    </td>
                    <td>{o.pickup.at ? dayjs(o.pickup.at).tz(tectransit.agency.time_zone).format(tectransit.timeFormat) : 'n/a'}</td>
                    <td>{o.dropoff.at ? dayjs(o.dropoff.at).tz(tectransit.agency.time_zone).format(tectransit.timeFormat) : 'n/a'}</td>
                    <td>{o.meters ? Math.round((100*o.meters)/consts.meters_in_mile)/100 : 'n/a'}</td>
                    <td><span className={o.status}>{o.status}</span></td>
                    <td>{this.getOrderActions(o)}</td>
                </tr>
            </React.Fragment>
        );
    }
}

