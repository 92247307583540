import React         from 'react';
import { useParams } from 'react-router-dom';

import * as UserType from 'shared/types/User';

import ViewUserInfo  from 'components/ViewUserInfo';
import MenuItem      from 'components/MenuItem';
import getApiPromise from 'utils/getApiPromise';
import Orders        from 'Driver/components/Orders';

interface Props {
}
export const User : React.FC<Props> = props => {
    const params         = useParams();
    const [user,setUser] = React.useState<UserType.User>();
    React.useEffect(() => {
        getApiPromise<UserType.Dehydrated>('/api/driver/user',"GET",undefined, { user_id: Number(params.id) }).then( user => {
            return setUser(UserType.hydrate(user));
        });
    },[params.id]);
    return (
        <MenuItem title="Passenger">
            { user ? (
                <React.Fragment>
                    <ViewUserInfo user={user}/>
                    <h4>Orders</h4>
                    <Orders user_id={Number(params.id)}/>
                </React.Fragment>
            ) : (
                <p>Loading...</p>
            )}
        </MenuItem>
    );
}

export default User;