import React               from 'react';
import { 
    useLocation,
    Location
}                          from 'react-router-dom';

import * as DVIRecordType  from 'shared/types/DVIRecord';
import arrayDedupe         from 'shared/utils/arrayDedupe';
import dayjs               from 'shared/utils/day-timezone';

import tectransit          from 'utils/TecTransit';
import MenuItem            from 'components/MenuItem';
import InfiniteTable       from 'components/InfiniteTable';
import dviDefectCheckboxes from 'utils/dviDefectCheckboxes';

export interface Props {
    location? : Location;
}
class RepairsTable extends InfiniteTable<DVIRecordType.PastRepairsResult> {

     // @ts-expect-error
     public  props       : Props;
     private dviDefects  = Object.assign({},...dviDefectCheckboxes);

    fetchRows( orderby:string, limit:number, skip:number ) {
        return this.getTablePromise('/api/maintenance/repairs','GET',undefined,{orderby,limit,skip});
    }
    renderThead() {
        return (
            <tr>
                <th onClick={()=>this.sort('ms')}>{this.getSortingIcon('ms')}Date</th>
                <th onClick={()=>this.sort('user_id')}>{this.getSortingIcon('user_id')}Manager</th>
                <th onClick={()=>this.sort('dvis.0.vehicle_id')}>{this.getSortingIcon('dvis.0.vehicle_id')}Fleet#</th>
                <th onClick={()=>this.sort('ms')}>{this.getSortingIcon('ms')}Miles</th>
                <th onClick={()=>this.sort('problems')}>{this.getSortingIcon('problems')}Problems</th>
                <th onClick={()=>this.sort('mechanic')}>{this.getSortingIcon('mechanic')}Mechanic</th>
                <th onClick={()=>this.sort('comment')}>{this.getSortingIcon('comment')}Comment</th>
                <th onClick={()=>this.sort('ms')}>{this.getSortingIcon('ms')}Work</th>
                <th onClick={()=>this.sort('ms')}>{this.getSortingIcon('ms')}Parts</th>
            </tr>
        );
    }
    renderRow( r:DVIRecordType.PastRepairsResult ) {
        // @ts-expect-error
        const defects = arrayDedupe<string>(...r.dvis.map(dvi => {
            return Object.keys(dvi.form).filter(k=>((k in this.dviDefects) && dvi.form[k]));
        }));
        const backgroundColor = this.props.location?.state?.dvi?.repair_id===r._id ? 'lightyellow' : 'white';
        return (
            <tr key={r._id} style={{backgroundColor}}>
                <td style={{verticalAlign:'top'}}>
                    {dayjs(r.ms).tz(tectransit.agency.time_zone).format(tectransit.timeFormat)}
                </td>
                <td style={{verticalAlign:'top'}}>
                    {[r.user?.given_name,r.user?.family_name].filter(n=>!!n).join(" ")}
                </td>
                <td style={{verticalAlign:'top'}}>
                    {r.dvis.map(dvi=>dvi.vehicle_id)}
                </td>
                <td style={{verticalAlign:'top'}}>
                    {Math.round(Math.max(...r.dvis.map(dvi=>dvi.form.odometer))*100)/100}
                </td>
                <td style={{verticalAlign:'top'}}>
                    {defects.map(k=>this.dviDefects[k]).join(', ')}
                </td>
                <td style={{verticalAlign:'top'}}>
                    {r.mechanic}
                </td>
                <td style={{verticalAlign:'top'}}>
                    {r.comment}
                </td>
                <td width="10%" style={{verticalAlign:'top'}}>
                    <table cellSpacing={0} cellPadding={0}>
                        <tbody>
                        {r.workItems?.map((wi,ndx)=> {
                            return <tr key={ndx}>
                                <td>{wi.description}</td>
                                <td>{wi.hours}</td>
                            </tr>;
                        })}
                        </tbody>
                    </table>
                </td>
                <td width="10%" style={{verticalAlign:'top'}}>
                    <table cellSpacing={0} cellPadding={0}>
                        <tbody>
                        {r.partItems?.map((pi,ndx)=> {
                            return <tr key={ndx}>
                                <td>{pi.qty}</td>
                                <td>{pi.part}</td>
                                <td>{pi.description}</td>
                                <td>{pi.cost}</td>
                            </tr>;
                        })}
                        </tbody>
                    </table>
                </td>
            </tr>
        );
    }
}

export default function Repairs() {
    const location = useLocation();
    return (
        <MenuItem title="Repairs">
            <RepairsTable 
                location={location}
            />
        </MenuItem>
    );
}
