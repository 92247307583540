import * as LatLngType              from 'shared/types/LatLng';
import * as ManifestType            from 'shared/types/Manifest';
import * as OrderType               from 'shared/types/Order';
import dayjs                        from 'shared/utils/day-timezone';

import tectransit                   from 'utils/TecTransit';
import Schedulable                  from 'Dispatcher/utils/Schedulable';
import WhiteboardContext            from 'Dispatcher/utils/WhiteboardContext';

export const getStepsRoughDifference = ( s1:ManifestType.Step.Hydrated<OrderType.Order<string>>, s2:ManifestType.Step.Hydrated<OrderType.Order<string>> ) : string => {
    // This is similar to ManifestType.areRouteStepsEqual but with rounding of time and latlngs
    // Also it compares dehydrated objects (vs. comparring them by Id)
    if( s1.name!==s2.name )
        return `names are different`;

    if( s1.latlng && s2.latlng ) {
        const metersDifference = LatLngType.getMetersBetween(tectransit.roundLatLng(s1.latlng),tectransit.roundLatLng(s2.latlng));
        if( metersDifference>5 ) {
            //console.log(`Rejecting per meters difference`,metersDifference);
            return `meters difference is ${metersDifference}`;
        }
    }

    if( s1.seconds && s2.seconds ) {
        const secondsDifference = Math.abs(tectransit.roundSeconds(s1.seconds)-tectransit.roundSeconds(s2.seconds));
        if( secondsDifference>tectransit.agency.max_lateness_seconds ) {
            //console.log(`rejecting per seconds difference`,{secondsDifference,metersDifference});
            return `seconds difference is ${secondsDifference}`;
        }
    }
    if( s1.name==='fixedroute' ) {
        if( !ManifestType.FixedRouteTrip.areEqual(s1.frTrip,s2.frTrip) )
            return `fixed routes are different`;
        return '';
    }
    else if( s1.name==='stop' ) {
        if( !ManifestType.Order.areEqual(s1.pickups||[],s2.pickups||[]) )
            return `pickups are different`;
        if( !ManifestType.Order.areEqual(s1.dropoffs||[],s2.dropoffs||[]) )
            return `dropoffs are different`;
        return '';
    }
    return '';
}

export abstract class DraftVehicleManifest<SCHEDULABLE extends Schedulable=Schedulable> {

    readonly isIntact       : Boolean;
    readonly steps          : ManifestType.Step.Hydrated<OrderType.Order<string>>[];
    readonly schedulables   : SCHEDULABLE[];                // All orders in the manifest after all deletions and additions
    readonly originalVm     : ManifestType.VehicleManifest.Hydrated<OrderType.Order<string>>;
    readonly problems       : string[];

    constructor(
        context             : WhiteboardContext<SCHEDULABLE>,
        originalVm          : ManifestType.VehicleManifest.Hydrated<OrderType.Order<string>>,
        steps               : ManifestType.Step.Hydrated<OrderType.Order<string>>[],
    ) {
        // @ts-expect-error
        const stepsFilter         = this.constructor.stepsFilter as ((step:ManifestType.Step)=>boolean);
        if( typeof stepsFilter !== 'function' )
            throw Error(`There should be stepsFilter in ${this.constructor.name}`);
        // @ts-expect-error
        const getByIdFromSteps    = this.constructor.getByIdFromSteps as((steps:ManifestType.Step.Hydrated[])=>Record<string,SCHEDULABLE>);
        if( typeof getByIdFromSteps !== 'function' )
            throw Error(`There should be getByIdFromSteps in ${this.constructor.name}`);

        const stepsWithSchedulables         = steps.filter(stepsFilter);
        const originalStepsWithSchedulables = originalVm.steps.filter(stepsFilter);

        if( stepsWithSchedulables.length===originalStepsWithSchedulables.length ) {
            const difference = stepsWithSchedulables.map((step,stepNdx)=>getStepsRoughDifference(step,originalStepsWithSchedulables[stepNdx]));
            this.isIntact = difference.every(e=>(e===''));
        }
        else {
            this.isIntact = false;
        }
        this.schedulables   = Object.values(getByIdFromSteps(stepsWithSchedulables));

        if( this.isIntact ) {
            // If the draft is intact (i.e. the same stop steps follow in the same order) then we just show original
            // VM steps as our own steps. If vehicle manifest has any problems, we just show them because we can't
            // just reject something that was sent to us by the server
            this.steps      = originalVm.steps;
            this.problems   = ManifestType.VehicleManifest.getProblems(originalVm);
        }
        else {
            // If the manifest has changed, then we simply can't show steps like 'driving', 'standby' etc.
            // We do not know them. We first need to ask the server to give us all the driving times and standbys.
            if( !context.workMinutes[0] )
                throw Error(`Agency is closed on ${dayjs(originalVm.dayEndAt).tz(tectransit.agency.time_zone).format(tectransit.timeFormat)}`);

            const vehicle = context.vehiclesById[originalVm.vehicle_id]||{};
            const depot   = context.depotsByName[vehicle.depot_name!];
            if( !depot )
                throw Error(`Cannot find depot '${vehicle.depot_name}'`);
            const depotLatLngStep : ManifestType.Step.Hydrated<OrderType.Order<string>> = {
                name     : 'depot',
                latlng   : depot,
                address  : depot.name,
                seconds  : undefined,
                problem  : undefined
            };
            this.steps      = (this.schedulables.length<1) ? [
                // There are no schedulables
                // In this case step we simply show the vehicle staying in the depot.
                {
                    ...depotLatLngStep,
                    duration : (context.agencyClosesSeconds-context.agencyOpensSeconds),
                    seconds  : context.agencyOpensSeconds
                }
            ] : [
                // There are some schedulables
                // Just make the best possible effort - show 'pre-trip' and 'post-trip' and all the 'stop' steps in between.
                // TODO:
                // Handle a situation when the duration of pre-trip or post-trip is 0
                {
                    ...depotLatLngStep,
                    name     : 'pre-trip' as ManifestType.StepName,
                    duration : ((tectransit.agency.pre_trip_duration_mins||6)*60),
                },
                ...stepsWithSchedulables,
                {
                    ...depotLatLngStep,
                    name     : 'post-trip' as ManifestType.StepName,
                    duration : ((tectransit.agency.post_trip_duration_mins||6)*60),
                }
            ];
            this.problems   = ManifestType.VehicleManifest.getProblems({steps:this.steps});
        }
        this.originalVm = originalVm;
    }
    abstract getWithoutSchedulable(
        context     : WhiteboardContext<SCHEDULABLE>,
        schedulable : SCHEDULABLE
    ) : DraftVehicleManifest<SCHEDULABLE>;
    abstract getWithSchedulable(
        context     : WhiteboardContext<SCHEDULABLE>,
        schedulable : SCHEDULABLE
    ) : DraftVehicleManifest<SCHEDULABLE>;
}

export default DraftVehicleManifest;