import React     from 'react';

import * as images       from 'images/';
import MenuItem          from 'components/MenuItem';
import Orders            from 'Passenger/components/Orders';

export default function OrdersMenuItem() {
    return (
        <MenuItem title={["Orders ",<a key='download' href='/api/passenger/orders?csv'><images.Download/></a>]}>
            <Orders/>
        </MenuItem>
    );
}
