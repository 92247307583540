import React             from 'react';
import { Link }          from "react-router-dom";

import * as OrderType           from 'shared/types/Order';
import * as OrdersPayloadType   from 'shared/types/OrdersPayload';
import dayjs                    from 'shared/utils/day-timezone';

import getApiPromise            from 'utils/getApiPromise';
import tectransit               from 'utils/TecTransit';
import getOrderTypeDescription  from 'utils/getOrderTypeDescription';
import * as googleMapsLinks     from 'utils/googleMapsLinks';
import InfiniteTable            from 'components/InfiniteTable';

export default class ScheduledOrders extends InfiniteTable<OrderType.Hydrated<OrderType.Order<string>>> {

    private agencyStartOfDay : number;

    constructor( props:any ) {
        super(props);
        // Always interpret `user.on_vacation_till` in agency time zone
        this.agencyStartOfDay = dayjs().tz(tectransit.agency.time_zone).startOf('day').valueOf();
    }
    fetchRows( orderby:string, limit:number, skip:number ) {
        return this.getTablePromise<OrdersPayloadType.Dehydrated<string>>('/api/passenger/orders/scheduled','GET',undefined,{orderby,limit,skip})
            .then( payload => {
                return OrdersPayloadType.hydrate(payload);
            });
    }
    deleteOrder( e:any, id:string ) {
        e.preventDefault();
        e.stopPropagation();
        return getApiPromise('/api/passenger/order/scheduled','DELETE',{id})
            .then( res => {
                if( !res ||res.err )
                    throw Error(res?.err||`empty response`);
                this.fetchPage(1,[]);
            })
            .catch( err => {
                this.alert.set(`Cannot delete order (${err.message})`);
            });
    }
    renderThead() {
        return (
            <>
                {((tectransit.user.on_vacation_till||0)<this.agencyStartOfDay) ? null : (
                    <tr>
                        <th colSpan={7}>
                            Note: The settings in <Link to={'/Passenger/Profile'}>your profile</Link> disallow recurring orders till
                            &nbsp;<b>{dayjs(tectransit.user.on_vacation_till||0).format('MM/DD/YYYY')}</b>
                        </th>
                    </tr>
                )}
                <tr>
                    <th className={"align-top"} onClick={()=>this.sort('ordered_at')}>
                        {this.getSortingIcon('ordered_at')}
                        Created
                    </th>
                    <th className={"align-top"} onClick={()=>this.sort('pickup.address')}>
                        {this.getSortingIcon('pickup.address')}
                        Pickup
                    </th>
                    <th className={"align-top"} onClick={()=>this.sort('dropoff.address')}>
                        {this.getSortingIcon('dropoff.address')}
                        Dropoff
                    </th>
                    <th className={'align-top'}>
                        SAs
                    </th>
                    <th className={"align-top"} onClick={()=>this.sort('type_description')}>
                        {this.getSortingIcon('type_desription')}
                        Type
                    </th>
                    <th className={"align-top"}>
                    </th>
                </tr>
            </>
        );
    }
    renderRow( so:(OrderType.Order<string>&{type_description:string}) ) {
        so.type_description = getOrderTypeDescription(so.type,so.repeat_pattern,dayjs(so.ordered_at));
        return (
            <tr key={so._id}>
              <td>
                  <div className="order-time">{dayjs(so.ordered_at).format("MM/DD/YYYY HH:mm")}</div>
              </td>
              <td>
                  {googleMapsLinks.getPlace(so.pickup.location,so.pickup.address)}
              </td>
              <td>
                  {googleMapsLinks.getPlace(so.dropoff.location,so.dropoff.address)}
              </td>
              <td>
                  {Object.entries(so.special_accommodations||{}).filter(([item,value]) => {
                      return !!value;
                  }).map( ([item,value])=> {
                      return (<div>{item}</div>);
                  })}
              </td>
              <td>{so.type_description}</td>
              <td><button type="button" className="btn-theme btn-small btn-delete" onClick={e=>this.deleteOrder(e,so._id!)}>Delete</button></td>
            </tr>
        );
    }
}

