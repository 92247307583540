import React            from 'react';
import {
    Route,
    NavLink }           from "react-router-dom";

import Vehicles         from 'Maintenance/Vehicles';
import Repairs          from 'Maintenance/Repairs';
import NewRepair        from 'Maintenance/NewRepair';
import DVIs             from 'Maintenance/DVIs';
import Photo            from 'Maintenance/Photo';
import Profile          from 'components/Profile';
import Role             from 'utils/Role';
import * as images      from 'images';
import App              from '../App';
import tectransit       from 'utils/TecTransit';

export default function Index( app:App ) : Role {
    const roleRoot          = "/Maintenance";
    const menuLinkClassName = "menu__link";
    const menuItems = [
        <div className="menu__list-title">Maintenance</div>,
        <div className="menu_item">
            <NavLink className={`${menuLinkClassName}`} onClick={()=>app.menuItemClick()} to={`${roleRoot}/Vehicles`}>
                <images.ManagerVehicles/>
                <span className="menu__name">Vehicles</span>
            </NavLink>
        </div>,
        <div className="menu_item">
            <NavLink className={`${menuLinkClassName}`} onClick={()=>app.menuItemClick()} to={`${roleRoot}/Repairs`}>
                <images.MaintenancePastRepairs/>
                <span className="menu__name">Repairs</span>
            </NavLink>
        </div>
    ];
    if( tectransit.user.roles.indexOf("Maintenance")===0 )
        menuItems.push(
            <div className="menu__item">
                <NavLink className={`${menuLinkClassName}`} onClick={()=>app.menuItemClick()} to={`${roleRoot}/Profile`}>
                    <images.Profile/>
                    <span className="menu__name">Profile</span>
                </NavLink>
            </div>
        );
    return {
        name : "Maintenance",
        root : roleRoot,
        items : menuItems,
        routes : [
            <Route
                key={"maintenance_profile"}
                path={`${roleRoot}/Profile`}
                element={<Profile/>} />,
            <Route
                key={"maintenance_repairs"}
                path={`${roleRoot}/Repairs`}
                element={<Repairs/>} />,
            <Route
                key={"maintenance_vehicles"}
                path={`${roleRoot}/Vehicles`}
                element={<Vehicles/>} />,
            <Route
                key={"maintenance_new_repair"}
                path={`${roleRoot}/NewRepair`}
                element={<NewRepair/>} />,
            <Route
                key={"maintenance_dvis"}
                path={`${roleRoot}/DVIs`}
                element={<DVIs/>} />,
            <Route
                key={"maintenance_photo"}
                path={`${roleRoot}/Photo`}
                element={<Photo/>} />,
            <Route
                key={"maintenance"}
                path={roleRoot}
                element={<Vehicles/>} />
        ]
    };
}
