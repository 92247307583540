import React, { FC }        from 'react';
import {
    useLocation,
    useNavigate
}                           from 'react-router-dom';

import * as VehicleType     from 'shared/types/Vehicle';
import getApiPromise        from 'utils/getApiPromise';

import * as MenuItem        from 'components/MenuItem';
import * as DVISTableType   from 'Maintenance/components/DVISTable';

interface Props {
}
const DVIs : FC<Props> = props => {

    const location       = useLocation();
    const navigate       = useNavigate();
    const vehicle        = location.state.vehicle as VehicleType.Vehicle;
    const [dvis,setDVIs]                = React.useState<DVISTableType.DVIType[]|undefined>();
    const [errMessage,setErrMessage]    = React.useState<string>('');

    React.useEffect(() => {
        getApiPromise<DVISTableType.DVIType[]>('/api/maintenance/vehicle/dvis','GET',{},{vehicle_id:vehicle._id,orderby:'ms desc',limit:1000})
            .then( dvis => {
                if( !dvis || dvis.err )
                    throw Error(dvis?.err||`dvis are empty`);
                setDVIs(dvis);
            })
            .catch( err => {
                setErrMessage(`Cannot get DVIs (${err.message})`);
            });
    },[vehicle._id]);

    return MenuItem.withMenuItem(`DVI records for Vehicle #${vehicle._id}`, (alert) => {
        if( errMessage ) {
            alert.set(errMessage);
            return false;
        }
        if( !dvis )
            return (<div>Loading...</div>);
        return  (
            <DVISTableType.DVISTable
                key     = {'dvisTable'}
                vehicle = {vehicle}
                dvis    = {dvis}
                navigate= {navigate}
            />
        );
    });
}

export default DVIs;
