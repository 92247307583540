import React              from 'react';

import Table              from 'react-bootstrap/Table';
import InfiniteScroll     from 'react-infinite-scroller';

import Alert              from 'utils/Alert';
import getApiPromise      from 'utils/getApiPromise';
import * as MenuItem      from 'components/MenuItem';

export type Row = Record<string,any>;

export interface Props {
    sortPropName?      : string;
    sortDirection?     : (1|-1);
}

export interface State<T extends Record<string,any>=Row> {
    sortPropName       : string;
    sortDirection      : (1|-1);
    initialLoad        : boolean;
    hasMore            : boolean;
    rows               : T[];
    correctPageLoaded? : number;
}

export abstract class InfiniteTable<T extends Record<string,any>=Row> extends React.Component {

    private pageSize     : number = 50;
    private queryingNow  : boolean = false;
    private isMounted_   : boolean = false;
    private pageLoaded   : number;
    public  alert        : Alert = new Alert();
    public  state        : State<T>;

    protected abstract fetchRows( orderby:string, pageSize:number, skip:number ) : Promise<T[]>;
    protected abstract renderThead() : any;
    protected abstract renderRow( r:T ) : any;
    protected          renderTfoot( colSpan:number ) {
        if( this.state.hasMore )
            return null;
        return (
            <tr key="-2">
                <td colSpan={colSpan} className="text-center"><strong>Total rows</strong>: {(this.state.rows||[]).length}</td>
            </tr>
        );
    }

    constructor( props:Props ) {
        super(props);
        this.pageSize   = 50;
        this.pageLoaded  = 0;
        this.state = {
            sortPropName  : (props.sortPropName || '_id'),
            sortDirection : (props.sortDirection || 1),
            initialLoad   : true,
            hasMore       : true,
            rows          : []
        };
    }
    //
    getTablePromise<P=T[]>( ...api_promise_args:any[] ) : Promise<P> {
        this.queryingNow = true;
        // @ts-expect-error TS2556
        return getApiPromise<P>(...api_promise_args)
            .then( table => {
                if( !table || table.err )
                    throw Error(table.err||'table is empty');
                return table;
            })
            .catch( err => {
                this.alert.set(`server api failed (${err.message})`);
                return [];
            })
            .finally(() => {
                this.queryingNow = false;
            });
    }
    getSortingIcon( propName:string ) {
        if( this.state.sortPropName!==propName )
            return '';
        if( this.state.sortDirection===1 )
            return <i className="fa fa-angle-double-up">&nbsp;</i>;
        return <i className="fa fa-angle-double-down">&nbsp;</i>;
    }
    static getObjectProp( r:Record<string,any>, parts:string[], ndx=0 ) : any {
        if( ndx>=parts.length )
            return r;
        if( typeof r !== 'object' )
            return r;
        r = r[parts[ndx]];
        return this.getObjectProp(r,parts,ndx+1);
    };
    // infinite pagination interface
    fetchPage = ( pageLoaded:number, initial_rows?:T[] ) => {
        // console.log(`InfiniteTable.fetchPage:this.state.rows.length=${(this.state.rows||[]).length},pageLoaded=${pageLoaded},this.pageLoaded=${this.pageLoaded}`);
        // InfiniteScroll has a bug where is does not update the page number when props have changed after
        // initial rendering. We need to reset the page number ourselves but have to do this only once after
        // a change in the properties. How do we do this ONLY ONCE?
        //
        // 1/ Cannot save it in object itself because object does not change when the props change
        // 2/ Cannot save it in the new props because props object is not extensible
        // 3/ The last resort is to save it state because thanks to getDerivedStateFromProps the state
        //    does change when the props change. But we cannot use this.setState() to update the state
        //    because this.setState() is not immediate and this.fetchPage() can be called before
        //    this.setState() has finished and then we will have bug. Therefore we need to update
        //    the state directly even though this is against all React rules.
        //
        // Please let me know if you find a better solution to the proplem. Perhaps we need to replace
        // 'react-infinite-scroller' component with 'react-infinite-scroll-component' but this is a
        // large TODO
        if( 'correctPageLoaded' in this.state ) {
            console.log(`resetting page to ${this.state.correctPageLoaded}, now is`,pageLoaded);
            pageLoaded = this.state.correctPageLoaded!;
            delete this.state.correctPageLoaded;
        }
        this.pageLoaded = pageLoaded;
        const orderby = this.state.sortPropName+((this.state.sortDirection>0)?' DESC':' ASC');
        return this.fetchRows(orderby,this.pageSize,(pageLoaded-1)*this.pageSize).then( new_rows => {
            if( !this.isMounted_ )
                return []; // the component could have unmounted by the time the data returned
            const rows = initial_rows || this.state.rows;
            rows.push(...(new_rows||[]));
            return this.setState({
                initialLoad : false,
                hasMore     : (new_rows?.length>=this.pageSize),
                rows        : rows
            });
        });
    }
    sort( propName:string ) {
        const sortDirection = (this.state.sortPropName===propName) ? (0-this.state.sortDirection) : -1;
        if( this.state.hasMore ) {
            // Has to grab rows from the server
            const orderby = propName+((sortDirection>0)?' DESC':' ASC');
            return this.fetchRows(orderby,this.state.rows.length,0).then( rows => {
                if( !this.isMounted_ )
                    return []; // the component could have unmounted by the time the data returned
                return this.setState({
                    hasMore        : (rows.length>=this.state.rows.length),
                    rows           : rows,
                    sortPropName : propName,
                    sortDirection : sortDirection
                });
            });
        }
        else {
            // All the sorting can happen on the client
            const props       = propName.split('.');
            const sorter = ( t1:T, t2:T ) => {
                const v1 = InfiniteTable.getObjectProp(t1,props);
                const v2 = InfiniteTable.getObjectProp(t2,props);
                return sortDirection*((v1<v2) ? 1 : ((v1>v2) ? -1 : 0));
            };
            return this.setState({
                rows           : this.state.rows.sort(sorter),
                sortPropName   : propName,
                sortDirection  : sortDirection
            });
        }
    }
    // React component methods
    componentDidMount() {
        this.isMounted_ = true;
    }
    componentWillUnmount() {
        this.isMounted_ = false;
    }
    render() {
        const thead      = this.renderThead();
        let colSpan = thead.props.children.length;
        const rows  = (this.state.rows||[]).map( r => {
            const rendering =  this.renderRow(r);
            colSpan = Math.max(colSpan,rendering.props.children.length);
            return rendering;
        });
        const tfoot      = this.renderTfoot(colSpan);
        return MenuItem.withAlert( alert => {
            this.alert = alert;
            return (
                <div className="content">
                    <div className="container-fluid">
                        <div className="wrapper">
                            <div className="card-body section-card table-theme">
                                <Table responsive hover size="sm">
                                    <thead>
                                        {thead}
                                    </thead>
                                    <InfiniteScroll
                                        ref         = {( is?:Record<string,any> ) => {
                                            // Stick the corrected pageLoaded into InfiniteScroll component at the first opportunity
                                            // console.log(`got_infinite_scroll:is.pageLoaded=`,(is||{}).pageLoaded,`,this.pageLoaded=`,this.pageLoaded);
                                            (is||{}).pageLoaded = this.pageLoaded;
                                        }}
                                        element     = 'tbody'
                                        useWindow   = {true}
                                        loadMore    = {this.fetchPage}
                                        hasMore     = {this.state.hasMore}
                                        initialLoad = {this.state.initialLoad}
                                        loader      = {<tr key="-1" className="loader"><td colSpan={colSpan} className="text-center">Loading ...</td></tr>}>
                                        {
                                            rows
                                        }
                                    </InfiniteScroll>
                                    <tfoot>
                                        {tfoot}
                                    </tfoot>
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>
            );
        });
    }
}

export default InfiniteTable;
