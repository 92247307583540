import {
    AGENCY__ADD_DRIVER,
    AGENCY__DELETE_DRIVER,
    AGENCY__UPDATE,
    USER__UPDATE,
    MENU__UPDATE
} from "./actionTypes";

const actions = {
    agency__add_driver : ( payload ) => ({
        type: AGENCY__ADD_DRIVER,
        payload
    }),
    agency__delete_driver : ( payload ) => ({
        type : AGENCY__DELETE_DRIVER,
        payload
    }),
    agency__update : ( payload ) => ({
        type : AGENCY__UPDATE,
        payload
    }),
    user__update : ( payload ) => ({
        type : USER__UPDATE,
        payload
    }),
    menu__update : ( payload ) => ({
        type: MENU__UPDATE,
        payload
    })
};

export default actions;
