import React                    from 'react';
import { Link }                 from "react-router-dom";

import * as OrderType           from 'shared/types/Order';
import * as OrdersPayloadType   from 'shared/types/OrdersPayload';
import dayjs                    from 'shared/utils/day-timezone';

import tectransit               from 'utils/TecTransit';
import getOrderTypeDescription  from 'utils/getOrderTypeDescription';
import * as googleMapsLinks     from 'utils/googleMapsLinks';
import InfiniteTable            from 'components/InfiniteTable';

interface ScheduledOrder extends OrderType.Hydrated<OrderType.Order<string>> {
    description        : string;
    descriptionSorter  : string;
}

export interface Props {
    user_id?           : number;
}

export default class StandingOrders extends InfiniteTable<ScheduledOrder> {

    // @ts-expect-error
    public  props      : Props;

    fetchRows( orderby:string, limit:number, skip:number ) {
        return this.getTablePromise<OrdersPayloadType.Dehydrated<string>>('/api/dispatcher/orders/scheduled','GET',undefined,{user_id:this.props.user_id,orderby,limit,skip})
            .then( payload => {
                return OrdersPayloadType.hydrate(payload).map( o => {
                    const ordered_at_mmnt = dayjs(o.ordered_at!).tz(tectransit.agency.time_zone);
                    const so = o as ScheduledOrder;
                    so.description          = getOrderTypeDescription(o.type,o.repeat_pattern,ordered_at_mmnt);
                    so.descriptionSorter    = ordered_at_mmnt.format("HH:mm");
                    return so;
                });
            });
    }
    renderThead() {
        return (
            <tr>
                { isNaN(this.props.user_id!)
                    ? <th className={"align-top"} onClick={()=>this.sort('user.name')}>{this.getSortingIcon('user.name')}User</th>
                    : null }
                <th className={"align-top"} onClick={()=>this.sort('pickup.address')}>
                    {this.getSortingIcon('pickup.address')}
                    <div className="thcenter">Pickup</div>
                </th>
                <th className={"align-top"} onClick={()=>this.sort('dropoff.address')}>
                    {this.getSortingIcon('dropoff.address')}
                    <div className="thcenter">Dropoff</div>
                </th>
                <th className={"align-top"} onClick={()=>this.sort('descriptionSorter')}>
                    {this.getSortingIcon('descriptionSorter')}
                    Description/Manage
                </th>
            </tr>
        );
    }
    renderRow( so:ScheduledOrder ) {
        const className = ((so.user?.on_vacation_till||0)>Date.now()) ? 'text-muted' : 'thcenter';
        return (
            <tr key={so._id}>
                { isNaN(this.props.user_id!)
                   ? <td><Link to={`/Dispatcher/User/${so.user_id}`}><div className={className}>{so.user?.name}</div></Link></td>
                   : null }
                <td>
                    {googleMapsLinks.getPlace(so.pickup.location,so.pickup.address)}
                </td>
                <td>
                    {googleMapsLinks.getPlace(so.dropoff.location,so.dropoff.address)}
                </td>
                <td>
                    <Link to={`/Dispatcher/StandingOrder/${so._id}`}>{so.description}</Link>
                </td>
            </tr>
        );
    }
}
