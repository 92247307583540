import React              from 'react';

import * as images        from 'images';
import tectransit         from 'utils/TecTransit';
import MapOptions         from 'utils/MapOptions';

export interface Props {
    inputProps : Record<string,any>;
    onSelect   : (acp:google.maps.places.AutocompletePrediction)=>void;
}

export default class PlacesAutocomplete extends React.Component {

    private autocompleteService : google.maps.places.AutocompleteService
    // @ts-expect-error
    public  props               : Props;
    public  state       : {
        address                 : string;
	autocompleteResponse?   : google.maps.places.AutocompleteResponse;
    };

    constructor( props:Props ) {
        super(props);
        this.autocompleteService = new google.maps.places.AutocompleteService();
        this.state               = {
            address : (props.inputProps.value||'')
        };
    }
    componentDidUpdate( prevProps:Props ) {
        if( prevProps.inputProps.value!==this.props.inputProps.value ) {
            this.setState({
                address : (this.props.inputProps.value||'')
            });
        }
    }
    render() {
        return [
            <input
                placeholder = "Search Places..."
                key         = "acInput"
                className   = "input-theme"
                type        = "text"
                {...this.props.inputProps}
                value       = {this.state.address}
                onChange    = {(e:any)=>{
                    e.preventDefault();
                    e.stopPropagation();
                    const address = e.target.value;
                    this.setState({address});
                    if( address.length>3 ) {
                        this.autocompleteService.getPlacePredictions({
                            input  : address,
                            bounds : MapOptions.getBounds(tectransit.getAgencyBoundary())
                        }).then( (autocompleteResponse:google.maps.places.AutocompleteResponse) => {
                            this.setState({
                                autocompleteResponse
                            });
                        }).catch( (err:Error) => {
                            console.error(`Google AutocompletionService error:`,err);
                        });
                    }
                    else {
                        this.setState({
                            autocompleteResponse : undefined
                        });
                    }
                }}/>,
            ...((this.state.autocompleteResponse?.predictions||[]).slice(0,5).map( (autocompletePrediction,ndx) => {
                return (
                    <div
                        key       = {`prediction_${ndx}`}
                        className = "addressPrediction__item"
                        style     = {{cursor:'pointer'}}
                        onClick   = {(e:any) => {
                            this.setState({
                                address              : autocompletePrediction.description,
                                autocompleteResponse : undefined
                            })
                            this.props.onSelect(autocompletePrediction);
                        }}>
                        <images.PlacesAutocomplete/>
                        <div className="addressPrediction__name">{autocompletePrediction.description}</div>
                    </div>
                )
            }))
        ];
    }
};
