import React              from 'react';
import {
    Route,
    NavLink }             from "react-router-dom";

import * as images              from 'images/';
import Map                      from '../Dispatcher/Map';
import Role                     from 'utils/Role';
import tectransit               from 'utils/TecTransit';
import App                      from 'App';

export default function Index( app:App ) : Role {
    const roleRoot          = "/Observer";
    const menuLinkClassName = "menu__link";
    if( tectransit.user.roles.includes("Dispatcher") ) {
        // Observer role is the same as Dispatcher role but only being able to see the vehicles
        // and not being able to do any changes to them. If user is already a dispatcher, then
        // this role is empty
        return {
            name  : "Observer",
            root  : roleRoot,
            items : [],
            routes: []
        };
    }
    const items = [
        <div className="menu__list-title">Observer</div>,
        <div className="menu__item">
            <NavLink className={`${menuLinkClassName}`} onClick={()=>app.menuItemClick()} to={`${roleRoot}/Map`}>
                <images.DispatcherMap/>
                <span className="menu__name">Map</span>
            </NavLink>
        </div>
    ];
    const routes = [
        <Route
            key={"observer"}
            path={roleRoot}
            element={<Map/>} />,
        <Route
            key={"observer_map"}
            path={`${roleRoot}/Map`}
            element={<Map/>} />,
    ];
    return {
        name  : "Observer",
        root  : roleRoot,
        items,
        routes
    };
}
