import * as ReportsType  from 'shared/types/Reports';

import InfiniteTable     from 'components/InfiniteTable';

export interface Props {
    min_ts  : string;
    max_ts  : string;
}
export default class RoutesReport extends InfiniteTable<ReportsType.OnDemand.RoutesRow> {

    // @ts-expect-error
    public  props      : Props;

    fetchRows( orderby:string, limit:number, skip:number ) {
        return this.getTablePromise('/api/manager/report/ondemand/routes','GET',undefined,{
            min_ts : this.props.min_ts,
            max_ts : this.props.max_ts,
            orderby,
            limit,
            skip
        });
    }
    renderThead() {
        return (
            <tr>
                <th className={"align-top"} onClick={()=>this.sort('yyyymm')}>{this.getSortingIcon('yyyymm')}Month</th>
                <th className={"align-top"} onClick={()=>this.sort('total_passengers')}>{this.getSortingIcon('total_passengers')}Total Passengers</th>
                <th className={"align-top"} onClick={()=>this.sort('revenue_hours')}>{this.getSortingIcon('revenue_hours')}Revenue Hours</th>
                <th className={"align-top"} onClick={()=>this.sort('non_revenue_hours')}>{this.getSortingIcon('revenue_hours')}Non Revenue Hours</th>
                <th className={"align-top"} onClick={()=>this.sort('total_hours')}>{this.getSortingIcon('total_hours')}Total Hours</th>
                <th className={"align-top"} onClick={()=>this.sort('total_orders')}>{this.getSortingIcon('total_orders')}Total Orders</th>
                <th className={"align-top"} onClick={()=>this.sort('cancellations')}>{this.getSortingIcon('cancellations')}Cancellations</th>
                <th className={"align-top"} onClick={()=>this.sort('noshows')}>{this.getSortingIcon('noshows')}No shows</th>
                <th className={"align-top"} onClick={()=>this.sort('late_pickups')}>{this.getSortingIcon('late_pickups')}Late Pickups</th>
                <th className={"align-top"} onClick={()=>this.sort('early_pickups')}>{this.getSortingIcon('early_pickups')}Early Pickups</th>
                <th className={"align-top"} onClick={()=>this.sort('missed')}>{this.getSortingIcon('missed')}Missed</th>
            </tr>
        );
    }
    renderRow( row:ReportsType.OnDemand.RoutesRow ) {
        return (
            <tr key={row.yyyymm}>
                <td>{row.yyyymm}</td>
                <td>{row.total_passengers}</td>
                <td>{row.revenue_hours.toFixed(2)}</td>
                <td>{row.non_revenue_hours.toFixed(2)}</td>
                <td>{row.total_hours.toFixed(2)}</td>
                <td>{row.total_orders}</td>
                <td>{row.cancellations}</td>
                <td>{row.noshows}</td>
                <td>{row.late_pickups}</td>
                <td>{row.early_pickups}</td>
                <td>{row.missed}</td>
            </tr>
        );
    }
}

