import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React      from 'react';
import ReactDOM   from 'react-dom';
import {
    BrowserRouter
}                 from 'react-router-dom';

import tectransit from 'utils/TecTransit';
import App        from 'App';

(async () => {
    try {
        if( await tectransit.init() ) {
            //@ts-expect-error TS2339
            window.tectransit = tectransit;
            ReactDOM.render(
                <BrowserRouter>
                    <App/>
                </BrowserRouter>,
                document.getElementById('root')
            );
        }
        else {
            // the browser is already redirected
        }
    }
    catch( err ) {
        ReactDOM.render(
            <p>Cannot init TecTransit: {(err as Error).message}</p>,
            document.getElementById('root')
        );
    }
})();

