import * as LatLngType              from 'shared/types/LatLng';
import * as ManifestType            from 'shared/types/Manifest';
import * as OrderType               from 'shared/types/Order';

import arrayGetSplice               from 'shared/utils/arrayGetSplice';

import tectransit                   from 'utils/TecTransit';
import * as SchedulableType         from 'Dispatcher/utils/Schedulable';
import WhiteboardContext            from 'Dispatcher/utils/WhiteboardContext';
import DraftVehicleManifest         from 'Dispatcher/utils/DraftVehicleManifest';

export class OnDemandDraftVehicleManifest extends DraftVehicleManifest<SchedulableType.OrderSchedulable> {

    static stepsFilter      = ManifestType.Order.stepsFilter;
    static getByIdFromSteps = ManifestType.Step.getOrdersById;

    private static getStepsWithoutSchedulable( allSteps:ManifestType.Step.Hydrated<OrderType.Order<string>>[], so:SchedulableType.OrderSchedulable ) : ManifestType.Step.Hydrated<OrderType.Order<string>>[] {
        return allSteps.filter(this.stepsFilter).map( stop => {
            return {
                ...stop,
                pickups  : stop.pickups?.filter(so2=>(so2._id!==so._id)),
                dropoffs : stop.dropoffs?.filter(so2=>(so2._id!==so._id)),
            }
        });
    }
    private static getStepsWithSchedulable( allSteps:ManifestType.Step.Hydrated<OrderType.Order<string>>[], so:SchedulableType.OrderSchedulable ) : ManifestType.Step.Hydrated<OrderType.Order<string>>[] {
        const stops         = allSteps.filter(this.stepsFilter);
        const eventInfo     = (so.type==='dropoff_ordered_at') ? {
            roundSeconds    : tectransit.roundSeconds(so.ordered_at!/1000),
            roundLatlng     : tectransit.roundLatLng(so.dropoff.location!),
            newStop           : {
                name        : 'stop' as ManifestType.StepName,
                latlng      : so.dropoff.location!,
                address     : so.dropoff.address,
                seconds     : (so.ordered_at!/1000),
                pickups     : [],
                dropoffs    : [so]
            },
            addToRS        : ( step:ManifestType.Step.Hydrated<OrderType.Order<string>> ) : ManifestType.Step.Hydrated<OrderType.Order<string>> => {
                return {
                    ...step,
                    dropoffs : [...(step.dropoffs||[]),so]
                };
            },
            insertOtherStop  : ( rss:ManifestType.Step.Hydrated<OrderType.Order<string>>[], stepInsertionNdx:number ) : ManifestType.Step.Hydrated<OrderType.Order<string>>[] => {
                rss.splice(Math.max(0,stepInsertionNdx-1),0,{
                    name        : 'stop' as ManifestType.StepName,
                    latlng      : so.pickup.location!,
                    address     : so.pickup.address,
                    pickups     : [so],
                    dropoffs    : []
                });
                return rss;
            }
        } : {
            roundSeconds    : tectransit.roundSeconds(so.ordered_at!/1000),
            roundLatlng     : tectransit.roundLatLng(so.pickup.location!),
            newStop          : {
                name        : 'stop' as ManifestType.StepName,
                latlng      : so.pickup.location!,
                address     : so.pickup.address,
                seconds     : (so.ordered_at!/1000),
                pickups     : [so],
                dropoffs    : []
            },
            addToRS        : ( step:ManifestType.Step.Hydrated<OrderType.Order<string>> ) : ManifestType.Step.Hydrated<OrderType.Order<string>> => {
                return {
                    ...step,
                    pickups : [...(step.pickups||[]),so]
                };
            },
            insertOtherStop  : ( rss:ManifestType.Step.Hydrated<OrderType.Order<string>>[], rsInsertionNdx:number ) : ManifestType.Step.Hydrated<OrderType.Order<string>>[] => {
                // We do not know where the other stop of the order is going to happen along the route.
                // It can be at any point after the current stop. Let's try to insert to immediately after
                // the current stop. This will make for a shorter roure. If this does not make a viable
                // route then dispatcher will have to adjust manually.
                rss.splice(Math.min(rsInsertionNdx+1,rss.length),0,{
                    name        : 'stop' as ManifestType.StepName,
                    latlng      : so.dropoff.location!,
                    address     : so.dropoff.address,
                    pickups     : [],
                    dropoffs    : [so]
                });
                return rss;
            }
        };
        const guessStopSeconds = ( stop:ManifestType.Step.Hydrated<OrderType.Order<string>> ) : number => {
            const stopAts = [
                ...(stop.pickups||[]).filter(so=>(so.type==='pickup_ordered_at')).map(so=>(so.ordered_at||0)),
                ...(stop.dropoffs||[]).filter(so=>(so.type==='dropoff_ordered_at')).map(so=>(so.ordered_at||0))
            ].filter(at=>(at>0));
            // If any of the stops include 0 time then we do not know when this stop is supposed to happen
            if( stopAts.length<1 )
                return 0;
            // Return the average
            return (stopAts.reduce((acc,at)=>acc+at,0)/stopAts.length)/1000;
        }
        const stopRoundSeconds = stops.map( stop => {
            return tectransit.roundSeconds(stop.seconds?stop.seconds:guessStopSeconds(stop));
        });

        // First see if there is already a step for the timed event of the order.
        // If so add the timed event to that step
        const stopNdx = stops.findIndex( (stop,stopNdx) => {
            const secondsDifference = Math.abs(eventInfo.roundSeconds-stopRoundSeconds[stopNdx]);
            if( secondsDifference>tectransit.agency.max_lateness_seconds ) {
                // console.log(`rejecting stop ${stopNdx} per time,secondsDifference=${secondsDifference}`);
                return false;
            }
            const metersDifference = LatLngType.getMetersBetween(eventInfo.roundLatlng,tectransit.roundLatLng(stop.latlng||{lat:0,lng:0}));
            if( metersDifference>5 ) {
                // console.log(`rejecting stop ${stopNdx} per location,metersDifference=${metersDifference}`);
                return false;
            }
            return true;
        });
        if( stopNdx>=0 )
            return eventInfo.insertOtherStop(arrayGetSplice([...stops],stopNdx,1,eventInfo.addToRS(stops[stopNdx])),stopNdx);
        // Insert the new stop immediately after the latest existing step with `seconds` less than new step seconds
        const insertNdx = stops.findLastIndex((stop,stopNdx) => {
            if( !stopRoundSeconds[stopNdx] )
                return false;   // We do not know when this stop is supposed to be
            if( stopRoundSeconds[stopNdx]>eventInfo.roundSeconds )
                return false;   // Too early to insert
            return true;
        });
        if( insertNdx>=0 )
            return eventInfo.insertOtherStop(arrayGetSplice([...stops],insertNdx+1,0,eventInfo.newStop),insertNdx+1);
        // Finally perhaps existing steps are empty
        // Insert at the start anyway
        return eventInfo.insertOtherStop([eventInfo.newStop,...stops],0);
    }
    private static getStepsFromSchedulableDnd(
        allSteps     : ManifestType.Step.Hydrated<OrderType.Order<string>>[],
        so           : SchedulableType.OrderSchedulable,
        srcStepNdx   : number,       // Potentially we do not need this arg because we can always finds the source by `so` and `soEventType`
        dstStepNdx   : number,
        replaceDst   : boolean,
        eventType    : ('pickup'|'dropoff')
    ) : ManifestType.Step.Hydrated<OrderType.Order<string>>[] {
        if( !so )
            throw Error(`need so`);
        if( (eventType!=='pickup') && (eventType!=='dropoff') )
            throw Error(`wrong order event type '${eventType}'`);
        let stopStepsLength = 0;
        const stopSteps = allSteps.filter( (step,stepNdx) => {
            if( step.name!=='stop' )
                return false;
            // `srcStepNdx` and `dstStepNdx` refer to *steps* but everything that goes below
            // refers to *stops*. So we need to translate "step indexes" to "stop indexes".
            if( stepNdx===dstStepNdx )
                dstStepNdx = stopStepsLength;
            if( stepNdx===srcStepNdx )
                srcStepNdx = stopStepsLength;
            stopStepsLength++;
            return true;
        });
        const srcStep = stopSteps[srcStepNdx];
        if( !srcStep )
            throw Error(`cannot get src step, ndx=${srcStepNdx}`);
        const ordersName    = `${eventType}s` as ('pickups'|'dropoffs');
        const newSrcStep = {
            ...srcStep,
            [ordersName]   : (srcStep[ordersName]||[]).filter(so2=>(so2._id!==so._id)),
        }
        const newStep = {
            name            : 'stop' as ManifestType.StepName,
            latlng          : so[eventType].location!,
            address         : so[eventType].address!,
            seconds         : ((so.type===`${eventType}_ordered_at`) ? (so.ordered_at!/1000) :undefined),
            pickups         : [],
            dropoffs        : [],
            [ordersName]    : [so],
        };
        const dstStep = stopSteps[dstStepNdx];
        if( !dstStep ) {
            if( dstStepNdx===-1 ) {
                // special case meaning that the srcStep needs to be inserted before the very first step
                return arrayGetSplice(arrayGetSplice([...stopSteps],srcStepNdx,1,newSrcStep),0,0,newStep);
            }
            throw Error(`cannot get dst step, ndx=${dstStepNdx}`);
        }
        if( (srcStep===dstStep) && replaceDst )
            return stopSteps;
        const stepsReducer = (acc:ManifestType.Step.Hydrated<OrderType.Order<string>>[],step:ManifestType.Step.Hydrated<OrderType.Order<string>>) => {
            // First reduce to create a new array of steps
            if( step===dstStep ) {
                if( replaceDst ) {
                    acc.push({
                        ...dstStep,
                        [ordersName]   : [...(dstStep[ordersName]||[]).filter(so2=>(so2._id!==so._id)),so]
                    });
                }
                else {
                    const newDst = (step!==srcStep) ? step : newSrcStep;
                    // So there we always insert the new step *after* the newDst step step.
                    // But the newDst step starts counting from 0 (i.e. the min possible value of `dstStepNdx` is 0
                    // as it comes from draggableId). This means that it is impossible to insert annything *before*
                    // the very first step or else we need to support draggableIds with negative step Ndxs
                    // Someday this will be fixed.
                    acc.push(newDst,newStep);
                }
            }
            else if( step===srcStep ) {
                acc.push(newSrcStep);
            }
            else {
                acc.push(step);
            }
            return acc;
        };
        return stopSteps.reduce(stepsReducer,[] as ManifestType.Step.Hydrated<OrderType.Order<string>>[]);
    }
    static validateSteps( steps:ManifestType.Step.Hydrated<OrderType.Order<string>>[] ) : ManifestType.Step.Hydrated<OrderType.Order<string>>[] {

        steps = steps.filter( step => {
            const at = Math.min(
                ...(step.pickups||[]).map(so=>((so.type==='pickup_ordered_at')?so.ordered_at!:Infinity)),
                ...(step.dropoffs||[]).map(so=>((so.type==='dropoff_ordered_at')?so.ordered_at!:Infinity))
            );
            step.seconds  = (at===Infinity) ? undefined : (at/1000);
            step.duration = ManifestType.Step.getBoardingSeconds(tectransit.agency,step);
            // whatever the problems could have been there before, we are revising steps
            // and those problems do not apply anymore. And seconds as well
            delete step.problem;
            // get rid of empty steps
            return (step.duration||0)>0;
        });

        interface OrderInfo {
            so          : OrderType.Order<string>;
            pickupNdx?  : number;
            dropoffNdx? : number;
        }
        Object.values(steps.reduce((acc,step,stepNdx) => {
            step.pickups?.forEach( so => {
                acc[so._id!] = {
                    ...acc[so._id!],
                    so,
                    pickupNdx:stepNdx
                };
            });
            step.dropoffs?.forEach( so => {
                acc[so._id!] = {
                    ...acc[so._id!],
                    so,
                    dropoffNdx:stepNdx
                };
            });
            return acc;
        },{} as Record<string,OrderInfo>)).forEach( orderInfo => {
            const so          = orderInfo.so;
            const pickupStep  = steps[orderInfo.pickupNdx!];
            const dropoffStep = steps[orderInfo.dropoffNdx!];
            if( !pickupStep || !pickupStep.latlng )
                throw Error(`Cannot find pickup step for order '${so._id}' of user '${so.user?.name}'`);
            else if( !dropoffStep || !dropoffStep.latlng )
                throw Error(`Cannot find dropoff step for order '${so._id}' of user '${so.user?.name}'`);
            else if( orderInfo.dropoffNdx!<=orderInfo.pickupNdx! )
                throw Error(`Pickup should happen before dropoff for order of user '${so.user?.name}'`);
            else if( LatLngType.getMetersBetween(tectransit.roundLatLng(so.pickup.location!),tectransit.roundLatLng(pickupStep.latlng))>5 )
                throw Error(`Order '${so._id}' cannot be picked up at '${pickupStep.address||LatLngType.toString(pickupStep.latlng)}'`);
            else if( LatLngType.getMetersBetween(tectransit.roundLatLng(so.dropoff.location!),tectransit.roundLatLng(dropoffStep.latlng))>5 )
                throw Error(`Order '${so._id}' cannot be dropped off at '${dropoffStep.address||LatLngType.toString(dropoffStep.latlng)}'`);
        });
        return steps;
    }
    getWithoutSchedulable(
        context : WhiteboardContext<SchedulableType.OrderSchedulable>,
        so      : SchedulableType.OrderSchedulable
    ) : OnDemandDraftVehicleManifest {
        return new OnDemandDraftVehicleManifest(
            context,
            this.originalVm,
            OnDemandDraftVehicleManifest.validateSteps(OnDemandDraftVehicleManifest.getStepsWithoutSchedulable(this.steps,so))
        );
    }
    getWithSchedulable(
        context : WhiteboardContext<SchedulableType.OrderSchedulable>,
        so      : SchedulableType.OrderSchedulable
    ) : OnDemandDraftVehicleManifest {
        return new OnDemandDraftVehicleManifest(
            context,
            this.originalVm,
            OnDemandDraftVehicleManifest.validateSteps(OnDemandDraftVehicleManifest.getStepsWithSchedulable(this.steps,so))
        );
    }
    getFromSchedulableDnd(
        context     : WhiteboardContext<SchedulableType.OrderSchedulable>,
        so          : SchedulableType.OrderSchedulable,
        srcStepNdx  : number,
        dstStepNdx  : number,
        replaceDst  : boolean,
        eventType   : ('pickup'|'dropoff')
    ) : OnDemandDraftVehicleManifest {
        return new OnDemandDraftVehicleManifest(
            context,
            this.originalVm,
            OnDemandDraftVehicleManifest.validateSteps(
                OnDemandDraftVehicleManifest.getStepsFromSchedulableDnd(
                    this.steps,
                    so,
                    srcStepNdx,
                    dstStepNdx,
                    replaceDst,
                    eventType
                )
            )
        );
    }
}

export default OnDemandDraftVehicleManifest;