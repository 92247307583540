import * as DnDType     from 'react-beautiful-dnd';

interface DraggableProps {
    draggableId : string;
    index       : number;
}
const Draggable : React.FC<DraggableProps> = props => {
    return (
        <DnDType.Draggable draggableId={props.draggableId} index={props.index}>
            {(provided) => {
                return (<span ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                    {props.children}
                </span>);
            }}
        </DnDType.Draggable>
    );
}

export default Draggable;