import { Link }                      from "react-router-dom";

import consts                        from 'shared/consts';
import * as DurationType             from 'shared/types/Duration';
import * as ReportsType              from 'shared/types/Reports';
import DateRangeTable                from 'Manager/components/DateRangeTable';

interface DriversRow extends ReportsType.OnDemand.DriversRow {
    // Fields calculated on the client
    revenue_miles       : number;
    non_revenue_miles   : number;
    total_miles         : number;
}
export default class DriverTable extends DateRangeTable<DriversRow> {
    renderThead() {
	return (
            <tr>
              <th className={"align-top"} onClick={()=>this.sort('_id')}>{this.getSortingIcon('_id')}Id</th>
              <th className={"align-top"} onClick={()=>this.sort('name')}>{this.getSortingIcon('name')}Name</th>
              <th className={"align-top"} onClick={()=>this.sort('revenue_hours')}>{this.getSortingIcon('revenue_hours')}Revenue Hours</th>
              <th className={"align-top"} onClick={()=>this.sort('non_revenue_seconds')}>{this.getSortingIcon('non_revenue_seconds')}Non Revenue Hours</th>
              <th className={"align-top"} onClick={()=>this.sort('total_seconds')}>{this.getSortingIcon('total_seconds')}Total Hours</th>
              <th className={"align-top"} onClick={()=>this.sort('revenue_miles')}>{this.getSortingIcon('revenue_hours')}Revenue Miles</th>
              <th className={"align-top"} onClick={()=>this.sort('non_revenue_miles')}>{this.getSortingIcon('non_revenue_hours')}Non Revenue Miles</th>
              <th className={"align-top"} onClick={()=>this.sort('total_miles')}>{this.getSortingIcon('total_hours')}Total Miles</th>
            </tr>
	);
    }
    renderRow( r:DriversRow ) {
        return (
            <tr key={r._id}>
                <td>{r._id||'n/a'}</td>
                <td>{r._id ? (<Link to={`/Manager/Passenger/${r._id}`}>{r.name||'n/a'}</Link>) : (r.name||'n/a') }</td>
                <td>{DurationType.secondsToHMS(r.revenue_seconds,60)}</td>
                <td>{DurationType.secondsToHMS(r.non_revenue_seconds,60)}</td>
                <td>{DurationType.secondsToHMS(r.total_seconds,60)}</td>
                <td>{r.revenue_miles.toFixed(2)}</td>
                <td>{r.non_revenue_miles.toFixed(2)}</td>
                <td>{r.total_miles.toFixed(2)}</td>
            </tr>
        );
    }
    fetchRows( orderby:string, limit:number, skip:number ) {
        return this.getTablePromise(...this.props.getTablePromiseArgs(orderby,limit,skip)).then( rows => {
            return rows.map( r => {
                r.revenue_miles       = r.revenue_meters/consts.meters_in_mile;
                r.non_revenue_miles   = r.non_revenue_meters/consts.meters_in_mile;
                r.total_miles         = r.total_meters/consts.meters_in_mile;
                return r;
            });
        });
    }
}

