import {
    readAndCompressImage
}                           from 'browser-image-resizer';
import React,{
    FC,
    ChangeEvent,
    useState }              from 'react';
import {
    useParams,
    useLocation,
    useNavigate
}                           from 'react-router-dom';
import {
    Credentials
}                           from "@aws-sdk/client-sts";
import {
    S3Client,
    PutObjectCommand,
    DeleteObjectCommand
}                           from "@aws-sdk/client-s3";

import consts               from 'shared/consts';
import Vehicle              from 'shared/types/Vehicle';
import * as DVIRecordType   from 'shared/types/DVIRecord';
import dayjs                from 'shared/utils/day-timezone';

import * as images          from 'images/';

import tectransit           from 'utils/TecTransit';
import getApiPromise        from 'utils/getApiPromise';
import Alert                from 'utils/Alert';

import * as MenuItem        from 'components/MenuItem';
import TriStateCheckbox     from 'components/TriStateCheckbox';
import dviDefectCheckboxes  from 'utils/dviDefectCheckboxes';

interface Props {
}
interface Photo {
    file        : File;
    resizedURL  : string;
}
const Dvi : FC<Props> = props => {

    const location = useLocation() as {
        state? : {
            vehicle   : Vehicle;
            dviRecord : DVIRecordType.DVIRecord;
        }
    };
    const params        = useParams<{type:string}>();
    const navigate      = useNavigate();
    const title         = (params.type==='pretrip') ? "Daily Vehicle Inspections Report" : 'Post Trip Inspection Report';

    // state
    const [vehicle,]            = useState<Vehicle|undefined>(location?.state?.vehicle);
    const [dviRecord,]          = useState<DVIRecordType.DVIRecord|undefined>(location?.state?.dviRecord);
    const [photos,setPhotos]    = useState<Photo[]>([]);

    if( !vehicle )
        return MenuItem.withMenuItem(title,( alert ) => {
            alert.set(`Vehicle is not provided in props`);
            return false;
        });
    if( !dviRecord )
        return MenuItem.withMenuItem(title,( alert ) => {
            alert.set(`DVI record is not provided in props`);
            return false;
        });

    const formInput = React.createRef() as React.RefObject<HTMLFormElement>;
    const getForm = () : Record<string,any> => {
        return (Array.from(formInput.current?.elements||[]) as HTMLInputElement[]).filter(input=>(input.type!=='submit')).reduce( (acc,input) => {
            if( input.type==='checkbox' ) {
                if( input.indeterminate ) {
                    const checkboxName = dviDefectCheckboxes.map(column=>(column[input.name])).find(v=>(typeof v === 'string'));
                    throw Error(`Checkbox '${checkboxName||input.name}' needs to be checked or cleared`);
                }
                acc[input.name] = input.checked;
            }
            else if( input.name==='odometer' ) {
                const odometer = Number(input.value||'NaN');
                if( isNaN(odometer) )
                    throw Error(`Odometer reading should be a number`);
                // Please see
                // 1/ https://github.com/TecTransit/TecTransit/issues/370
                // 2/ a note about min odometer value in `Server/api/driver.ts`
                const minOdometer = (vehicle.odometer_meters||0)/consts.meters_in_mile;
                if( odometer<minOdometer )
                    throw Error(`Odometer reading should be not less than the previous value (${minOdometer.toFixed(2)} miles)`);
                acc[input.name] = odometer;
            }
            else if( (input.name||'')==='' ) {
                // ignore
            }
            else {
                acc[input.name] = input.value.trim();
            }
            return acc;
        },{} as Record<string,any>);
    }
    const getS3Client = async () : Promise<S3Client> => {
        if( !tectransit.agency.issue428_s3_bucket_name )
            throw Error(`s3_bucket_name is not set`);
        return getApiPromise<Credentials>('/api/driver/dviCredentials','GET').then( credentials => {
            if( !credentials || credentials.err )
                throw Error(credentials?.err||`credentials are empty`);
            return new S3Client({
                region     : 'us-east-2',
                credentials: {
                    accessKeyId     : credentials.AccessKeyId!,
                    secretAccessKey : credentials.SecretAccessKey!,
                    sessionToken    : credentials.SessionToken
                }
            });
        });
    }
    const readFile = ( file:File ) : Promise<Uint8Array> => {
        const fileReader    = new FileReader();
        return new Promise<Uint8Array>((resolve,reject) => {
            fileReader.onerror  = ( e ) => {
                reject(e);
            }
            fileReader.onload  = ( e ) => {
                resolve(fileReader.result as Uint8Array);
            };
            fileReader.readAsArrayBuffer(file);
        });
    }
    const uploadPhotos = async ( s3Client:S3Client, photos:Photo[] ) : Promise<string[]> => {
        return Promise.all(
            photos.map( photo => {
                return readFile(photo.file).then( buffer => {
                    const key = `DVIs/${vehicle._id}/${dayjs().format("DD-MM-YYYY")}/${photo.file.name}`;
                    return s3Client.send(new PutObjectCommand({
                        Bucket  : tectransit.agency.issue428_s3_bucket_name!,
                        Key     : key,
                        Body    : buffer
                    })).then( () => {
                        console.log(`Key ${key} is uploaded to ${tectransit.agency.issue428_s3_bucket_name}`);
                        return key;
                    });
                });
            })
        );
    }
    const deletePhotos = async ( s3Client:S3Client, photoKeys:string[] ) : Promise<any[]> => {
        return Promise.all(
            photoKeys.map( key => {
                return s3Client.send(new DeleteObjectCommand({
                    Bucket  : tectransit.agency.issue428_s3_bucket_name!,
                    Key     : key,
                })).then( () => {
                    console.log(`Key ${key} is deleted from ${tectransit.agency.issue428_s3_bucket_name}`);
                    return key;
                });
            })
        );
    }
    const onSaveDvi = async ( e:any, alert:Alert ) : Promise<any> => {
        e.preventDefault();
        e.stopPropagation();
        try {
            const form  = getForm();
            if( !form.noDefectsFound && (!form.aboveDefectsCorrected || !form.aboveDefectsNeedNotBeCorrected) ) {
                if( !form.remarks )
                    throw Error(`You indicated that it is not safe to operate the vehicle. Please provide details in the remarks section`);
                if( !window.confirm(`You indicated that it is not safe to operate the vehicle. `+
                                    `The vehicle will be sent to maintenance and removed from operations. `+
                                    `The agency management will be notified. `+
                                    '\n\n'+
                                    `Do you confirm?`) )
                    throw Error(`User cancelled the operation`);
            }
            try {
                const s3Client = await getS3Client();
                try {
                    form.photoKeys = await uploadPhotos(s3Client,photos);
                    try {
                        const dviRecord = await getApiPromise<DVIRecordType.DVIRecord>('/api/driver/dvi','POST',form,{type:params.type});
                        if( !dviRecord || dviRecord.err )
                            throw Error(dviRecord?.err||`res is empty`);
                        if( DVIRecordType.isAcceptable(dviRecord,tectransit.user._id,dayjs().tz(tectransit.agency.time_zone).startOf('day').valueOf()) ) {
                            if( params.type==='posttrip' ) {
                                // navigate does not work in this case
                                window.location.href = `/auth/logout?${(new URLSearchParams({msg:'PTI is saved'})).toString()}`;
                                return;
                            }
                            else {
                                return navigate('/Driver/Route',{
                                    replace : true,
                                    state  : {dviRecord,vehicle}
                                });
                            }
                        }
                    }
                    catch( err ) {
                        alert.set(`Cannot create DVI record (${(err as Error).message})`);
                    }
                    await deletePhotos(s3Client,form.photoKeys);
                }
                catch( err ) {
                    alert.set(`Cannot upload photos (${(err as Error).message})`);
                }
            }
            catch( err ) {
                alert.set(`Cannot get credentials (${(err as Error).message})`);
            }
        }
        catch( err ) {
            alert.set(`Form is invalid (${(err as Error).message})`);
        }
    };
    return MenuItem.withMenuItem(title,( alert ) => {
        if( vehicle.unavailability_reason ) {
            alert.set(`This vehicle is not safe to operate (${vehicle.unavailability_reason})`);
            return;
        }
        let tabIndex = 1;
        const hasAnyCheckbox = dviDefectCheckboxes.some(column => {
            return Object.keys(column).some(key=>!tectransit.agency.hidden_dvi_checkboxes?.includes(key));
        });
        const photoWidthPx  = 500;
        return (<form ref={formInput}>
            <div className="wrapper">
                <table width="100%">
                <tbody>
                    <tr>
                        <td colSpan={2} className="header-condition">
                            <p>{ dviRecord.ms ? (<>
                                Vehicle <b>{dviRecord.vehicle_id}</b> was last inspected at <b>{dayjs(dviRecord.ms).tz(tectransit.agency.time_zone).format(tectransit.timeFormat)}</b>.
                            </>) : (<>
                                Vehicle <b>{dviRecord.vehicle_id}</b> has never been inspected.
                            </>) }</p>
                            <p><b>AS REQUIRED BY THE D.O.T. FEDERAL MOTOR CARRIER SAFETY REGULATIONS</b></p>
                            <p>please review the vehicle condition and submit the following inspection report.</p>
                        </td>
                    </tr>
                    <tr className="item-condition">
                        <td>
                            <b>CARRIER:</b>
                        </td>
                        <td>
                            {[tectransit.user.given_name,tectransit.user.family_name].filter(n=>!!n).join(" ")}
                        </td>
                    </tr>
                    <tr className="item-condition">
                        <td>
                            <b>FLEET ID#</b>:
                        </td>
                        <td>
                            {vehicle._id}
                        </td>
                    </tr>
                    <tr className="item-condition">
                        <td>
                            <b>Make, Model, Year</b>:
                        </td>
                        <td>
                            {vehicle.make_model_year}
                        </td>
                    </tr>
                    <tr className="item-condition">
                        <td>
                            <b>License Plate</b>:
                        </td>
                        <td>
                            {vehicle.license_plate}, {vehicle.license_state}
                        </td>
                    </tr>
                    <tr className="item-condition">
                        <td>
                            <b>ODOMETER READING</b>:
                        </td>
                        <td>
                            <input
                                className   = "input-theme input-theme-min"
                                placeholder = "miles"
                                name        = "odometer"
                                type        ="text"
                                size        = {10}
                                maxLength   = {10}
                                style       = {{border:'solid 1px'}}
                                tabIndex    = {tabIndex++}
                            />
                        </td>
                    </tr>
                    {hasAnyCheckbox && (<>
                        <tr className="item-condition">
                            <td colSpan={2}>
                                <b>EQUIPMENT CHECK</b> (put checkmarks on parts that are out of order)
                            </td>
                        </tr>
                        <tr className="item-condition">
                            <td colSpan={2}>
                                <div className="item-condition-inn field-group">
                                    {dviDefectCheckboxes.map( (column,ndx) => {
                                        const keys = Object.keys(column).filter(key=>!tectransit.agency.hidden_dvi_checkboxes?.includes(key));
                                        if( keys.length<1 )
                                            return null;
                                        return (
                                            <div key={ndx}>
                                                {keys.map( key => {
                                                    if( tectransit.agency.hidden_dvi_checkboxes?.includes(key) )
                                                        return null;
                                                    return (<div key={key} className="field-group">
                                                        <TriStateCheckbox id={key} name={key}/>
                                                        <label htmlFor={key} className="checkbox" tabIndex={tabIndex++}>{column[key]}</label>
                                                    </div>);
                                                })}
                                            </div>
                                        );
                                    })}
                                </div>
                            </td>
                        </tr>
                    </>)}
                    <tr className="item-condition">
                        <td>
                            <b>REMARKS</b>
                        </td>
                        <td>
                            <textarea
                                className   = "input-theme input-theme-min"
                                cols        = {80}
                                name        = "remarks"
                                style       = {{border:'solid 1px'}}
                                tabIndex    = {tabIndex++}
                            />
                        </td>
                    </tr>
                    {tectransit.agency.issue428_s3_bucket_name && (
                        <tr className="item-condition">
                            <td valign='top'>
                                <b>PHOTOS</b>:
                            </td>
                            <td>
                                {photos.map((photo,ndx) => {
                                    return (<div 
                                        key  ={`photo_ ${ndx}`} 
                                        style={{
                                            position:'relative',
                                            width   :`${photoWidthPx}px`,
                                        }}>
                                            <img 
                                                id  ={`userPhoto_${ndx}`} 
                                                src ={photo.resizedURL} 
                                                alt ={`User #${ndx+1}`}
                                                style={{
                                                    width   :`${photoWidthPx}px`,
                                                }}
                                            />
                                            <div 
                                                style={{
                                                    'position'  : 'absolute',
                                                    'top'       : '8px',
                                                    'right'     : '6px',
                                                    'cursor'    : 'pointer'
                                                }} 
                                                onClick={()=>{
                                                    setPhotos(photos.filter((_,i)=>(i!==ndx)));
                                                }}>
                                                <images.Close/>
                                            </div>
                                            <br/>
                                    </div>);
                                })}
                                <label
                                    className   = "btn-theme btn-small" 
                                    style       = {{
                                        width:`${photoWidthPx}px`
                                    }}
                                    tabIndex    = {tabIndex++}
                                >
                                    Add Photo
                                    <input
                                        style   = {{opacity:0}} 
                                        type    = "file" 
                                        accept  = "image/*"
                                        onChange= {(e:ChangeEvent<HTMLInputElement>)=>{
                                            e.stopPropagation();
                                            e.preventDefault();
                                            const file      = (e.target.files||[])[0];
                                            if( !file )
                                                return;
                                            readAndCompressImage(file,{maxWidth:photoWidthPx})
                                                .then( resized => {
                                                    setPhotos([...photos,{
                                                        file,
                                                        resizedURL:URL.createObjectURL(resized)
                                                    }]);
                                                })
                                                .catch( err => {
                                                    alert.set(`Cannot resize image (${err.message})`);
                                                });
                                        }}
                                    />
                                </label>
                            </td>
                        </tr>
                    )}
                    <tr>
                        <td colSpan={2} className="field-group">
                            <input type="checkbox" id="noDefectsFound" name="noDefectsFound" />
                            <label htmlFor="noDefectsFound" className="checkbox" tabIndex={tabIndex++}>CONDITIONS OF THE ABOVE VEHICLE ARE SATISFACTORY</label>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2} className="field-group">
                            <input type="checkbox" id="aboveDefectsCorrected" name="aboveDefectsCorrected" />
                            <label htmlFor="aboveDefectsCorrected" className="checkbox" tabIndex={tabIndex++}>ABOVE DEFECTS CORRECTED</label>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2} className="field-group">
                            <input type="checkbox" id="aboveDefectsNeedNotBeCorrected" name="aboveDefectsNeedNotBeCorrected" />
                            <label htmlFor="aboveDefectsNeedNotBeCorrected" className="checkbox"  tabIndex={tabIndex++}>ABOVE DEFECTS NEED NOT BE CORRECTED FOR SAFE OPERATION OF THE VEHICLE</label>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <button 
                                className   = "btn-theme btn-theme-min" 
                                onClick     = {(e)=>onSaveDvi(e,alert)} 
                                tabIndex    = {tabIndex++}>
                                Save
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
            </div>
        </form>);
    });
}

export default Dvi;
