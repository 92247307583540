import { Link }                     from "react-router-dom";

import * as ReportsType             from 'shared/types/Reports';
import dayjs                        from 'shared/utils/day-timezone';
import DateRangeTable               from 'Manager/components/DateRangeTable';

import tectransit                   from "utils/TecTransit";

export default class BoardingsTable extends DateRangeTable<ReportsType.BoardingRow> {
    renderThead() {
        return (
            <tr>
                <th className={"align-top"} onClick={()=>this.sort('at')}>{this.getSortingIcon('at')}Time</th>
                <th className={"align-top"} onClick={()=>this.sort('vehicle_id')}>{this.getSortingIcon('Vehicle')}Vehicle #</th>
                <th className={"align-top"} onClick={()=>this.sort('driver_name')}>{this.getSortingIcon('driver_name')}Driver</th>
                <th className={"align-top"} onClick={()=>this.sort('user_name')}>{this.getSortingIcon('user_name')}User</th>
                <th className={"align-top"} onClick={()=>this.sort('trip_name')}>{this.getSortingIcon('trip_name')}Trip</th>
                <th className={"align-top"} onClick={()=>this.sort('stop_name')}>{this.getSortingIcon('stop_name')}Stop</th>
            </tr>
        );
    }
    renderRow( row:ReportsType.BoardingRow ) {
        return (
            <tr key={`${row.driver_id}_${row.at}`}>
                <td>{dayjs(row.at).tz(tectransit.agency.time_zone).format(tectransit.timeFormat)}</td>
                <td>{row.vehicle_id}</td>
                <td><Link to={`/Dispatcher/User/${row.driver_id}`}>{row.driver_name}</Link></td>
                <td><Link to={`/Dispatcher/User/${row.user_id}`}>{row.user_name}</Link></td>
                <td>{row.trip_name}</td>
                <td>{row.stop_name}</td>
            </tr>
        );
    }
    fetchRows( orderby:string, limit:number, skip:number ) {
        return this.getTablePromise(...this.props.getTablePromiseArgs(orderby,limit,skip));
    }
}

