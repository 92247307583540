import React       from 'react';

import DateRangeReport from 'Manager/components/DateRangeReport';
import DriverTable     from 'Manager/components/FixedRoutes/DriverTable';

export default class Drivers extends DateRangeReport {
    getTitle() : (string|React.ReactNode) {
        const urlSearchParams = new URLSearchParams({
            csv    : '1',
            min_ts : this.state.min_ts.valueOf().toString(),
            max_ts : this.state.max_ts.valueOf().toString()
        });
        return (
            <React.Fragment>
                Drivers from {this.state.min_ts} to {this.state.max_ts}
                <a key='download' href={`/api/manager/report/fixedroutes/drivers?${urlSearchParams.toString()}`}><i className="fa fa-download"/></a>
            </React.Fragment>
        );
    }
    getTable() : React.ReactNode {
        const getTablePromiseArgs = ( orderby:string, limit:number, skip:number ) => {
            return [
                '/api/manager/report/fixedroutes/drivers',
                'GET',
                undefined,
                {
                    min_ts   : this.state.min_ts,
                    max_ts   : this.state.max_ts,
                    orderby,
                    limit,
                    skip
                }
            ];
        }
        return <DriverTable min_ts={this.state.min_ts} max_ts={this.state.max_ts} getTablePromiseArgs={getTablePromiseArgs}/>;
    }
}
