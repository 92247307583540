import dayjs        from 'shared/utils/day-timezone';

import getOrdinal   from 'utils/getOrdinal';

export const getOrderRepeatPatternDescription = (repeat_pattern: (string | undefined), repeat_at: dayjs.Dayjs): string => {
    switch( (repeat_pattern||'') ) {
        case 'daily':
            return `daily at ${repeat_at.format("HH:mm")}`;
        case 'weekly':
            return `weekly on ${repeat_at.format("dddd [at] HH:mm")}`;
        case 'monthly_nth_weekday':
            return `monthly on ${getOrdinal(Math.floor((repeat_at.date() - 1) / 7) + 1)} ${repeat_at.format("dddd [at] HH:mm")}`;
        case 'monthly_last_weekday':
            return `monthly on last ${repeat_at.format("dddd [at] HH:mm")}`;
        case 'every_weekday':
            return `every weekday at ${repeat_at.format('HH:mm')}`;
    }
    return repeat_pattern || `once at ${repeat_at.format("MM/DD/YYYY HH:mm")}`;
}

export const getOrderTypeDescription = ( type:string, repeat_pattern:(string|undefined), repeat_at:dayjs.Dayjs ): string => {
    switch( type||'' ) {
    case 'pickup_ordered_at':
        return `pickup ${getOrderRepeatPatternDescription(repeat_pattern,repeat_at)}`;
    case 'dropoff_ordered_at':
        return `dropoff ${getOrderRepeatPatternDescription(repeat_pattern,repeat_at)}`;
    }
    return `${type} ${getOrderRepeatPatternDescription(repeat_pattern,repeat_at)}`;
}

export default getOrderTypeDescription;