import { Link }                      from "react-router-dom";

import * as DurationType             from 'shared/types/Duration';
import * as ReportsType              from 'shared/types/Reports';
import DateRangeTable                from 'Manager/components/DateRangeTable';

export default class PayrollTable extends DateRangeTable<ReportsType.PayrollRow> {
    renderThead() {
        return (
            <tr>
                <th className={"align-top"} onClick={()=>this.sort('driver_name')}>{this.getSortingIcon('driver_name')}Driver Name</th>
                <th className={"align-top"} onClick={()=>this.sort('date')}>{this.getSortingIcon('date')}Date</th>
                <th className={"align-top"} onClick={()=>this.sort('regular_hours')}>{this.getSortingIcon('regular_hours')}Regular Hours</th>
                <th className={"align-top"} onClick={()=>this.sort('regular_pay')}>{this.getSortingIcon('regular_pay')}Regular Pay</th>
                <th className={"align-top"} onClick={()=>this.sort('overtime_hours')}>{this.getSortingIcon('overtime_hours')}Overtime Hours</th>
                <th className={"align-top"} onClick={()=>this.sort('overtime_pay')}>{this.getSortingIcon('overtime_pay')}Overtime Pay</th>
                <th className={"align-top"} onClick={()=>this.sort('total_pay')}>{this.getSortingIcon('total_pay')}Total Pay</th>
            </tr>
        );
    }
    renderRow( row:ReportsType.PayrollRow ) {
        row.regular_pay = row.regular_rate*row.regular_hours;
        row.overtime_pay = row.overtime_rate*row.overtime_hours;
        row.total_pay = row.regular_pay + row.overtime_pay;
        return (
            <tr key={`${row.driver_id}_${row.date}`}>
              <td>{row.driver_id ? (<Link to={`/Manager/Passenger/${row.driver_id}`}>{row.driver_name||'n/a'}</Link>) : (row.driver_name||'n/a') }</td>
              <td>{row.date}</td>
              <td>{DurationType.secondsToHMS(row.regular_hours*3600,60)}</td>
              <td>{`$ ${row.regular_pay.toFixed(2)}`}</td>
              <td>{DurationType.secondsToHMS(row.overtime_hours*3600,60)}</td>
              <td>{`$ ${row.overtime_pay.toFixed(2)}`}</td>
              <td>{`$ ${row.total_pay.toFixed(2)}`}</td>
            </tr>
        );
    }
    fetchRows( orderby:string, limit:number, skip:number ) {
        return this.getTablePromise(...this.props.getTablePromiseArgs(orderby,limit,skip));
    }
}

