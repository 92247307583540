import React                        from 'react';
import {
    Route,
    NavLink   }                     from "react-router-dom";

import * as images                  from 'images/';
import Alerts                       from 'Manager/Config/Alerts';
import Hours                        from 'Manager/Config/Hours';
import FixedRoutes                  from 'Manager/Config/FixedRoutes';
import Settings                     from 'Manager/Config/Settings';
import Vehicles                     from 'Manager/Config/Vehicles';
import DVIs                         from 'Manager/Config/DVIs';
import RouteTester                  from 'Manager/Config/RouteTester';
import OnDemandReportsDrivers       from 'Manager/Reports/OnDemand/Drivers';
import OnDemandReportsVehicles      from 'Manager/Reports/OnDemand/Vehicles';
import OnDemandReportsRoutes        from 'Manager/Reports/OnDemand/Routes';
import FixedRouteReportsDrivers     from 'Manager/Reports/FixedRoutes/Drivers';
import FixedRouteReportsVehicles    from 'Manager/Reports/FixedRoutes/Vehicles';
import FixedRouteReportsRoutes      from 'Manager/Reports/FixedRoutes/Routes';
import ReportsPayroll               from 'Manager/Reports/Payroll';
import ReportsBoardings             from 'Manager/Reports/Boardings';
import User                         from 'Dispatcher/components/User';
import BoundariesEditor             from 'Manager/components/BoundariesEditor';
import Role                         from 'utils/Role';
import App                          from 'App';

import tectransit                   from 'utils/TecTransit';

export const Index = ( app:App ) : Role => {
    const roleRoot          = "/Manager";
    const menuLinkClassName = "menu__link";
    const items = [
        <div  className="menu__list-title">Agency</div>,
        <div className="menu__item">
            <NavLink className={`${menuLinkClassName}`} onClick={()=>app.menuItemClick()} to={`${roleRoot}/Config/Hours`}>
                <images.ManagerHours/>
                <span className="menu__name">Hours & Holidays</span>
            </NavLink>
        </div>,
        <div className="menu__item">
            <NavLink className={`${menuLinkClassName}`} onClick={()=>app.menuItemClick()} to={`${roleRoot}/Config/Map`}>
                <images.ManagerServiceArea/>
                <span className="menu__name">Service Area</span>
            </NavLink>
        </div>
    ];
    if( tectransit.agency.fixed_route_enabled ) {
        items.push(
            <div className="menu__item">
                <NavLink className={`${menuLinkClassName}`} onClick={()=>app.menuItemClick()} to={`${roleRoot}/Config/FixedRoutes`}>
                    <images.ManagerServiceArea/>
                    <span className="menu__name">Fixed Routes</span>
                </NavLink>
            </div>
        );
    }
    if( Array.isArray(tectransit.agency.issue333_paratransit_areas) ) {
        items.push(
            <div className="menu__item">
                <NavLink className={`${menuLinkClassName}`} onClick={()=>app.menuItemClick()} to={`${roleRoot}/Config/Paratransit/Map`}>
                    <images.ManagerServiceArea/>
                    <span className="menu__name">Paratransit Boundaries</span>
                </NavLink>
            </div>
        );
    }
    items.push(
        <div className="menu__item">
            <NavLink className={`${menuLinkClassName}`} onClick={()=>app.menuItemClick()} to={`${roleRoot}/Config/Vehicles`}>
                <images.ManagerVehicles/>
                <span className="menu__name">Vehicles</span>
            </NavLink>
        </div>,
        <div className="menu__item">
            <NavLink className={menuLinkClassName} onClick={()=>app.menuItemClick()} to={`${roleRoot}/Config/Etc`}>
                <images.ManagerSettings/>
                <span className="menu__name">Settings</span>
            </NavLink>
        </div>,
        <div className="menu__item">
            <NavLink className={`${menuLinkClassName}`} onClick={()=>app.menuItemClick()} to={`${roleRoot}/Config/Alerts`}>
                <images.Alerts/>
                <span className="menu__name">Alerts</span>
            </NavLink>
        </div>,
        <div className="menu__item">
            <NavLink className={`${menuLinkClassName}`} onClick={()=>app.menuItemClick()} to={`${roleRoot}/Config/DVIs`}>
                <images.Checkboxes/>
                <span className="menu__name">DVIs</span>
            </NavLink>
        </div>
    );
    if( tectransit.agency.on_demand_enabled ) {
        items.push(
            <div  className="menu__list-title">{tectransit.agency.fixed_route_enabled?'On Demand ':''}Reports</div>,
            <div className="menu__item">
                <NavLink className={`${menuLinkClassName}`} onClick={()=>app.menuItemClick()} to={`${roleRoot}/Reports/OnDemand/Drivers`}>
                    <images.ManagerDrivers/>
                    <span className="menu__name">Drivers</span>
                </NavLink>
            </div>,
            <div className="menu__item">
                <NavLink className={`${menuLinkClassName}`} onClick={()=>app.menuItemClick()} to={`${roleRoot}/Reports/OnDemand/Vehicles`}>
                    <images.ManagerVehicles/>
                    <span className="menu__name">Vehicles</span>
                </NavLink>
            </div>,
            <div className="menu__item">
                <NavLink className={`${menuLinkClassName}`} onClick={()=>app.menuItemClick()} to={`${roleRoot}/Reports/OnDemand/Routes`}>
                    <images.DispatcherRoutes/>
                    <span className="menu__name">Routes</span>
                </NavLink>
            </div>
        );
    }
    if( tectransit.agency.fixed_route_enabled ) {
        /*
        See https://github.com/TecTransit/TecTransit/issues/359
        items.push(
            <div  className="menu__list-title">{tectransit.agency.on_demand_enabled?'Fixed Route ':''}Reports</div>,
            <div className="menu__item">
                <NavLink className={`${menuLinkClassName}`} onClick={()=>app.menuItemClick()} to={`${roleRoot}/Reports/FixedRoute/Drivers`}>
                    <images.ManagerDrivers/>
                    <span className="menu__name">Drivers</span>
                </NavLink>
            </div>,
            <div className="menu__item">
                <NavLink className={`${menuLinkClassName}`} onClick={()=>app.menuItemClick()} to={`${roleRoot}/Reports/FixedRoute/Vehicles`}>
                    <images.ManagerVehicles/>
                    <span className="menu__name">Vehicles</span>
                </NavLink>
            </div>,
            <div className="menu__item">
                <NavLink className={`${menuLinkClassName}`} onClick={()=>app.menuItemClick()} to={`${roleRoot}/Reports/FixedRoute/Routes`}>
                    <images.DispatcherRoutes/>
                    <span className="menu__name">Routes</span>
                </NavLink>
            </div>
        );
        */
    }
    items.push(
        <div className="menu__item">
            <NavLink className={`${menuLinkClassName}`} onClick={()=>app.menuItemClick()} to={`${roleRoot}/Reports/Payroll`}>
                <images.ManagerPayroll/>
                <span className="menu__name">Payroll</span>
            </NavLink>
        </div>
    );
    if( tectransit.agency.issue422_public_key )
        items.push(
            <div className="menu__item">
                <NavLink className={`${menuLinkClassName}`} onClick={()=>app.menuItemClick()} to={`${roleRoot}/Reports/Boardings`}>
                    <images.ManagerPayroll/>
                    <span className="menu__name">Boardings</span>
                </NavLink>
            </div>
        );
    const routes = [
        <Route
            key={"manager_config_hours"}
            path={`${roleRoot}/Config/Hours`}
            element={<Hours/>} />,
        <Route
            key={"manager_config_map"}
            path={`${roleRoot}/Config/Map`}
            element={<BoundariesEditor
                key                     = { 'boundaries' }
                title                   = { 'Service Area' }
                boundariesPropertyName  = { 'boundaries' }
            />}
        />,
        <Route
            key={"manager_config_routetester"}
            path={`${roleRoot}/Config/RouteTester`}
            element={<RouteTester/>} />,
    ];
    if( tectransit.agency.fixed_route_enabled ) {
        routes.push(
            <Route
                key={"manager_config_fixedroutes"}
                path={`${roleRoot}/Config/FixedRoutes`}
                element={<FixedRoutes/>}
            />,
            <Route
                key={"manager_config_fixedroutes_tripId"}
                path={`${roleRoot}/Config/FixedRoutes/:tripId`}
                element={<FixedRoutes/>}
            />
        )
    }
    if( Array.isArray(tectransit.agency.issue333_paratransit_areas) ) {
        routes.push(
            <Route
                key={"manager_config_paratransit_map"}
                path={`${roleRoot}/Config/Paratransit/Map`}
                element={<BoundariesEditor
                    key                    = { 'issue333_paratransit_areas' }
                    title                  = { 'Paratransit Boundaries' }
                    boundariesPropertyName = { 'issue333_paratransit_areas' }
                />}
            />
        );
    }
    routes.push(
        <Route
            key={"manager_config_vehicles"}
            path={`${roleRoot}/Config/Vehicles`}
            element={<Vehicles/>} />,
        <Route
            key={"manager_config_settings"}
            path={`${roleRoot}/Config/Etc`}
            element={<Settings/>} />,
        <Route
            key={"manager_alerts"}
            path={`${roleRoot}/Config/Alerts/*`}
            element={<Alerts/>} />,
        <Route
            key={"manager_config_dvicheckboxes"}
            path={`${roleRoot}/Config/DVIs`}
            element={<DVIs/>} />
    );
    if( tectransit.agency.on_demand_enabled ) {
        routes.push(
            <Route
                key={"manager_reports_ondemand_drivers"}
                path={`${roleRoot}/Reports/OnDemand/Drivers`}
                element={<OnDemandReportsDrivers/>} />,
            <Route
                key={"manager_reports_ondemand_vehicles"}
                path={`${roleRoot}/Reports/OnDemand/Vehicles`}
                element={<OnDemandReportsVehicles/>} />,
            <Route
                key={"manager_reports_ondemand_routes"}
                path={`${roleRoot}/Reports/OnDemand/Routes`}
                element={<OnDemandReportsRoutes/>} />
        );
    }
    if( tectransit.agency.fixed_route_enabled ) {
        routes.push(
            <Route
                key={"manager_reports_fixedroute_drivers"}
                path={`${roleRoot}/Reports/FixedRoute/Drivers`}
                element={<FixedRouteReportsDrivers/>} />,
            <Route
                key={"manager_reports_fixedroute_vehicles"}
                path={`${roleRoot}/Reports/FixedRoute/Vehicles`}
                element={<FixedRouteReportsVehicles/>} />,
            <Route
                key={"manager_reports_fixedroute_routes"}
                path={`${roleRoot}/Reports/FixedRoute/Routes`}
                element={<FixedRouteReportsRoutes/>} />
        );
    }
    routes.push(
        <Route
            key={"manager_reports_payroll"}
            path={`${roleRoot}/Reports/Payroll`}
            element={<ReportsPayroll/>} />,
        <Route
            key={"manager_reports_boardings"}
            path={`${roleRoot}/Reports/Boardings`}
            element={<ReportsBoardings/>} />,
        <Route
            key={"manager_passenger"}
            path={`${roleRoot}/Passenger/:id`}
            element={<User/>} />,
        <Route
            key={"manager"}
            path={roleRoot}
            element={<Hours/>} />
    );
    return {
        name   : "Manager",
        root   : "/Manager",
        items,
        routes
    };
}

export default Index;
