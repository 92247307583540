import LatLng           from './LatLng';
import * as LegType     from './Leg';
import * as OrderType   from './Order';

export interface SecondsAndMeters {
    seconds          : number;
    meters           : number;
}

export interface Metrics {
    revenue          : SecondsAndMeters;
    non_revenue      : SecondsAndMeters;
}

export interface Route<T extends (OrderType.OrderTypes|OrderType.IdTypes)=OrderType.Order<number>> {
    legs               : LegType.Leg<LegType.Event<T>>[];
    bounds?            : { northeast:LatLng,southwest:LatLng };
    waypoint_order?    : number[];
    copyrights?        : string;
    summary?           : string;
    overview_polyline? : { points:string };
    last_locations?    : (LatLng & { seconds:number })[];
}

export const getMeters = <T extends (OrderType.OrderTypes|OrderType.IdTypes)>( route:(Route<T>|undefined) ) : number => {
    if( !route )
        return 0;
    return (route.legs||[]).reduce((a,leg)=>a+LegType.getMeters(leg),0);
}

export const getSeconds = <T extends (OrderType.OrderTypes|OrderType.IdTypes)>( route:(Route<T>|undefined) ) : number => {
    if( !route )
        return 0;
    return (route.legs||[]).reduce((a,leg)=>a+LegType.getSeconds(leg),0);
}

export default Route;
