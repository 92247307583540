import consts                        from 'shared/consts';

import * as DurationType             from 'shared/types/Duration';
import * as ReportsType              from 'shared/types/Reports';
import DateRangeTable                from 'Manager/components/DateRangeTable';

interface VehiclesRow extends ReportsType.OnDemand.VehiclesRow {
    total_hours         : number;
    total_miles         : number;
    revenue_miles       : number;
    non_revenue_miles   : number;
    passengers_per_hour : number;
    passengers_per_mile : number;
}

export default class VehicleTable extends DateRangeTable<VehiclesRow> {
    renderThead() {
        return (
            <tr>
              <th className={"align-top"} onClick={()=>this.sort('_id')}>{this.getSortingIcon('_id')}Id</th>
              <th className={"align-top"} onClick={()=>this.sort('license_plate')}>{this.getSortingIcon('license_plate')}License Plate</th>
              <th className={"align-top"} onClick={()=>this.sort('make_model_year')}>{this.getSortingIcon('make_model_year')}Make/Model</th>
              <th className={"align-top"} onClick={()=>this.sort('passengers')}>{this.getSortingIcon('passengers')}Passengers</th>
              <th className={"align-top"} onClick={()=>this.sort('hours')}>{this.getSortingIcon('hours')}Hours</th>
              <th className={"align-top"} onClick={()=>this.sort('miles')}>{this.getSortingIcon('miles')}Miles</th>
              <th className={"align-top"} onClick={()=>this.sort('passengers_per_hour')}>{this.getSortingIcon('passengers_per_hour')}Passengers/Hour</th>
              <th className={"align-top"} onClick={()=>this.sort('passengers_per_mile')}>{this.getSortingIcon('passengers_per_mile')}Passengers/Mile</th>
              <th className={"align-top"} onClick={()=>this.sort('revenue_seconds')}>{this.getSortingIcon('revenue_seconds')}Revenue Hours</th>
              <th className={"align-top"} onClick={()=>this.sort('revenue_miles')}>{this.getSortingIcon('revenue_miles')}Revenue Miles</th>
              <th className={"align-top"} onClick={()=>this.sort('non_revenue_seconds')}>{this.getSortingIcon('non_revenue_seconds')}Not Revenue Hours</th>
              <th className={"align-top"} onClick={()=>this.sort('non_revenue_miles')}>{this.getSortingIcon('non_revenue_miles')}Not Revenue Miles</th>
            </tr>
        );
    }
    renderRow( r:VehiclesRow ) {
        return (
            <tr key={r._id}>
                <td>{r._id}</td>
                <td>{`${r.license_state||''} ${r.license_plate}`}</td>
                <td>{r.make_model_year}</td>
                <td>{r.passengers}</td>
                <td>{DurationType.secondsToHMS(r.total_seconds,60)}</td>
                <td>{r.total_miles!.toFixed(2)}</td>
                <td>{r.passengers_per_hour!.toFixed(2)}</td>
                <td>{r.passengers_per_mile!.toFixed(2)}</td>
                <td>{DurationType.secondsToHMS(r.revenue_seconds,60)}</td>
                <td>{r.revenue_miles!.toFixed(2)}</td>
                <td>{DurationType.secondsToHMS(r.non_revenue_seconds,60)}</td>
                <td>{r.non_revenue_miles!.toFixed(2)}</td>
            </tr>
        );
    }
    fetchRows( orderby:string, limit:number, skip:number ) {
        return this.getTablePromise(...this.props.getTablePromiseArgs(orderby,limit,skip)).then( rows => {
            return rows.map( r => {
                r.total_hours         = r.total_seconds/3600;
                r.revenue_miles       = r.revenue_meters/consts.meters_in_mile;
                r.non_revenue_miles   = r.non_revenue_meters/consts.meters_in_mile;
                r.total_miles         = r.total_meters/consts.meters_in_mile;
                r.passengers_per_hour = r.passengers/r.total_hours;
                r.passengers_per_mile = r.passengers/r.total_miles;
                return r;
            });
        });
    }
}
