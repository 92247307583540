import {
    AGENCY__ADD_DRIVER,
    AGENCY__DELETE_DRIVER,
    AGENCY__UPDATE
} from "../actionTypes";

export default function agency( state, action ) {
    const get_new_state = () => {
        switch( action.type ) {
        case AGENCY__ADD_DRIVER: {
            const { user_id, vehicle_id } = action.payload||{};
            return {
                vehicles_by_driver_id : {
                    ...state.vehicles_by_driver_id,
                    [user_id]: vehicle_id
                }
            };
        }
        case AGENCY__DELETE_DRIVER: {
            const { user_id } = action.payload||{};
            const vehicles_by_driver_id = {...state.vehicles_by_driver_id};
            delete vehicles_by_driver_id[user_id];
            return {
                vehicles_by_driver_id
            };
        }
        case AGENCY__UPDATE: {
            return action.payload;
        }
        default:
            return {};
        }
    };
    return {
        ...state,
        ...get_new_state()
    };
}
