import * as ManifestType            from 'shared/types/Manifest';
import * as FixedRouteType          from 'shared/types/FixedRoute';
import * as OrderType               from 'shared/types/Order';

import * as SchedulableType         from 'Dispatcher/utils/Schedulable';
import WhiteboardContext            from 'Dispatcher/utils/WhiteboardContext';
import DraftVehicleManifest         from 'Dispatcher/utils/DraftVehicleManifest';

export class FixedRouteDraftVehicleManifest extends DraftVehicleManifest<SchedulableType.FixedRouteSchedulable> {

    static stepsFilter      = ManifestType.FixedRouteTrip.stepsFilter;
    static getByIdFromSteps = ManifestType.Step.getFixedRouteTripsById;

    private static getStepsWithoutSchedulable( allSteps:ManifestType.Step.Hydrated<OrderType.Order<string>>[], fr:SchedulableType.FixedRouteSchedulable ) : ManifestType.Step.Hydrated<OrderType.Order<string>>[] {
        return allSteps.filter(this.stepsFilter).filter( s =>{
            return (s.frTrip?._id!==fr._id);
        });
    }
    private static addStepInOrder( allSteps:ManifestType.Step.Hydrated<OrderType.Order<string>>[], newStep:ManifestType.Step.Hydrated<OrderType.Order<string>> ) : ManifestType.Step.Hydrated<OrderType.Order<string>>[] {
        const firstStopArrivalTime = newStep.frTrip!.stops[0].stop_time.arrival_time;
        const steps                = allSteps.filter(this.stepsFilter);
        let   ndxOfFirstStepWithGreaterArrivalTime = steps.findIndex( step => {
            if( !step.frTrip )
                return false;
            if( step.frTrip.stops[0].stop_time.arrival_time.localeCompare(firstStopArrivalTime)<=0 )
                return false;
            return true;
        });
        if( ndxOfFirstStepWithGreaterArrivalTime<0 )
            return [...steps,newStep];
        return [...steps.slice(0,ndxOfFirstStepWithGreaterArrivalTime),newStep,...steps.slice(ndxOfFirstStepWithGreaterArrivalTime)];
    }
    getWithoutSchedulable(
        context : WhiteboardContext<SchedulableType.FixedRouteSchedulable>,
        fr      : SchedulableType.FixedRouteSchedulable
    ) : FixedRouteDraftVehicleManifest {
        return new FixedRouteDraftVehicleManifest(
            context,
            this.originalVm,
            FixedRouteDraftVehicleManifest.getStepsWithoutSchedulable(this.steps,fr)
        );
    }
    getWithSchedulable(
        context : WhiteboardContext<SchedulableType.FixedRouteSchedulable>,
        fr      : SchedulableType.FixedRouteSchedulable
    ) : FixedRouteDraftVehicleManifest {
        const vehicle = context.vehiclesById[this.originalVm.vehicle_id];
        if( vehicle?.fixed_route_name!==fr.route_name )
            console.log(`Assigning fixed route '${fr.name}' to vehicle on route '${vehicle?.fixed_route_name}'`);
        const firstStopSeconds = FixedRouteType.getSecondsFromHHmm(fr.stops[0].stop_time.arrival_time);
        const lastStopSeconds  = FixedRouteType.getSecondsFromHHmm(fr.stops.at(-1)!.stop_time.arrival_time);
        return new FixedRouteDraftVehicleManifest(
            context,
            this.originalVm,
            FixedRouteDraftVehicleManifest.addStepInOrder(this.steps,{
                name       : 'fixedroute' as ManifestType.StepName,
                seconds    : (context.agencyManifest.dayStartAt/1000)+firstStopSeconds,
                duration   : (lastStopSeconds-firstStopSeconds),
                frTrip     : fr
            })
        );
    }
}

export default FixedRouteDraftVehicleManifest;