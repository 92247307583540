import * as OrdersPayloadType   from 'shared/types/OrdersPayload';
import Orders                   from 'Dispatcher/components/Orders';

export interface Props {
}

export default class UnassignedOrders extends Orders {
    // @ts-expect-error
    public  props      : Props;
    protected fetchRows( orderby:string, limit:number, skip:number ) {
        return this.getTablePromise<OrdersPayloadType.Dehydrated<number>>(
            '/api/dispatcher/orders/assigned',
            'GET',
            undefined,
            {orderby,limit,skip}
        ).then(OrdersPayloadType.hydrate);
    }
}
