import React    from 'react';

import * as images  from 'images/';
import MenuItem     from 'components/MenuItem';
import Orders       from 'Driver/components/Orders';

export default function Orders2() {
    return (
        <MenuItem title={["Past Orders ",<a key='download' href='/api/driver/orders?csv'><images.Download/></a>]}>
            <Orders/>
        </MenuItem>
    );
}
