import dayjs            from '../utils/day-timezone';
import * as AgencyType  from '../types/Agency';

export const getAgencyWorkMinutes = ( agency:AgencyType.Agency, at?:number ) : AgencyType.NumRange[] => {
    const startOfDay    = dayjs(at).tz(agency.time_zone).startOf('day');
    // If there is a holiday then the agency is closed that day
    const holidayName   = agency.holidays[startOfDay.format("MM/DD/YYYY")];
    if( holidayName )
        return [];
    // Map to minutes
    // 0 - Sunday should become 6, Monday should become 0
    const dateStartMins = startOfDay.valueOf()/(60*1000);
    return agency.workminutes[(startOfDay.day()+6)%7].map( range => {
        return [dateStartMins+range[0],dateStartMins+range[1]];
    });
}

export default getAgencyWorkMinutes;
