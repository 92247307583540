import { FC } from 'react';

export const Download: FC<any> = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
         stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
         className="feather feather-download">
        <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
        <polyline points="7 10 12 15 17 10"></polyline>
        <line x1="12" y1="15" x2="12" y2="3"></line>
    </svg>
);

export const PassengerNewOrder : FC<any> = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
         stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
         className="feather feather-plus-circle">
        <circle cx="12" cy="12" r="10"></circle>
        <line x1="12" y1="8" x2="12" y2="16"></line>
        <line x1="8" y1="12" x2="16" y2="12"></line>
    </svg>
);

export const PassengerPastOrders : FC<any> = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
         stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
         className="feather feather-archive">
        <polyline points="21 8 21 21 3 21 3 8"></polyline>
        <rect x="1" y="3" width="22" height="5"></rect>
        <line x1="10" y1="12" x2="14" y2="12"></line>
    </svg>
);

export const PassengerScheduledOrders : FC<any> = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
         stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
         className="feather feather-rotate-cw">
        <polyline points="23 4 23 10 17 10"></polyline>
        <path d="M20.49 15a9 9 0 1 1-2.12-9.36L23 10"></path>
    </svg>
);

export const Profile : FC<any> = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
         stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
         className="feather feather-user">
        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
        <circle cx="12" cy="7" r="4"></circle>
    </svg>
);

export const PastOrder : FC<any> = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
         fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
         strokeLinejoin="round" className="feather feather-clock">
        <circle cx="12" cy="12" r="10"></circle>
        <polyline points="12 6 12 12 16 14"></polyline>
    </svg>
);

export const BackToMap : FC<any> = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
         fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
         strokeLinejoin="round" className="feather feather-arrow-left">
        <line x1="19" y1="12" x2="5" y2="12"></line>
        <polyline points="12 19 5 12 12 5"></polyline>
    </svg>
);

export const DeleteDriver : FC<any> = props => (
    <svg onClick={props.onClick}
         xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
         strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-trash-2">
        <polyline points="3 6 5 6 21 6"></polyline>
        <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
        <line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line>
    </svg>
);

export const AddDriver : FC<any> = props => (
    <svg onClick={props.onClick}
         xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
         strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-user-plus">
        <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
        <circle cx="8.5" cy="7" r="4"></circle>
        <line x1="20" y1="8" x2="20" y2="14"></line>
        <line x1="23" y1="11" x2="17" y2="11"></line>
    </svg>
);

export const ManagerSettings : FC<any> = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
         stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
         className="feather feather-settings">
        <circle cx="12" cy="12" r="3"></circle>
        <path
            d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
    </svg>
);

export const ManagerHours : FC<any> = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
         stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
         className="feather feather-clock">
        <circle cx="12" cy="12" r="10"></circle>
        <polyline points="12 6 12 12 16 14"></polyline>
    </svg>
);

export const ManagerServiceArea : FC<any> = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
         stroke="blue" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
         className="feather feather-map">
        <polygon points="1 6 1 22 8 18 16 22 23 18 23 2 16 6 8 2 1 6"></polygon>
        <line x1="8" y1="2" x2="8" y2="18"></line>
        <line x1="16" y1="6" x2="16" y2="22"></line>
    </svg>
);

export const ManagerFixedRoutes : FC<any> = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
	 stroke="currentColor" strokeWidth="2" strokeLinecap="round"
	 strokeLinejoin="round"
	 className="feather feather-pause-circle">
	<circle cx="12" cy="12" r="10"></circle>
	<line x1="10" y1="15" x2="10" y2="9"></line>
	<line x1="14" y1="15" x2="14" y2="9"></line>
    </svg>
);

export const ManagerDrivers : FC<any> = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
        <g transform="matrix(0.5 0 0 0.5 0 0)">
            <path d="M27 40C 27 41.6569 25.6569 43 24 43C 22.3431 43 21 41.6569 21 40C 21 38.3431 22.3431 37 24 37C 25.6569 37 27 38.3431 27 40z" stroke="none" fill="#333333" fillRule="nonzero" />
            <path d="M30.6187 37.1037C 30.1899 35.5033 31.1396 33.8583 32.74 33.4295L32.74 33.4295L34.6719 32.9118C 36.2723 32.483 37.9173 33.4328 38.3461 35.0332L38.3461 35.0332L39.3814 38.8969C 39.8102 40.4973 38.8604 42.1423 37.26 42.5711L37.26 42.5711L35.3282 43.0887C 33.7278 43.5176 32.0828 42.5678 31.654 40.9674L31.654 40.9674L30.6187 37.1037zM33.2576 35.3613C 32.7242 35.5043 32.4076 36.0526 32.5505 36.5861L32.5505 36.5861L33.5858 40.4498C 33.7288 40.9832 34.2771 41.2998 34.8106 41.1569L34.8106 41.1569L36.7424 40.6392C 37.2759 40.4963 37.5925 39.948 37.4495 39.4145L37.4495 39.4145L36.4142 35.5508C 36.2713 35.0173 35.723 34.7007 35.1895 34.8437L35.1895 34.8437L33.2576 35.3613z" stroke="none" fill="#333333" fillRule="nonzero" />
            <path d="M9.65396 35.0324C 10.0828 33.432 11.7278 32.4823 13.3282 32.9111L13.3282 32.9111L15.26 33.4287C 16.8604 33.8576 17.8102 35.5026 17.3814 37.103L17.3814 37.103L16.3461 40.9667C 15.9173 42.5671 14.2723 43.5168 12.6719 43.088L12.6719 43.088L10.74 42.5704C 9.13961 42.1415 8.18986 40.4965 8.61869 38.8961L8.61869 38.8961L9.65396 35.0324zM12.8106 34.843C 12.2771 34.7 11.7288 35.0166 11.5858 35.5501L11.5858 35.5501L10.5505 39.4138C 10.4076 39.9472 10.7242 40.4956 11.2576 40.6385L11.2576 40.6385L13.1895 41.1561C 13.723 41.2991 14.2713 40.9825 14.4142 40.449L14.4142 40.449L15.4495 36.5853C 15.5925 36.0519 15.2759 35.5035 14.7424 35.3606L14.7424 35.3606L12.8106 34.843z" stroke="none" fill="#333333" fillRule="nonzero" />
            <path d="M15.8378 39L23 39L23 31.0549C 20.1303 31.3722 17.6672 33.0387 16.2592 35.406C 16.0095 34.9247 15.5662 34.546 15.0012 34.3947L15.0012 34.3947L14.5983 34.2867C 16.5287 31.1168 20.0172 29 24 29C 27.9832 29 31.4718 31.1171 33.4022 34.2874L33.4022 34.2874L32.9988 34.3954C 32.434 34.5468 31.9908 34.9253 31.7411 35.4064C 30.3331 33.0389 27.8699 31.3722 25 31.0549L25 31.0549L25 39L32.1621 39L32.6199 40.7086C 32.647 40.8098 32.6814 40.9071 32.7223 41L32.7223 41L24 41L15.2773 41C 15.3185 40.9068 15.353 40.8093 15.3802 40.7078L15.3802 40.7078L15.8378 39zM15.0966 41.324C 14.6828 41.9256 13.9602 42.2651 13.2156 42.1769C 13.4542 43.3648 13.8842 44.4842 14.4722 45.5007L14.4722 45.5007L16.2034 44.4993C 15.6481 43.5392 15.2649 42.4671 15.0966 41.324zM31.7966 44.4993C 32.3518 43.5394 32.7349 42.4675 32.9033 41.3246C 33.3171 41.9262 34.0397 42.2658 34.7843 42.1777C 34.5457 43.3653 34.1157 44.4844 33.5278 45.5007L33.5278 45.5007L31.7966 44.4993z" stroke="none" fill="#333333" fillRule="nonzero" />
            <path d="M15 17L15 14L17 14L17 17C 17 20.866 20.134 24 24 24C 27.866 24 31 20.866 31 17L31 17L31 14L33 14L33 17C 33 21.9706 28.9706 26 24 26C 19.0294 26 15 21.9706 15 17z" stroke="none" fill="#333333" fillRule="nonzero" />
            <path d="M15.5989 6.25348C 15.0904 6.66596 15 6.95645 15 7.09677C 15 8.53676 15.2324 9.66613 15.4583 10.424C 15.5256 10.6495 15.5922 10.8421 15.6519 11L15.6519 11L32.3481 11C 32.4078 10.8421 32.4745 10.6496 32.5417 10.4241C 32.7676 9.66629 33 8.53693 33 7.09678C 33 6.95645 32.9096 6.66596 32.4011 6.25348C 31.9098 5.85504 31.1661 5.46148 30.2339 5.11465C 28.3749 4.42299 25.9931 4 24 4C 22.0069 4 19.6251 4.42299 17.7661 5.11465C 16.8339 5.46148 16.0902 5.85504 15.5989 6.25348zM31.7015 13L16.2985 13C 16.3655 13.09 16.4474 13.1843 16.5475 13.2811C 17.3021 14.0108 19.2284 15 24 15C 28.7716 15 30.6979 14.0108 31.4525 13.2811C 31.5526 13.1843 31.6345 13.09 31.7015 13zM13.9878 12.2059C 13.9684 12.1635 13.9475 12.1164 13.9253 12.0648C 13.8174 11.8142 13.6787 11.455 13.5417 10.9953C 13.2676 10.0758 13 8.75356 13 7.09678C 13 6.07581 13.6321 5.27356 14.3391 4.70015C 15.0634 4.1127 16.0284 3.62723 17.0687 3.24019C 19.1546 2.46411 21.7728 2 24 2C 26.2272 2 28.8454 2.46411 30.9313 3.24019C 31.9716 3.62723 32.9366 4.1127 33.6609 4.70015C 34.3679 5.27356 35 6.07581 35 7.09677C 35 8.75371 34.7324 10.076 34.4583 10.9955C 34.3213 11.4551 34.1826 11.8143 34.0747 12.0649C 34.0525 12.1165 34.0316 12.1635 34.0122 12.2059C 34.0109 12.3756 33.9934 12.5932 33.9375 12.8431C 33.8141 13.3952 33.5101 14.0736 32.8428 14.7189C 31.5291 15.9892 28.9555 17 24 17C 19.0445 17 16.4709 15.9892 15.1572 14.7189C 14.4899 14.0736 14.1859 13.3952 14.0625 12.8431C 14.0066 12.5932 13.9891 12.3756 13.9878 12.2059z" stroke="none" fill="#333333" fillRule="nonzero" />
            <path d="M20 8C 20 7.44772 20.4477 7 21 7L21 7L27 7C 27.5523 7 28 7.44772 28 8C 28 8.55228 27.5523 9 27 9L27 9L21 9C 20.4477 9 20 8.55228 20 8z" stroke="none" fill="#333333" fillRule="nonzero" />
        </g>
    </svg>
);

export const ManagerVehicles : FC<any> = props => (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="3 0 24 32">
        <path d="M 9 4 C 6.800781 4 5 5.800781 5 8 L 5 13 L 3 13 L 3 16 L 5 16 L 5 27 C 5 27.550781 5.449219 28 6 28 L 9 28 L 9.34375 27 L 22.65625 27 L 23 28 L 26 28 C 26.550781 28 27 27.550781 27 27 L 27 16 L 29 16 L 29 13 L 27 13 L 27 8 C 27 5.800781 25.199219 4 23 4 Z M 9 6 L 23 6 C 24.117188 6 25 6.882813 25 8 L 7 8 C 7 6.882813 7.882813 6 9 6 Z M 7 10 L 15 10 L 15 17 L 7 17 Z M 17 10 L 25 10 L 25 17 L 17 17 Z M 7 19 L 25 19 L 25 25 L 7 25 Z M 8 21 L 8 23 L 12 23 L 12 21 Z M 20 21 L 20 23 L 24 23 L 24 21 Z"/>
    </svg>
);

export const ManagerPayroll : FC<any> = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <line x1="12" y1="1" x2="12" y2="23"></line>
        <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
    </svg>
);

export const Alerts : FC<any> = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
        stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
        className="feather feather-alert-triangle">
        <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z">
        </path><line x1="12" y1="9" x2="12" y2="13"></line><line x1="12" y1="17" x2="12.01" y2="17"></line>
    </svg>
);

export const Checkboxes : FC<any> = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
        stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
        className="feather feather-check-square">
        <polyline points="9 11 12 14 22 4"></polyline>
        <path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11"></path>
    </svg>
);

export const MaintenanceCurrentRepairs : FC<any> = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
         stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-tool">
        <path d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z"></path>
    </svg>
);

export const MaintenancePastRepairs : FC<any> = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
         stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
         className="feather feather-archive">
        <polyline points="21 8 21 21 3 21 3 8"></polyline>
        <rect x="1" y="3" width="22" height="5"></rect>
        <line x1="10" y1="12" x2="14" y2="12"></line>
    </svg>
);

export const PlusSign : FC<any> = props => (
    <svg key="plussign" onClick={props.onClick}
         xmlns="http://www.w3.org/2000/svg" width={props.width||24} height={props.height||24} viewBox="0 0 24 24" fill="none" stroke="currentColor"
         strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-plus-square">
        <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
        <line x1="12" y1="8" x2="12" y2="16"></line>
        <line x1="8" y1="12" x2="16" y2="12"></line>
    </svg>
);

export const MinusSign : FC<any> = props => (
    <svg key="minussign" onClick={props.onClick}
	 xmlns="http://www.w3.org/2000/svg" width={props.width||24} height={props.height||24} viewBox="0 0 24 24" fill="none" stroke="currentColor"
	 strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-minus-square">
	<rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
	<line x1="8" y1="12" x2="16" y2="12"></line>
    </svg>
);

export const TrashSign : FC<any> = props => (
    <svg key="trashsign" onClick={props.onClick}
         xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 24 24" fill="none" stroke="currentColor"
         strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-trash-2">
        <polyline points="3 6 5 6 21 6"></polyline>
        <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
        <line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line>
    </svg>
);

export const AddAddress    = PlusSign;
export const DeleteAddress = TrashSign;

export const SwapPlaces : FC<any> = props => (
    <div className="swap-places">
        <span className="swap-places__arrow --top">
            <svg width="13" height="6" viewBox="0 0 13 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M3 4V6L0 3L3 0V2H13V4H3Z"></path>
            </svg>
        </span>
        <span className="swap-places__arrow --bottom">
            <svg width="13" height="6" viewBox="0 0 13 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M3 4V6L0 3L3 0V2H13V4H3Z"></path>
            </svg>
        </span>
    </div>
);

export const PlacesAutocomplete : FC<any> = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
         stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
         className="feather feather-list">
        <line x1="8" y1="6" x2="21" y2="6"></line>
        <line x1="8" y1="12" x2="21" y2="12"></line>
        <line x1="8" y1="18" x2="21" y2="18"></line>
        <line x1="3" y1="6" x2="3.01" y2="6"></line>
        <line x1="3" y1="12" x2="3.01" y2="12"></line>
        <line x1="3" y1="18" x2="3.01" y2="18"></line>
    </svg>
);

export const Logout : FC<any> = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
         stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
         className="feather feather-log-out">
        <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
        <polyline points="16 17 21 12 16 7"></polyline>
        <line x1="21" y1="12" x2="9" y2="12"></line>
    </svg>
);

export const Alert : FC<any> = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
         stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
         className="feather feather-alert-circle">
        <circle cx="12" cy="12" r="10"></circle>
        <line x1="12" y1="8" x2="12" y2="12"></line>
        <line x1="12" y1="16" x2="12.01" y2="16"></line>
    </svg>
);

export const Close : FC<any> = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
         stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
         className="feather feather-x">
        <line x1="18" y1="6" x2="6" y2="18"></line>
        <line x1="6" y1="6" x2="18" y2="18"></line>
    </svg>
);

export const Pickup : FC<any> = props => (
    <svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path fill="#1D1E7A" d="m12 17 1-2V9.858c1.721-.447 3-2 3-3.858 0-2.206-1.794-4-4-4S8 3.794 8 6c0 1.858 1.279 3.411 3 3.858V15l1 2zM10 6c0-1.103.897-2 2-2s2 .897 2 2-.897 2-2 2-2-.897-2-2z"/>
        <path fill="#1D1E7A" d="m16.267 10.563-.533 1.928C18.325 13.207 20 14.584 20 16c0 1.892-3.285 4-8 4s-8-2.108-8-4c0-1.416 1.675-2.793 4.267-3.51l-.533-1.928C4.197 11.54 2 13.623 2 16c0 3.364 4.393 6 10 6s10-2.636 10-6c0-2.377-2.197-4.46-5.733-5.437z"/>
    </svg>
);

export const Dropoff : FC<any> = props => (
    <svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path fill="#1D1E7A" d="m12 17 1-2V9.858c1.721-.447 3-2 3-3.858 0-2.206-1.794-4-4-4S8 3.794 8 6c0 1.858 1.279 3.411 3 3.858V15l1 2z"/>
        <path fill="#1D1E7A" d="m16.267 10.563-.533 1.928C18.325 13.207 20 14.584 20 16c0 1.892-3.285 4-8 4s-8-2.108-8-4c0-1.416 1.675-2.793 4.267-3.51l-.533-1.928C4.197 11.54 2 13.623 2 16c0 3.364 4.393 6 10 6s10-2.636 10-6c0-2.377-2.197-4.46-5.733-5.437z"/>
    </svg>
);

export const DriverMap : FC<any> = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
         stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
         className="feather feather-map">
        <polygon points="1 6 1 22 8 18 16 22 23 18 23 2 16 6 8 2 1 6"></polygon>
        <line x1="8" y1="2" x2="8" y2="18"></line>
        <line x1="16" y1="6" x2="16" y2="22"></line>
    </svg>
);

export const DriverRoute : FC<any> = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
         stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
         className="feather feather-table">
        <path d="M9 3H5a2 2 0 0 0-2 2v4m6-6h10a2 2 0 0 1 2 2v4M9 3v18m0 0h10a2 2 0 0 0 2-2V9M9 21H5a2 2 0 0 1-2-2V9m0 0h18"></path>
    </svg>
);

export const DriverManifest  : FC<any> = props => (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
        fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-clock">
        <circle cx="12" cy="12" r="10"></circle>
        <polyline points="12 6 12 12 16 14"></polyline>
    </svg>
);

export const DriverPastOrders : FC<any> = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
         stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
         className="feather feather-archive">
        <polyline points="21 8 21 21 3 21 3 8"></polyline>
        <rect x="1" y="3" width="22" height="5"></rect>
        <line x1="10" y1="12" x2="14" y2="12"></line>
    </svg>
);

export const DispatcherMap : FC<any> = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
         stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
         className="feather feather-map">
        <polygon points="1 6 1 22 8 18 16 22 23 18 23 2 16 6 8 2 1 6"></polygon>
        <line x1="8" y1="2" x2="8" y2="18"></line>
        <line x1="16" y1="6" x2="16" y2="22"></line>
    </svg>
);

export const DispatcherRoutes : FC<any> = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
         stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
         className="feather feather-table">
        <path d="M9 3H5a2 2 0 0 0-2 2v4m6-6h10a2 2 0 0 1 2 2v4M9 3v18m0 0h10a2 2 0 0 0 2-2V9M9 21H5a2 2 0 0 1-2-2V9m0 0h18"></path>
    </svg>
);

export const DispatcherPassengers : FC<any> = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
         stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
         className="feather feather-users">
        <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
        <circle cx="9" cy="7" r="4"></circle>
        <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
        <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
    </svg>
);

export const DispatcherStaff : FC<any> = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
         stroke="blue" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
         className="feather feather-users">
        <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
        <circle cx="9" cy="7" r="4"></circle>
        <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
        <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
    </svg>
);

export const DispatcherWhiteboard : FC<any> = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
         stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-eye">
        <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
        <circle cx="12" cy="12" r="3"></circle>
    </svg>
);

export const DispatcherOrders : FC<any> = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
         stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
         className="feather feather-package">
        <line x1="16.5" y1="9.4" x2="7.5" y2="4.21"></line>
        <path
            d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
        <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
        <line x1="12" y1="22.08" x2="12" y2="12"></line>
    </svg>
);
export const EditDepot : FC<any> = props => (
    <svg
         xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor"
         strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit">
        <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
    </svg>
);