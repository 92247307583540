import React            from 'react';
import * as LatLngType  from 'shared/types/LatLng';

export const addressRE = /^(.+),\s*([A-Z]{2}\s+[0-9]+,\s+[A-Za-z]+)$/i;

export const twoLinesAddressFormatter = (line1:string,line2:string) : React.ReactElement => {
    if( !line2 )
        return (<>{line1}</>);
    return (<>{line1}<br/>{line2}</>);
};

export const singleLineAddressFormatter = (line1:string,line2:string) : React.ReactElement => {
    if( !line2 )
        return (<>{line1}</>);
    return (<>{line1}, {line2}</>);
};

export const onlyFirstLineAddressFormatter = (line1:string,line2:string) : React.ReactElement => {
    if( !line2 )
        return (<>{line1}</>);
    return (<>{line1}</>);
};

export const getAddress = (
    address     : (string|undefined),
    formatter   : ((line1:string,line2:string)=>React.ReactElement) = twoLinesAddressFormatter
) : (React.ReactElement|undefined) => {
    if( !address )
        return undefined;
    const re         = /^(.+),\s*([A-Z]{2}\s+[0-9]+,\s+[A-Za-z]+)$/;
    const matches = String(address).match(re);
    if( !matches )
        return <>{address}</>;
    return formatter(matches[1],matches[2]);
};

export const getAddressFromLatLng = ( latlng:(LatLngType.LatLng|undefined|null) ) : string => {
    if( !latlng )
        return `(undefined)`;
    return `(${latlng.lat.toFixed(4)},${latlng.lng.toFixed(4)})`;
};

export const getPlace = (
    latlng      : (LatLngType.LatLng|undefined|null),
    address     : (React.ReactElement|string|undefined),
    formatter   : ((line1:string,line2:string)=>React.ReactElement) = twoLinesAddressFormatter
) : React.ReactElement => {
    if( !address )
        address = <>{getAddressFromLatLng(latlng)}</>;
    else if( typeof address==='string' )
        address = getAddress(address,formatter);
    const url = `https://www.google.com/maps/place/${latlng?.lat},${latlng?.lng}`;
    return <a href={url} rel='noreferrer' target='_blank'>{address}</a>;
};

export const getDirections = (
    latlng      : LatLngType.LatLng,
    address     : (React.ReactElement|string|undefined),
    formatter   : ((line1:string,line2:string)=>React.ReactElement) = twoLinesAddressFormatter
) : React.ReactElement => {
    if( !address )
        address = <>{getAddressFromLatLng(latlng)}</>;
    else if( typeof address==='string' )
        address = getAddress(address,formatter);
    const urlSearchParams = new URLSearchParams({
        destination : LatLngType.toString(latlng),
        travelMode  : 'driving',
        dir_action  : 'navigate'
    });
    const url = `https://www.google.com/maps/dir/?api=1&${urlSearchParams.toString()}`;
    return (<a href={url} rel='noreferrer' target='_blank'>{address}</a>);
}
