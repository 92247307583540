import React              from 'react';
import { Navigate }       from 'react-router-dom';
import * as MenuItem      from 'components/MenuItem';
import Alert              from 'utils/Alert';

export interface Props {
    title?        : string;
    searchParams? : URLSearchParams;
}

export default class UsersSearch extends React.Component {

    // @ts-expect-error
    public props : Props;
    public state : {
        text         : string
    };
    public alert : Alert = new Alert();

    constructor( props:Props ) {
        super(props);
        this.state = {
            text : ''
        };
    }

    private onSearchUser = ( text:string ) => {
        if( !text ) {
            // do nothing
        }
        else if( (text!=='*') && !/^\d{2,}$/.test(text) && (text.length<3) ) {
            this.alert.set(`Please provide at least 3 characters to search users`);
        }
        else {
            this.setState({text});
        }
    };

    render() {

        if( this.state.text ) {
            const searchParams = new URLSearchParams(this.props.searchParams);
            searchParams.set('text',this.state.text);
            return (
                <Navigate to={`/Dispatcher/Users?${searchParams.toString()}`}/>
            );
        }

        return MenuItem.withAlert( (alert_) => {
            this.alert = alert_;
            return (
                <div className="content section-card-inner">
                    <div className="section-card top-1">
                        <div className="passenger-search">
                            <div className="w-4">
                                <input
                                    id              = "text"
                                    className       = "input-theme"
                                    defaultValue    = {this.props.searchParams?.get('text')||''}
                                    type            = "search"
                                    placeholder     = {`Search ${this.props.title||'users'}`}
                                    aria-label      = "Search"
                                    onKeyDown       = { (e) => {
                                        if( e.key!=='Enter' )
                                            return;
                                        // @ts-expect-error
                                        return this.onSearchUser(e.target.value);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            );
        });
    }
}
