import React                    from 'react';
import {
    Link,
    useParams,
    useNavigate
}                               from 'react-router-dom';

import * as OrderType           from 'shared/types/Order';
import dayjs                    from 'shared/utils/day-timezone';

import tectransit               from 'utils/TecTransit';
import getOrderTypeDescription  from 'utils/getOrderTypeDescription';
import Alert                    from 'utils/Alert';
import getApiPromise            from 'utils/getApiPromise';
import * as MenuItem            from 'components/MenuItem';
import * as googleMapsLinks     from 'utils/googleMapsLinks';

interface ScheduledOrderProps {
}

const ScheduledOrder : React.FC<ScheduledOrderProps> = props => {

    const params = useParams() as { id: string };
    const [loading, setLoading] = React.useState<boolean>(true);
    const [so, setScheduledOrder] = React.useState<OrderType.Order<string>|undefined>(undefined);

    let alert       = new Alert();
    const navigate  = useNavigate();

    React.useEffect(() => {
        getApiPromise<OrderType.Order<string>>('/api/dispatcher/scheduledOrder',"GET", undefined, { id: params.id })
            .then( so => {
                if( !so || so.err )
                    throw Error(so?.err||'unknown error');
                setLoading(false);
                setScheduledOrder(so);
            })
            .catch(err => {
                alert.set(`Cannot get order (${err.message})`);
            });
    }, [params.id]);
    const patchScheduledOrder = ( e:React.ChangeEvent<HTMLSelectElement>, patch:Partial<OrderType.Order<string>> ) => {
        e.preventDefault();
        e.stopPropagation();
        getApiPromise<OrderType.Order<string>>('/api/dispatcher/scheduledOrder',"POST",patch,{id:params.id })
            .then( so => {
                if (!so || so.err)
                    throw Error(so?.err || 'unknown error');
                setScheduledOrder(so);
                alert.set(`Order is updated`,5000);
            })
            .catch( err => {
                alert.set(`Cannot save order (${err.message})`);
            });
    }
    const deleteScheduledOrder = (e: React.MouseEvent<HTMLButtonElement>, so:OrderType.Order<string> ) => {
        e.preventDefault();
        e.stopPropagation();
        if( !window.confirm(`Do you really want to delete scheduled order for user '${so.user?.name}' from '${so.pickup.address}' to '${so.dropoff.address}'`) )
            return;
        return getApiPromise('/api/dispatcher/order/scheduled','DELETE',{id:so._id})
            .then( response => {
                if( !response || response.err )
                    throw Error(response?.err||'unknown error');
                navigate('/Dispatcher/Orders/Scheduled');
            })
            .catch( err => {
                alert.set(`Cannot delete order (${err.message})`);
            });
    }
    return MenuItem.withMenuItem("Scheduled Order", (alert_) => {
        alert = alert_;
        if( loading || !so )
            return (<p className="alert alert-primary" role="alert">Loading...</p>);
        // TODO:
        // Making the list of possible values for `timeOptions` is less than perfect because
        // we do not really know for which day of the week the user has ordered it
        // For example, let's say a user make a daily recurring order on Wednesday.
        // This order is daily. Therefore it can happen on Friday.
        // Therefore we should not apply Wednesday agency open hours to Friday schedule
        const orderDay    = dayjs(so.original_ordered_at||so.ordered_at!).tz(tectransit.agency.time_zone).startOf('day');
        const timeOptions = (tectransit.agency.workminutes[(orderDay.day()+6)%7]||[]).reduce((acc,minsRange) => {
            // Convert each mins range into a set of seconds with 5 mins increment
            for( let min=minsRange[0]; min<=minsRange[1]; min+=5 )
                acc.push(orderDay.valueOf()+(min*60*1000));
            return acc;
        },[] as number[]);
        // TODO:
        // Suggest only those depots that are in the same service area as the order
        const plannedDepotNameOptions = ['',...tectransit.agency.depots.map(d=>d.name)];
        const repeatPatternOptions = {
            'once'                  : 'Once',
            'daily'                 : 'Every Day',
            'weekly'                : 'Once/Week on specific weekday',
            'monthly_nth_weekday'   : 'Once/Month on specific weekday',
            'monthly_last_weekday'  : 'Once/Month on last weekday',
            'annually'              : 'Once/Year',
            'every_weekday'         : 'Every Monday through Friday',
        } as Record<OrderType.RepeatPattern,string>;
        return (<div className="wrapper">
            <table width="50%" style={{borderSpacing:'15px'}}>
                <tbody>
                <tr>
                    <td><strong>User:</strong></td>
                    <td><Link to={`/Dispatcher/User/${so.user_id}`}>{so.user?.name || 'n/a'}</Link></td>
                </tr>
                <tr>
                    <td><strong>Created At:</strong></td>
                    <td>{dayjs(so.original_ordered_at||so.ordered_at).tz(tectransit.agency.time_zone).format(tectransit.timeFormat)}</td>
                </tr>
                <tr>
                    <td><strong>Created By:</strong></td>
                    <td><Link to={`/Dispatcher/User/${so.creator_id}`}>{so.user_id===so.creator_id?so.user?.name:'(click)'}</Link></td>
                </tr>
                <tr>
                    <td><strong>Updated By:</strong></td>
                        <td><Link to={`/Dispatcher/User/${so.updater_id||so.creator_id}`}>{so.user_id===(so.updater_id||so.creator_id)?so.user?.name:'(click)'}</Link></td>
                </tr>
                <tr>
                    <td><strong>From:</strong></td>
                    <td>{googleMapsLinks.getPlace(so.pickup.location,so.pickup.address,googleMapsLinks.singleLineAddressFormatter)}</td>
                </tr>
                <tr>
                    <td><strong>To:</strong></td>
                    <td>{googleMapsLinks.getPlace(so.dropoff.location,so.dropoff.address,googleMapsLinks.singleLineAddressFormatter)}</td>
                </tr>
                <tr>
                    <td valign='top'>
                        <strong>Type:</strong>
                    </td>
                    <td>
                        <select
                            className   = "input-theme input-theme-min"
                            value       = {so.type}
                            onChange    = {(e) => patchScheduledOrder(e,{ type: e.target.value as OrderType.Type })}>
                            {['pickup_ordered_at','dropoff_ordered_at'].map(t=>{
                                return <option key={t} value={t}>{getOrderTypeDescription(t,so.repeat_pattern,dayjs(so.ordered_at).tz(tectransit.agency.time_zone))}</option>;
                            })}
                        </select>
                    </td>
                </tr>
                <tr>
                    <td valign='top'>
                        <strong>Time of {so.type.replace(/^(pickup|dropoff)(_.+)$/,'$1')}:</strong>
                    </td>
                    <td>
                        <select
                            className   = "input-theme input-theme-min"
                            value       = {so.ordered_at!}
                            onChange    = {(e) => patchScheduledOrder(e,{ ordered_at: Number(e.target.value), original_ordered_at: (so.original_ordered_at||so.ordered_at) })}>
                            {timeOptions.map( o=>{
                                return <option key={o} value={o}>{dayjs(o).tz(tectransit.agency.time_zone).format("HH:mm")}</option>;
                            })}
                        </select>
                        <div>(This is when the <b>dispatchers</b> want to have it)</div>
                    </td>
                </tr>
                <tr>
                    <td valign='top'>
                        <strong>Repeat Pattern:</strong>
                    </td>
                    <td>
                        <select
                            className   = "input-theme input-theme-min"
                            value       = {so.repeat_pattern||'once'}
                            onChange    = {(e) => patchScheduledOrder(e,{ repeat_pattern : e.target.value as OrderType.RepeatPattern })}>
                            {Object.entries(repeatPatternOptions).map( ([rp,description]) => {
                                return <option key={rp} value={rp}>{description}</option>;
                            })}
                        </select>
                    </td>
                </tr>
                <tr>
                    <td valign='top'>
                        <strong>Original Type:</strong>
                    </td>
                    <td>
                        {getOrderTypeDescription(so.type,so.repeat_pattern,dayjs(so.original_ordered_at||so.ordered_at!).tz(tectransit.agency.time_zone))}
                        <div>(This is when the <b>user</b> originally ordered it)</div>
                    </td>
                </tr>
                <tr>
                    <td valign='top'>
                        <strong>Planned Depot</strong>
                    </td>
                    <td>
                        <select
                            className   ="input-theme input-theme-min"
                            value       ={so.planned_depot_name}
                            onChange    ={(e) => patchScheduledOrder(e, { planned_depot_name: e.target.value })}>
                            {plannedDepotNameOptions.map((dn,ndx) => (<option key={ndx} value={dn||''}>{dn||'(default)'}</option>))}
                        </select>
                        <div>(Here <b>dispatchers</b> can pre-select vehicles of which depot are to carry this order)</div>
                        <div>(<b>(default)</b> means closest to pickup point)</div>
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td>
                        <button type="button" className="btn-theme btn-small btn-delete" onClick={e=>deleteScheduledOrder(e,so)}>Delete</button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>);
    });
}

export default ScheduledOrder;