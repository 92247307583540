import tectransit   from 'utils/TecTransit';
import * as LegType from 'shared/types/Leg';

export default class MapOptions {
    public maxSpan  : number;
    public bounds   : google.maps.LatLngBoundsLiteral;
    public center   : google.maps.LatLngLiteral;
    public zoom     : number;

    static getBounds( boundary:(google.maps.LatLngLiteral|google.maps.LatLng)[], padding=0 ) : google.maps.LatLngBoundsLiteral {
        if( boundary.length<2 )
            padding = Math.max(padding,0.0625);
        const lats = boundary.map(b=>(typeof b.lat === 'number')?b.lat:b.lat());
        const lngs = boundary.map(b=>(typeof b.lng === 'number')?b.lng:b.lng());
        return {
            east:   Math.max(...lngs)+padding,
            west:   Math.min(...lngs)-padding,
            north:  Math.max(...lats)+padding,
            south:  Math.min(...lats)-padding
        };
    }
    static getLegBounds( leg:(LegType.Leg|undefined) ) : google.maps.LatLngBoundsLiteral {
        const allLatLngs = (leg?.steps||[]).map(s=>[s.start_location,s.end_location]).flat();
        return MapOptions.getBounds( (allLatLngs.length>0) ? allLatLngs : tectransit.agency.boundaries!.flat() );
    }
    static getZoom( degrees:number, pixels:number, center:google.maps.LatLngLiteral ) : number {
        // See https://stackoverflow.com/questions/9356724/google-map-api-zoom-range
        return Math.log2( (pixels*1.406279524*Math.cos(center.lat*Math.PI/180)) / Math.abs(degrees) );
    }
    constructor( bounds:google.maps.LatLngBoundsLiteral ) {
        // These values are ignored by Google MapOptions
        // See https://developers.google.com/maps/documentation/javascript/reference/map#MapOptions
        this.maxSpan = Math.max(bounds.east-bounds.west,bounds.north-bounds.south);
        this.bounds = bounds;
        // These values are used by Google MapOptions
        this.center = {
            lat : ((bounds.north+bounds.south)/2),
            lng : ((bounds.east+bounds.west)/2),
        };
        const mapDiv = document.getElementById("map") || {clientWidth:800,clientHeight:600};
        const zoomX  = MapOptions.getZoom(bounds.east-bounds.west,mapDiv.clientWidth,this.center);
        const zoomY  = MapOptions.getZoom(bounds.north-bounds.south,mapDiv.clientHeight,this.center);
        this.zoom   = Math.floor(Math.min(zoomX,zoomY));
    }
}
