import * as DnDType                     from 'react-beautiful-dnd';
import {
    Link }                              from "react-router-dom";

import * as LatLngType                  from 'shared/types/LatLng';
import * as DurationType                from 'shared/types/Duration';
import dayjs                            from 'shared/utils/day-timezone';

import tectransit                       from 'utils/TecTransit';
import * as googleMapsLinks             from 'utils/googleMapsLinks';
import getOrderTypeDescription          from 'utils/getOrderTypeDescription';
import deletedOrderStyle                from 'utils/deletedOrderStyle';

import * as SchedulableType             from 'Dispatcher/utils/Schedulable';
import WhiteboardContext                from 'Dispatcher/utils/WhiteboardContext';
import WhiteboardDraggable              from 'Dispatcher/components/WhiteboardDraggable'
import OnDemandDraftVehicleManifest        from 'Dispatcher/utils/OnDemandDraftVehicleManifest';

export class OnDemandWhiteboardContext extends WhiteboardContext<SchedulableType.OrderSchedulable> {

    static DraftVehicleManifestConstructor  = OnDemandDraftVehicleManifest;

    getSchedulablesById() {
        return (this.agencyManifest.ordersById||{}) as Record<string,SchedulableType.OrderSchedulable>;
    }
    onDragEnd( dragUpdate:DnDType.DragUpdate ) : Record<string,OnDemandDraftVehicleManifest> {
        if( !dragUpdate.destination )
            throw Error(`drag destination is empty`);
        const draggableIdRE                         = /^(pickup|dropoff|scheduled)Order_(.+)$/;
        const droppableRE                           = /^vehicle_(\d+)_step_(-?\d+)(?:_(\d))$/;
        const [,srcVehicleId,srcStepNdx,]            = dragUpdate.source.droppableId.match(droppableRE)||[0,-1,-1,0];
        const [,dstVehicleId,dstStepNdx,replaceDst]  = dragUpdate.destination.droppableId.match(droppableRE)||[0,-1,-1,0];
        const [,draggedOrderType,draggedOrderId]    = dragUpdate.draggableId.match(draggableIdRE)||[0,"",""];
        const draggedOrder                          = this.getSchedulablesById()[draggedOrderId];
        const srcVehicleManifest                    = this.draftVehicleManifestsById[srcVehicleId] as OnDemandDraftVehicleManifest;
        const dstVehicleManifest                    = this.draftVehicleManifestsById[dstVehicleId] as OnDemandDraftVehicleManifest;
        console.debug({
            'source.droppageId' : dragUpdate.source.droppableId,
            'destination,dropppableId' : dragUpdate.destination.droppableId,
            draggedOrderId,
            srcVehicleId,
            dstVehicleId,
            srcStepNdx,
            dstStepNdx
        });
        if( !draggedOrder )
            throw Error(`Cannot find order by id '${draggedOrderId}'`);
        else if( srcVehicleManifest && dstVehicleManifest ) {
            if( srcVehicleManifest===dstVehicleManifest ) {
                // This means that we are dragging order events within the vehicle itself
                return {
                    [srcVehicleId] : srcVehicleManifest.getFromSchedulableDnd(
                        this,
                        draggedOrder,
                        Number(srcStepNdx),
                        Number(dstStepNdx),
                        Boolean(Number(replaceDst||0)),
                        draggedOrderType as ('pickup'|'dropoff')
                    )
                };
            }
            else {
                // This means that we are dragging entire orders (because if we move 'pickup' from vehicle X to vehicle X,
                // then 'dropoff' also needs to be movedto Y. Because pickup and dropoff needs to be on the same vehicle.
                return {
                    [srcVehicleId] : srcVehicleManifest.getWithoutSchedulable(
                        this,
                        draggedOrder
                    ),
                    [dstVehicleId] : dstVehicleManifest.getWithSchedulable(
                        this,
                        draggedOrder
                    )
                };
            }
        }
        else if( (dragUpdate.source.droppableId==='scheduled_orders') && dstVehicleManifest ) {
            // Order is moved from order list to the vehicles
            if( draggedOrder.vehicle ) {
                this.alert.set(
                    `This order is already allocated to vehicle '${draggedOrder.vehicle.license_plate||'??'}, ${draggedOrder.vehicle.license_state||'??'}'`,
                    6000
                );
            }
            else {
                return {
                    [dstVehicleId] : dstVehicleManifest.getWithSchedulable(
                        this,
                        draggedOrder
                    )
                };
            }
        }
        else if( srcVehicleManifest && (dragUpdate.destination.droppableId==='scheduled_orders') ) {
            // Order is moved back from vehicles to the order list
            if( !draggedOrder.vehicle ) {
                this.alert.set(
                    `This order is not allocated to a vehicle`,
                    6000
                );
            }
            else {
                return {
                    [srcVehicleId] : srcVehicleManifest.getWithoutSchedulable(
                        this,
                        draggedOrder
                    )
                };
            }
        }
        return {};
    }
    getHeaderText() : JSX.Element {
        return (<>
            During <b>{dayjs(this.agencyManifest.dayStartAt).tz(tectransit.agency.time_zone).format('MM/DD/YYYY')}</b>&nbsp;
            the agency has <b>{this.agencyManifest.vehicleManifests.length||'no'}</b> vehicle manifests&nbsp;
            and <b>{Object.keys(this.agencyManifest.ordersById||{}).length||'no'}</b> pre-scheduled orders.
        </>);
    }
    getSchedulablesTable(): JSX.Element {
        const schedulables = Object.values(this.getSchedulablesById()).sort((so1,so2)=>((so1.ordered_at||0)-(so2.ordered_at||0)));
        const columnNames = ["Vehicle","Passenger","Pickup","Dropoff","Type"];
        const droppableId = `scheduled_orders`;
        return (
            <DnDType.Droppable key={droppableId} droppableId={droppableId}>
                {(provided) => {
                    return (<span {...provided.droppableProps} ref={provided.innerRef}>
                        <table style={{/*'border':'1px solid black',*/'width':'100%'}}>
                            <tbody style={{verticalAlign:"top",textAlign:'left'}}>
                                <tr>
                                    {columnNames.map( name => {
                                        return (<th key={name} align="left">{name}</th>);
                                    })}
                                </tr>
                                {schedulables.map( (so,ndx) => {
                                    const key = `${so._id!}@${so.vehicle ? so.vehicle._id : -1}`;
                                    return (
                                        <tr key={key} style={so.vehicle ? {} : {backgroundColor:'red'}}>
                                            <td>{so.vehicle ? `${so.vehicle.license_plate||'??'}, ${so.vehicle?.license_state||'??'}` : 'n/a'}</td>
                                            <td>
                                                <WhiteboardDraggable
                                                    draggableId = {`scheduledOrder_${so._id!}`}
                                                    index       = {ndx}>
                                                    <Link to={`/Dispatcher/User/${so?.user_id!}`}>{so?.user?.name}</Link>
                                                </WhiteboardDraggable>
                                            </td>
                                            <td>{googleMapsLinks.getPlace(so.pickup.location,so.pickup.address)}</td>
                                            <td>{googleMapsLinks.getPlace(so.dropoff.location,so.dropoff.address)}</td>
                                            <td><Link to={`/Dispatcher/StandingOrder/${so._id}`}>{getOrderTypeDescription(so.type,so.repeat_pattern,dayjs(so.ordered_at||so.pickup.eta).tz(tectransit.agency.time_zone))}</Link></td>
                                        </tr>
                                    );
                                })}
                                <tr>
                                    <th align="center" colSpan={columnNames.length}>
                                        {schedulables.length} orders, {schedulables.reduce((acc,so)=>(acc+(so.vehicle?0:1)),0)||'no'} to be scheduled
                                    </th>
                                </tr>
                            </tbody>
                        </table>
                        {provided.placeholder}
                    </span>);
                }}
            </DnDType.Droppable>
        );
    }
    getDraftVehicleManifestStepCards( dvm:OnDemandDraftVehicleManifest ) : React.ReactNode[] {
        const tableStyle = {
            background      : (dvm.isIntact ? '#ffffff' : '#f0dd25'),
            borderRadius    : '0.5rem',
            boxShadow       : '0 4px 25px 0 rgb(0 0 0 / 10%)',
            padding         : '0.5rem',
            marginTop       : '5px',
            marginBottom    : '5px',
            width           : '100%'
        };
        let draggableNdx = 0;
        let seatsTaken = 0;
        let wheelchairsTaken = 0;
        const getOrderDraggable = ( draggableId:string, so:SchedulableType.OrderSchedulable ) : React.ReactNode => {
            const contents = !so.user?._id ? <span style={deletedOrderStyle}>deleted order</span> : (<Link to={`/Dispatcher/StandingOrder/${so._id}`}>
                {so?.user?.name}
            </Link>);
            return (<WhiteboardDraggable
                key         = { draggableId }
                draggableId = { draggableId }
                index       = { draggableNdx++ }>
                {' '}
                { contents }
            </WhiteboardDraggable>);
        }
        return dvm.steps.map((step,stepNdx) => {
            const pickups       = step.pickups||[];
            const dropoffs      = step.dropoffs||[];
            const showStepName  = (step.name!=='stop') || ((pickups.length+dropoffs.length)===0);
            const secondsStyle  = step.problem ? {color:'red',fontWeight:'bold'} : {};
            const stepMoment    = step.seconds ? dayjs(step.seconds*1000).tz(tectransit.agency.time_zone) : undefined;
            return (<table key={`${stepNdx}_${step.name}`} style={tableStyle}>
                <tbody>
                    {step.latlng && (
                        <tr>
                            <td colSpan={3} valign="top">{googleMapsLinks.getPlace(step.latlng,step.address,googleMapsLinks.singleLineAddressFormatter)}</td>
                        </tr>
                    )}
                    <tr>
                        <td style={{width:'10%',verticalAlign:'top',...secondsStyle}} title={step.problem||stepMoment?.format(tectransit.timeFormat)||''}>
                            {stepMoment?.format('HH:mm')||'??'}
                        </td>
                        <td style={{width:'65%',verticalAlign:'top'}}>
                            {showStepName?(<strong>{step.name}</strong>):''}
                            {(dropoffs.length>0) && (<div>
                                <strong title={`Before dropoff: seats:${seatsTaken}, wheelchairs:${wheelchairsTaken}`}>dropoff</strong>
                                {dropoffs.map(so=>{
                                    seatsTaken       -= so.seats;
                                    wheelchairsTaken -= so.wheelchairs;
                                    return getOrderDraggable(`dropoffOrder_${so._id!}`,so);
                                })}
                            </div>)}
                            {(pickups.length>0) && (<div>
                                <strong title={`Before pickup: seats:${seatsTaken}, wheelchairs:${wheelchairsTaken}`}>pickup</strong>
                                {pickups.map(so=>{
                                    seatsTaken       += so.seats;
                                    wheelchairsTaken += so.wheelchairs;
                                    return getOrderDraggable(`pickupOrder_${so._id!}`,so);
                                })}
                            </div>)}
                        </td>
                        <td style={{width:'100px'}}>
                            { step.duration ? DurationType.secondsToHMS(step.duration,60) : '??' }
                        </td>
                    </tr>
                </tbody>
            </table>);
        });
    }
    getDraftVehicleManifestSteps( dvm:OnDemandDraftVehicleManifest ) : React.ReactNode {
        const dvmCards = this.getDraftVehicleManifestStepCards(dvm);
        return dvmCards.map( (card,cardNdx) => {
            // We need to wrap every card into a <DnDType.Droppable/> object and - in addition -
            // insert <DnDType.Droppable/> objects in between the cards so that the dispatchers
            // can drag a card in between 2 other cards
            const droppableId = `vehicle_${dvm.originalVm.vehicle_id}_step_${cardNdx}`;
            const droppables = [
                // Dropping on this droppable should _replace_ the step identified by `step_...` part in `droppableId`
                // This is why the final `droppableId` has "_1" added to it. This will become `true` in `replaceDst`
                <DnDType.Droppable key={droppableId+"_1"} droppableId={droppableId+"_1"}>
                    {(provided) => {
                        return (
                            <div className="col" {...provided.droppableProps} ref={provided.innerRef}>
                                {card}
                                {provided.placeholder}
                            </div>
                        );
                    }}
                </DnDType.Droppable>
            ];
            if( cardNdx<(dvmCards.length-1) ) {
                droppables.push(
                    // Dropping on this droppable should _insert after_ the step identified by `step_...` part
                    // in `droppableId`. This is why the final `droppableId` has "_0" added to it. This will
                    // become `false` in `replaceDst`
                    <DnDType.Droppable key={droppableId+"_0"} droppableId={droppableId+"_0"}>
                        {(provided) => {
                            return (
                                <div className="col" {...provided.droppableProps} ref={provided.innerRef}>
                                    &nbsp;
                                    {provided.placeholder}
                                </div>
                            );
                        }}
                    </DnDType.Droppable>
                );
            }
            return droppables;
        });
    }
    getDraftVehicleManifestStops( dvm:OnDemandDraftVehicleManifest ) : JSX.Element {
        const latlngs = dvm.steps.filter(s=>!!s.latlng).map(s=>s.latlng!);
        return (
            <a href={`https://www.google.com/maps/dir/${latlngs.map(ll=>encodeURI(LatLngType.toString(ll))).join('/')}`}>
                {`${(!dvm.isIntact)?'about ':''}${latlngs.length}`}
            </a>
        );
    }
}

export default OnDemandWhiteboardContext;