import React from 'react';

export default class ErrorBoundary extends React.Component {

    public state : {
        error? : Error
    } = {};

    static getDerivedStateFromError(error:Error) {
        // Update state so the next render will show the fallback UI.
        return { error };
    }

    render() {
        if (this.state.error)
            return <p className="text-center"><h1>{this.state.error?.message}</h1></p>;
        return this.props.children;
    }
}
