import React                from "react";
import Modal                from "react-bootstrap/Modal";

import * as UserType                from 'shared/types/User';
import * as OrderType               from 'shared/types/Order';
import dayjs                        from 'shared/utils/day-timezone';
import tectransit                   from "utils/TecTransit";
import getOrdinal                   from 'utils/getOrdinal';

export interface TimeOption {
    value               : number
    text                : string;
    disabled            : boolean;
}

export interface Props {
    orderTypes         : OrderType.Type[];
    user               : UserType.User;
    order              : OrderType.Order;
    getTimeOptions     : () => TimeOption[];
    onSaveOrder        : (order:OrderType.Order) => void;
    onCreateOrder      : (order:OrderType.Order) => Promise<TimeOption[]|void>;
}

export class OrderModal extends React.Component {

    public static orderTypeOptions   = {
        'asap'               : 'ASAP',
        'pickup_ordered_at'  : 'Departing At',
        'dropoff_ordered_at' : 'Arriving At'
    } as Record<OrderType.Type,string>;
    private setupReturnTrip         : Boolean;
    public  props                   : Props;
    public  state : {
        order        : OrderType.Order;
        timeOptions  : TimeOption[];
    };

    constructor( props:Props ) {
        super(props);
        this.props                  = props;
        this.setupReturnTrip        = (props.order.setup_return_order===true);
        this.state = {
            order : {
                ...props.order,
                type               : (props.orderTypes.find(t=>(t===props.order.type))||props.orderTypes[0]),
                // Never setup return trip unless the user explicitly asked for it
                setup_return_order : false
            },
            timeOptions : this.props.getTimeOptions()
        };
    }
    private patchOrder = ( event:any, patch:Partial<OrderType.Order> ) => {
        event.preventDefault();
        event.stopPropagation();
        this.setState({
            order : {
                ...this.state.order,
                ...patch
            }
        });
    }

    render() {

        const wrapInModal = ( content:React.ReactNode ) : React.ReactNode => {
            return (
                <Modal
                    centered
                    dialogClassName="modal-90w"
                    aria-labelledby="order-modal"
                    show={true}
                    onEntered = {(modal) => {
                        window.scrollTo(0,0);
                    }}
                    onHide={() => {
                        this.props.onSaveOrder(this.state.order)
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{this.setupReturnTrip?`Return `:``}Order Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {content}
                    </Modal.Body>
                </Modal>
            );
        };

        if( this.state.timeOptions.length<=0 )
            return wrapInModal(<>Sorry, agency <b>{tectransit.agency.name}</b> service is not available</>);

        if( this.props.orderTypes.length<=0 )
            return wrapInModal(<>Sorry, agency <b>{tectransit.agency.name}</b> does not allow passengers to create orders</>);

        const orderedMoment = dayjs(this.state.order.ordered_at||new Date()).tz(tectransit.agency.time_zone);

        return wrapInModal(<>
            <div className="modal-inner">
            <div className="modal-info-title">From</div>
            <div className="modal-info-content">{this.state.order.pickup?.address||'n/a'}</div>
            </div>
            <div className="modal-inner">
            <div className="modal-info-title">To</div>
            <div className="modal-info-content">{this.state.order.dropoff?.address||'n/a'}</div>
            </div>
            { !this.props.user.special_accommodations_on_order ? null : tectransit.agency.accommodations.map( (accommodation,accommodation_ndx) => {
                const acc_lower = accommodation.replace(/\W/g, '').toLowerCase();
                const checked   = !!(this.state.order.special_accommodations||{})[acc_lower];
                return (
                    <div key={`accommodation_${accommodation_ndx}:${checked}`} className="field-group modal-inner modal-info-title">
                        <input
                            type="checkbox"
                            id={ acc_lower }
                            checked={checked}
                            onChange={(e) => {
                                const special_accommodations = {
                                    ...this.state.order.special_accommodations,
                                    [acc_lower] : e.target.checked
                                };
                                const seats                  = (special_accommodations?.wheelchair?0:1) + (special_accommodations?.personalcareattendant?1:0);
                                const wheelchairs            = special_accommodations?.wheelchair?1:0;
                                return this.patchOrder(e,{
                                    seats,
                                    wheelchairs,
                                    special_accommodations
                                });
                            }}
                        />
                        <label htmlFor={ acc_lower } className="checkbox">{ accommodation }</label>
                    </div>
                );
            }) }
            <div className="modal-inner">
                <div className="modal-info-title">When</div>
                <div className="modal-info-content">
                    <select
                        className="input-theme input-theme-min"
                        value   = {this.state.order.type}
                        onChange= {(e:any) => this.patchOrder(e,{type:e.target.value})}
                    >
                        { this.props.orderTypes.map(typ=>(<option key={typ} value={typ}>{OrderModal.orderTypeOptions[typ]}</option>)) }
                    </select>
                </div>
            </div>
            {(this.state.order.type==="asap") ? null : (
                <div className="modal-inner">
                    <div className="modal-info-content hours-block">
                        <select
                            size        = {5}
                            defaultValue= {this.state.order.ordered_at!}
                            onChange    = {(e:any) => this.patchOrder(e,{ordered_at:Number(e.target.value)})}
                        >
                            {this.state.timeOptions.map((o,ndx) => (<option key={ndx} value={o.value*1000} disabled={o.disabled}>{o.text}</option>))}
                        </select>
                    </div>
                    <p><small>Time zone: <strong>{tectransit.agency.time_zone}</strong></small></p>
                </div>
            )}
            {(this.state.order.type==="asap") ? null : (
                <div className="modal-inner">
                    <div className="modal-info-title">Repeat</div>
                    <div className="modal-info-content">
                        <select
                            className   = "input-theme input-theme-min"
                            value       = {this.state.order.repeat_pattern}
                            onChange    = {(e)=>this.patchOrder(e,{repeat_pattern:e.target.value as OrderType.RepeatPattern})}>
                            <option value="never">Never</option>
                            <option value="daily">Daily at {orderedMoment.format("hh:mm A")}</option>
                            <option value="weekly">Weekly on {orderedMoment.format("dddd")}</option>
                            <option value="monthly_nth_weekday">Monthly on {getOrdinal(Math.floor((orderedMoment.date()-1)/7)+1)} {orderedMoment.format("dddd")}</option>
                            {(orderedMoment.daysInMonth()<(orderedMoment.date()+7)) ? <option value="monthly_last_weekday">Monthly on last {orderedMoment.format("dddd")}</option> : null}
                            {(orderedMoment.day()<6) ? <option value="every_weekday">Every weekday</option> : null }
                        </select>
                    </div>
                </div>
            )}
            <div className="modal-info top-1" style={{display:'flex',alignItems:'center'}}>
                <button
                    className="btn-theme btn-small"
                    onClick={(e)=>{
                        e.preventDefault();
                        e.stopPropagation();
                        this.props.onCreateOrder(this.state.order).then( timeOptions => {
                            if( Array.isArray(timeOptions) )
                                this.setState({timeOptions});
                        });
                    }}>
                    Book
                </button>
                {this.setupReturnTrip ? null : (
                    <label
                        className="checkbox" style={{marginLeft:'auto'}}>
                        Setup Return Order
                        <input
                            type="checkbox"
                            key={`setup_return_order:${this.state.order.setup_return_order}`}
                            checked={(this.state.order.setup_return_order===true)}
                            onChange={(e:any) => this.patchOrder(e,{setup_return_order:e.target.checked})}
                        />
                    </label>
                )}
            </div>
        </>);
    }
}

export default OrderModal;