import consts from '../consts';

export interface Distance {
    value : number;
    text  : string;
}

export const fromMeters = ( meters:number ) : Distance => {
    return {
        value : Math.floor(meters),
        text  : `${Math.round(meters/(consts.meters_in_mile/100))/100} mi`
    }
}

export default Distance;
