import React                        from 'react';
import {
    Link }                          from "react-router-dom";
import DatePicker                   from "react-datepicker";

import * as LatLngType              from 'shared/types/LatLng';
import * as DistanceType            from 'shared/types/Distance';
import * as DurationType            from 'shared/types/Duration';
import * as AgencyType              from 'shared/types/Agency';
import * as ManifestType            from 'shared/types/Manifest';
import * as UserType                from 'shared/types/User';
import * as VehicleType             from 'shared/types/Vehicle';
import * as OrderType               from 'shared/types/Order';
import rehash                       from 'shared/utils/rehash';
import hashById                     from 'shared/utils/hashById';
import arrayDedupe                  from 'shared/utils/arrayDedupe';
import dayjs                        from 'shared/utils/day-timezone';

import Alert                        from 'utils/Alert';
import tectransit                   from 'utils/TecTransit';
import getApiPromise                from 'utils/getApiPromise';
import deletedOrderStyle            from 'utils/deletedOrderStyle';
import * as googleMapsLinks         from 'utils/googleMapsLinks';
import * as MenuItem                from 'components/MenuItem';
import * as VehicleManifestsType    from 'Dispatcher/components/VehicleManifests';

interface RouteStepProps {
    step        : ManifestType.Step.Hydrated<OrderType.Order<string>>;
    nextStep    : (ManifestType.Step.Hydrated<OrderType.Order<string>>|undefined);
}
const RouteStep : React.FC<RouteStepProps> = props => {
    const {step,nextStep} = props;
    const backgroundsByName = {
        'depot'      : '#ffbf00',
        'standby'    : '#ffbf00',
        'break'      : '#ffbf00',
    };
    const tableStyle      = {
        // @ts-expect-error
        background              : (backgroundsByName[step.name]||'#dcdcdc'),
        borderRadius            : '0.5rem',
        boxShadow               : '0 4px 25px 0 rgb(0 0 0 / 10%)',
        padding                 : '0.5rem',
        marginBottom            : '15px',
        width                   : '100%'
    };
    const getOrderSpan = ( so:OrderType.Order<string>, ndx:number ) => {
        const contents = !so.user?._id ? <span style={deletedOrderStyle} title={so._id}>deleted order</span> : (<Link to={`/Dispatcher/StandingOrder/${so._id}`}>
            {so?.user?.name}
        </Link>);
        return (<span key={so._id}>
            {(ndx===0)?(' of '):(', ')}
            { contents }
        </span>);
    }
    const [
        stepName,
        stepLatLng,
        stepAddress
    ] = (step.name==='fixedroute') ? [
        step.frTrip!.name,
        step.frTrip!.stops[0],
        step.frTrip!.stops[0].name
    ] : [
        step.name,
        step.latlng,
        step.address
    ];
    const secondsStyle  = step.problem ? {color:'red',fontWeight:'bold'} : {};
    const stepMoment    = step.seconds ? dayjs(step.seconds*1000).tz(tectransit.agency.time_zone) : undefined;
    return (
        <table style={tableStyle}>
            <tbody>
                {step.latlng && (
                    <tr>
                        <td colSpan={3} valign="top">{googleMapsLinks.getPlace(stepLatLng,stepAddress,googleMapsLinks.singleLineAddressFormatter)}</td>
                    </tr>
                )}
                <tr>
                    <td style={{width:'10%',verticalAlign:'top',...secondsStyle}} title={step.problem||stepMoment?.format(tectransit.timeFormat)||''}>
                        {stepMoment?.format('HH:mm')||'??'}
                    </td>
                    <td style={{width:'65%'}}>
                        <strong>{stepName}</strong>{step.etc?` (${step.etc})`:''}
                        {((step.pickups?.length||0)>0) && (<div>
                            <strong>pickup</strong>
                            {step.pickups?.map(getOrderSpan)}
                        </div>)}
                        {((step.dropoffs?.length||0)>0) && (<div>
                            <strong>dropoff</strong>
                            {step.dropoffs?.map(getOrderSpan)}
                        </div>)}
                        {((step.seconds!+step.duration!)<(nextStep?.seconds||0)) && (
                            <>
                                &nbsp;and {DurationType.secondsToHMS(nextStep!.seconds!-step.seconds!-step.duration!,60)} wait
                            </>
                        )}
                    </td>
                    <td>{DurationType.secondsToHMS(props.step.duration!,60)}</td>
                </tr>
            </tbody>
        </table>
    );
}

export interface VehicleManifestProps {
    vm : ManifestType.VehicleManifest.Hydrated<OrderType.Order<string>>;
}
const VehicleManifest : React.FC<VehicleManifestProps> = props => {
    const vm = props.vm;
    return (
        <div className="col">
            {vm.steps.map((step,sndx) => {
                return (
                    <RouteStep
                        key         = {sndx}
                        step        = {step}
                        nextStep    = {vm.steps[sndx+1]}
                    />
                );
            })}
        </div>
    );
}

interface Props {
    type : ('fixedroute'|'ondemand');
}
export default class ManifestsMenuItem extends React.Component {

    private alert                  : Alert = new Alert();

    public props   : Props;
    public state   : {
        vehicleManifests : (ManifestType.VehicleManifest.Hydrated<OrderType.Order<string>>[]|undefined);
        driversById      : (Record<number,UserType.User>|undefined);
        vehiclesById     : (Record<number,VehicleType.Vehicle>|undefined);
        manifestMoment   : dayjs.Dayjs;
    } = {
        vehicleManifests : undefined,
        driversById      : undefined,
        vehiclesById     : undefined,
        manifestMoment   : dayjs.tz(Date.now(),tectransit.agency.time_zone).startOf('day')
    };

    private getVehicleManifestsAndDriversId( mmnt:dayjs.Dayjs ) : Promise<{
        vehicleManifests : (ManifestType.VehicleManifest.Hydrated<OrderType.Order<string>>[]|undefined)
        driversById      : (Record<number,UserType.User>|undefined)
    }> {
        return getApiPromise<ManifestType.VehicleManifest.Dehydrated<string>[]>(`/api/dispatcher/${this.props.type}/vehicleManifests`,"GET",undefined,{date:mmnt.format('YYYY-MM-DD')})
            .then( dehydratedVehicleManifests => {
                if( !dehydratedVehicleManifests || dehydratedVehicleManifests.err )
                    throw Error(`Cannot get vehicle manifests (${dehydratedVehicleManifests?.err||'unknown error'})`);
                const getVehicleManifestDrivers = ( vm:ManifestType.VehicleManifest.Dehydrated<string> ) : (number|undefined)[] => {
                    return [...AgencyType.getDriverIdsByVehicleId(tectransit.agency,vm.vehicle_id),vm.committed_driver_id];
                }
                const driverIds = arrayDedupe(dehydratedVehicleManifests.map(getVehicleManifestDrivers).flat().filter(did=>Number.isInteger(did)));
                return Promise.all(driverIds.map(did=>getApiPromise<UserType.Dehydrated>('/api/dispatcher/user','GET',undefined,{id:did})))
                    .then( drivers => {
                        return {
                            vehicleManifests : dehydratedVehicleManifests
                                .map( vm => {
                                    const usersById = rehash(vm.usersById,user=>UserType.hydrate(user));
                                    return ManifestType.VehicleManifest.hydrate(
                                        vm,
                                        rehash(vm.ordersById,order=>OrderType.hydrate(order,usersById)),
                                        vm.fixedRouteStopsById||{}
                                    );
                                })
                                .sort((vm1,vm2)=>{
                                    // VMs can be returned in any order, so let's sort VMs by vehicle_id
                                    // to make this order consistent
                                    return vm1.vehicle_id-vm2.vehicle_id;
                                }),
                            driversById      : hashById(drivers.map(UserType.hydrate))
                        };
                    })
                    .catch( err => {
                        throw Error(`Cannot init drivers '${err.message}'`);
                    });
            })
            .catch( err => {
                this.alert.set(`Cannot init vehicle manifests (${err.message})`);
                return this.state;
            });
    }
    constructor( props:Props ) {
        super(props);
        this.props = props;
        Promise.all([
            this.getVehicleManifestsAndDriversId(this.state.manifestMoment),
            getApiPromise<VehicleType.Vehicle[]>('/api/dispatcher/vehicles','GET')
                .then( vehicles => {
                    if( !vehicles || vehicles.err )
                        throw Error(`Cannot get vehicles (${vehicles?.err||'unknown error'})`);
                    return vehicles.reduce((acc,vehicle)=>({...acc,[vehicle._id]:vehicle}),{} as Record<number,VehicleType.Vehicle>);
                })
                .catch( err => {
                    this.alert.set(`Cannot init vehicles (${err.message})`);
                    return this.state;
                }),
        ]).then( ([{vehicleManifests,driversById},vehiclesById])=>{
            this.setState({
                vehicleManifests,
                driversById,
                vehiclesById
            });
        })
    }
    render() {
        const getDatePicker = () => {
            return (<DatePicker
                selected={this.state.manifestMoment.toDate()}
                onChange={(date:(Date|null)) => {
		    if( !date )
		        return;
                    const manifestMoment = dayjs(date).tz(tectransit.agency.time_zone).startOf('day');
                    this.getVehicleManifestsAndDriversId(manifestMoment).then( ({vehicleManifests,driversById}) => {
                        this.setState({
                            vehicleManifests,
                            driversById,
                            manifestMoment
                        });
                    });
                }}
            />);
        }
        return MenuItem.withMenuItem(`Manifests for ${this.state.manifestMoment.format('YYYY-MM-DD')}`,( alert ) => {
            this.alert = alert;
            if( !this.state.vehicleManifests )
                return (<div style={{textAlign:'center'}}>Loading vehicle manifests...</div>);
            if( !this.state.driversById )
                return (<div style={{textAlign:'center'}}>Loading drivers...</div>);
            if( !this.state.vehiclesById )
                return (<div style={{textAlign:'center'}}>Loading vehicles...</div>);
            return (
                <div className="wrapper">
                    <VehicleManifestsType.VehicleManifests
                        vehicleManifests    = { this.state.vehicleManifests }
                        driversById         = { this.state.driversById }
                        vehiclesById        = { this.state.vehiclesById }
                        getStatus           = { ( ndx ) => {
                            const vm = this.state.vehicleManifests![ndx];
                            const problems = ManifestType.VehicleManifest.getProblems(vm);
                            if( problems.length<1 )
                                return (<span style={{color:'green'}}><strong>ok</strong></span>);
                            if( problems.length<2 )
                                return (<span style={{color:'red'}}><strong>{problems[0]}</strong></span>);
                            return (<span style={{color:'red'}}><strong>{problems.length} problems</strong></span>);
                        }}
                        getStops            = { ( ndx ) => {
                            const vm = this.state.vehicleManifests![ndx];
                            if( this.props.type==='fixedroute' ) {
                                return (
                                    <strong>{this.state.vehiclesById![vm.vehicle_id]?.fixed_route_name||'??'}</strong>
                                );
                            }
                            else {
                                const latlngs = (vm.steps||[]).filter(s=>!!s.latlng).map(s=>s.latlng!);
                                return (
                                    <a href={`https://www.google.com/maps/dir/${latlngs.map(ll=>encodeURI(LatLngType.toString(ll))).join('/')}`}>
                                        {latlngs.length}
                                    </a>
                                );
                            }
                        }}
                        getDistance         = { ( ndx ) => {
                            return DistanceType.fromMeters(this.state.vehicleManifests![ndx].distance).text;
                        }}
                        getSteps            = { ( ndx ) => {
                            return <VehicleManifest vm={this.state.vehicleManifests![ndx]}/>;
                        }}
                    />
                    <table style={{margin:'auto'}}>
                        <tbody>
                            <tr>
                                <td valign="top">
                                    <strong>See manifests for other days:</strong>
                                </td>
                                <td valign="top">
                                    {getDatePicker()}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            );
        });
    }
}
