import React from "react";

import * as UserType    from 'shared/types/User';
import * as VehicleType from 'shared/types/Vehicle';
import tectransit       from "utils/TecTransit";
import getApiPromise    from 'utils/getApiPromise';

export interface Props {
    user           : UserType.User;
}
export interface State {
    user?                : UserType.User;
    vehicles             : VehicleType.Vehicle[];
    autocompleteAddress  : string;
}
export class ViewUserInfo extends React.Component<Props,State> {

    constructor( props:Props ) {
        super(props);
        this.state = {
            user                : props.user,
            vehicles            : [],
            autocompleteAddress : ''
        };
        this.setUserState(props.user)
    }
    private setUserState( user:UserType.User ) {
        if( tectransit.user.roles.includes("Dispatcher") ) {
            if( (user.roles||[]).includes("Driver") ) {
                // A dispatcher looking at a driver user
                getApiPromise<VehicleType.Vehicle[]>("/api/dispatcher/vehicles").then( vehicles => {
                    return this.setState({ vehicles });
                });
            }
        }
        else if( tectransit.user.roles.includes("Driver") ) {
            if( (user.roles||[]).includes("Driver") ) {
                // A driver looking at another driver and neededing to know her vehicles
                // TODO: implement /api/driver/vehicles API
            }
        }
        else {
            if (tectransit.user._id !== user._id)
                throw Error(`Access denied`);
        }
    }
    setUser( user:UserType.User ) {
        this.setUserState(user);
        return this.setState({ user });
    }
    render() {
        const getYesNoRow = ( name:string, key:number, yes_or_no:boolean ) => {
            return (
                <tr key={key}>
                    <td>{name}</td>
                    <td>{yes_or_no ? "Yes" : "No" }</td>
                </tr>
            );
        };
        const renderAddresses = () => {
            const entries = Object.entries(this.state.user?.addresses || {});
            return (
                <table>
                    <tbody>
                        { (entries.length===0) ? (<tr><td>(no addresses)</td></tr>) : entries.map( ([address_name,address]) => {
                              return (
                                  <tr key={address_name}>
                                      <td>{address_name}</td>
                                      <td><a href={`https://www.google.com/maps/place/${address.location.lat},${address.location.lng}`}>{address.address}</a></td>
                                  </tr>
                              );
                        }) }
                    </tbody>
                </table>
            );
        };
        const user = this.state.user;
        if( !user )
            return <div>Loading...</div>;
        return (
            <div className="content">
                <div className="container-fluid container">
                    <div className="section-card">
                        <table><tbody>
                            <tr>
                                <td colSpan={2}>
                                    <img width={100} alt={user.name || ""} src={user.picture || ""} />
                                </td>
                            </tr>
                            <tr>
                                <td>Given Name</td>
                                <td>{user.given_name || ""}</td>
                            </tr>
                            <tr>
                                <td>Family Name</td>
                                <td>{user.family_name || ""}</td>
                            </tr>
                            <tr>
                                <td>Email</td>
                                <td>{user.email || ""}</td>
                            </tr>
                            <tr>
                                <td>Phone</td>
                                <td>{user.phone || ""}</td>
                            </tr>
                            <tr>
                                <td >Roles</td>
                                <td>{(user.roles || []).join(",")}</td>
                            </tr>
                        </tbody></table>
                    </div>
                    <div className="section-card">
                        <div className="section-card__title">Addresses</div>
                        {renderAddresses()}
                    </div>
                    <div className="section-card">
                        <div className="section-card__title">Special Accommodations</div>
                        <table><tbody>
                            {tectransit.agency.accommodations.map( (accommodation,ndx) => getYesNoRow(accommodation,ndx,(user.special_accommodations||{})[accommodation.replace(/\W/g,'').toLowerCase()]) )}
                        </tbody></table>
                    </div>
                    <div className="section-card">
                        <div className="section-card__title">Mode of Transportation</div>
                        <table><tbody>
                            {tectransit.agency.modes_of_transportation.map( (mode,ndx) => getYesNoRow(mode,ndx,(user.mode_of_transportation||{})[mode.replace(/\W/g,'').toLowerCase()]) )}
                        </tbody></table>
                    </div>
                </div>
            </div>
        );
    }
}

export default ViewUserInfo;
