import React             from 'react';
import {
    Link,
    useLocation
}                        from 'react-router-dom';

import * as UserType     from 'shared/types/User';
import dayjs             from 'shared/utils/day-timezone';

import * as images       from 'images/';
import tectransit        from 'utils/TecTransit';
import MenuItem          from 'components/MenuItem';
import InfiniteTable     from 'components/InfiniteTable';
import UsersSearch       from 'Dispatcher/components/UsersSearch';

export interface Props {
    searchParams : URLSearchParams;
}

class UsersTable extends InfiniteTable<UserType.User> {

    // @ts-expect-error
    public  props       : Props;

    fetchRows( orderby:string, limit:number, skip:number ) {
        const params = {
            orderby,
            limit,
            skip
        } as Record<string,any>;
        this.props.searchParams.forEach((value,key) => {
            params[key] = value;
        });
        return this.getTablePromise<UserType.Dehydrated[]>('/api/dispatcher/users','GET',undefined,params).then( dehydrated => {
            return dehydrated.map(UserType.hydrate);
        });
    }
    renderThead() {
        return (
            <tr>
                <th onClick={()=>this.sort('_id')}>{this.getSortingIcon('_id')}#</th>
                <th onClick={()=>this.sort('picture')}>{this.getSortingIcon('picture')}Picture</th>
                <th onClick={()=>this.sort('name')}>{this.getSortingIcon('name')}Name</th>
                <th onClick={()=>this.sort('created_at')}>{this.getSortingIcon('created_at')}Created At</th>
                <th onClick={()=>this.sort('provider')}>{this.getSortingIcon('provider')}Provider</th>
                <th onClick={()=>this.sort('email')}>{this.getSortingIcon('email')}Email</th>
                <th onClick={()=>this.sort('roles')}>{this.getSortingIcon('roles')}Roles</th>
            </tr>
        );
    }
    renderRow( u:UserType.User ) {
        return (
            <tr key={u._id}>
                <td>{u._id}</td>
                <td><Link to={`/Dispatcher/User/${u._id}`}><img width={100} alt={u.name} src={`${u.picture}?${Date.now()}`}/></Link></td>
                <td>
                    {u.name||([u.given_name,u.family_name].filter(n=>!!n).join(" "))}
                </td>
                <td>
                    {dayjs(u.created_at).tz(tectransit.agency.time_zone).format(tectransit.timeFormat)}
                </td>
                <td>
                    {u.provider}
                </td>
                <td>
                    {u.email}
                </td>
                <td>
                    {(u.roles||[]).join(',')}
                </td>
            </tr>
        );
    }
}

export default function Users( props:Record<string,any> ) {
    // Also
    // See https://stackoverflow.com/questions/35792275/how-to-force-remounting-on-react-components
    const location     = useLocation();
    const searchParams = new URLSearchParams(location.search||'');
    const title        = searchParams.get('title') || (searchParams.get('inRoles')||'user').split(',').map(role=>`${role}s`).join(' or ');
    return (
        <MenuItem title={[title,<a key='download' href={`/api/dispatcher/users${location.search}&csv`}><images.Download/></a>]}>
            <UsersSearch key={`${location.search}_search`} title={title} searchParams={searchParams}/>
            <UsersTable  key={`${location.search}_table`} searchParams={searchParams}/>
        </MenuItem>
    );
}
