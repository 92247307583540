import OrderIdTypes    from '../../OrderIdTypes';

import * as LatLngType from './LatLng';
import * as UserType   from './User';
import arrayTrimEnd    from '../utils/arrayTrimEnd';

export type Type          = 'asap' | 'pickup_ordered_at' | 'dropoff_ordered_at';
export type Status        = 'unknown' | 'ordered' | 'scheduled' | 'pickedup' | 'finished' | 'cancelled' | 'noshow' | 'missed';
export type RepeatPattern = 'once' | 'daily' | 'weekly' | 'monthly_nth_weekday' | 'monthly_last_weekday' | 'annually' | 'every_weekday';
export type IdTypes       = OrderIdTypes;
export type OrderTypes    = Order<OrderIdTypes>;

export const finalStatuses = ['finished','cancelled','noshow','missed'] as Status[];

export interface Event {
    location?               : LatLngType.LatLng; // in some contexts on client this can be empty (e.g. when the user has not yet chosen the pickup/dropoff)
    address?                : string;            // address of the event, if available
    eta?                    : number;            // current ETA in milliseconds
    earliest_eta?           : number;            // the earliest ETA, in milliseconds
    at?                     : number;            // when this actually happened, in milliseconds
};

export interface SpecialAccommodations {
    wheelchair?             : boolean;
    personalcareattendant?  : boolean;
    [x:string]              : (boolean|undefined);
}

export interface Order<T extends IdTypes=number> {

    // There are multiple kinds of orders saved in database and used in runtime:
    //
    // 1/ a regular order - used in server runtime, passed to the client, saved in `orders` collection
    // 2/ scheduled order - saved in `schedule` collection, represents a recurring order by user
    // 3/ manifest orders - records of orders in driver manifests
    //
    // This interface represents the base for all kinds of orders
    _id?                    : T;                // number for regular orders, for scheduled orders it is `mongodb.ObjectId` on the server and string on the client
    status                  : Status;
    type                    : Type;
    pickup                  : Event;
    dropoff                 : Event;
    seats                   : number;           // regular seats taken by the order
    wheelchairs             : number;           // wheelchairs taken by the order
    special_accommodations  : SpecialAccommodations;
    repeat_pattern?         : RepeatPattern;    // used when type `pickup_ordered_at` or `dropoff_ordered_at`

    created_at?             : Date;
    updated_at?             : Date;
    ordered_at?             : number;           // if `type` is `pickup_ordered_at` or `dropoff_ordered_at`, this sets when then pickup/dropoff is supposed to happen. In milliseconds
    scheduled_at?           : number;           // This is when the order was scheduled on the vehicles. In milliseconds

    user_id?                : number;
    creator_id?             : number;
    updater_id?             : number;
    driver_id?              : number;           // Stores who was the driver on the order
    scheduler_id?           : string;           // If order was produced by scheduler then this is the id of the scheduled order
    vehicle_id?             : number;           // The vehicle that carried this order

    linked_order_id?        : number;           // If this dry order in DB was created based on another "regular" order, then we will know it
    setup_return_order?     : boolean;          // Used at ordering
    original_ordered_at?    : number;           // Per issue #312 the dispatcher can change 'ordered_at'. This saves the original 'ordered_at'
    planned_depot_name?     : string;           // Per issue #312 dispatchers can "pin" the order to be served out of specific depot
    meters?                 : number;           // Overall number of meters the order is driven for
    message?                : string;           // Server uses it to pass a message to the client

    // Some things that are convenient to carry around with the order when server handles it
    user?                   : UserType.User;
    driver?                 : UserType.User;
}

export const isEqual = <T extends OrderTypes>( o1:T, o2:T ) : boolean => {
    return ((o1.user?._id||o1.user_id)===(o2.user?._id||o2.user_id)) &&
        (o1.type===o2.type) &&
        LatLngType.isEqual(o1.pickup.location!,o2.pickup.location!) &&
        LatLngType.isEqual(o1.dropoff.location!,o2.dropoff.location!);
}

export const getDescription = <T extends OrderTypes>( order:T ) : string => {
    return order._id ? `#${order._id}` : `from '${order.pickup.address||JSON.stringify(order.pickup.location)}' to '${order.dropoff.address||JSON.stringify(order.dropoff.location)}'`;
}

export const dedupe = <T extends OrderTypes>( orders:T[] ) : T[] => {
    // Intelligency dedupe orders taking into account their _id, scheduler_id and
    // the fact that they might not have any of those. Do the best effort to.
    const ordersById           = {} as Record<string,T>;
    const ordersBySchedulerId  = {} as Record<string,T>;
    const ordersByThemselves   = new Set<T>();
    orders.forEach( order => {
        if( order._id !== undefined )
            ordersById[String(order._id)] = order;
        else if( order.scheduler_id )
            ordersBySchedulerId[order.scheduler_id] = order;
        else
            ordersByThemselves.add(order);
    });
    return [
        ...Object.values(ordersById),
        ...Object.values(ordersBySchedulerId),
        ...Array.from(ordersByThemselves.values())
    ];
};

export const setEventEta = ( event:Event, eta:number ) : Event => {
    if( !isNaN(eta) ) {
        event.eta = eta;
        event.earliest_eta = Math.min(event.eta,event.earliest_eta||Infinity);
    }
    return event;
}

export type Hydrated<T extends OrderTypes> = Omit<
    T,
    'linked_order_id'|'setup_return_order'|'original_ordered_at'|'planned_depot_name'|'message'
>;

export type DehydratedEvent = [
    [number,number]?,
    string?,
    number?,
    number?,
    number?
];

export type Dehydrated<T extends IdTypes> = [
    T|undefined,
    Status,
    Type,
    DehydratedEvent,
    DehydratedEvent,
    number,
    number,
    Record<string,any>,
    RepeatPattern?,
    number?,    // created_at
    number?,
    number?,
    number?,
    number?,    // user_id
    number?,
    number?,
    number?,    // driver_id
    string?,
    number?,
    number?,    // meters
];

export const hydrateEvent = ( dehydrated:DehydratedEvent ) : Event => {
    return {
        location    : dehydrated[0] ? { lat:dehydrated[0][0], lng:dehydrated[0][1] } : undefined,
        address     : dehydrated[1],
        eta         : dehydrated[2],
        earliest_eta: dehydrated[3],
        at          : dehydrated[4]
    };
}

export const hydrate = <T extends IdTypes>( dehydrated:Dehydrated<T>, usersById:Record<number,UserType.User> ) : Hydrated<Order<T>> => {
    return {
        _id                     : dehydrated[0],
        status                  : dehydrated[1],
        type                    : dehydrated[2],
        pickup                  : hydrateEvent(dehydrated[3]),
        dropoff                 : hydrateEvent(dehydrated[4]),
        seats                   : dehydrated[5],
        wheelchairs             : dehydrated[6],
        special_accommodations  : dehydrated[7],
        repeat_pattern          : dehydrated[8],
        created_at              : dehydrated[9]  ? new Date(dehydrated[9]) : undefined,
        updated_at              : dehydrated[10] ? new Date(dehydrated[10]) : undefined,
        ordered_at              : dehydrated[11],
        scheduled_at            : dehydrated[12],
        user_id                 : dehydrated[13],
        creator_id              : dehydrated[14],
        updater_id              : dehydrated[15],
        driver_id               : dehydrated[16],
        scheduler_id            : dehydrated[17],
        vehicle_id              : dehydrated[18],
        meters                  : dehydrated[19],
        user                    : usersById[dehydrated[13]!],
        driver                  : usersById[dehydrated[16]!]
    };
}

export const dehydrateEvent = ( event:Event ) : DehydratedEvent => {
    return [
        event.location ? [event.location.lat,event.location.lng] : undefined,
        event.address,
        event.eta,
        event.earliest_eta,
        event.at
    ];
}

export const dehydrate = <T extends IdTypes>( order:Hydrated<Order<T>>, usersById:Record<number,UserType.Dehydrated> ) : Dehydrated<T> => {
    if( order.user )
        usersById[order.user._id] = UserType.dehydrate(order.user);
    if( order.driver )
        usersById[order.driver._id] = UserType.dehydrate(order.driver);
    const dehydrated : Dehydrated<T> = [
        order._id,
        order.status,
        order.type,
        dehydrateEvent(order.pickup),
        dehydrateEvent(order.dropoff),
        order.seats,
        order.wheelchairs,
        order.special_accommodations,
        order.repeat_pattern,
        order.created_at ? order.created_at.valueOf() : undefined,
        order.updated_at ? order.updated_at.valueOf() : undefined,
        order.ordered_at,
        order.scheduled_at,
        order.user_id,
        order.creator_id,
        order.updater_id,
        order.driver_id,
        order.scheduler_id,
        order.vehicle_id,
        order.meters
    ];
    return arrayTrimEnd(dehydrated) as Dehydrated<T>;
}

export default Order;
