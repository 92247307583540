import * as VehicleType from './Vehicle';
import * as UserType    from './User';

export interface DVIRecord<T=string/*|mongodb.ObjectId*/> {
    _id?        : T;
    driver_id   : number;
    vehicle_id  : number;
    ms          : number;
    type        : string;
    form        : Record<string,any>;
    repair_id?  : T;
}

export interface DVIsResult<T=DVIRecord> {
    vehicle     : VehicleType.Vehicle;
    dvis        : (T & { drivers:UserType.User[] })[];
}

export interface RepairWorkItem {
    description : string;
    hours       : number;
}

export interface RepairPartItem {
    qty         : number;
    part        : string;
    description : string;
    cost        : number;
}

export interface Repair<T=string/*|mongodb.ObjectId*/> {
    _id             : T,
    ms              : number;
    mechanic        : string;
    comment         : string;
    workItems       : RepairWorkItem[];
    partItems       : RepairPartItem[];
    user_id         : number;
    vehicle_id      : number;
}

export type PastRepairsResult<T=string/*|mongodb.ObjectId*/> = Repair<T> & {
    dvis        : DVIRecord<T>[];
    user        : UserType.User;
};

export const isAcceptable = ( dviRecord:DVIRecord, driver_id:number, msStartOfDay:number ) => {
    // DVI record is acceptable only if this vehicle was already
    // inspected by this driver today in agency time zone
    return (dviRecord.driver_id===driver_id) && (dviRecord.ms>=msStartOfDay);
};

export const isRepairValid = ( repair:Partial<Repair> ) : string => {
    if( !repair.mechanic || !repair.mechanic.trim() )
        return 'Mechanic name is required';
    if( !repair.comment || !repair.comment.trim() )
        return 'Comment is required';
    if( !Array.isArray(repair.workItems) || repair.workItems.length<1 )
        return 'Work performed is required';
    if( !Array.isArray(repair.partItems) )
        return 'Parts list is required';
    if( !Number.isInteger(repair.vehicle_id) )
        return 'Vehicle ID is required';
    if( !Number.isInteger(repair.user_id) )
        return 'User ID is required';
    return '';
}

export default DVIRecord;
